import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../layout/Sidebar";
import { useHistory } from "react-router-dom";
import {
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  INITIAL_ROUTE,
} from "../common/RoutePaths";
import API from "../redux/API";
import {
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  FormFeedback,
} from "reactstrap";
import { MdArrowBack } from "react-icons/md";
import { authHeader, getRmID } from "../common/Mainfunctions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { CREATE_CLIENT, CLIENT_LIST } from "../common/urls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EMAIL_REGEX } from "../constants/validation";
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export default function Alerttabs(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  return (
    <div>
      <button onClick={() => setState({ isPaneOpen: true })}>
        Click me to open right pane!
      </button>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isPaneOpen}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <div>And I am pane content. BTW, what rocks?</div>
        <br />
        <img src="img.png" />
      </SlidingPane>
    </div>
  );
}
