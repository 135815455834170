import React, { useState, Fragment, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { SETTINGS_ROUTE, USER_MANAGEMENT_ROUTE } from "../common/RoutePaths";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../images/logo1.png";
import API from "../redux/API";
import { AiOutlineHome, AiOutlineBell, AiFillSetting } from "react-icons/ai";
import { FaUsersCog, FaClipboardList } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import {
  getDisplayName,
  getProfileImage,
  getProfileName,
  getRole,
  logOutFun,
  getRmID,
} from "../common/Mainfunctions";

import {
  CLIENT_NOTIFICATION,
  NOTIFICATION_STATUS,
  PERMISSION,
} from "../common/urls";
import { authHeader, getAlert, getAlertToast } from "../common/Mainfunctions";
import {
  ASSETS_MANAGEMENT_ROUTE,
  CREATE_LIST,
  ON_BOARDING,
  LIST_PORTFOLIO,
} from "../common/RoutePaths";
import { INITIAL_ROUTE } from "../common/RoutePaths";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pusher from "pusher-js";
//import Pusher from "pusher-js/with-encryption";
export default function Sidebar() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [overlayer, setoverlayer] = useState(false);
  const [notilist, setNotiList] = useState([]);
  const [notidot, setNotidot] = useState(0);
  const [notiid, setNotiid] = useState("");
  const [per, setper] = useState("");

  const [cusnotification, setCusnotification] = useState(0);
  const [rowperpage, setRowPerPage] = useState(10);
  const [activeMenu, setActiveItem] = useState("/");
  const toggle = () => setIsOpen(!isOpen);
  const [read, setread] = useState("0");

  const overlay = () => setoverlayer(!overlayer);
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setper(response.data.data);

      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };

  // useEffect(() => {
  //   const pusher = new Pusher("0cb5556987cd209dec5f", {
  //     cluster: "ap1",
  //   });
  //   const channel = pusher.subscribe("my-channel");
  //   channel.bind("my-event", function (data) {
  //     notificationList();
  //   });
  // });

  const logOutClick = () => {
    logOutFun(history);
  };
  const notificationstatus = async (notiId) => {
    const config = {
      method: "GET",
      url: NOTIFICATION_STATUS + notiId,
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      const { status } = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const notificationList = async (search) => {
    console.log("search", search);
    const config = {
      method: "GET",
      url: CLIENT_NOTIFICATION + getRmID(),
      headers: authHeader(),
      params: {
        index: cusnotification,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);

      const { data = [], unread } = response.data;

      setNotiList(data);
      setNotidot(unread);
    } catch (error) {
      console.error(error);
      logOutFun(history);
    }
  };
  useEffect(() => {
    notificationList();
    setInterval(() => notificationList(), 20000);
  }, []);

  useEffect(() => {
    getpermission();
  }, [getRmID]);

  useEffect(() => {
    console.log(getProfileImage(), "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  }, [getProfileImage]);

  useEffect(() => {
    if (read == "1") {
      notificationList();
      notificationstatus();
    }
  }, [read]);

  let location = useLocation();
  useEffect(() => {
    let currentActive = window.location.pathname.split("/")[1] || "/";
    setActiveItem(currentActive);
  }, [location]);
  return (
    <Fragment>
      <SlidingPane
        isOpen={state.isPaneOpen}
        onRequestClose={() => {
          setState({ isPaneOpen: false });
        }}
      >
        <div className="">
          <div className="">
            <ul class="list-group">
              {notilist.map((appPage, index) => {
                return (
                  <li class="list-group-item">
                    <p className="m-0">Message:{appPage.message}</p>
                    <p className="m-0 pt-1">
                      <small>{appPage.created_at}</small>
                      <span
                        class="badge m-0 float-right"
                        onClick={() => {
                          notificationstatus(appPage.id);
                        }}
                      >
                        {(() => {
                          if (appPage.status != 1) {
                            return (
                              <div
                                className="btn-read m-0"
                                onClick={() => {
                                  setread("1");
                                }}
                              >
                                Mark as read
                              </div>
                            );
                          }
                        })()}
                      </span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <br />
      </SlidingPane>
      <div className="">
        <Navbar className="navbar-bg" expand="md">
          <Link to={INITIAL_ROUTE}>
            <NavbarBrand>
              <img src={logo} />
            </NavbarBrand>
          </Link>
          <NavItem className="none-bell text-white">
            <span className="text-white alertCont mt-3" onClick={overlay}>
              {(() => {
                if (notidot >= 4) {
                  return <span className="bobble-mobile"></span>;
                }
              })()}
              <span className="pr-1">
                <svg
                  className="text-white imgmar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-bell-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg>
              </span>
            </span>
          </NavItem>
          <NavbarToggler onClick={toggle}>
            <svg
              className="imgmar"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z" />
            </svg>
          </NavbarToggler>
        </Navbar>
        <ul className="pl-0 side_ul" navbar>
          {per.home == "yes" && (
            <li className="mt-4 ">
              <Link
                to={ON_BOARDING}
                active={activeMenu === "onboarding" ? true : false}
              >
                <span>
                  <AiOutlineHome className="mr-3"></AiOutlineHome>
                </span>
                <span className="menu_none"> Home</span>
              </Link>
            </li>
          )}
          {per.user_management == "yes" && (
            <>
              {getRole() !== "rm" && (
                <li>
                  <Link
                    to={USER_MANAGEMENT_ROUTE}
                    className={activeMenu === "user-management" ? "active" : ""}
                  >
                    <span>
                      <FaUsersCog className="mr-3"></FaUsersCog>
                    </span>
                    <span className="menu_none"> User Management</span>
                  </Link>
                </li>
              )}
            </>
          )}
          {per.client_management == "yes" && (
            <li className="">
              <Link
                to={INITIAL_ROUTE}
                className={activeMenu === "/" ? "active" : ""}
              >
                <span>
                  <FaClipboardList className="mr-3"></FaClipboardList>
                </span>
                <span className="menu_none"> Client Management</span>
              </Link>
            </li>
          )}
          {per.security_list == "yes" && (
            <li>
              <Link
                to={ASSETS_MANAGEMENT_ROUTE}
                className={activeMenu === "assets-management" ? "active" : ""}
              >
                <span>
                  <GoChecklist className="mr-3"></GoChecklist>
                </span>
                <span className="menu_none">Security List</span>
              </Link>
            </li>
          )}
          {per.bond_management == "yes" && (
            <li>
              <Link
                to={CREATE_LIST}
                className={activeMenu === "create-list" ? "active" : ""}
              >
                <span>
                  <GoChecklist className="mr-3"></GoChecklist>
                </span>
                <span className="menu_none">Bond Management</span>
              </Link>
            </li>
          )}

          <li>
            <Link
              to={SETTINGS_ROUTE}
              className={activeMenu === "settings" ? "active" : ""}
            >
              <span>
                <AiFillSetting className="mr-3"></AiFillSetting>
              </span>
              <span className="menu_none">Settings</span>
            </Link>
          </li>
          {per.alerts == "yes" && (
            <li className="mobile-none">
              <Link
                className="alertCont"
                className={activeMenu === "user" ? "active" : ""}
              >
                {(() => {
                  if (notidot >= 1) {
                    return <span className="bobble-mobile"></span>;
                  }
                })()}
                <span>
                  <AiOutlineBell
                    className="mr-3"
                    onClick={() => setState({ isPaneOpen: true })}
                  ></AiOutlineBell>
                </span>
                <span
                  onClick={() => setState({ isPaneOpen: true })}
                  className="menu_none"
                >
                  Alerts
                </span>
              </Link>
            </li>
          )}
        </ul>
        <div>
          <div className="stick_bottom">
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag="a" className="nav-link" caret>
                <img
                  className="avatar"
                  // src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  src={
                    getProfileImage() ||
                    "https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  }
                />{" "}
                <span>{getDisplayName()}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag="a"
                  onClick={logOutClick}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
      {/* 
            <center><p>Sidebar</p>
            <Link to={SIGNIN_ROUTE} >Sign In</Link>
            </center> */}
    </Fragment>
  );
}
