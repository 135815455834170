import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NEW_ASSET_URL } from "../common/urls";
import { authHeader } from "../common/Mainfunctions";
import API from "../redux/API";
import Swal from "sweetalert2";
import { getAlert } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";

export default function AddNewAsset(props) {
  const { modal, toggle, successCallBack } = props;
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    asset_fundname: Yup.string().trim().required("Fund name is required"),
    asset_manager: Yup.string().trim().required("Manager is required"),
    asset_class: Yup.string().trim().required("Asset class is required"),
    asset_strategy: Yup.string().trim().required("Strategy is required"),
    asset_geo_focus: Yup.string()
      .trim()
      .required("Geographical Focus is required"),
    asset_fund_size: Yup.string().trim().required("Fund Size is required"),
    asset_inceptiondate: Yup.string()
      .trim()
      .required("Inception Date  is required"),
    asset_currency: Yup.string().trim().required("Currency  is required"),
    asset_status: Yup.string().trim().required("Status required"),
    asset_auditor: Yup.string().trim().required("Auditor is  required"),
    asset_custodian: Yup.string().trim().required("Custodian is  required"),
    asset_administrator: Yup.string()
      .trim()
      .required("Administrator is  required"),
    asset_noticeperiod: Yup.string().trim().required("Notice period required"),
    asset_lockupperiod: Yup.string().trim().required("Lockup period required"),
    asset_management_fee: Yup.string()
      .trim()
      .required("Management Fee required"),
    asset_minimum_investment: Yup.string()
      .trim()
      .required("Minimum Investment required"),
    asset_performance_fee: Yup.string()
      .trim()
      .required("Performance Fee required"),
    asset_expected_returns: Yup.string()
      .trim()
      .required("Expected Return required"),

    max_weightage: Yup.string().trim().required("Expected Max Weightage"),

    cash_flow_yield: Yup.string().trim().required("Expected Cash Flow Yield"),

    asset_redemptionperiod: Yup.string()
      .trim()
      .required("Redemption Period required"),
  });
  const getAl = (type = "success", text = "", timer = 5000) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    showConfirmButton: false,
    timer: timer,
  });

  const saveFormValues = async (values) => {
    setLoading(true);
    const config = {
      method: "POST",
      url: NEW_ASSET_URL,
      headers: authHeader(),
      data: values,
    };

    try {
      const response = await API(config);
      const { status } = response.data;

      if (status === "Success") {
        Swal.fire(getAl("success", "Asset added Successfully"));
        successCallBack();
      }
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
      setLoading(false);
    }
  };

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      asset_fundname: "",
      asset_manager: "",
      asset_class: "",
      asset_strategy: "",
      asset_geo_focus: "",
      asset_fund_size: "",
      asset_inceptiondate: "",
      asset_currency: "",
      asset_status: "",
      asset_auditor: "",
      asset_custodian: "",
      asset_administrator: "",
      asset_noticeperiod: "",
      asset_lockupperiod: "",
      asset_management_fee: "",
      asset_minimum_investment: "",
      asset_performance_fee: "",
      asset_expected_returns: "",
      max_weightage: "",
      cash_flow_yield: "",
      asset_redemptionperiod: "",
    },
    validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  return (
    <Modal isOpen={modal} toggle={toggle} className="">
      <Form form="true" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>Add New Assets</ModalHeader>
        <ModalBody className="p-3">
          <FormGroup>
            <Label for="asset_fundname">Fund Name</Label>
            <Input
              type="text"
              name="asset_fundname"
              id="asset_fundname"
              placeholder="Enter Fund Name"
              invalid={
                errors.asset_fundname && touched.asset_fundname ? true : false
              }
              onChange={handleChange}
              value={values.asset_fundname}
            />
            <FormFeedback>
              {errors.asset_fundname && touched.asset_fundname
                ? errors.asset_fundname
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_manager">Manager</Label>
            <Input
              type="text"
              name="asset_manager"
              id="asset_manager"
              invalid={
                errors.asset_manager && touched.asset_manager ? true : false
              }
              onChange={handleChange}
              value={values.asset_manager}
              placeholder="Enter Manager"
            />
            <FormFeedback>
              {errors.asset_manager && touched.asset_manager
                ? errors.asset_manager
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="">Asset Class</Label>
            <Input
              type="text"
              name="asset_class"
              id="asset_class"
              placeholder="Enter Asset Class"
              invalid={errors.asset_class && touched.asset_class ? true : false}
              onChange={handleChange}
              value={values.asset_class}
            />
            <FormFeedback>
              {errors.asset_class && touched.asset_class
                ? errors.asset_class
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_strategy">Strategy</Label>
            <Input
              type="text"
              name="asset_strategy"
              id="asset_strategy"
              placeholder="Enter Strategy"
              invalid={
                errors.asset_strategy && touched.asset_strategy ? true : false
              }
              onChange={handleChange}
              value={values.asset_strategy}
            />
            <FormFeedback>
              {errors.asset_strategy && touched.asset_strategy
                ? errors.asset_strategy
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_geo_focus">Geographical Focus</Label>
            <Input
              type="text"
              name="asset_geo_focus"
              id="asset_geo_focus"
              placeholder="Enter Geographical Focus"
              invalid={
                errors.asset_geo_focus && touched.asset_geo_focus ? true : false
              }
              onChange={handleChange}
              value={values.asset_geo_focus}
            />
            <FormFeedback>
              {errors.asset_geo_focus && touched.asset_geo_focus
                ? errors.asset_geo_focus
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="">Fund Size</Label>
            <Input
              type="text"
              name="asset_fund_size"
              id="asset_fund_size"
              placeholder="Enter Fund Size"
              invalid={
                errors.asset_fund_size && touched.asset_fund_size ? true : false
              }
              onChange={handleChange}
              value={values.asset_fund_size}
            />
            <FormFeedback>
              {errors.asset_fund_size && touched.asset_fund_size
                ? errors.asset_fund_size
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_inceptiondate">Inception Date</Label>
            <Input
              type="date"
              name="asset_inceptiondate"
              id="asset_inceptiondate"
              placeholder="Enter Inception Date"
              invalid={
                errors.asset_inceptiondate && touched.asset_inceptiondate
                  ? true
                  : false
              }
              onChange={handleChange}
              value={values.asset_inceptiondate}
            />

            <FormFeedback>
              {errors.asset_inceptiondate && touched.asset_inceptiondate
                ? errors.asset_inceptiondate
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_currency">Currency</Label>
            <Input
              type="text"
              name="asset_currency"
              id="asset_currency"
              placeholder="Enter Currency"
              invalid={
                errors.asset_currency && touched.asset_currency ? true : false
              }
              onChange={handleChange}
              value={values.asset_currency}
            />
            <FormFeedback>
              {errors.asset_currency && touched.asset_currency
                ? errors.asset_currency
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_status">Status</Label>
            <Input
              type="text"
              name="asset_status"
              id="asset_status"
              placeholder="Enter Status"
              invalid={
                errors.asset_status && touched.asset_status ? true : false
              }
              onChange={handleChange}
              value={values.asset_status}
            />
            <FormFeedback>
              {errors.asset_status && touched.asset_status
                ? errors.asset_status
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_auditor">Auditor</Label>
            <Input
              type="text"
              name="asset_auditor"
              id="asset_auditor"
              placeholder="Enter Auditor"
              invalid={
                errors.asset_auditor && touched.asset_auditor ? true : false
              }
              onChange={handleChange}
              value={values.asset_auditor}
            />
            <FormFeedback>
              {errors.asset_auditor && touched.asset_auditor
                ? errors.asset_auditor
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_custodian">Custodian</Label>
            <Input
              type="text"
              name="asset_custodian"
              id="asset_custodian"
              placeholder="Enter Custodian"
              invalid={
                errors.asset_custodian && touched.asset_custodian ? true : false
              }
              onChange={handleChange}
              value={values.asset_custodian}
            />
            <FormFeedback>
              {errors.asset_custodian && touched.asset_custodian
                ? errors.asset_custodian
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="administrator">Administrator</Label>
            <Input
              type="text"
              name="asset_administrator"
              id="asset_administrator"
              placeholder="Enter Administrator"
              value={values.asset_administrator}
              onChange={handleChange}
              invalid={
                errors.asset_administrator && touched.asset_administrator
                  ? true
                  : false
              }
            />
            <FormFeedback>
              {errors.asset_administrator && touched.asset_administrator
                ? errors.asset_administrator
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_noticeperiod">Notice Period</Label>
            <Input
              type="text"
              name="asset_noticeperiod"
              id="asset_noticeperiod"
              placeholder="Enter Notice Period"
              invalid={
                errors.asset_noticeperiod && touched.asset_noticeperiod
                  ? true
                  : false
              }
              value={values.asset_noticeperiod}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_noticeperiod && touched.asset_noticeperiod
                ? errors.asset_noticeperiod
                : ""}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="asset_lockupperiod">Lockup Period</Label>
            <Input
              type="text"
              name="asset_lockupperiod"
              id="asset_lockupperiod"
              placeholder="Enter Lockup Period"
              invalid={
                errors.asset_lockupperiod && touched.asset_lockupperiod
                  ? true
                  : false
              }
              value={values.asset_lockupperiod}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_lockupperiod && touched.asset_lockupperiod
                ? errors.asset_lockupperiod
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_management_fee">Management Fee</Label>
            <Input
              type="text"
              name="asset_management_fee"
              id="asset_management_fee"
              placeholder="Enter Management Fee"
              invalid={
                errors.asset_management_fee && touched.asset_management_fee
                  ? true
                  : false
              }
              value={values.asset_management_fee}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_management_fee && touched.asset_management_fee
                ? errors.asset_management_fee
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_minimum_investment">Minimum Investment</Label>
            <Input
              type="text"
              name="asset_minimum_investment"
              id="asset_minimum_investment"
              placeholder="Enter Minimum Investment"
              invalid={
                errors.asset_minimum_investment &&
                touched.asset_minimum_investment
                  ? true
                  : false
              }
              value={values.asset_minimum_investment}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_minimum_investment &&
              touched.asset_minimum_investment
                ? errors.asset_minimum_investment
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_performance_fee">Performance Fee</Label>
            <Input
              type="text"
              name="asset_performance_fee"
              id="asset_performance_fee"
              placeholder="Enter Performance Fee"
              invalid={
                errors.asset_performance_fee && touched.asset_performance_fee
                  ? true
                  : false
              }
              value={values.asset_performance_fee}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_performance_fee && touched.asset_performance_fee
                ? errors.asset_performance_fee
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_expected_returns">Expected Returns</Label>
            <Input
              type="text"
              name="asset_expected_returns"
              id="asset_expected_returns"
              placeholder="Enter Expected Returns"
              invalid={
                errors.asset_expected_returns && touched.asset_expected_returns
                  ? true
                  : false
              }
              value={values.asset_expected_returns}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_expected_returns && touched.asset_expected_returns
                ? errors.asset_expected_returns
                : ""}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="max_weightage">Max Weightage</Label>
            <Input
              type="text"
              name="max_weightage"
              id="max_weightage"
              placeholder="Enter Maximum Weightage"
              invalid={
                errors.max_weightage && touched.max_weightage ? true : false
              }
              value={values.max_weightage}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.max_weightage && touched.max_weightage
                ? errors.max_weightage
                : ""}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="asset_industry_sector">Asset Industry Sector</Label>
            <Input
              type="text"
              name="asset_industry_sector"
              id="asset_industry_sector"
              placeholder="Enter Asset Industry Sector"
              invalid={
                errors.asset_industry_sector && touched.asset_industry_sector
                  ? true
                  : false
              }
              value={values.asset_industry_sector}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_industry_sector && touched.asset_industry_sector
                ? errors.asset_industry_sector
                : ""}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="asset_upside_potential">Asset Upside Potential</Label>
            <Input
              type="text"
              name="asset_upside_potential"
              id="asset_upside_potential"
              placeholder="Enter Asset Upside Potential"
              invalid={
                errors.asset_upside_potential && touched.asset_upside_potential
                  ? true
                  : false
              }
              value={values.asset_upside_potential}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_upside_potential && touched.asset_upside_potential
                ? errors.asset_upside_potential
                : ""}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="cash_flow_yield">Cash Flow Yield</Label>
            <Input
              type="text"
              name="cash_flow_yield"
              id="cash_flow_yield"
              placeholder="Enter Cash Flow Yield"
              invalid={
                errors.cash_flow_yield && touched.cash_flow_yield ? true : false
              }
              value={values.cash_flow_yield}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.cash_flow_yield && touched.cash_flow_yield
                ? errors.cash_flow_yield
                : ""}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="asset_redemptionperiod">Redemption Period</Label>
            <Input
              type="text"
              name="asset_redemptionperiod"
              id="asset_redemptionperiod"
              placeholder="Enter Redemption Period"
              invalid={
                errors.asset_redemptionperiod && touched.asset_redemptionperiod
                  ? true
                  : false
              }
              value={values.asset_redemptionperiod}
              onChange={handleChange}
            />
            <FormFeedback>
              {errors.asset_redemptionperiod && touched.asset_redemptionperiod
                ? errors.asset_redemptionperiod
                : ""}
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading}>
            {loading && <CustomLoader loading={loading ? true : false} />}
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
