import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BENCHMARK_ADD_URL, BENCHMARK_FULL_LIST_URL } from "../common/urls";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getDisplayName,
} from "../common/Mainfunctions";
import API from "../redux/API";
import CustomLoader from "../common/CustomLoader";
import Swal from "sweetalert2";
export default function BenchMarks(props) {
  const [loading, setLoading] = useState(false);
  const { formData } = props;

  const [list, setList] = useState([]);

  const validationSchema = Yup.object({
    benchmark1: Yup.string().trim().required("Please Select Benchmark "),
    benchmark2: Yup.string()
      .trim()
      .required("Please Select Benchmark")
      .test("benchmatch-match", "Benchmarks can not be same", function (value) {
        return this.parent.benchmark1 !== value;
      }),
  });
  const saveFormValues = async (values) => {
    setLoading(true);
    const config = {
      method: "POST",
      url: BENCHMARK_ADD_URL,
      headers: authHeader(),
      data: values,
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status == "Success") {
        Swal.fire(getAlertToast("Success", "Benchmark Updated Successfully"));
      } else {
        Swal.fire(getAlert("Error", "Failed to update"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: formData,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      saveFormValues(values);
    },
  });
  const benchmarklisting = async () => {
    const config = {
      method: "GET",
      url: BENCHMARK_FULL_LIST_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
      // let option = [];
      // data.map((element) => {
      //   option.push({ ...option, label: element.value, value: element.id });
      // });
      setList(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    benchmarklisting();
  }, []);
  return (
    <Fragment>
      <Form form="true" onSubmit={handleSubmit}>
        <Row>
          <Col md="12" md={{ size: 6, offset: 3 }}>
            <FormGroup>
              <Label for="benchmark1">Benchmarks 1</Label>
              <Input
                type="select"
                name="benchmark1"
                id="benchmark1"
                value={values.benchmark1}
                onChange={handleChange}
                invalid={errors.benchmark1 && touched.benchmark1 ? true : false}
              >
                <option headers>Please select Benchmark</option>

                {list &&
                  list.map((record) => {
                    return (
                      <option key={record.id} value={record.value}>
                        {record.value}
                      </option>
                    );
                  })}
              </Input>
              <FormFeedback>
                {errors.benchmark1 && touched.benchmark1
                  ? errors.benchmark1
                  : ""}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="benchmark2">Benchmarks 2</Label>
              <Input
                type="select"
                name="benchmark2"
                id="benchmark2"
                value={values.benchmark2}
                onChange={handleChange}
                invalid={errors.benchmark2 && touched.benchmark2 ? true : false}
              >
                <option headers>Please select Benchmark</option>
                {list &&
                  list.map((record) => {
                    return (
                      <option key={`record_${record.id}`} value={record.value}>
                        {record.value}
                      </option>
                    );
                  })}
              </Input>
              <FormFeedback>
                {errors.benchmark2 && touched.benchmark2
                  ? errors.benchmark2
                  : ""}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Button type="submit" color="primary" className="d-block m-auto">
          Update
          {loading && <CustomLoader loading={loading} />}
        </Button>
      </Form>
    </Fragment>
  );
}
