import React from "react";
export default 
 function CustomTableHeader({ tableColumns ,tableColumnsstock}) {
  return (
    <thead style={{ backgroundColor: "" }}>
      <tr>
        {tableColumns?.map(({ title }, key) => (
          <th key={`head_list_${key}`}>{title}</th>
        ))}
      </tr> 
      <tr>
        {tableColumnsstock?.map(({ title }, key) => (
          <th key={`head_list_${key}`}>{title}</th>
        ))}
      </tr>
    </thead>
    
  );


}

// function CustomTableHeader({  }) {
//   return (
//     <thead style={{ backgroundColor: "" }}>
//       <tr>
//         {tableColumnsstock.map(({ title }, key) => (
//           <th key={`head_list_${key}`}>{title}</th>
//         ))}
//       </tr>
      
//     </thead>
//   );


// }