import React, { Fragment, useState, useEffect } from "react";
import Sidebar from "../layout/Sidebar";
import {
  Container,
  Card,
  CardTitle,
  Button,
  Row,
  Col,
  CardBody,
  Form,
  Collapse,
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { UncontrolledTooltip } from "reactstrap";

import { Prompt, useHistory, useParams } from "react-router-dom";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";
import {
  EXPOSURE_LIST,
  PORTFOLIO_CREATE,
  PORTFOLIO_EDIT_URL,
  PORTFOLIO_UPDATE_URL,
  PERMISSION,
} from "../common/urls";
import {
  PORTFOLIO_INPUTS_EDIT_ROUTE,
  PORTFOLIO_INPUTS_CREATE_ROUTE,
} from "../common/RoutePaths";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getSelectedClientID,
  getRmID,
} from "../common/Mainfunctions";
import Swal from "sweetalert2";
import API from "../redux/API";
import AssetInputs from "./AssetInputs";
import OptimisationInputs from "./OptimisationInputs";
import AssetLevel from "./OptimisationConstraints/AssetLevel";
import Asset from "./OptimisationConstraints/Asset";
import Regional from "./OptimisationConstraints/Regional";
import RatingAndProductConstraints from "./OptimisationConstraints/RatingAndProductConstraints";
import FixedIncomeSectorConstraints from "./OptimisationConstraints/FixedIncomeSectorConstraints";
import CurrencyConstrainsts from "./OptimisationConstraints/CurrencyConstraints";
import PortfolioSummary from "./PortfolioSummary";
import EquitiesSectorConstraints from "./OptimisationConstraints/EquitiesSectorConstraints";
import SelectClient from "../client/selectClient";
import { EMAIL_REGEX, FLOAT_VALUE_REGEX } from "../constants/validation";
import { any } from "underscore";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function PortfolioInputs(props) {
  let Flag;
  let constraintsFlag;
  let update_type;
  if (props.location.state) {
    let { leverageFlag, constraintFlag } = props.location.state;
    Flag = leverageFlag;
    constraintsFlag = constraintFlag;

    update_type = constraintsFlag ? "change_constraint" : "modify_leverage";
  }
  const [modal, setModal] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  // Toggle for Modal
  const toggless = () => setModal(!modal);

  const history = useHistory();
  const params = useParams();
  const [toggleId, setToggleId] = useState(1);
  const [portfolioId, setPortfolioId] = useState("");
  const [permission, setpermission] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [regValues, setRegValues] = useState([]);
  const [exposureList, setExposureList] = useState({});
  const [selectClientModal, setSelectClientModal] = useState(false);
  const selectClientToggle = () => setSelectClientModal(!selectClientModal);
  const [porfolioI, setPortfolioI] = useState(params.id);
  const [isAssetOpen, setIsAssetOpen] = useState(false);
  const [err, seterr] = useState("");
  const [isAsset, setIsAsset] = useState(false);
  const [assetValues, setAssetValues] = useState([]);
  const [formIsHalfFilledOut, setFilled] = useState(true);
  const [value, setValue] = React.useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({
    portfolio_name: "",
    portfolio_currency: "",
    portfolio_clientequity: "",
    portfolio_costofborrowing: 0,
    portfolio_costofborrowing_benchmark: "",
    portfolio_core_percentage: "",
    portfolio_core_dollar: "",
    portfolio_leverage: 0,
    portfolio_shariahcomplaint: false,
    portfolio_coreunit: "$",
    portfolio_expect_annualreturn: 3,
    portfolio_expect_maxdrawdown: 10,
    // portfolio_cashflow_yield: "",
    portfolio_liquidity: "",
    portfolio_cash_min: "",
    portfolio_cash_max: "",
    portfolio_equities_min: "",
    portfolio_equities_max: "",
    portfolio_equities_directholding_min: "",
    portfolio_equities_directholding_max: "",
    portfolio_fixedincome_min: "",
    portfolio_fixedincome_max: "",
    portfolio_fixedincome_directholding_min: "",
    portfolio_fixedincome_directholding_max: "",
    portfolio_alternative_min: "",
    portfolio_alternative_max: "",
    portfolio_alternative_directholding_min: "",
    portfolio_alternative_directholding_max: "",
    portfolio_cash_status: false,
    portfolio_alternative_status: false,
    portfolio_equities_status: false,
    portfolio_fixedincome_status: false,
    portfolio_exposure_status: false,
    portfolio_exposure_value: "",
    portfolio_exposure_min: "",
    portfolio_exposure_max: "",
    portfolio_exposure2_status: false,
    portfolio_exposure2_value: "",
    portfolio_exposure2_min: "",
    portfolio_exposure2_max: "",
    portfolio_financialscoco_status: false,
    portfolio_financialscoco_min: "",
    portfolio_financialscoco_max: "",
    portfolio_credit_status: false,
    portfolio_credit_min: "",
    portfolio_credit_max: "",
    portfolio_viceindustries_status: false,
    portfolio_viceindustries_min: "",
    portfolio_viceindustries_max: "",
    portfolio_realestate_status: false,
    portfolio_realestate_min: "",
    portfolio_realestate_max: "",
    portfolio_it_status: false,
    portfolio_it_min: "",
    portfolio_it_max: "",
    portfolio_healthcare_status: false,
    portfolio_healthcare_min: "",
    portfolio_healthcare_max: "",
    portfolio_financials_status: false,
    portfolio_financials_min: "",
    portfolio_financials_max: "",
    portfolio_discretionary_status: false,
    portfolio_discretionary_min: "",
    portfolio_discretionary_max: "",
    portfolio_comservice_status: false,
    portfolio_comservice_min: "",
    portfolio_comservice_max: "",
    portfolio_industrials_status: false,
    portfolio_industrials_min: "",
    portfolio_industrials_max: "",
    portfolio_staples_status: false,
    portfolio_staples_min: "",
    portfolio_staples_max: "",
    portfolio_energy_status: false,
    portfolio_energy_min: "",
    portfolio_energy_max: "",
    portfolio_utilities_status: false,
    portfolio_utilities_min: "",
    portfolio_utilities_max: "",
    portfolio_materials_status: false,
    portfolio_materials_min: "",
    portfolio_materials_max: "",
    portfolio_usd_status: false,
    portfolio_eur_status: false,
    portfolio_gbp_status: false,
    portfolio_jpy_status: false,
    portfolio_aud_status: false,
    portfolio_nzd_status: false,
    portfolio_cad_status: false,
    portfolio_chf_status: false,
    portfolio_nok_status: false,
    portfolio_sek_status: false,
    portfolio_usd_max: "",
    portfolio_usd_min: "",
    portfolio_eur_min: "",
    portfolio_eur_max: "",
    portfolio_gbp_min: "",
    portfolio_gbp_max: "",
    portfolio_jpy_min: "",
    portfolio_jpy_max: "",
    portfolio_aud_min: "",
    portfolio_aud_max: "",
    portfolio_nzd_max: "",
    portfolio_nzd_min: "",
    portfolio_cad_min: "",
    portfolio_cad_max: "",
    portfolio_chf_min: "",
    portfolio_chf_max: "",
    portfolio_nok_min: "",
    portfolio_nok_max: "",
    portfolio_sek_min: "",
    portfolio_sek_max: "",
    portfolio_esc_viceindustries_status: false,
    portfolio_esc_viceindustries_min: "",
    portfolio_esc_viceindustries_max: "",
    portfolio_esc_realestate_status: false,
    portfolio_esc_it_status: false,
    portfolio_esc_it_min: "",
    portfolio_esc_it_max: "",
    portfolio_esc_healthcare_status: false,
    portfolio_esc_healthcare_min: "",
    portfolio_esc_healthcare_max: "",
    portfolio_esc_financials_status: false,
    portfolio_esc_financials_min: "",
    portfolio_esc_financials_max: "",
    portfolio_esc_discretionary_status: false,
    portfolio_esc_discretionary_min: "",
    portfolio_esc_discretionary_max: "",
    portfolio_esc_comservice_status: false,
    portfolio_esc_comservice_min: "",
    portfolio_esc_comservice_max: "",
    portfolio_esc_industrials_status: false,
    portfolio_esc_industrials_min: "",
    portfolio_esc_industrials_max: "",
    portfolio_esc_staples_status: false,
    portfolio_esc_staples_min: "",
    portfolio_esc_staples_max: "",
    portfolio_esc_energy_status: false,
    portfolio_esc_energy_min: "",
    portfolio_esc_energy_max: "",
    portfolio_esc_utilities_status: false,
    portfolio_esc_utilities_min: "",
    portfolio_esc_utilities_max: "",
    portfolio_esc_materials_status: false,
    portfolio_esc_materials_min: "",
    portfolio_esc_materials_max: "",
    update_type: update_type,
    portfolio_regional: [],
    //cash_flow_threshold:[],
  });

  const toggle = (id) => {
    if (id === toggleId) {
      setToggleId(0);
    } else {
      setToggleId(id);
    }
  };
  const assetToggles = () => {
    setIsAsset(!isAsset);
  };

  const assetToggle = () => {
    setIsAssetOpen(!isAssetOpen);
  };

  const getExposureList = async () => {
    // setLoading(true);
    const config = {
      method: "GET",
      url: EXPOSURE_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
      setExposureList(data);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const getPortfolioDetails = async (id) => {
    Swal.fire(getAlertToast("success", "Please wait..."));
    setDataLoading(true);
    const config = {
      method: "GET",
      url: PORTFOLIO_EDIT_URL + "/" + id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;

      if (data.portfolio_coreunit == "$") {
        data.portfolio_core_percentage = data.portfolio_core_dollar;
      }
      if (data.portfolio_shariahcomplaint === 1) {
        data.portfolio_shariahcomplaint = true;
      } else {
        data.portfolio_shariahcomplaint = false;
      }

      if (data.portfolio_cash_status === 1) {
        data.portfolio_cash_status = true;
      } else {
        data.portfolio_cash_status = false;
      }
      if (data.portfolio_fixedincome_status === 1) {
        data.portfolio_fixedincome_status = true;
      } else {
        data.portfolio_fixedincome_status = false;
      }
      if (data.portfolio_equities_status === 1) {
        data.portfolio_equities_status = true;
      } else {
        data.portfolio_equities_status = false;
      }
      if (data.portfolio_alternative_status === 1) {
        data.portfolio_alternative_status = true;
      } else {
        data.portfolio_alternative_status = false;
      }
      if (data.portfolio_financialscoco_status === 1) {
        data.portfolio_financialscoco_status = true;
      } else {
        data.portfolio_financialscoco_status = false;
      }
      if (data.portfolio_credit_status === 1) {
        data.portfolio_credit_status = true;
      } else {
        data.portfolio_credit_status = false;
      }
      if (data.portfolio_realestate_status === 1) {
        data.portfolio_realestate_status = true;
      } else {
        data.portfolio_realestate_status = false;
      }
      if (data.portfolio_it_status === 1) {
        data.portfolio_it_status = true;
      } else {
        data.portfolio_it_status = false;
      }
      if (data.portfolio_healthcare_status === 1) {
        data.portfolio_healthcare_status = true;
      } else {
        data.portfolio_healthcare_status = false;
      }
      if (data.portfolio_financials_status === 1) {
        data.portfolio_financials_status = true;
      } else {
        data.portfolio_financials_status = false;
      }
      if (data.portfolio_discretionary_status === 1) {
        data.portfolio_discretionary_status = true;
      } else {
        data.portfolio_discretioary_status = false;
      }
      if (data.portfolio_industrials_status === 1) {
        data.portfolio_industrials_status = true;
      } else {
        data.portfolio_industrials_status = false;
      }
      if (data.portfolio_staples_status === 1) {
        data.portfolio_staples_status = true;
      } else {
        data.portfolio_staples_status = false;
      }
      if (data.portfolio_energy_status === 1) {
        data.portfolio_energy_status = true;
      } else {
        data.portfolio_energy_status = false;
      }
      if (data.portfolio_utilities_status === 1) {
        data.portfolio_utilities_status = true;
      } else {
        data.portfolio_utilities_status = false;
      }
      if (data.portfolio_materials_status === 1) {
        data.portfolio_materials_status = true;
      } else {
        data.portfolio_materials_status = false;
      }
      if (data.portfolio_comservice_status === 1) {
        data.portfolio_comservice_status = true;
      } else {
        data.portfolio_comservice_status = false;
      }
      if (data.portfolio_esc_realestate_status === 1) {
        data.portfolio_esc_realestate_status = true;
      } else {
        data.portfolio_esc_realestate_status = false;
      }
      if (data.portfolio_esc_it_status === 1) {
        data.portfolio_esc_it_status = true;
      } else {
        data.portfolio_esc_it_status = false;
      }
      if (data.portfolio_esc_healthcare_status === 1) {
        data.portfolio_esc_healthcare_status = true;
      } else {
        data.portfolio_esc_healthcare_status = false;
      }
      if (data.portfolio_esc_financials_status === 1) {
        data.portfolio_esc_financials_status = true;
      } else {
        data.portfolio_esc_financials_status = false;
      }
      if (data.portfolio_esc_discretionary_status === 1) {
        data.portfolio_esc_discretionary_status = true;
      } else {
        data.portfolio_esc_discretioary_status = false;
      }
      if (data.portfolio_esc_industrials_status === 1) {
        data.portfolio_esc_industrials_status = true;
      } else {
        data.portfolio_esc_industrials_status = false;
      }
      if (data.portfolio_esc_staples_status === 1) {
        data.portfolio_esc_staples_status = true;
      } else {
        data.portfolio_esc_staples_status = false;
      }
      if (data.portfolio_esc_energy_status === 1) {
        data.portfolio_esc_energy_status = true;
      } else {
        data.portfolio_esc_energy_status = false;
      }
      if (data.portfolio_esc_utilities_status === 1) {
        data.portfolio_esc_utilities_status = true;
      } else {
        data.portfolio_esc_utilities_status = false;
      }
      if (data.portfolio_esc_materials_status === 1) {
        data.portfolio_esc_materials_status = true;
      } else {
        data.portfolio_esc_materials_status = false;
      }
      if (data.portfolio_esc_comservice_status === 1) {
        data.portfolio_esc_comservice_status = true;
      } else {
        data.portfolio_esc_comservice_status = false;
      }
      if (data.portfolio_usd_status === 1) {
        data.portfolio_usd_status = true;
      } else {
        data.portfolio_usd_status = false;
      }
      if (data.portfolio_eur_status === 1) {
        data.portfolio_eur_status = true;
      } else {
        data.portfolio_eur_status = false;
      }
      if (data.portfolio_gbp_status === 1) {
        data.portfolio_gbp_status = true;
      } else {
        data.portfolio_gbp_status = false;
      }
      if (data.portfolio_jpy_status === 1) {
        data.portfolio_jpy_status = true;
      } else {
        data.portfolio_jpy_status = false;
      }
      if (data.portfolio_aud_status === 1) {
        data.portfolio_aud_status = true;
      } else {
        data.portfolio_aud_status = false;
      }
      if (data.portfolio_nzd_status === 1) {
        data.portfolio_nzd_status = true;
      } else {
        data.portfolio_nzd_status = false;
      }
      if (data.portfolio_cad_status === 1) {
        data.portfolio_cad_status = true;
      } else {
        data.portfolio_cad_status = false;
      }
      if (data.portfolio_chf_status === 1) {
        data.portfolio_chf_status = true;
      } else {
        data.portfolio_chf_status = false;
      }
      if (data.portfolio_nok_status === 1) {
        data.portfolio_nok_status = true;
      } else {
        data.portfolio_nok_status = false;
      }
      if (data.portfolio_sek_status === 1) {
        data.portfolio_sek_status = true;
      } else {
        data.portfolio_sek_status = false;
      }
      data.portfolio_RMuser = data.portfolio_user;
      setFormData({ ...data });
      setRegValues(data.portfolio_regional);
      localStorage.setItem("SELECTED_CLIENT_ID", data.portfolio_user);
      console.log(data.SelectUser, "SelectUserSelectUserSelectUserSelectUser");
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);
  useEffect(() => {
    const { id = "" } = params;
    if (id !== "") {
      setPortfolioId(id);
      getPortfolioDetails(id);
    }
    getExposureList();
  }, []);
  const leverageCheck = () => {
    // let modify = false;

    if (portfolioId && constraintsFlag) {
      return true;
    }
  };

  let validationSchema = Yup.object({
    portfolio_name: Yup.string()
      .trim()
      .required("Prtfolio name required")
      .max(15, "Max limit is 15"),

    portfolio_currency: Yup.string().trim().required("Currency required"),
    portfolio_clientequity: Yup.string()
      .trim()
      .required("Client equity required"),
    // portfolio_RMuser: Yup.string().trim().required("RM Us er  required"),
    portfolio_core_percentage: Yup.string().trim().required("Core required"),
    // .matches(FLOAT_VALUE_REGEX, "Please give valid core"),
    // isConstraints: leverageCheck(),

    // portfolio_leverage: Yup.string()
    //   .test(constraintsFlag == true)
    //   .notrequired(),
    // when("portfolioId", {
    //   is: leverageCheck() === false,
    //   then: Yup.string().notRequired(),
    //   otherwise: Yup.string().trim().required("Leverage required").nullable(),
    // }),

    // portfolio_cashflow_yield: Yup.string().trim().required(" required"),
    portfolio_liquidity: Yup.string().trim().required("Liquidity required"),
    portfolio_cash_min: Yup.string().test(
      "portfolio_cash_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_cash_status } = this.parent;
        return portfolio_cash_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_cash_max: Yup.string().test(
      "portfolio_cash_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_cash_min, portfolio_cash_status } = this.parent;
        return portfolio_cash_status
          ? typeof portfolio_cash_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_cash_min)
            : true
          : true;
      }
    ),
    portfolio_equities_min: Yup.string().test(
      "portfolio_equities_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_equities_status } = this.parent;
        return portfolio_equities_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_equities_max: Yup.string().test(
      "portfolio_equities_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_equities_min, portfolio_equities_status } =
          this.parent;
        return portfolio_equities_status
          ? typeof portfolio_equities_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_equities_min)
            : true
          : true;
      }
    ),
    portfolio_fixedincome_min: Yup.string().test(
      "portfolio_fixedincome_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_fixedincome_status } = this.parent;
        return portfolio_fixedincome_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_fixedincome_max: Yup.string().test(
      "portfolio_fixedincome_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_fixedincome_min, portfolio_fixedincome_status } =
          this.parent;
        return portfolio_fixedincome_status
          ? typeof portfolio_fixedincome_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_fixedincome_min)
            : true
          : true;
      }
    ),
    portfolio_alternative_min: Yup.string().test(
      "portfolio_alternative_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_alternative_status } = this.parent;
        return portfolio_alternative_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_alternative_max: Yup.string().test(
      "portfolio_alternative_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_alternative_min, portfolio_alternative_status } =
          this.parent;
        return portfolio_alternative_status
          ? typeof portfolio_alternative_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_alternative_min)
            : true
          : true;
      }
    ),
    portfolio_realestate_min: Yup.string().test(
      "portfolio_realestate_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_realestate_status } = this.parent;
        return portfolio_realestate_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_realestate_max: Yup.string().test(
      "portfolio_realestate_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_realestate_min, portfolio_realestate_status } =
          this.parent;
        return portfolio_realestate_status
          ? typeof portfolio_realestate_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_realestate_min)
            : true
          : true;
      }
    ),
    portfolio_it_min: Yup.string().test(
      "portfolio_it_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_it_status } = this.parent;
        return portfolio_it_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_it_max: Yup.string().test(
      "portfolio_it_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_it_min, portfolio_it_status } = this.parent;
        return portfolio_it_status
          ? typeof portfolio_it_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_it_min)
            : true
          : true;
      }
    ),
    portfolio_healthcare_min: Yup.string().test(
      "portfolio_healthcare_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_healthcare_status } = this.parent;
        return portfolio_healthcare_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_healthcare_max: Yup.string().test(
      "portfolio_healthcare_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_healthcare_min, portfolio_healthcare_status } =
          this.parent;
        return portfolio_healthcare_status
          ? typeof portfolio_healthcare_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_healthcare_min)
            : true
          : true;
      }
    ),
    portfolio_financials_min: Yup.string().test(
      "portfolio_financials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_financials_status } = this.parent;
        return portfolio_financials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_financials_max: Yup.string().test(
      "portfolio_financials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_financials_min, portfolio_financials_status } =
          this.parent;
        return portfolio_financials_status
          ? typeof portfolio_financials_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_financials_min)
            : true
          : true;
      }
    ),
    portfolio_discretionary_min: Yup.string().test(
      "portfolio_discretionary_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_discretionary_status } = this.parent;
        return portfolio_discretionary_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_discretionary_max: Yup.string().test(
      "portfolio_discretionary_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_discretionary_min, portfolio_discretionary_status } =
          this.parent;
        return portfolio_discretionary_status
          ? typeof portfolio_discretionary_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_discretionary_min)
            : true
          : true;
      }
    ),
    portfolio_comservice_min: Yup.string().test(
      "portfolio_comservice_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_comservice_status } = this.parent;
        return portfolio_comservice_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_comservice_max: Yup.string().test(
      "portfolio_comservice_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_comservice_min, portfolio_comservice_status } =
          this.parent;
        return portfolio_comservice_status
          ? typeof portfolio_comservice_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_comservice_min)
            : true
          : true;
      }
    ),
    portfolio_industrials_min: Yup.string().test(
      "portfolio_industrials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_industrials_status } = this.parent;
        return portfolio_industrials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_industrials_max: Yup.string().test(
      "portfolio_industrials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_industrials_min, portfolio_industrials_status } =
          this.parent;
        return portfolio_industrials_status
          ? typeof portfolio_industrials_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_industrials_min)
            : true
          : true;
      }
    ),
    portfolio_staples_min: Yup.string().test(
      "portfolio_staples_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_staples_status } = this.parent;
        return portfolio_staples_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_staples_max: Yup.string().test(
      "portfolio_staples_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_staples_min, portfolio_staples_status } = this.parent;
        return portfolio_staples_status
          ? typeof portfolio_staples_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_staples_min)
            : true
          : true;
      }
    ),
    portfolio_energy_min: Yup.string().test(
      "portfolio_energy_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_energy_status } = this.parent;
        return portfolio_energy_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_energy_max: Yup.string().test(
      "portfolio_energy_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_energy_min, portfolio_energy_status } = this.parent;
        return portfolio_energy_status
          ? typeof portfolio_energy_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_energy_min)
            : true
          : true;
      }
    ),
    portfolio_utilities_min: Yup.string().test(
      "portfolio_utilities_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_utilities_status } = this.parent;
        return portfolio_utilities_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_utilities_max: Yup.string().test(
      "portfolio_utilities_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_utilities_min, portfolio_utilities_status } =
          this.parent;
        return portfolio_utilities_status
          ? typeof portfolio_utilities_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_utilities_min)
            : true
          : true;
      }
    ),
    portfolio_materials_min: Yup.string().test(
      "portfolio_materials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_materials_status } = this.parent;
        return portfolio_materials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_materials_max: Yup.string().test(
      "portfolio_materials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_materials_min, portfolio_materials_status } =
          this.parent;
        return portfolio_materials_status
          ? typeof portfolio_materials_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_materials_min)
            : true
          : true;
      }
    ),
    portfolio_realestate_min: Yup.string().test(
      "portfolio_realestate_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_realestate_status } = this.parent;
        return portfolio_esc_realestate_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_realestate_max: Yup.string().test(
      "portfolio_realestate_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_realestate_min, portfolio_esc_realestate_status } =
          this.parent;
        return portfolio_esc_realestate_status
          ? typeof portfolio_realestate_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_realestate_min)
            : true
          : true;
      }
    ),
    portfolio_esc_it_min: Yup.string().test(
      "portfolio_esc_it_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_it_status } = this.parent;
        return portfolio_esc_it_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_it_max: Yup.string().test(
      "portfolio_esc_it_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_esc_it_min, portfolio_esc_it_status } = this.parent;
        return portfolio_esc_it_status
          ? typeof portfolio_esc_it_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_esc_it_min)
            : true
          : true;
      }
    ),
    portfolio_esc_healthcare_min: Yup.string().test(
      "portfolio_esc_healthcare_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_healthcare_status } = this.parent;
        return portfolio_esc_healthcare_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_healthcare_max: Yup.string().test(
      "portfolio_esc_healthcare_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_esc_healthcare_min,
          portfolio_esc_healthcare_status,
        } = this.parent;
        return portfolio_esc_healthcare_status
          ? typeof portfolio_esc_healthcare_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_healthcare_min)
            : true
          : true;
      }
    ),
    portfolio_esc_financials_min: Yup.string().test(
      "portfolio_esc_financials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_financials_status } = this.parent;
        return portfolio_esc_financials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_financials_max: Yup.string().test(
      "portfolio_esc_financials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_esc_financials_min,
          portfolio_esc_financials_status,
        } = this.parent;
        return portfolio_esc_financials_status
          ? typeof portfolio_esc_financials_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_financials_min)
            : true
          : true;
      }
    ),
    portfolio_esc_discretionary_min: Yup.string().test(
      "portfolio_esc_discretionary_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_discretionary_status } = this.parent;
        return portfolio_esc_discretionary_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_discretionary_max: Yup.string().test(
      "portfolio_esc_discretionary_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_esc_discretionary_min,
          portfolio_esc_discretionary_status,
        } = this.parent;
        return portfolio_esc_discretionary_status
          ? typeof portfolio_esc_discretionary_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_discretionary_min)
            : true
          : true;
      }
    ),
    portfolio_esc_comservice_min: Yup.string().test(
      "portfolio_esc_comservice_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_comservice_status } = this.parent;
        return portfolio_esc_comservice_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_comservice_max: Yup.string().test(
      "portfolio_esc_comservice_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_esc_comservice_min,
          portfolio_esc_comservice_status,
        } = this.parent;
        return portfolio_esc_comservice_status
          ? typeof portfolio_esc_comservice_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_comservice_min)
            : true
          : true;
      }
    ),
    portfolio_esc_industrials_min: Yup.string().test(
      "portfolio_esc_industrials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_industrials_status } = this.parent;
        return portfolio_esc_industrials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_industrials_max: Yup.string().test(
      "portfolio_esc_industrials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_esc_industrials_min,
          portfolio_esc_industrials_status,
        } = this.parent;
        return portfolio_esc_industrials_status
          ? typeof portfolio_esc_industrials_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_industrials_min)
            : true
          : true;
      }
    ),
    portfolio_esc_staples_min: Yup.string().test(
      "portfolio_esc_staples_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_staples_status } = this.parent;
        return portfolio_esc_staples_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_staples_max: Yup.string().test(
      "portfolio_esc_staples_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_esc_staples_min, portfolio_esc_staples_status } =
          this.parent;
        return portfolio_esc_staples_status
          ? typeof portfolio_esc_staples_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_staples_min)
            : true
          : true;
      }
    ),
    portfolio_esc_energy_min: Yup.string().test(
      "portfolio_esc_energy_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_energy_status } = this.parent;
        return portfolio_esc_energy_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_energy_max: Yup.string().test(
      "portfolio_esc_energy_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_esc_energy_min, portfolio_esc_energy_status } =
          this.parent;
        return portfolio_esc_energy_status
          ? typeof portfolio_esc_energy_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_energy_min)
            : true
          : true;
      }
    ),
    portfolio_esc_utilities_min: Yup.string().test(
      "portfolio_esc_utilities_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_utilities_status } = this.parent;
        return portfolio_esc_utilities_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_utilities_max: Yup.string().test(
      "portfolio_esc_utilities_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_esc_utilities_min, portfolio_esc_utilities_status } =
          this.parent;
        return portfolio_esc_utilities_status
          ? typeof portfolio_esc_utilities_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_utilities_min)
            : true
          : true;
      }
    ),
    portfolio_esc_materials_min: Yup.string().test(
      "portfolio_esc_materials_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_esc_materials_status } = this.parent;
        return portfolio_esc_materials_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_esc_materials_max: Yup.string().test(
      "portfolio_esc_materials_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_esc_materials_min, portfolio_esc_materials_status } =
          this.parent;
        return portfolio_esc_materials_status
          ? typeof portfolio_esc_materials_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_esc_materials_min)
            : true
          : true;
      }
    ),
    portfolio_usd_min: Yup.string().test(
      "portfolio_usd_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_usd_status } = this.parent;
        return portfolio_usd_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_usd_max: Yup.string().test(
      "portfolio_usd_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_usd_min, portfolio_usd_status } = this.parent;
        return portfolio_usd_status
          ? typeof portfolio_usd_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_usd_min)
            : true
          : true;
      }
    ),
    portfolio_eur_min: Yup.string().test(
      "portfolio_eur_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_eur_status } = this.parent;
        return portfolio_eur_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_eur_max: Yup.string().test(
      "portfolio_eur_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_eur_min, portfolio_eur_status } = this.parent;
        return portfolio_eur_status
          ? typeof portfolio_eur_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_eur_min)
            : true
          : true;
      }
    ),
    portfolio_gbp_min: Yup.string().test(
      "portfolio_gbp_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_gbp_status } = this.parent;
        return portfolio_gbp_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_gbp_max: Yup.string().test(
      "portfolio_gbp_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_gbp_min, portfolio_gbp_status } = this.parent;
        return portfolio_gbp_status
          ? typeof portfolio_gbp_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_gbp_min)
            : true
          : true;
      }
    ),
    portfolio_jpy_min: Yup.string().test(
      "portfolio_jpy_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_jpy_status } = this.parent;
        return portfolio_jpy_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_jpy_max: Yup.string().test(
      "portfolio_jpy_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_jpy_min, portfolio_jpy_status } = this.parent;
        return portfolio_jpy_status
          ? typeof portfolio_jpy_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_jpy_min)
            : true
          : true;
      }
    ),
    portfolio_aud_min: Yup.string().test(
      "portfolio_aud_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_aud_status } = this.parent;
        return portfolio_aud_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_aud_max: Yup.string().test(
      "portfolio_aud_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_aud_min, portfolio_aud_status } = this.parent;
        return portfolio_aud_status
          ? typeof portfolio_aud_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_aud_min)
            : true
          : true;
      }
    ),
    portfolio_nzd_min: Yup.string().test(
      "portfolio_nzd_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_nzd_status } = this.parent;
        return portfolio_nzd_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_nzd_max: Yup.string().test(
      "portfolio_nzd_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_nzd_min, portfolio_nzd_status } = this.parent;
        return portfolio_nzd_status
          ? typeof portfolio_nzd_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_nzd_min)
            : true
          : true;
      }
    ),
    portfolio_cad_min: Yup.string().test(
      "portfolio_cad_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_cad_status } = this.parent;
        return portfolio_cad_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_cad_max: Yup.string().test(
      "portfolio_cad_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_cad_min, portfolio_cad_status } = this.parent;
        return portfolio_cad_status
          ? typeof portfolio_cad_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_cad_min)
            : true
          : true;
      }
    ),
    portfolio_chf_min: Yup.string().test(
      "portfolio_chf_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_chf_status } = this.parent;
        return portfolio_chf_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_chf_max: Yup.string().test(
      "portfolio_chf_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_chf_min, portfolio_chf_status } = this.parent;
        return portfolio_chf_status
          ? typeof portfolio_chf_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_chf_min)
            : true
          : true;
      }
    ),
    portfolio_nok_min: Yup.string().test(
      "portfolio_nok_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_nok_status } = this.parent;
        return portfolio_nok_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_nok_max: Yup.string().test(
      "portfolio_nok_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_nok_min, portfolio_nok_status } = this.parent;
        return portfolio_nok_status
          ? typeof portfolio_nok_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_nok_min)
            : true
          : true;
      }
    ),
    portfolio_sek_min: Yup.string().test(
      "portfolio_sek_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_sek_status } = this.parent;
        return portfolio_sek_status ? parseInt(value ? value : 0) <= 100 : true;
      }
    ),
    portfolio_sek_max: Yup.string().test(
      "portfolio_sek_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_sek_min, portfolio_sek_status } = this.parent;
        return portfolio_sek_status
          ? typeof portfolio_sek_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_sek_min)
            : true
          : true;
      }
    ),
    portfolio_exposure_min: Yup.string().test(
      "portfolio_exposure_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_exposure_status } = this.parent;
        return portfolio_exposure_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_exposure_max: Yup.string().test(
      "portfolio_exposure_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_exposure_min, portfolio_exposure_status } =
          this.parent;
        return portfolio_exposure_status
          ? typeof portfolio_exposure_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_exposure_min)
            : true
          : true;
      }
    ),
    portfolio_financialscoco_min: Yup.string().test(
      "portfolio_financialscoco_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_financialscoco_status } = this.parent;
        return portfolio_financialscoco_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_financialscoco_max: Yup.string().test(
      "portfolio_financialscoco_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const {
          portfolio_financialscoco_min,
          portfolio_financialscoco_status,
        } = this.parent;
        return portfolio_financialscoco_status
          ? typeof portfolio_financialscoco_min !== "undefined"
            ? parseInt(value ? value : 100) >=
              parseInt(portfolio_financialscoco_min)
            : true
          : true;
      }
    ),
    portfolio_credit_min: Yup.string().test(
      "portfolio_credit_min_test",
      "Min value must be lesser than 100",
      function (value) {
        const { portfolio_credit_status } = this.parent;
        return portfolio_credit_status
          ? parseInt(value ? value : 0) <= 100
          : true;
      }
    ),
    portfolio_credit_max: Yup.string().test(
      "portfolio_financialscoco_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_credit_min, portfolio_credit_status } = this.parent;
        return portfolio_credit_status
          ? typeof portfolio_credit_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_credit_min)
            : true
          : true;
      }
    ),
    portfolio_2_max: Yup.string().test(
      "portfolio_2_max_test",
      "Max value must be greater than Min value",
      function (value) {
        const { portfolio_2_min, portfolio_2_status = true } = this.parent;
        return portfolio_2_status
          ? typeof portfolio_2_min !== "undefined"
            ? parseInt(value ? value : 100) >= parseInt(portfolio_2_min)
            : true
          : true;
      }
    ),
  });
  if (portfolioId && !constraintsFlag) {
    validationSchema = validationSchema.shape({
      /*portfolio_costofborrowing_benchmark: Yup.string()
        .trim()
        .required("Cost of borrowing (benchmark) required")
        .nullable(),*/
      portfolio_leverage: Yup.string()
        .trim()
        .required("Leverage required")
        .nullable(),
      portfolio_costofborrowing: Yup.string()
        .trim()
        .required("Cost of borrowing required")
        .nullable(),
    });
    Swal.fire(
      getAlertToast(
        "success",
        portfolioId
          ? "portfolio updated successfully"
          : "portfolio created successfully"
      )
    );
  }

  const portfolioCreation = async (values) => {
    console.log(values.portfolio_RMuser, "hhhhhhhhhhhhhhhhhhhhhhhh");

    setLoading(true);
    setFilled(false);
    const {
      portfolio_id,
      portfolio_name,
      portfolio_currency,
      portfolio_clientequity,
      portfolio_RMuser,
      portfolio_core_percentage,
      portfolio_coreunit,
      portfolio_costofborrowing,
      portfolio_costofborrowing_benchmark,
      portfolio_leverage,
      portfolio_shariahcomplaint,
      portfolio_expect_annualreturn,
      portfolio_expect_maxdrawdown,
      // portfolio_cashflow_yield,
      portfolio_liquidity,
      portfolio_cash_status,
      portfolio_cash_min,
      portfolio_cash_max,
      portfolio_equities_status,
      portfolio_equities_min,
      portfolio_equities_max,
      portfolio_equities_directholding_max,
      portfolio_equities_directholding_min,
      portfolio_fixedincome_status,
      portfolio_fixedincome_min,
      portfolio_fixedincome_max,
      portfolio_fixedincome_directholding_max,
      portfolio_fixedincome_directholding_min,
      portfolio_alternative_status,
      portfolio_alternative_min,
      portfolio_alternative_max,
      portfolio_alternative_directholding_max,
      portfolio_alternative_directholding_min,
      portfolio_exposure_status,
      portfolio_exposure_value,
      portfolio_exposure_max,
      portfolio_exposure_min,
      portfolio_exposure2_status,
      portfolio_exposure2_value,
      portfolio_exposure2_max,
      portfolio_exposure2_min,
      portfolio_financialscoco_status,
      portfolio_financialscoco_min,
      portfolio_financialscoco_max,
      portfolio_credit_status,
      portfolio_credit_min,
      portfolio_credit_max,
      portfolio_viceindustries_status,
      portfolio_realestate_status,
      portfolio_it_status,
      portfolio_healthcare_status,
      portfolio_financials_status,
      portfolio_discretionary_status,
      portfolio_comservice_status,
      portfolio_industrials_status,
      portfolio_staples_status,
      portfolio_energy_status,
      portfolio_utilities_status,
      portfolio_materials_status,
      portfolio_viceindustries_max,
      portfolio_viceindustries_min,
      portfolio_realestate_min,
      portfolio_realestate_max,
      portfolio_it_min,
      portfolio_it_max,
      portfolio_healthcare_min,
      portfolio_healthcare_max,
      portfolio_financials_min,
      portfolio_financials_max,
      portfolio_discretionary_min,
      portfolio_discretionary_max,
      portfolio_comservice_min,
      portfolio_comservice_max,
      portfolio_industrials_min,
      portfolio_industrials_max,
      portfolio_staples_min,
      portfolio_staples_max,
      portfolio_energy_min,
      portfolio_energy_max,
      portfolio_utilities_min,
      portfolio_utilities_max,
      portfolio_materials_min,
      portfolio_materials_max,
      portfolio_usd_status,
      portfolio_eur_status,
      portfolio_gbp_status,
      portfolio_jpy_status,
      portfolio_aud_status,
      portfolio_nzd_status,
      portfolio_cad_status,
      portfolio_chf_status,
      portfolio_nok_status,
      portfolio_sek_status,
      portfolio_esc_viceindustries_min,
      portfolio_esc_viceindustries_max,

      portfolio_esc_it_min,
      portfolio_esc_it_max,
      portfolio_esc_healthcare_min,
      portfolio_esc_healthcare_max,
      portfolio_esc_financials_min,
      portfolio_esc_financials_max,
      portfolio_esc_discretionary_min,
      portfolio_esc_discretionary_max,
      portfolio_esc_comservice_min,
      portfolio_esc_comservice_max,
      portfolio_esc_industrials_min,
      portfolio_esc_industrials_max,
      portfolio_esc_staples_min,
      portfolio_esc_staples_max,
      portfolio_esc_energy_min,
      portfolio_esc_energy_max,
      portfolio_esc_utilities_min,
      portfolio_esc_utilities_max,
      portfolio_esc_materials_min,
      portfolio_esc_materials_max,
      portfolio_esc_viceindustries_status,
      portfolio_esc_realestate_status,
      portfolio_esc_it_status,
      portfolio_esc_healthcare_status,
      portfolio_esc_financials_status,
      portfolio_esc_discretionary_status,
      portfolio_esc_comservice_status,
      portfolio_esc_industrials_status,
      portfolio_esc_staples_status,
      portfolio_esc_energy_status,
      portfolio_esc_utilities_status,
      portfolio_esc_materials_status,
      portfolio_usd_max,
      portfolio_usd_min,
      portfolio_eur_min,
      portfolio_eur_max,
      portfolio_gbp_min,
      portfolio_gbp_max,
      portfolio_jpy_min,
      portfolio_jpy_max,
      portfolio_aud_min,
      portfolio_aud_max,
      portfolio_nzd_max,
      portfolio_nzd_min,
      portfolio_cad_min,
      portfolio_cad_max,
      portfolio_chf_min,
      portfolio_chf_max,
      portfolio_nok_min,
      portfolio_nok_max,
      portfolio_sek_min,
      portfolio_sek_max,
      portfolio__assetname,
      portfolio__custom,
      portfolio__suggested,
      portfolio__empty,
    } = values;

    console.log(
      permission,
      "SELECTED_CLIENT_IDSELECTED_CLIENT_IDSELECTED_CLIENT_ID"
    );
    const config = {
      method: portfolioId ? "PUT" : "POST",
      url: portfolioId
        ? PORTFOLIO_UPDATE_URL + "/" + portfolioId
        : PORTFOLIO_CREATE,
      headers: authHeader(),
      data: {
        portfolio_id,
        assets: assetValues,
        update_type: update_type,
        portfolio_user:
          permission.user_permission == "arm"
            ? portfolio_RMuser
            : getSelectedClientID(),
        portfolio_name,
        portfolio_currency,
        portfolio_clientequity,
        portfolio_core_percentage:
          portfolio_coreunit === "%" ? portfolio_core_percentage : 0,
        portfolio_coreunit,
        portfolio_core_dollar:
          portfolio_coreunit === "%"
            ? (portfolio_core_percentage / 100) * portfolio_clientequity
            : portfolio_core_percentage,
        portfolio_costofborrowing,
        portfolio_costofborrowing_benchmark,
        portfolio_leverage,
        portfolio_shariahcomplaint: portfolio_shariahcomplaint ? 1 : 0,
        portfolio_expect_annualreturn,
        portfolio_expect_maxdrawdown,
        portfolio_cashflow_yield: 0,
        portfolio_liquidity,
        portfolio_cash_status: portfolio_cash_status ? 1 : 0,
        portfolio_cash_min: portfolio_cash_min ? portfolio_cash_min : 0,
        portfolio_cash_max: portfolio_cash_max ? portfolio_cash_max : 100,
        portfolio_equities_status: portfolio_equities_status ? 1 : 0,
        portfolio_equities_min: portfolio_equities_min
          ? portfolio_equities_min
          : 0,
        portfolio_equities_max: portfolio_equities_max
          ? portfolio_equities_max
          : 100,
        portfolio_equities_directholding_min:
          portfolio_equities_directholding_min
            ? portfolio_equities_directholding_min
            : 0,
        portfolio_equities_directholding_max:
          portfolio_equities_directholding_max
            ? portfolio_equities_directholding_max
            : 100,
        portfolio_fixedincome_status: portfolio_fixedincome_status ? 1 : 0,
        portfolio_fixedincome_min: portfolio_fixedincome_min
          ? portfolio_fixedincome_min
          : 0,
        portfolio_fixedincome_max: portfolio_fixedincome_max
          ? portfolio_fixedincome_max
          : 100,
        portfolio_fixedincome_directholding_min:
          portfolio_fixedincome_directholding_min
            ? portfolio_fixedincome_directholding_min
            : 0,
        portfolio_fixedincome_directholding_max:
          portfolio_fixedincome_directholding_max
            ? portfolio_fixedincome_directholding_max
            : 100,
        portfolio_alternative_status: portfolio_alternative_status ? 1 : 0,
        portfolio_alternative_min: portfolio_alternative_min
          ? portfolio_alternative_min
          : 0,
        portfolio_alternative_max: portfolio_alternative_max
          ? portfolio_alternative_max
          : 100,
        portfolio_alternative_directholding_min:
          portfolio_alternative_directholding_min
            ? portfolio_alternative_directholding_min
            : 0,
        portfolio_alternative_directholding_max:
          portfolio_alternative_directholding_max
            ? portfolio_alternative_directholding_max
            : 100,
        portfolio_exposure_status: portfolio_exposure_status ? 1 : 0,
        portfolio_exposure_value,
        portfolio_exposure_min: portfolio_exposure_min
          ? portfolio_exposure_min
          : 0,
        portfolio_exposure_max: portfolio_exposure_max
          ? portfolio_exposure_max
          : 100,
        portfolio_exposure2_status: portfolio_exposure2_status ? 1 : 0,
        portfolio_exposure2_value,
        portfolio_exposure2_min: portfolio_exposure2_min
          ? portfolio_exposure2_min
          : 0,
        portfolio_exposure2_max: portfolio_exposure2_max
          ? portfolio_exposure2_max
          : 100,
        portfolio_financialscoco_status: portfolio_financialscoco_status
          ? 1
          : 0,
        portfolio_financialscoco_min: portfolio_financialscoco_min
          ? portfolio_financialscoco_min
          : 0,
        portfolio_financialscoco_max: portfolio_financialscoco_max
          ? portfolio_financialscoco_max
          : 100,
        portfolio_credit_status: portfolio_credit_status ? 1 : 0,
        portfolio_credit_min: portfolio_credit_min ? portfolio_credit_min : 0,
        portfolio_credit_max: portfolio_credit_max ? portfolio_credit_max : 100,
        portfolio_viceindustries_status: portfolio_viceindustries_status
          ? 1
          : 0,
        portfolio_realestate_status: portfolio_realestate_status ? 1 : 0,
        portfolio_it_status: portfolio_it_status ? 1 : 0,
        portfolio_healthcare_status: portfolio_healthcare_status ? 1 : 0,
        portfolio_financials_status: portfolio_financials_status ? 1 : 0,
        portfolio_discretionary_status: portfolio_discretionary_status ? 1 : 0,
        portfolio_comservice_status: portfolio_comservice_status ? 1 : 0,
        portfolio_industrials_status: portfolio_industrials_status ? 1 : 0,
        portfolio_staples_status: portfolio_staples_status ? 1 : 0,
        portfolio_energy_status: portfolio_energy_status ? 1 : 0,
        portfolio_utilities_status: portfolio_utilities_status ? 1 : 0,
        portfolio_materials_status: portfolio_materials_status ? 1 : 0,
        portfolio_viceindustries_min: portfolio_viceindustries_min
          ? portfolio_viceindustries_min
          : 0,
        portfolio_viceindustries_max: portfolio_viceindustries_max
          ? portfolio_viceindustries_max
          : 100,
        portfolio_realestate_min: portfolio_realestate_min
          ? portfolio_realestate_min
          : 0,
        portfolio_realestate_max: portfolio_realestate_max
          ? portfolio_realestate_max
          : 100,
        portfolio_it_min: portfolio_it_min ? portfolio_it_min : 0,
        portfolio_it_max: portfolio_it_max ? portfolio_it_max : 100,
        portfolio_healthcare_min: portfolio_healthcare_min
          ? portfolio_healthcare_min
          : 0,
        portfolio_healthcare_max: portfolio_healthcare_max
          ? portfolio_healthcare_max
          : 100,
        portfolio_financials_min: portfolio_financials_min
          ? portfolio_financials_min
          : 0,
        portfolio_financials_max: portfolio_financials_max
          ? portfolio_financials_max
          : 0,
        portfolio_discretionary_min: portfolio_discretionary_min
          ? portfolio_discretionary_min
          : 0,
        portfolio_discretionary_max: portfolio_discretionary_max
          ? portfolio_discretionary_max
          : 100,
        portfolio_comservice_min: portfolio_comservice_min
          ? portfolio_comservice_min
          : 0,
        portfolio_comservice_max: portfolio_comservice_max
          ? portfolio_comservice_max
          : 100,
        portfolio_industrials_min: portfolio_industrials_min
          ? portfolio_industrials_min
          : 0,
        portfolio_industrials_max: portfolio_industrials_max
          ? portfolio_industrials_max
          : 100,
        portfolio_staples_min: portfolio_staples_min
          ? portfolio_staples_min
          : 0,
        portfolio_staples_max: portfolio_staples_max
          ? portfolio_staples_max
          : 100,
        portfolio_energy_min: portfolio_energy_min ? portfolio_energy_min : 0,
        portfolio_energy_max: portfolio_energy_max ? portfolio_energy_max : 100,
        portfolio_utilities_min: portfolio_utilities_min
          ? portfolio_utilities_min
          : 0,
        portfolio_utilities_max: portfolio_utilities_max
          ? portfolio_utilities_max
          : 100,
        portfolio_materials_min: portfolio_materials_min
          ? portfolio_materials_min
          : 0,
        portfolio_materials_max: portfolio_materials_max
          ? portfolio_materials_max
          : 100,
        portfolio_usd_status: portfolio_usd_status ? 1 : 0,
        portfolio_eur_status: portfolio_eur_status ? 1 : 0,
        portfolio_gbp_status: portfolio_gbp_status ? 1 : 0,
        portfolio_jpy_status: portfolio_jpy_status ? 1 : 0,
        portfolio_aud_status: portfolio_aud_status ? 1 : 0,
        portfolio_nzd_status: portfolio_nzd_status ? 1 : 0,
        portfolio_cad_status: portfolio_cad_status ? 1 : 0,
        portfolio_chf_status: portfolio_chf_status ? 1 : 0,
        portfolio_nok_status: portfolio_nok_status ? 1 : 0,
        portfolio_sek_status: portfolio_sek_status ? 1 : 0,
        portfolio_esc_viceindustries_status: portfolio_esc_viceindustries_status
          ? 1
          : 0,
        portfolio_esc_realestate_status: portfolio_esc_realestate_status
          ? 1
          : 0,
        portfolio_esc_it_status: portfolio_esc_it_status ? 1 : 0,
        portfolio_esc_healthcare_status: portfolio_esc_healthcare_status
          ? 1
          : 0,
        portfolio_esc_financials_status: portfolio_esc_financials_status
          ? 1
          : 0,
        portfolio_esc_discretionary_status: portfolio_esc_discretionary_status
          ? 1
          : 0,
        portfolio_esc_comservice_status: portfolio_esc_comservice_status
          ? 1
          : 0,
        portfolio_esc_industrials_status: portfolio_esc_industrials_status
          ? 1
          : 0,
        portfolio_esc_staples_status: portfolio_esc_staples_status ? 1 : 0,
        portfolio_esc_energy_status: portfolio_esc_energy_status ? 1 : 0,
        portfolio_esc_utilities_status: portfolio_esc_utilities_status ? 1 : 0,
        portfolio_esc_materials_status: portfolio_esc_materials_status ? 1 : 0,
        portfolio_esc_viceindustries_min: portfolio_esc_viceindustries_min
          ? portfolio_esc_viceindustries_min
          : 0,
        portfolio_esc_viceindustries_max: portfolio_esc_viceindustries_max
          ? portfolio_esc_viceindustries_max
          : 100,
        portfolio_realestate_min: portfolio_realestate_min
          ? portfolio_realestate_min
          : 0,
        portfolio_realestate_max: portfolio_realestate_max
          ? portfolio_realestate_max
          : 100,
        portfolio_esc_it_min: portfolio_esc_it_min ? portfolio_esc_it_min : 0,
        portfolio_esc_it_max: portfolio_esc_it_max ? portfolio_esc_it_max : 100,
        portfolio_esc_healthcare_min: portfolio_esc_healthcare_min
          ? portfolio_esc_healthcare_min
          : 0,
        portfolio_esc_healthcare_max: portfolio_esc_healthcare_max
          ? portfolio_esc_healthcare_max
          : 100,
        portfolio_esc_financials_min: portfolio_esc_financials_min
          ? portfolio_esc_financials_min
          : 0,
        portfolio_esc_financials_max: portfolio_esc_financials_max
          ? portfolio_esc_financials_max
          : 100,
        portfolio_esc_discretionary_min: portfolio_esc_discretionary_min
          ? portfolio_esc_discretionary_min
          : 0,
        portfolio_esc_discretionary_max: portfolio_esc_discretionary_max
          ? portfolio_esc_discretionary_max
          : 100,
        portfolio_esc_comservice_min: portfolio_esc_comservice_min
          ? portfolio_esc_comservice_min
          : 0,
        portfolio_esc_comservice_max: portfolio_esc_comservice_max
          ? portfolio_esc_comservice_max
          : 100,
        portfolio_esc_industrials_min: portfolio_esc_industrials_min
          ? portfolio_esc_industrials_min
          : 0,
        portfolio_esc_industrials_max: portfolio_esc_industrials_max
          ? portfolio_esc_industrials_max
          : 100,
        portfolio_esc_staples_min: portfolio_esc_staples_min
          ? portfolio_esc_staples_min
          : 0,
        portfolio_esc_staples_max: portfolio_esc_staples_max
          ? portfolio_esc_staples_max
          : 100,
        portfolio_esc_energy_min: portfolio_esc_energy_min
          ? portfolio_esc_energy_min
          : 0,
        portfolio_esc_energy_max: portfolio_esc_energy_max
          ? portfolio_esc_energy_max
          : 100,
        portfolio_esc_utilities_min: portfolio_esc_utilities_min
          ? portfolio_esc_utilities_min
          : 0,
        portfolio_esc_utilities_max: portfolio_esc_utilities_max
          ? portfolio_esc_utilities_max
          : 100,
        portfolio_esc_materials_min: portfolio_esc_materials_min
          ? portfolio_esc_materials_min
          : 0,
        portfolio_esc_materials_max: portfolio_esc_materials_max
          ? portfolio_esc_materials_max
          : 100,
        portfolio_regional: regValues,
        portfolio_usd_min: portfolio_usd_min ? portfolio_usd_min : 0,
        portfolio_usd_max: portfolio_usd_max ? portfolio_usd_max : 100,
        portfolio_eur_min: portfolio_eur_min ? portfolio_eur_min : 0,
        portfolio_eur_max: portfolio_eur_max ? portfolio_eur_max : 100,
        portfolio_gbp_min: portfolio_gbp_min ? portfolio_gbp_min : 0,
        portfolio_gbp_max: portfolio_gbp_max ? portfolio_gbp_max : 100,
        portfolio_jpy_min: portfolio_jpy_min ? portfolio_jpy_min : 0,
        portfolio_jpy_max: portfolio_jpy_max ? portfolio_jpy_max : 100,
        portfolio_aud_min: portfolio_aud_min ? portfolio_aud_min : 0,
        portfolio_aud_max: portfolio_aud_max ? portfolio_aud_max : 100,
        portfolio_nzd_min: portfolio_nzd_min ? portfolio_nzd_min : 0,
        portfolio_nzd_max: portfolio_nzd_max ? portfolio_nzd_max : 100,
        portfolio_cad_min: portfolio_cad_min ? portfolio_cad_min : 0,
        portfolio_cad_max: portfolio_cad_max ? portfolio_cad_max : 100,
        portfolio_chf_min: portfolio_chf_min ? portfolio_chf_min : 0,
        portfolio_chf_max: portfolio_chf_max ? portfolio_chf_max : 100,
        portfolio_nok_min: portfolio_nok_min ? portfolio_nok_min : 0,
        portfolio_nok_max: portfolio_nok_max ? portfolio_nok_max : 100,
        portfolio_sek_min: portfolio_sek_min ? portfolio_sek_min : 0,
        portfolio_sek_max: portfolio_sek_max ? portfolio_sek_max : 100,
        portfolio__suggested: portfolio__suggested ? portfolio__suggested : 10,
        portfolio__custom: portfolio__custom ? portfolio__custom : 10,
        portfolio__empty: portfolio__empty ? portfolio__empty : 10,
      },
    };
    // if (
    //   portfolio_name != "" &&
    //   portfolio_currency != "" &&
    //   portfolio_clientequity != "" &&
    //   portfolio_core_percentage != "" &&
    //   portfolio_cashflow_yield != "" &&
    //   portfolio_liquidity != ""
    // ) {

    // }

    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "Success") {
        Swal.fire(
          getAlertToast(
            "success",
            portfolioId
              ? "portfolio updated successfully"
              : "portfolio created successfully"
          )
        );
        history.push(`/List-portfolio/${getSelectedClientID()}`);
        console.log(porfolioI, "");
      }
    } catch (error) {
      console.error(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
      setLoading(false);
    }
    Swal.fire(
      getAlertToast(
        "success",
        portfolioId
          ? "portfolio updated successfully"
          : "portfolio created successfully"
      )
    );
  };
  useEffect(() => {
    if (formIsHalfFilledOut != false) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [formIsHalfFilledOut]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...formData,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values, setFieldValue) => {
      //console.log(values, "i8i8i8i8i8666666");
      if (!Flag) {
        try {
          values.portfolio_clientequity = values.portfolio_clientequity.replace(
            /,/g,
            ""
          );
        } catch (error) {
          console.log(error);
          values.portfolio_clientequity = values.portfolio_clientequity;
        }
        try {
          values.portfolio_core_percentage =
            values.portfolio_core_percentage.replace(/,/g, "");
        } catch (error) {
          console.log(error);
          values.portfolio_core_percentage = values.portfolio_core_percentage;
        }
      }
      let {
        portfolio_cash_min = 0,
        portfolio_equities_min = 0,
        portfolio_fixedincome_min = 0,
        portfolio_alternative_min = 0,
        portfolio_cash_max = 100,
        portfolio_equities_max = 100,
        portfolio_fixedincome_max = 100,
        portfolio_alternative_max = 100,
      } = values;

      let minTotal =
        parseInt(portfolio_cash_min || 0) +
        parseInt(portfolio_equities_min || 0) +
        parseInt(portfolio_fixedincome_min || 0) +
        parseInt(portfolio_alternative_min || 0);

      let maxTotal =
        parseInt(portfolio_cash_max || 100) +
        parseInt(portfolio_equities_max || 100) +
        parseInt(portfolio_fixedincome_max || 100) +
        parseInt(portfolio_alternative_max || 100);
      let regionalFlag = false;
      if (regValues && regValues.length > 0) {
        regValues.map((record) => {
          if (record.min > record.max) {
            regionalFlag = true;
          }
        });
      }
      if (!getSelectedClientID()) {
        Swal.fire(getAlert("error", "Please Select Client!"));
        selectClientToggle();
      } else if (regionalFlag == "true") {
        Swal.fire(
          getAlert("error", "Max value must be greater than Min value ")
        );
      } else if (minTotal > 100) {
        Swal.fire(
          getAlert(
            "error",
            "The sum of minimums should be less than or equal to 100"
          )
        );
        window.scrollTo(0, 250);
        setToggleId(3);
        setIsAssetOpen(true);
      } else if (maxTotal < 100) {
        Swal.fire(
          getAlert(
            "error",
            "The sum of maximums should be more than or equal to 100"
          )
        );
        window.scrollTo(0, 250);
        setToggleId(3);
        setIsAssetOpen(true);
      } else {
        portfolioCreation(values);
      }
    },
  });
  const commonItems = (tabArray, errorArray) => {
    return tabArray.some((field) => errorArray.includes(field));
  };

  console.log(getSelectedClientID, "88888888888888888888888888");
  useEffect(() => {
    console.log(isSubmitting, isValid, errors, "isSubmitting, isValid, errors");
    if (isValid || !isSubmitting) return;
    let firstTabArray = [
      "portfolio_name",
      "portfolio_currency",
      "portfolio_clientequity",
      "portfolio_RMuser",
      "portfolio_core",
      "portfolio_costofborrowing_benchmark",
      "portfolio_costofborrowing",
      "portfolio_leverage",
    ];
    let secondTabArray = ["portfolio_cashflow_yield", "portfolio_liquidity"];
    const errorArray = Object.keys(errors);
    if (!isValid) {
      if (commonItems(firstTabArray, errorArray)) {
        setToggleId(1);
      } else {
        setToggleId(2);
      }
      window.scrollTo(0, 200);
    }
  }, [isSubmitting, isValid, errors]);

  // useEffect(() => {
  //   console.log(
  //     formValues.portfolio_RMuser,
  //     "l88111111111111111111111111111111111"
  //   );
  // }, [formValues]);
  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper form-control.is-invalid">
          <div className="bg_d6 p-3">
            <Row className="">
              <Col sm="8">

              {porfolioI &&<h3 className="text_16_c4">Edit Portfolio</h3> }
              { !porfolioI &&<h3 className="text_16_c4">Create New Portfolio</h3>}
              </Col>

              <Col sm="4" className="text-right">
              
               <div onClick={() => history.goBack()}>  
                  <a className="text_16_c4" >
                    <BiArrowBack></BiArrowBack> Back
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <Container fluid>
            <Card className="min-screenheight st_card">
              <CardTitle className="p-0 mt-4">
                <span className="list_cli">Portfolio Inputs</span>
              </CardTitle>
              <CardBody className="p-0">
                <Form form="true" onSubmit={handleSubmit}>
                  <div className="text-left mt-2 mb-2">
                    {Object.values(errors).length > 0 && showError && (
                      <div className="current_class p-3">
                        <h6 className="p-0">Please fill the value</h6>
                        {Object.values(errors).map((key) => {
                          return <p className="mb-0">- {key}!</p>;
                        })}
                      </div>
                    )}
                  </div>
                  <Row>
                    <Col lg={8}>
                      <div className="summaryTopPosition">
                        <Tabs
                          className="summarys_tabs port_li"
                          forceRenderTabPanel={true}
                          selectedIndex={tabIndex}
                          onSelect={(index) => setTabIndex(index)}
                        >
                          <TabList class="w-100 summary_tabs nav nav-tabs cr">
                            <Tab>Portfolio Information</Tab>
                            <Tab>Optimization Constraints</Tab>
                            <Tab>Advanced Constraints</Tab>
                          </TabList>
                          <TabPanel>
                            <div className="my-collapse ">
                              <AssetInputs
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                formValues={values}
                                Flag={Flag}
                                setFilled={setFilled}
                                constraintsFlag={constraintsFlag}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="my-collapse">
                              <OptimisationInputs
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                formValues={values}
                                Flag={Flag}
                                setFilled={setFilled}
                                setFieldValue={setFieldValue}
                                constraintsFlag={constraintsFlag}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="my-collapse">
                              {/* sub Collapse 1 */}

                              {(() => {
                                /*if (portfolioId) {
                                  return (
                                    <Asset
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      formValues={values}
                                      errors={errors}
                                      touched={touched}
                                      toggle={assetToggles}
                                      isOpen={isAsset}
                                      Flag={Flag}
                                      constraintsFlag={constraintsFlag}
                                      setAssetValues={setAssetValues}
                                    />
                                  );
                                }*/
                              })()}
                              {/* */}
                              <AssetLevel
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                formValues={values}
                                errors={errors}
                                touched={touched}
                                toggle={assetToggle}
                                setFieldValue={setFieldValue}
                                isOpen={isAssetOpen}
                                Flag={Flag}
                                constraintsFlag={constraintsFlag}
                                tabIndex={tabIndex}
                              />
                              {/* */}

                              {/* sub Collapse 2 */}
                              <Regional
                                handleChange={handleChange}
                                formValues={values}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                regValues={regValues}
                                setRegValues={setRegValues}
                                Flag={Flag}
                                constraintsFlag={constraintsFlag}
                              />
                              {/* */}

                              {/* sub Collapse 3 */}
                              <RatingAndProductConstraints
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                formValues={values}
                                exposureList={exposureList}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                Flag={Flag}
                                constraintsFlag={constraintsFlag}
                              />
                              {/* */}

                              {/* sub Collapse 4 */}
                              <FixedIncomeSectorConstraints
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                formValues={values}
                                Flag={Flag}
                                setFieldValue={setFieldValue}
                                constraintsFlag={constraintsFlag}
                              />
                              {/* */}

                              {/* sub Collapse 6 */}
                              <EquitiesSectorConstraints
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                formValues={values}
                                Flag={Flag}
                                constraintsFlag={constraintsFlag}
                              />
                              {/* */}

                              {/* sub Collapse 5 */}
                              <CurrencyConstrainsts
                                handleChange={handleChange}
                                formValues={values}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                Flag={Flag}
                                constraintsFlag={constraintsFlag}
                              />
                              {/* */}
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                      <div className="mt-5">
                        <Button
                          type="submit"
                          value="submit"
                          className="d-block pl-5 pr-5 m-auto pt-2 pb-2 mt-5 port_btn"
                          color="primary"
                          disabled={loading ? true : false}
                          onClick={() => setShowError(true)}
                        >
                          {loading && <Spinner color="light" />}
                          Submit
                        </Button>
                      </div>
                    </Col>

                    {/* 1st collapse */}

                    {/* 1st collapse */}
                    {/* 2nd collapse */}

                    {/* 3rd Collapse */}

                    <Col lg={4} className="scr">
                      <PortfolioSummary
                        formValues={values}
                        exposureList={exposureList}
                        regValues={regValues}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>

        {selectClientModal && (
          <SelectClient
            selectClientModal={setSelectClientModal}
            selectClientToggle={selectClientToggle}
          />
        )}
        <Prompt
          when={formIsHalfFilledOut}
          message="You have unsaved changes, are you sure you want to leave?"
        />
      </Fragment>
    </div>
  );
}
