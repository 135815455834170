import React, { Fragment, useState, useEffect } from "react";
import Sidebar from "../layout/Sidebar";
import { useHistory } from "react-router-dom";
import API from "../redux/API";
import {
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  FormFeedback,
} from "reactstrap";
import { MdArrowBack } from "react-icons/md";
import { authHeader, getRole } from "../common/Mainfunctions";
import Swal from "sweetalert2";
import {
  RM_ARM_LIST_URL,
  RM_USER_CREATE,
  SEARCH_CLIENT_LIST,
  CLIENT_LISTING_URL,
  RM_EDIT_URL,
} from "../common/urls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EMAIL_REGEX } from "../constants/validation";
import { getAlert, getAlertToast, getRmID } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import Multiselect from "multiselect-react-dropdown";
import { USER_MANAGEMENT_ROUTE } from "../common/RoutePaths";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useDispatch } from "react-redux";
import Select from "react-select";
import MultipleValueTextInput from "react-multivalue-text-input";
import { TiTick } from "react-icons/ti";

export default function AddUser(props) {
  const {
    edituserModal,
    selectUserToggle,
    userId,
    email,
    role,
    profile,
    submitCallBack,
  } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [rowperpage, setRowPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const [clientLoading, setClientLoading] = useState(false);
  const [currentpage, setCurrentPage] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [selectedRole, setselectedRole] = useState("");
  const [searchListuser, setsearchListuser] = useState([]);
  const [isPasswordType, setPasswordInputType] = useState(true);
  const [selectedRM, setSelectedRM] = useState([]);
  const [inputRMlist, setinputRMlist] = useState([]);
  const [inputlist, setinputlist] = useState([]);
  const [inputARMlist, setinputARMlist] = useState([]);
  const [isPasswordType1, setPasswordInputType1] = useState(true);
  const [roleSelect, setRoleSelect] = useState("");
  const [userList, setUserList] = useState("");
  const dispatch = useDispatch();
  const [selectedARM, setSelectedARM] = useState([]);
  const [opt, setopt] = React.useState("");
  const [opt1, setopt1] = React.useState("");
  const [selectvalue, setselectvalue] = useState([]);

  useEffect(() => {
    // console.log(selectedARM, "99999999999999999999999999999");
    let todos = [];
    selectedARM.map((option) => {
      todos.push(option.value);

      console.log(todos, "99999999999999999999999999999");
    });
    setselectvalue(todos);
  }, [selectedARM]);

  const togglePasswordType = () => {
    setPasswordInputType(!isPasswordType);
  };
  const togglePasswordType1 = () => {
    setPasswordInputType1(!isPasswordType1);
  };

  const [initialData, setFormData] = useState({
    role: "",
    email: "",
    // password: "",
    //confirm_password: "",
    profile_picture: "",
  });
  const handleMultiSelectChange = (selectedOptions, e) => {
    setSelectedOption(selectedOptions);
  };
  const handleMultiSelectARM = (selectedARM, e) => {
    setSelectedARM(selectedARM);
  };
  const validationSchema = Yup.object({
    //role: Yup.string().trim().required("Role is required"),
    /*password: Yup.string().trim().required("Password is required"),

    confirm_password: Yup.string()
      .required("Confirm password is required")
      .test("passwords-match", "Password mismatch", function (value) {
        return this.parent.password === value;
      }),*/
    email: Yup.string()
      .trim()
      .required("email is required")
      .matches(EMAIL_REGEX, "Invalid email"),
    // profile_picture: Yup.string().required("Profile Picture is required"),
  });

  const saveFormValues = async (values) => {
    const { profile_picture } = initialData;
    console.log(profile_picture);
    setLoading(true);
    let selOptions =
      inputRMlist &&
      inputRMlist.map((option) => {
        return option.value;
      });
    let selRMclient =
      inputlist &&
      inputlist.map((option) => {
        return option.value;
      });
    let selARMOptions =
      inputARMlist &&
      inputARMlist.map((option) => {
        return option.value;
      });
    console.log(selRMclient, "selclientselclientselclientselclientselclient");
    values.clients = selRMclient.join(",");
    values.rm_users = selOptions.join(",");
    values.arm_users = selARMOptions.join(",");
    const formData = new FormData();

    if (selectedRole == "rm") {
      formData.append("email", values.email);
      formData.append("arm_users", values.arm_users);
      formData.append("role", selectedRole);
      // formData.append("clients", values.clients);
      // formData.append("profile_picture", profile_picture);
    }

    if (selectedRole == "arm") {
      formData.append("email", values.email);
      formData.append("rm_users", values.rm_users);
      formData.append("role", selectedRole);
    }
    if (selectedRole == "ic") {
      formData.append("email", values.email);
     formData.append("role", selectedRole);
    }
    if (selectedRole == "admin") {
      formData.append("email", values.email);
     formData.append("role", selectedRole);
    }
     if (selectedRole == "superadmin") {
      formData.append("email", values.email);
     formData.append("role", selectedRole);
    }
    const config = {
      method: "POST",
      url: RM_USER_CREATE,
      headers: authHeader(),
      data: formData,
      "content-type": "multipart/form-data",
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status === "Success") {
        Swal.fire(getAlertToast("Success", "User created Successfully"));
        history.push(USER_MANAGEMENT_ROUTE);
      } else {
        Swal.fire(getAlert("error", message));
      }
    } catch (error) {
      Swal.fire(getAlert("error", error.message));
    } finally {
      setLoading(false);
    }
  };
  const handleChangeFun = (e) => {
    const { files = [] } = e.target;
    setFormData({ ...initialData, profile_picture: files[0] });
    // handleChange(e);
  };
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: initialData,
      validationSchema,
      onSubmit: (values) => {
        saveFormValues(values);
      },
    });

    useEffect(()=>{
      if(selectedRole=="rm"){
        setRoleSelect("arm")
      }
      if(selectedRole=="arm"){
        setRoleSelect("rm")
      }
    },[selectedRole,roleSelect]);

  const getRM_ARM_NameList = async (search) => {
    const config = {
      method: "GET",
      url: RM_ARM_LIST_URL,
      headers: authHeader(),
      params: {
        role: userList ==""? roleSelect: userList,
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setClientOptions(clientList);
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };
  const handleMultiSelectChangeRM= (selectedRMOptions, e) => {
    setSelectedRM(selectedRMOptions);
  }
  useEffect(() => {
    getRM_ARM_NameList();
  }, [selectedRole]);

  const RMDetails = async () => {
    const config = {
      method: "GET",
      url: RM_EDIT_URL + userId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      const { client = [] } = data;
      let selclient = [];
      client.map((record) => {
        selclient.push({ label: record.client_name, value: record.client_id });
      });
      setSelectedOption(selclient);
    } catch (error) {
      console.error(error);
    }
  };

  const searchClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SEARCH_CLIENT_LIST,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setsearchListuser(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputRMChange = (newValue) => {
    const inputRMlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputRMlist);
    return inputRMlist;
  };
  const handleInputRMChanges = (newValue) => {
    const inputRMlist = newValue.replace(/\W/g, "");
    searchClientList(inputRMlist);
    return inputRMlist;
  };
  console.log(inputRMlist, "inputlistinputlistinputlistinputlistinputlist");
  useEffect(() => {}, [handleInputRMChange]);

  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: CLIENT_LISTING_URL,
      headers: authHeader(),
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let client = data.map((e, i) => ({
        label: e.client_name,
        value: e.client_id,
      }));
      setClientList(client);
      console.log(client, "444444444444444444444");
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };
  useEffect(() => {
    getClientNameList();
    RMDetails();
  }, []);
  const handleInputChange = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputlist);
    return inputlist;
  };
  const handleInputChanges = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    searchClientList(inputlist);
    return inputlist;
  };
  useEffect(() => {}, [handleInputChange]);

  const handleInputARMChange = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputlist);
    return inputlist;
  };
  const handleInputARMChanges = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    searchClientList(inputlist);
    return inputlist;
  };
  useEffect(() => {}, [handleInputARMChange]);
  console.log(inputlist, "inputlistinputlistinputlistinputlistinputlist");
  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper">
          <div className="bg_d6 p-3">
            <div className="row">
              <div class="col-sm-8">
                <h3 className="text_16_c4">Add New User</h3>
              </div>
              <div className="text-right col-sm-4">
                <a className="text_16_c4" onClick={() => history.goBack("/")}>
                  <MdArrowBack></MdArrowBack> Back
                </a>
              </div>
            </div>
          </div>
          <Container fluid>
            <div className="min-screenheight mt-4">
              <CardBody>
                <Row>
                  <Col sm="12" lg={{ size: 4, offset: 4 }}>
                    <Form form="true" onSubmit={handleSubmit} className="mt-3">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Your email"
                          invalid={errors.email && touched.email ? true : false}
                          onChange={handleChange}
                          values={values.email}
                        />
                        <FormFeedback>
                          {errors.email && touched.email ? errors.email : ""}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="role">Role</Label>
                        <Input
                          type="select"
                          name="role"
                          //invalid={errors?.role && touched?.role ? true : false}
                          onChange={(e) => {
                            setselectedRole(e?.target?.value);
                            if(e?.target?.value=="rm"){
                              setUserList("arm");
                            }   
                            if(e?.target?.value=="arm"){
                              setUserList("rm");
                            }
                            console.log(e?.target?.value,'e?.target?.value')
                          }}
                          value={selectedRole}
                        >
                          {console.log(
                            values.role,
                            "values={values.email}values={values.email}"
                          )}
                          <option value="">Select Role</option>
                          <option value="admin">Admin</option>
                          <option value="superadmin" selected>
                            Super Admin
                          </option>
                          <option value="rm">RM</option>
                          <option value="arm">ARM</option>
                          <option value="ic">IC</option>
                        </Input>
                        {/* <FormFeedback>
                          {errors.role && touched.role ? errors.role : ""}
                        </FormFeedback> */}
                      </FormGroup>
                      {selectedRole == "arm" && (
                        <FormGroup>
                          <Label for="rm">RM User List</Label>
                          <Select
                            name="rm"
                            options={clientOptions}
                            isMulti
                            // defaultValue={{ label:selectedRM,value:"10" }}
                            onChange={(e) => {
                              setopt(e);
                              setinputRMlist(e);
                            handleMultiSelectChangeRM();
                              }}
                            className="select_rad"
                            onInputChange={handleInputRMChanges}
                            value={selectedRM}
                          />
                        </FormGroup>
                      )}
                     
                     {selectedRole == "rm" && (
                        <FormGroup>
                          <Label for="arm">ARM User List</Label>  
                          <Select
                            name="arm"
                            options={clientOptions}
                            isMulti
                            onChange={(e) => {
                              setopt1(e);
                              setinputARMlist(e);
                            handleMultiSelectChange();
                            }}
                            className="select_rad"
                            onInputChange={handleInputARMChanges}
                            value={selectedARM}
                          />
                        </FormGroup>
                      )}
                      {console.log(
                        opt,
                        "searchListusersearchListusersearchListuser"
                      )}

                      <Button
                        type="submit"
                        className="d-block pl-5 pr-5 w-100 pt-2 pb-2 "
                        color="primary"
                      >
                        {loading && <CustomLoader loading={loading} />}
                        Submit
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Container>
        </div>
      </Fragment>
    </div>
  );
}
