import React, { useRef } from "react";
import { useEffect, useState, Fragment } from "react";
import { authHeader, numToPercentage } from "../common/Mainfunctions";
import API from "../redux/API";
import { Row, Col, Card, CardTitle, CardBody, Table } from "reactstrap";
import CustomTableHeader from "../common/CustomTableHeader";
import { PERFORMANCE_SUMMARY_URL } from "../common/urls";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import CustomLoader from "../common/CustomLoader";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { chunk, debounce } from "underscore";

export default function PerformanceSummary(props) {
  const [performanceSummaryData, setPerformanceSummaryData] = useState([]);
  const [performanceMatrixData, setPerformanceMatrixData] = useState([]);
  const [performanceTimeseriesData, setPerformanceTimeseriesData] = useState(
    []
  );
  const [portfolioName, setPortfolioName] = useState("");
  const [xaxis, setXaxis] = useState("");
  const [yaxis, setYaxis] = useState("");
  const [loading, setLoading] = useState(true);
  const [version, setversion] = useState("");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(-100);
  const [chartHeight, setChartHeight] = useState(500);

  const { porfolioId, filter, statusreturn } = props;

  const { year } = filter;
  console.log("filteryear", year);

  useEffect(() => {
    console.log(statusreturn, "kkkaaaaaaaaaaa");
  }, [statusreturn]);

  const tableColumns = [
    { title: "" },
    { title: portfolioName },
    { title: xaxis },
    { title: yaxis },
  ];
  const count_array = [1, 2, 3, 4, 5, 6];
  const tableColumnsPortfolio = [
    { title: "Year" },
    { title: "Jan" },
    { title: "Feb" },
    { title: "March" },
    { title: "April" },
    { title: "May" },
    { title: "June" },
    { title: "July" },
    { title: "Aug" },
    { title: "Sep" },
    { title: "Oct" },
    { title: "Nov" },
    { title: "Dec" },
    { title: "Total" },
  ];
  const matrics_Data_Format = {
    1: {
      field: "his_performance_metrics_12_month_return",
      title: "Last 12 months return",
    },
    2: {
      field: "his_performance_metrics_year_return",
      title: "Year-to-date-return",
    },
    3: {
      field: "his_performance_metrics_annual_return",
      title: "Historical annualized return in time window",
    },
    4: {
      field: "his_performance_metrics_annual_volatility",
      title: "Annualized volatility in the time window",
    },
    5: {
      field: "his_performance_metrics_sharpe_ratio",
      title: "Annual Sharpe Ratio (Rf=0.3%)",
    },
    6: { field: "his_performance_metrics_max_drawdown", title: "Max drawdown" },
  };

  useEffect(() => {
    getPerformanceList();
  }, [porfolioId]);

  const getPerformanceList = async () => {
    const config = {
      method: "GET",
      url: PERFORMANCE_SUMMARY_URL,
      headers: authHeader(),
      params: { id: porfolioId },
    };

    try {
      const response = await API(config);
      const { data = [] } = response.data;

      const {
        his_performance = [],
        his_performance_metrics = [],
        his_time_series = [],
        portfolio_name,
        x_axis,
        y_axis,
      } = data;

      setPerformanceSummaryData(his_performance);
      setPerformanceMatrixData(his_performance_metrics);
      setPerformanceTimeseriesData(his_time_series);
      setPortfolioName(portfolio_name);
      setXaxis(x_axis);
      setYaxis(y_axis);

      let min = minValue;
      let max = maxValue;
      his_performance.map((performance, i) => {
        if (
          parseFloat(performance["his_performance_bal_port_y1"]) < min &&
          performance["his_performance_bal_port_y1"]
        ) {
          min = parseFloat(performance["his_performance_bal_port_y1"]);
        }

        if (
          parseFloat(performance["his_performance_global_equ_y3"]) < min &&
          performance["his_performance_global_equ_y3"]
        ) {
          min = parseFloat(performance["his_performance_global_equ_y3"]);
        }

        if (
          parseFloat(performance["his_performance_msci_y2"]) < min &&
          performance["his_performance_msci_y2"]
        ) {
          min = parseFloat(performance["his_performance_msci_y2"]);
        }

        if (
          performance["his_performance_bal_port_y1"] > max &&
          performance["his_performance_bal_port_y1"]
        ) {
          max = parseFloat(performance["his_performance_bal_port_y1"]);
        }
        if (
          performance["his_performance_global_equ_y3"] > max &&
          performance["his_performance_global_equ_y3"]
        ) {
          max = parseFloat(performance["his_performance_global_equ_y3"]);
        }
        if (
          performance["his_performance_msci_y2"] > max &&
          performance["his_performance_msci_y2"]
        ) {
          max = parseFloat(performance["his_performance_msci_y2"]);
        }
      });

      var convertMinValue = min + min * 0.2;
      var convertMaxValue = max + max * 0.2;

      setMinValue(parseFloat(convertMinValue.toFixed(2)));
      setMaxValue(parseFloat(convertMaxValue.toFixed(2)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filterChange = (query) => {
    console.log(performanceSummaryData);
    console.log("query", query);
    let selOptions = [];

    console.log("pdp", performanceSummaryData);
    if (performanceSummaryData) {
      console.log("pd", performanceSummaryData);
      performanceSummaryData.map((element) => {
        console.log(element.his_performance_bal_port_y1);
      });
    }

    // selOptions.push(filteredOption);
    // setPerformanceSummaryData(selOptions);
  };
  console.log("per", performanceSummaryData);
  useEffect(() => {
    getPerformanceList();
  }, []);
  //Debounce
  // const delayedQuery = useRef(debounce((year) => chk(year), 500)).current;
  // const chk = (q) => {
  //   console.log("chkfun", performanceSummaryData, q);
  //   performanceSummaryData.map((element, index) => {
  //     console.log(element.his_performance_date_x);
  //   });
  //   let filtered = performanceSummaryData.find(
  //     (element) => element.his_performance_date_x === q
  //   );
  //   setPerformanceSummaryData(filtered);
  //   console.log("filterresult", filtered);
  // };
  // useEffect(() => {
  //   chk(year);
  //   // delayedQuery(year);
  // }, [year]);

  useEffect(() => {
    if (
      document.body.clientWidth >= 1200 &&
      document.body.clientWidth <= 1350
    ) {
      setChartHeight(600);
    } else if (
      document.body.clientWidth > 1350 &&
      document.body.clientWidth <= 1450
    ) {
      setChartHeight(650);
    }
  }, [minValue, maxValue, porfolioId]);
  console.log("nreturn", performanceSummaryData);

  return (
    <div className="overflow_hight per_hight pl-4 pr-4 mt-5">
      {statusreturn != "processing" ? (
        <>
          <Row>
            <Col xl="6">
              <Card className="m-0 h-100">
                <CardTitle className="p-0">
                  <p className="text_16_58 my-2 pl-2">
                    Portfolio Historical Performance
                  </p>
                  <hr className="m-0"></hr>
                </CardTitle>

                <CardBody>
                  {!loading ? (
                    <Fragment>
                      <div
                        style={{ width: "100%", height: `${chartHeight}px` }}
                      >
                        <ResponsiveContainer>
                          <LineChart
                            // style={{ width: "100%" }}
                            // width={600}
                            // height={380}
                            data={performanceSummaryData}
                          >
                            <Legend />
                            <Tooltip />
                            <Line
                              type="monotone"
                              name={portfolioName}
                              dataKey="his_performance_bal_port_y1"
                              stroke="#8884d8"
                              dot={false}
                              pointStyle="rectRot"
                              strokeWidth={2}
                            />
                            <Line
                              type="monotone"
                              name={xaxis}
                              dataKey="his_performance_msci_y2"
                              stroke="green"
                              dot={false}
                              pointStyle="rect"
                              strokeWidth={2}
                            />
                            <Line
                              type="monotone"
                              name={yaxis}
                              dataKey="his_performance_global_equ_y3"
                              stroke="black"
                              dot={false}
                              pointStyle="rectRot"
                              strokeWidth={2}
                            />
                            {/* <CartesianGrid stroke="#ccc" /> */}
                            <XAxis dataKey="his_performance_date_x" />
                            <YAxis
                              type="number"
                              step="50"
                              list="tick-list"
                              tickCount={6}
                              tickSize={10}
                              allowDecimals={true}
                              allowDataOverflow={true}
                              // domain={[minValue,maxValue]}
                              domain={[
                                minValue,
                                Math.round(maxValue / 50) * 50,
                              ]}
                              step={50}
                              unit="%"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </Fragment>
                  ) : (
                    loading && <CustomLoader loading={loading} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card className="h-100">
                <CardTitle className="p-0">
                  <p className="text_16_58 my-2 pl-2">
                    {" "}
                    Portfolio Vs Benchmarks
                  </p>
                  <hr className="m-0"></hr>
                </CardTitle>

                <CardBody>
                  <Table hover responsive>
                    {/* <CustomTableHeader tableColumns={tableColumns} /> */}

                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}></th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          {portfolioName}
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          {xaxis}
                        </th>
                        <th style={{ width: "40%", textAlign: "center" }}>
                          {yaxis}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {!loading ? (
                        <Fragment>
                          {count_array.length > 0 ? (
                            count_array.map((element, index) => {
                              return (
                                <tr key={`performance_key_${index}`}>
                                  <th>{matrics_Data_Format[element].title}</th>
                                  {performanceMatrixData.map(
                                    (record, index1) => {
                                      return (
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            record[
                                              matrics_Data_Format[element].field
                                            ]
                                          }
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <CustomEmptyTableRecord
                              columnsCount={performanceMatrixData.length}
                            />
                          )}
                        </Fragment>
                      ) : (
                        <CustomTableLoader columnsCount={tableColumns.length} />
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl="12">
              <Card>
                <CardTitle className="p-0">
                  <p className="text_16_58 my-2 pl-2">
                    Historical Time Series of Portfolio Return
                  </p>
                  <hr className="m-0"></hr>
                </CardTitle>
                <CardBody>
                  <Table hover responsive>
                    <CustomTableHeader tableColumns={tableColumnsPortfolio} />
                    <tbody>
                      {!loading ? (
                        <Fragment>
                          {performanceTimeseriesData.length > 0 ? (
                            performanceTimeseriesData.map((record, index) => {
                              const {
                                his_time_series_date,
                                his_time_series_jan,
                                his_time_series_feb,
                                his_time_series_mar,
                                his_time_series_apr,
                                his_time_series_may,
                                his_time_series_jun,
                                his_time_series_jul,
                                his_time_series_aug,
                                his_time_series_sep,
                                his_time_series_oct,
                                his_time_series_nov,
                                his_time_series_dec,
                                his_time_series_total,
                              } = record;
                              return (
                                <tr key={index + 1}>
                                  <td>{his_time_series_date}</td>
                                  <td>
                                    {(his_time_series_jan * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_feb * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_mar * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_apr * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_may * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_jun * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_jul * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_aug * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_sep * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_oct * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_nov * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_dec * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                  <td>
                                    {(his_time_series_total * 100).toFixed(1) +
                                      "%"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <CustomEmptyTableRecord
                              columnsCount={tableColumnsPortfolio.length}
                            />
                          )}
                        </Fragment>
                      ) : (
                        <CustomTableLoader
                          columnsCount={tableColumnsPortfolio.length}
                        />
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        "Data is being Re-Generated ..."
      )}
    </div>
  );
}
