import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import API from "../redux/API";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import {
  SEARCH_CLIENT_LIST,
  ARM_LIST_URL,
  RM_ARM_LIST_URL,
  CLIENT_LISTING_URL,
  RM_EDIT_URL,
  USER_DETAILS,
  RM_LIST_URL,
} from "../common/urls";
import {
  authHeader,
  getAlert,
  getRmID,
  getAlertToast,
} from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import { useDispatch } from "react-redux";
import Select from "react-select";
import FormFeedback from "reactstrap/lib/FormFeedback";
import Swal from "sweetalert2";

export default function EditSuperAdmin(props) {
  const {
    edituserModal,
    selectUserToggle,
    userId,
    email,
    role,
    profile,
    submitCallBack,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [clientOptions, setClientOptions] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [selectedRole, setselectedRole] = useState("");
  const [roleSelect, setRoleSelect] = useState("");
  const [userList, setUserList] = useState("");
  const [clientList, setClientList] = useState([]);
  const [inputRMlist, setinputRMlist] = useState([]);
  const [searchListuser, setsearchListuser] = useState([]);
  const [rowperpage, setRowPerPage] = useState(1000);
  const [inputARMlist, setinputARMlist] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);
  const [selectedARMOption, setSelectedARMOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [currentpage, setCurrentPage] = useState(0);
  const [list, setList] = useState([]);
  const [inputClientlist, setinputClientlist] = useState([]);
  const [ARMlist, setARMList] = useState([]);
  const [opt, setopt] = React.useState("");
  const [opt1, setopt1] = React.useState("");
  const [selectedARM, setSelectedARM] = useState([]);
  const [selectedRM, setSelectedRM] = useState([]);
  const [formData, setFormData] = useState({
    role: role,
    profile_picture: profile?"" :"",
    is_image_update: "no",
  });


  const validationSchema = Yup.object({
     role: Yup.string().trim().required("Role is required"),
  });
  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: (values) => {
      UpdateUserDetails(values);
      console.log(values,'userListuserListuserListuserList')
    },
  });
  useEffect(() => {
    setselectedRole(role);
  }, [role]);

  const handleMultiSelectChange = (selectedOptions, e) => {
    setSelectedARM(selectedOptions);
  }
  const handleMultiSelectChangeRM= (selectedRMOptions, e) => {
    setSelectedRM(selectedRMOptions);
  }
  const UpdateUserDetails = async (values) => {
    // values.profile_picture = image;
    let selRMlist =
      inputRMlist &&
      inputRMlist.map((option) => {
        return option.value;
      });
    let selOptions =
      inputClientlist &&
      inputClientlist.map((option) => {
        return option.value;
      });
    let selARMOptions =
      inputARMlist &&
      inputARMlist.map((option) => {
        return option.value;
      });
    console.log(selRMlist, "selRMclientselRMclientselRMclient");
    values.clients = selOptions.join(",");
    values.arm_users = selRMlist.join(",");
    values.rm_users = selARMOptions.join(",");

    const fileData = new FormData();
    const formData = new FormData();

    const { profile_picture, is_image_update } = formData;
    formData.append("role", selectedRole);
    formData.append("is_image_update", formik.values.is_image_update);
    formData.append("profile_picture", formik.values.profile_picture);
console.log(formik.values.profile_picture,'formik.values.profile_pictureformik.values.profile_picture')
    if (selectedRole == "rm") {
      formData.append("email", email);
      formData.append("rm_users", formik.values.rm_users);
    }

    if (selectedRole == "arm") {
      formData.append("email", email);
      formData.append("rm_users", formik.values.arm_users);
    }

    // if (is_image_update == "yes") {
    //   fileData.append("profile_picture", profile_picture);
    // } else {
    //   fileData.append("profile_picture", "");
    // }
    // fileData.append("clients", values.clients);

    console.log(values);
    setLoading(true);
    const config = {
      method: "POST",
      url: USER_DETAILS + userId,
      headers: authHeader(),
      data: formData,
      "content-type": "multipart/form-data",
    };
    try {
      const response = await API(config);
      const { status, uploadfile } = response.data;
      if (status == "Success") {
        console.log("success");
        localStorage.setItem("PROFILE_IMAGE", uploadfile);

        Swal.fire(getAlertToast("Success", "Profile Updated Successfully"));
      }
      submitCallBack();
    } catch (error) {
      console.log(error[0], "kkkkkkkkkkkkkkkkkk777777777777777777");

      Swal.fire(getAlert("Error", error.message));
    } finally {
      setLoading(false);
    }
  };
  const RMDetails = async () => {
    const config = {
      method: "GET",
      url: RM_EDIT_URL + userId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      const { arm = [] } = data;
      const { rm = [] } = data;
      let armselclient = [];
      let rmselclient=[];
      arm.map((record) => {
        armselclient.push({ label: record.email, value: record.id });
      });
      rm.map((record) => {
        rmselclient.push({ label: record.email, value: record.id });
      });
      setSelectedARM(armselclient);
      setSelectedRM(rmselclient);
      console.log(
        rmselclient,
        "selclientselclientselclientselclientselclientselclient"
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RM_LIST_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      let List = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setList(List);
      console.log(List, "dddddddddddddddddddddddddd");
      setTotalRecords(totalrecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClientList();
  }, []);

  const getARM_LIST_URLList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: ARM_LIST_URL + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      console.log(data, "5555555555555555555555555555");
      let cList = data.map((e, i) => ({
        label: e.client_name,
        value: e.client_id,
      }));
      setARMList(cList);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getARM_LIST_URLList();
  }, [getRmID]);

  const choosenImage = (event) => {
    console.log(event.target.files, "event555555555555555555555555555555555");
    setFormData({
      ...formData,
      profile_picture: event.target.files[0],
      is_image_update: "yes",
    });
    formik.setFieldValue("profile_picture", event.target.files[0]);
  };
  useEffect(()=>{
    if(role=="rm"){
      setRoleSelect("arm")
    }
    if(role=="arm"){
      setRoleSelect("rm")
    }
  },[role,roleSelect]);

console.log(roleSelect,'User ManagementUser Management')
  const getRM_ARM_NameList = async (search) => {
    const config = {
      method: "GET",
      url: RM_ARM_LIST_URL,
      headers: authHeader(),
      params: {
        role: userList ==""? roleSelect: userList ,
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setClientOptions(clientList);
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };

  useEffect(() => {
    getRM_ARM_NameList();
    RMDetails();
  }, [selectedRole,userList]);

  const searchClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SEARCH_CLIENT_LIST,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setsearchListuser(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: CLIENT_LISTING_URL,
      headers: authHeader(),
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let client = data.map((e, i) => ({
        label: e.client_name,
        value: e.client_id,
      }));
      setClientList(client);
      console.log(client, "444444444444444444444");
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };
  useEffect(() => {
    getClientNameList();
    RMDetails();
  }, []);

  const handleInputChange = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputlist);
    return inputlist;
  };

  const handleInputChanges = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    searchClientList(inputlist);
    return inputlist;
  };

  useEffect(() => {}, [handleInputChange]);
  //...........................
  const handleInputARMChange = (newValue) => {
    const inputARMlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputARMlist);
    return inputARMlist;
  };

  const handleInputARMChanges = (newValue) => {
    const inputARMlist = newValue.replace(/\W/g, "");
    searchClientList(inputARMlist);
    return inputARMlist;
  };

  useEffect(() => {}, [handleInputARMChange]);
  //.....................................
  const handleInputRMChange = (newValue) => {
    const inputRMlist = newValue.replace(/\W/g, "");
    getRM_ARM_NameList(inputRMlist);
    return inputRMlist;
  };

  const handleInputRMChanges = (newValue) => {
    const inputRMlist = newValue.replace(/\W/g, "");
    searchClientList(inputRMlist);
    return inputRMlist;
  };

  useEffect(() => {}, [handleInputRMChange]);
  return (
    <Modal isOpen={edituserModal} toggle={selectUserToggle} className="">
      <ModalHeader toggle={selectUserToggle}>Edit Profile</ModalHeader>
      <ModalBody>
        <div className="mt-4 mb-2 p-2">
          <Form form="true" onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm="12">
                <Row>
                  <Col md="12" className="mb-3">
                    <div className="editProOption">
                      <img class="update-avatar d-block" src={profile} />

                      <Input
                        // id="upload"
                        class="profile_picture__input"
                        type="file"
                        name="profile_picture"
                        id="profile_picture"
                        accept=".png, .jpg"
                        onChange={choosenImage}
                        values={formik.values.profile_picture}
                      />
                      <label for="upload">Choose file</label>
                    </div>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label for=""> Email</Label>
                      <Input
                        type="text"
                        name=""
                        id=""
                        // placeholder="Enter first name"
                        value={email}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label for="role">Role</Label>
                      <Input
                          type="select"
                          name="role"
                          //invalid={errors?.role && touched?.role ? true : false}
                          onChange={(e) => {
                            setselectedRole(e?.target?.value);
                            if(e?.target?.value=="rm"){
                              setUserList("arm");
                            }
                            if(e?.target?.value=="arm"){
                              setUserList("rm");
                            }
                            console.log(e?.target?.value,'e?.target?.value')
                          }}
                          value={selectedRole}
                        >
                        
                          <option value="">Select Role</option>
                          <option value="admin">Admin</option>
                          <option value="superadmin" selected>
                            Super Admin
                          </option>
                          <option value="rm">RM</option>
                          <option value="arm">ARM</option>
                          <option value="ic">IC</option>
                        </Input>
                      {console.log(
                        selectedRole,
                        "oooooooooooooooooooooooooooooooo"
                      )}
                      <FormFeedback>
                        {formik.errors.role && formik.touched.role
                          ? formik.errors.role
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    {console.log(role, "getRmIDgetRmIDgetRmIDgetRmID")}
                    {/*..RM... LIST...*/}

                    {selectedRole == "arm" && (
                        <FormGroup>
                          <Label for="rm">RM User List</Label>
                          <Select
                            name="rm"
                            options={clientOptions}
                            isMulti
                            // defaultValue={{ label:selectedRM,value:"10" }}
                            onChange={(e) => {
                              setopt(e);
                              setinputRMlist(e);
                            handleMultiSelectChangeRM();
                              }}
                            className="select_rad"
                            onInputChange={handleInputRMChanges}
                            value={selectedRM}
                          />
                        </FormGroup>
                      )}
                    <FormFeedback>
                      {formik.errors.rm && formik.touched.rm
                        ? formik.errors.rm
                        : ""}
                    </FormFeedback>

                    {selectedRole == "rm" && (
                        <FormGroup>
                          <Label for="arm">ARM User List</Label>  
                          <Select
                            name="arm"
                            options={clientOptions}
                            isMulti
                            onChange={(e) => {
                              setopt1(e);
                              setinputARMlist(e);
                            handleMultiSelectChange();
                            }}
                            className="select_rad"
                            onInputChange={handleInputARMChanges}
                            value={selectedARM}
                          />
                        </FormGroup>
                      )}
                    {console.log(selectedARM,
                      "selectedARMOptionselectedARMOptionselectedARMOption"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button color="primary" className="d-block m-auto" type="submit">
              {loading && <CustomLoader loading={loading} />}
              Update
            </Button>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}
