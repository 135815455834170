import React, { Fragment, useState } from "react";
import { UncontrolledCollapse } from "reactstrap";
import NumberFormat from "react-number-format";
export default function PortfolioSummary(props) {
  const { formValues = {}, exposureList = [], regValues = {} } = props;
  const [type, setType] = useState("currency");

  const {
    portfolio_name = "",
    portfolio_currency = "",
    portfolio_exposure2_status = 0,
    portfolio_clientequity = "",
    portfolio_costofborrowing = 0,
    portfolio_it_status = 0,
    portfolio_core_percentage = 0,
    portfolio_leverage = 0,
    portfolio_realestate_status = 0,
    portfolio_viceindustries_status = 0,
    portfolio_financials_status = 0,
    portfolio_financialscoco_status = 0,
    portfolio_shariahcomplaint = false,
    portfolio_coreunit = "%",
    portfolio_expect_annualreturn,
    portfolio_expect_maxdrawdown,
    // portfolio_cashflow_yield,
    portfolio_liquidity,
    portfolio_alternative_directholding_min = 0,
    portfolio_alternative_max = 0,
    portfolio_alternative_min = 0,
    portfolio_alternative_directholding_max = 0,
    portfolio_cash_max = 0,
    portfolio_cash_min = 0,
    portfolio_equities_directholding_min = 0,
    portfolio_equities_directholding_max = 0,
    portfolio_equities_min = 0,
    portfolio_equities_max = 0,
    portfolio_fixedincome_directholding_min = 0,
    portfolio_fixedincome_directholding_max = 0,
    portfolio_fixedincome_min = 0,
    portfolio_fixedincome_max = 0,
    portfolio_exposure_value,
    portfolio_financialscoco_min = 0,
    portfolio_financialscoco_max = 0,
    portfolio_credit_max = 0,
    portfolio_credit_min = 0,
    portfolio_usd_max,
    portfolio_usd_min,
    portfolio_eur_min,
    portfolio_eur_max,
    portfolio_gbp_min,
    portfolio_gbp_max,
    portfolio_jpy_min,
    portfolio_jpy_max,
    portfolio_aud_min,
    portfolio_aud_max,
    portfolio_nzd_max,
    portfolio_nzd_min,
    portfolio_cad_min,
    portfolio_cad_max,
    portfolio_chf_min,
    portfolio_chf_max,
    portfolio_nok_min,
    portfolio_nok_max,
    portfolio_sek_min,
    portfolio_sek_max,
    portfolio_viceindustries_min = 0,
    portfolio_viceindustries_max = 0,
    portfolio_realestate_min = 0,
    portfolio_realestate_max = 0,
    portfolio_it_min = 0,
    portfolio_it_max = 0,
    portfolio_healthcare_min = 0,
    portfolio_healthcare_max = 0,
    portfolio_financials_min = 0,
    portfolio_financials_max = 0,
    portfolio_discretionary_min = 0,
    portfolio_discretionary_max = 0,
    portfolio_comservice_min = 0,
    portfolio_comservice_max = 0,
    portfolio_industrials_min = 0,
    portfolio_industrials_max = 0,
    portfolio_staples_min = 0,
    portfolio_staples_max = 0,
    portfolio_energy_min = 0,
    portfolio_energy_max = 0,
    portfolio_utilities_min = 0,
    portfolio_utilities_max = 0,
    portfolio_materials_min = 0,
    portfolio_materials_max = 0,
    portfolio_esc_viceindustries_min = 0,
    portfolio_esc_viceindustries_max = 0,
    portfolio_esc_it_min = 0,
    portfolio_esc_realestate_min = 0,
    portfolio_esc_realestate_max = 0,
    portfolio_esc_it_max = 0,
    portfolio_esc_healthcare_min = 0,
    portfolio_esc_healthcare_max = 0,
    portfolio_esc_financials_min = 0,
    portfolio_esc_financials_max = 0,
    portfolio_esc_discretionary_min = 0,
    portfolio_esc_discretionary_max = 0,
    portfolio_esc_comservice_min = 0,
    portfolio_esc_comservice_max = 0,
    portfolio_esc_industrials_min = 0,
    portfolio_esc_industrials_max = 0,
    portfolio_esc_staples_min = 0,
    portfolio_esc_staples_max = 0,
    portfolio_esc_energy_min = 0,
    portfolio_esc_energy_max = 0,
    portfolio_esc_utilities_min = 0,
    portfolio_esc_utilities_max = 0,
    portfolio_esc_materials_min = 0,
    portfolio_esc_materials_max = 0,
    portfolio_cash_status,
    portfolio_fixedincome_status,
    portfolio_equities_status,
    portfolio_alternative_status,
    portfolio_exposure_status,
    portfolio_credit_status,
    portfolio_esc_viceindustries_status,
    portfolio_esc_realestate_status,
    portfolio_esc_it_status,
    portfolio_healthcare_status,
    portfolio_finland_status,
    portfolio_comservice_status,
    portfolio_discretionary_status,
    portfolio_industrials_status,
    portfolio_staples_status,
    portfolio_energy_status,
    portfolio_utilities_status,
    portfolio_materials_status,
    portfolio_usd_status,
    portfolio_eur_status,
    portfolio_gbp_status,
    portfolio_jpy_status,
    portfolio_aud_status,
    portfolio_nzd_status,
    portfolio_cad_status,
    portfolio_chf_status,
    portfolio_nok_status,
    portfolio_sek_status,
    portfolio_esc_healthcare_status,
    portfolio_esc_financials_status,
    portfolio_esc_comservice_status,
    portfolio_esc_discretionary_status,
    portfolio_esc_industrials_status,
    portfolio_esc_staples_status,
    portfolio_esc_energy_status,
    portfolio_esc_utilities_status,
    portfolio_esc_materials_status,
    portfolio_exposure_IG,
    portfolio_exposure_HY,
    portfolio_asia_status,
    portfolio_americas_status,
    portfolio_ec_uk_status,
    portfolio_emerging_markets_status,
    portfolio_global1_status,
    portfolio_global2_status,
    portfolio_mena_status,
    portfolio_exposure_min,
    portfolio_exposure_max,

    portfolio_exposure2_min,
    portfolio_exposure2_max,
  } = formValues;
  let port;
  if (portfolio_coreunit == "%") {
    port = portfolio_core_percentage;
  } else {
    port = portfolio_core_percentage
      .toString()

      .replace(/[^\d.]/g, "")

      .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

      .replace(/\.(\d{2})\d+/, ".$1")

      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let exposureFilterList =
    exposureList &&
    exposureList.length > 0 &&
    exposureList.find(
      (obj) => obj.lc_exposure_id === parseInt(portfolio_exposure_value)
    );
  let { lc_exposure_name = "" } = exposureFilterList || {};

  return (
    <div className="portfolio-summary">
      <h4 className="">Portfolio Summary</h4>
      <div className="overflow_hight">
        <div className="portfolio-details">
          <h5 className="">Porfolio Asset Inputs</h5>
          <ul>
            <li>Portfolio name</li>
            <li>
              <span>{portfolio_name}</span>
            </li>
          </ul>
          <ul>
            <li>Currency</li>
            <li>
              <span>{portfolio_currency}</span>
            </li>
          </ul>
          <ul>
            <li>Client equity</li>
            <li>
              <span>
                <NumberFormat
                  thousandSeparator={true}
                  value={portfolio_clientequity}
                  displayType={"text"}
                />
                {/* .toString()

                .replace(/[^\d.]/g, "")

                .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

                .replace(/\.(\d{2})\d+/, ".$1")

                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
              </span>
            </li>
          </ul>
          {portfolio_costofborrowing != null && (
            <ul>
              <li>Cost of Borrowing</li>
              <li>
                <span>{portfolio_costofborrowing} bps</span>
              </li>
            </ul>
          )}
          {portfolio_coreunit != 0 && (
            <ul>
              <li>Core</li>
              {portfolio_coreunit == "$" && (
                <li>
                  <span>
                    {portfolio_coreunit}
                    {port}
                  </span>
                </li>
              )}
              {portfolio_coreunit != "$" && (
                <li>
                  <span>
                    {port}
                    {portfolio_coreunit}
                  </span>
                </li>
              )}
            </ul>
          )}
          {portfolio_leverage != null && (
            <ul>
              <li>Leverage</li>
              <li>
                <span>{portfolio_leverage}</span>
              </li>
            </ul>
          )}
          {portfolio_shariahcomplaint != 0 && (
            <ul>
              <li>Shariah Compliant</li>
              <li>
                <span>{portfolio_shariahcomplaint ? "Yes" : "No"}</span>
              </li>
            </ul>
          )}
          <span style={{ margin: "4rem" }}></span>
        </div>
        <div className="portfolio-details">
          <h5 className="">Portfolio Optimisation Inputs</h5>
          {portfolio_expect_annualreturn != 0 && (
            <ul>
              <li>Expected annual return </li>
              <li>
                <span>{portfolio_expect_annualreturn}%</span>
              </li>
            </ul>
          )}
          {portfolio_expect_maxdrawdown != 0 && (
            <ul>
              <li> Min Expected Drawdown</li>
              <li>
                <span>{portfolio_expect_maxdrawdown}%</span>
              </li>
            </ul>
          )}
          {/* {portfolio_cashflow_yield != 0 && (
            <ul>
              <li>Cash flow Yield </li>
              <li>
                <span>
                  {portfolio_cashflow_yield}%
                  {console.log(
                    portfolio_cashflow_yield,
                    "ssssssssssssssssssssssss"
                  )}
                </span>
              </li>
            </ul>
          )} */}
          {portfolio_liquidity != 0 && (
            <ul>
              <li>Liquidity (Monthly)</li>
              <li>
                <span>{portfolio_liquidity}%</span>
              </li>
            </ul>
          )}
          <span style={{ margin: "4rem" }}></span>
        </div>
        <div className="portfolio-details pb-4">
          <h5>Portfolio Optimisation Constraints</h5>
          {(portfolio_cash_status != 0 ||
            portfolio_fixedincome_status != 0 ||
            portfolio_equities_status != 0 ||
            portfolio_alternative_status != 0) && (
            <ul className="multi-sublist">
              <li>
                <b>Asset Level</b>
              </li>
              <li>
                {portfolio_cash_status != 0 && (
                  <span>
                    Cash ({portfolio_cash_min ? portfolio_cash_min : 0} -{" "}
                    {portfolio_cash_max ? portfolio_cash_max : 0}) {"%"}
                  </span>
                )}

                {portfolio_fixedincome_status != 0 && (
                  <span className="hu">
                    Fixed Income (
                    {portfolio_fixedincome_min ? portfolio_fixedincome_min : 0}%{" "}
                    -{" "}
                    {portfolio_fixedincome_max ? portfolio_fixedincome_max : 0}){" "}
                    <br></br>
                    Fixed Income Direct holdings(
                    {portfolio_fixedincome_directholding_min
                      ? portfolio_fixedincome_directholding_min
                      : 0}
                    % -{" "}
                    {portfolio_fixedincome_directholding_max
                      ? portfolio_fixedincome_directholding_max
                      : 0}
                    % )
                  </span>
                )}
                {portfolio_equities_status != 0 && (
                  <span className="hu">
                    Equities (
                    {portfolio_equities_min ? portfolio_equities_min : 0}% -{" "}
                    {portfolio_equities_max ? portfolio_equities_max : 0}% ){" "}
                    <br></br>
                    Equities Direct holdings(
                    {portfolio_equities_directholding_min
                      ? portfolio_equities_directholding_min
                      : 0}
                    % -{" "}
                    {portfolio_equities_directholding_max
                      ? portfolio_equities_directholding_max
                      : 0}
                    % )
                  </span>
                )}
                {portfolio_alternative_status != 0 && (
                  <span className="hu">
                    Alternatives (
                    {portfolio_alternative_min ? portfolio_alternative_min : 0}%{" "}
                    -{" "}
                    {portfolio_alternative_max ? portfolio_alternative_max : 0}%
                    ) <br></br>
                    Alternatives Direct holdings(
                    {portfolio_alternative_directholding_min
                      ? portfolio_alternative_directholding_min
                      : 0}
                    % -{" "}
                    {portfolio_alternative_directholding_max
                      ? portfolio_alternative_directholding_max
                      : 0}
                    % )
                  </span>
                )}
              </li>
            </ul>
          )}

          {regValues.length > 0 && (
            <ul className="multi-sublist">
              <li>
                <b>Regional</b>
              </li>
              {regValues &&
                regValues.slice(0, 3).map((region, key) => {
                  return (
                    <li>
                      {" "}
                      <span key={key}>
                        {region.country_name} ({region.min ? region.min : 0}%-
                        {region.max ? region.max : 0}%)
                      </span>
                    </li>
                  );
                })}
              {regValues && regValues.length > 3 ? (
                <Fragment>
                  <a id="more1">+{regValues.length - 3} more</a>
                  <UncontrolledCollapse toggler="#more1">
                    {regValues &&
                      regValues.slice(3).map((region) => {
                        return (
                          <li>
                            <span key={region.country_id}>
                              {region.country_name}(
                              {region.min ? region.min : 0}
                              %-
                              {region.max ? region.max : 0}%)
                            </span>
                          </li>
                        );
                      })}
                  </UncontrolledCollapse>
                </Fragment>
              ) : (
                ""
              )}
            </ul>
          )}
          {(portfolio_exposure_status != 0 ||
            portfolio_exposure2_status != 0 ||
            portfolio_credit_status != 0 ||
            portfolio_financialscoco_status != 0 ||
            portfolio_credit_status != 0) && (
            <ul className="multi-sublist">
              <li>
                <b>Rating Constraints</b>
              </li>
              {portfolio_exposure_status != 0 && (
                <li>
                  <span>
                    IG ({portfolio_exposure_min ? portfolio_exposure_min : 0}
                    %-
                    {portfolio_exposure_max ? portfolio_exposure_max : 0}%)
                  </span>
                </li>
              )}
              {portfolio_exposure2_status != 0 && (
                <li>
                  <span>
                    HY ({portfolio_exposure2_min ? portfolio_exposure2_min : 0}
                    %-
                    {portfolio_exposure2_max ? portfolio_exposure2_max : 0}
                    %)
                  </span>
                </li>
              )}

              <li>
                {lc_exposure_name != "" && (
                  <span>Exposure - {lc_exposure_name}</span>
                )}
                {portfolio_financialscoco_status != 0 && (
                  <span>
                    Ex to Financials (
                    {portfolio_financialscoco_min
                      ? portfolio_financialscoco_min
                      : 0}
                    %-
                    {portfolio_financialscoco_max
                      ? portfolio_financialscoco_max
                      : 0}
                    %)
                  </span>
                )}
                {portfolio_credit_status != 0 && (
                  <span>
                    Ex to Structured (
                    {portfolio_credit_min ? portfolio_credit_min : 0}%-
                    {portfolio_credit_max ? portfolio_credit_max : 0}%)
                  </span>
                )}
              </li>
            </ul>
          )}

          {(portfolio_viceindustries_status != 0 ||
            portfolio_realestate_status != 0 ||
            portfolio_it_status != 0 ||
            portfolio_healthcare_status != 0 ||
            portfolio_financials_status != 0 ||
            portfolio_comservice_status != 0 ||
            portfolio_discretionary_status != 0 ||
            portfolio_industrials_status != 0 ||
            portfolio_staples_status != 0 ||
            portfolio_energy_status != 0 ||
            portfolio_utilities_status != 0 ||
            portfolio_materials_status != 0) && (
            <ul className="multi-sublist">
              <li>
                <b>Sector Constraints</b>
              </li>
              {/* {type == "fixed" && ( */}
              <li>
                {portfolio_viceindustries_status != 0 && (
                  <span>
                    Ex to Vice Industries (
                    {portfolio_viceindustries_min
                      ? portfolio_viceindustries_min
                      : 0}
                    %-
                    {portfolio_viceindustries_max
                      ? portfolio_viceindustries_max
                      : 0}
                    %)
                  </span>
                )}
                {portfolio_realestate_status != 0 && (
                  <span>
                    Ex to REITs / Real ..(
                    {portfolio_realestate_min ? portfolio_realestate_min : 0}
                    %-
                    {portfolio_realestate_min ? portfolio_realestate_max : 0}
                    %)
                  </span>
                )}
                {portfolio_it_status != 0 && (
                  <span>
                    Ex Information T...(
                    {portfolio_it_min ? portfolio_it_min : 0}%-
                    {portfolio_it_max ? portfolio_it_max : 0}%)
                  </span>
                )}
                {portfolio_healthcare_status != 0 && (
                  <span>
                    Exposure to Health Care(
                    {portfolio_healthcare_min ? portfolio_healthcare_min : 0}
                    %-
                    {portfolio_healthcare_max ? portfolio_healthcare_max : 0}
                    %)
                  </span>
                )}
                {portfolio_financials_status != 0 && (
                  <span>
                    Exposure to Financials (
                    {portfolio_financials_min ? portfolio_financials_min : 0}
                    %-
                    {portfolio_financials_max ? portfolio_financials_max : 0}
                    %)
                  </span>
                )}
                {portfolio_comservice_status != 0 && (
                  <span>
                    Exposure to Communication Services (
                    {portfolio_comservice_min ? portfolio_comservice_min : 0}
                    %-
                    {portfolio_comservice_max ? portfolio_comservice_max : 0}
                    %)
                  </span>
                )}
                {portfolio_discretionary_status != 0 && (
                  <span>
                    Exposure to Consumer Discretionary (
                    {portfolio_discretionary_min
                      ? portfolio_discretionary_min
                      : 0}
                    %-
                    {portfolio_discretionary_max
                      ? portfolio_discretionary_max
                      : 0}
                    %)
                  </span>
                )}
                {portfolio_industrials_status != 0 && (
                  <span>
                    Exposure to Industrials (
                    {portfolio_industrials_min ? portfolio_industrials_min : 0}
                    %-
                    {portfolio_industrials_max ? portfolio_industrials_max : 0}
                    %)
                  </span>
                )}
                {portfolio_staples_status != 0 && (
                  <span>
                    Exposure to Consumer Staples (
                    {portfolio_staples_min ? portfolio_staples_min : 0}%-
                    {portfolio_staples_max ? portfolio_staples_max : 0}%)
                  </span>
                )}
                {portfolio_energy_status != 0 && (
                  <span>
                    Exposure to Consumer Energy(
                    {portfolio_energy_min ? portfolio_energy_min : 0}%-
                    {portfolio_energy_max ? portfolio_energy_max : 0}%)
                  </span>
                )}
                {portfolio_utilities_status != 0 && (
                  <span>
                    Exposure to Consumer Utilities (
                    {portfolio_utilities_min ? portfolio_utilities_min : 0}
                    %-
                    {portfolio_utilities_max ? portfolio_utilities_max : 0}
                    %)
                  </span>
                )}
                {portfolio_materials_status != 0 && (
                  <span>
                    Exposure to Consumer Materials (
                    {portfolio_materials_min ? portfolio_materials_min : 0}
                    %-
                    {portfolio_materials_max ? portfolio_materials_max : 0}
                    %)
                  </span>
                )}
              </li>
              {/* )} */}
            </ul>
          )}

          {(portfolio_usd_status != 0 ||
            portfolio_eur_status != 0 ||
            portfolio_gbp_status != 0 ||
            portfolio_jpy_status != 0 ||
            portfolio_aud_status != 0 ||
            portfolio_nzd_status != 0 ||
            portfolio_cad_status != 0 ||
            portfolio_chf_status != 0 ||
            portfolio_nok_status != 0 ||
            portfolio_sek_status != 0) && (
            <ul className="multi-sublist">
              <li>
                <b>Currency Constraints</b>
              </li>

              {type == "currency" && (
                <li>
                  {portfolio_usd_status != 0 && (
                    <span>
                      USD ({portfolio_usd_min ? portfolio_usd_min : 0}%-
                      {portfolio_usd_max ? portfolio_usd_max : 0}%)
                    </span>
                  )}
                  {/* {portfolio_eur_status != 0 && (
                    <span>
                      EUR({portfolio_eur_min ? portfolio_eur_min : 0}%-
                      {portfolio_eur_max ? portfolio_eur_max : 0}%)
                    </span>
                  )}
                  {portfolio_gbp_status != 0 && (
                    <span>
                      GBP({portfolio_gbp_min ? portfolio_gbp_min : 0}%-
                      {portfolio_gbp_max ? portfolio_gbp_max : 0}%)
                    </span>
                  )}
                  {portfolio_jpy_status != 0 && (
                    <span>
                      JPY ({portfolio_jpy_min ? portfolio_jpy_min : 0}%-
                      {portfolio_jpy_max ? portfolio_jpy_max : 0}%)
                    </span>
                  )}
                  {portfolio_aud_status != 0 && (
                    <span>
                      AUD ({portfolio_aud_min ? portfolio_aud_min : 0}%-
                      {portfolio_aud_max ? portfolio_aud_max : 0}%)
                    </span>
                  )}
                  {portfolio_nzd_status != 0 && (
                    <span>
                      NZD ({portfolio_nzd_min ? portfolio_nzd_min : 0}%-
                      {portfolio_nzd_max ? portfolio_nzd_max : 0}%)
                    </span>
                  )}
                  {portfolio_cad_status != 0 && (
                    <span>
                      CAD ({portfolio_cad_min ? portfolio_cad_min : 0}%-
                      {portfolio_cad_max ? portfolio_cad_max : 0}%)
                    </span>
                  )}
                  {portfolio_chf_status != 0 && (
                    <span>
                      CHF ({portfolio_chf_min ? portfolio_chf_min : 0}%-
                      {portfolio_chf_max ? portfolio_chf_max : 0}%)
                    </span>
                  )}
                  {portfolio_nok_status != 0 && (
                    <span>
                      NOK ({portfolio_nok_min ? portfolio_nok_min : 0}%-
                      {portfolio_nok_max ? portfolio_nok_max : 0}%)
                    </span>
                  )}
                  {portfolio_sek_status != 0 && (
                    <span>
                      SEK ({portfolio_sek_min ? portfolio_sek_min : 0}%-
                      {portfolio_sek_max ? portfolio_sek_max : 0}%)
                    </span>
                  )}*/}
                </li> 
              )}
            </ul>
          )}

          <ul className="multi-sublist">
            {/* <li>Equities Sector Constraints</li> */}
            {/* {type == "equities" && ( */}
            <li>
              {portfolio_esc_realestate_status != 0 && (
                <span>
                  Ex to REITs / Real ..(
                  {portfolio_esc_realestate_min
                    ? portfolio_esc_realestate_min
                    : 0}
                  %-
                  {portfolio_esc_realestate_max
                    ? portfolio_esc_realestate_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_it_status != 0 && (
                <span>
                  Ex Information T...(
                  {portfolio_esc_it_min ? portfolio_esc_it_min : 0}%-
                  {portfolio_esc_it_max ? portfolio_esc_it_max : 0}
                  %)
                </span>
              )}
              {portfolio_esc_healthcare_status != 0 && (
                <span>
                  Exposure to Health Care(
                  {portfolio_esc_healthcare_min
                    ? portfolio_esc_healthcare_min
                    : 0}
                  %-
                  {portfolio_esc_healthcare_max
                    ? portfolio_esc_healthcare_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_financials_status != 0 && (
                <span>
                  Exposure to Financials (
                  {portfolio_esc_financials_min
                    ? portfolio_esc_financials_min
                    : 0}
                  %-
                  {portfolio_esc_financials_max
                    ? portfolio_esc_financials_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_comservice_status != 0 && (
                <span>
                  Exposure to Communication Services (
                  {portfolio_esc_comservice_min
                    ? portfolio_esc_comservice_min
                    : 0}
                  %-
                  {portfolio_esc_comservice_max
                    ? portfolio_esc_comservice_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_discretionary_status != 0 && (
                <span>
                  Exposure to Consumer Discretionary (
                  {portfolio_esc_discretionary_min
                    ? portfolio_esc_discretionary_min
                    : 0}
                  %-
                  {portfolio_esc_discretionary_max
                    ? portfolio_esc_discretionary_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_industrials_status != 0 && (
                <span>
                  Exposure to Industrials (
                  {portfolio_esc_industrials_min
                    ? portfolio_esc_industrials_min
                    : 0}
                  %-
                  {portfolio_esc_industrials_max
                    ? portfolio_esc_industrials_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_staples_status != 0 && (
                <span>
                  Exposure to Consumer Staples (
                  {portfolio_esc_staples_min ? portfolio_esc_staples_min : 0}
                  %-
                  {portfolio_esc_staples_max ? portfolio_esc_staples_max : 0}
                  %)
                </span>
              )}
              {portfolio_esc_energy_status != 0 && (
                <span>
                  Exposure to Consumer Energy(
                  {portfolio_esc_energy_min ? portfolio_esc_energy_min : 0}
                  %-
                  {portfolio_esc_energy_max ? portfolio_esc_energy_max : 0}
                  %)
                </span>
              )}
              {portfolio_esc_utilities_status != 0 && (
                <span>
                  Exposure to Consumer Utilities (
                  {portfolio_esc_utilities_min
                    ? portfolio_esc_utilities_min
                    : 0}
                  %-
                  {portfolio_esc_utilities_max
                    ? portfolio_esc_utilities_max
                    : 0}
                  %)
                </span>
              )}
              {portfolio_esc_materials_status != 0 && (
                <span>
                  Exposure to Consumer Materials (
                  {portfolio_esc_materials_min
                    ? portfolio_esc_materials_min
                    : 0}
                  %-
                  {portfolio_esc_materials_max
                    ? portfolio_esc_materials_max
                    : 0}
                  %)
                </span>
              )}
            </li>
            {/* )} */}
          </ul>
        </div>
      </div>
    </div>
  );
}
