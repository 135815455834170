import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Table,
  Button,
  Row,
  Col,
  Tooltip,
  CardBody,
  Modal,
} from "reactstrap";
import classnames from "classnames";

import Bonds from "./AssetBreakdown/Bonds";
import Recommended from "./AssetBreakdown/Recommended";
import Stocks from "./AssetBreakdown/Stocks";
import Funds from "./AssetBreakdown/Funds";
import NumberFormat from "react-number-format";
import { authHeader } from "../common/Mainfunctions";
import { ASSET_SUMMARY_URL } from "../common/urls";
import API from "../redux/API";
import Swal from "sweetalert2";
import { getAlert } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import moment from "moment";
import {
  SAVE_ALLOCATION,
  PIE_CHART,
  PIE_CHART_COMP,
  PORTFOLIO_GET,
  RESET_MODIFYALLOCATION,
  SAVE_MODIFYALLOCATION,
} from "../common/urls";

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Sector,
  ResponsiveContainer,
} from "recharts";

function useMergeState(initialState) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState) =>
    setState((prevState) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

export default function AssetBreakdown(props) {
  const colorSet = {
    Alternatives: "#B74E09",
    Equities: "#61B22E",
    "Fixed Income": "#4B2DF7",
    "Portfolio Hedge": "#5EB999",
    "Direct Holdings - Bonds": "#5DBDE7",
    "Direct Holdings - Stocks": "#DD629B",
    "Funds - Commodities Long Only": "#B2A6A3",
    "Funds - Commodities Non-Directional": "#C9212C",
    "Funds - Credit Long Only": "#E63DC4",
    "Funds - Credit Long/Short": "#A13C50",
    "Funds - Equities Long Biased": "#4E4327",
    "Funds - Equities Long Only": "#76A9CA",
    "Funds - Equities Long/Short": "#DD7C03",
    "Funds - Equities Long/Short Directional": "#80D077",
    "Funds - Healthcare Long/Short": "#48A6B8",
    "Funds - Insurance Linked Securities": "#AC9FA1",
    "Funds - Long Biased CDS": "#D84CE4",
    "Funds - Long Biased Credit": "#D6FE6E",
    "Funds - Macro": "#D67956",
    "Funds - Macro Arbitrage": "#158AA4",
    "Funds - Macro RV": "#A7D400",
    "Funds - Merger and SPACs Arbitrage": "#381902",
    "Funds - Multi Sector Credit": "#6AD714",
    "Funds - Multi-Strat": "#0FBA51",
    "Funds - Structured Credit": "#EF274A",
    "Funds - Systematic Volatility Arbitrage": "#9E6D0A",
    "Funds - Systematic Volatility Relative Value": "#578C79",
    "Funds - Trade Finance": "#990B61",
    "Funds - U.S. Secondary Life Insurance": "#990c61",
    "Notes - AI/Quant": "#F57ADD",
    "Notes - Commodities CTA": "#FB8136",
    AUD: "#1DEEF5",
    CHF: "#BB34C2",
    CNY: "#89FEAC",
    EUR: "#49577E",
    GBP: "#E9BB2A",
    HKD: "#E9BB2A",
    JPY: "#A262DF",
    KRW: "#8BB529",
    SGD: "#3D714E",
    USD: "#DAEB9D",
    Americas: "#A1B35B",
    "Asia Pacific": "#31F277",
    Austria: "#A43B68",
    Canada: "#1E7DB8",
    China: "#81BBA2",
    "E.U. & U.K.": "#6B1CAA",
    Finland: "#24F932",
    France: "#757B02",
    Germany: "#7415F6",

    Global: "#B71d09",
    "Great Britain": "#5cB777",
    Israel: "#DD6255",
    Japan: "#A13c40",
    Russia: "#DD7a02",
    "South Korea": "#AC9Fc7",
    Spain: "#D67926",
    Switzerland: "#D67935",
    "U.S.": "#EF273d",
    Communications: "#990f81",
    "Consumer Discretionary": "#F55ADc",
    "Consumer Staples": "#A13b55",
    Diversified: "#49527d",
    Energy: "#80D147",
    Financials: "#49505b",
    Funds: "#D6Fc4E",
    "Health Care": "#A7a418",
    Industrials: "#578a78",
    Materials: "#FB8126",
    "Real Estate": "#8Be429",
    Technology: "#A1c05B",
    Utilities: "#6B1CA9",
  };
  {
  }
  const [activeTab, setActiveTab] = useState("1");
  const [assetData, setAssetData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [dropdownOpen, setOpen] = React.useState(false);
  const [dropdownOpen1, setOpen1] = React.useState(false);
  const [dropdownOpen2, setOpen2] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [AddRemovechange, setAddRemovechange] = useState(false);
  const [totper, setTotper] = useState("");
  const [chartdetails, setchartdetails] = useState({});
  const [reloadfun, setreloadfun] = useState(false);
  const [ViewBond, setViewBond] = useState(false);
  const [chartdetails2, setchartdetails2] = useState({});
  const [recommenedStockList, setRecommendedStockist] = useState([]);
  const [recommenedFundList, setRecommendedFundList] = useState([]);
  const [recommendedBondList, setRecommendedBondList] = useState([]);

  const {
    porfolioId,
    lastupdated,
    stockOptions,
    allocationBond,
    setCheckBoxModify,
    checkBoxModify,
    showSelectAll,
    setShowSelectAll,
    setModifyBond,
    ModifyBond,
    setAllocationBond,
    fundOptions,
    fundLastUpdated,
    allocation,
    setAllocation,
    setgroupselection,
    setselectedbondid,
    settotaleqvalue,
    Flags,
    setEditActive,
    EditActive,
  } = props;
  const toggleSector = () => setOpen(!dropdownOpen);
  const toggleSector2 = () => setOpen2(!dropdownOpen2);
  const toggleAsset = () => setOpen1(!dropdownOpen1);
  const [type, settype] = useState("Asset");
  const [types, settypes] = useState("Geo");
  const [cashType, setCashType] = useState("cash");
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);
  const [eqvalue, seteqvalue] = useState(0);
  const [pievalue, setpievalue] = useState([]);
  const [pievalue2, setpievalue2] = useState([]);
  const [activeIndex, setactiveIndex] = useState("");
  const [activeIndex2, setactiveIndex2] = useState("");
  const [filterBy, setFilterBy] = useState("assettype");
  const [filter, setFilter] = useState("");
  const [filterType, setFilterType] = useState("geo");
  const [cashFilltertype, setCashFillterType] = useState("cash");
  const [morethanhundred, setmorethanhundred] = useState(false);
  const [dollarmorethanhundred, setdollarmorethanhundred] = useState(false);
  const [idafteredit, setidafteredit] = useState("1");
  const [id, setversionid] = useState("");
  const [totalpercentage, settotalpercentage] = useState(0);
  const [totaldollar, settotaldollar] = useState(0);
  const [percentage, setpercentage] = useState(0);
  const [percentagefund, setpercentagefund] = useState(0);
  const [percentagestock, setpercentagestock] = useState(0);
  const [dollar, setdollar] = useState(0);
  const [dollarstock, setdollarstock] = useState(0);
  const [dollarfund, setdollarfund] = useState(0);
  const [minusvalue, setminusvalue] = useState(0);
  const [lowestValue, setLowestValue] = useState("0.1");
  const [changeLowValue, setChangeLowValue] = useState("");
  const [groupselected, setgroupselected] = useState(false);
  const viewToggle = () => setViewModal(!viewModal);
  const [viewModal, setViewModal] = useState(false);
  const [modals, setModals] = useState(false);
  const [modalrecom, setModalrecom] = useState(false);
  const togglerecom = () => setModalrecom(!modalrecom);
  const toggles = () => setModals(!modals);
  const [dataClicked, setDataClicked] = useState(false);

  const renderActiveShapeClicked = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      midAngle,
      fill,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 150) * cos;
    const sy = cy + (outerRadius - 150) * sin;
    return (
      <Sector
        cx={sx}
        cy={sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      index,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g
        onClick={(e) => {
          // setactiveIndex(3)
          //setFilter(payload.name);
          //setDataClicked(true);
        }}
      >
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {dataClicked == true
            ? `${(percent * 100).toFixed(1)}%`
            : `${value.toFixed(1)}%`}
          {console.log(percent, "percentpercent")}
        </text>
      </g>
    );
  };

  //const [morethanhundredfund,setmorethanhundredfund] = useState("");
  //const [morethanhundredstock,setmorethanhundredstock] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getAl = (type = "success", text = "", timer = 5000) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    showConfirmButton: false,
    timer: timer,
  });

  const getAssetBreakdown = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: ASSET_SUMMARY_URL,
      headers: authHeader(),
      params: { id: porfolioId },
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
      const { asset_bond = [], asset_funds = [], asset_stock = [] } = data;
      setAssetData(asset_bond);
      setFundData(asset_funds);
      setStockData(asset_stock);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const assetReset = () => {
    setAssetData([]);
    setFundData([]);
    setStockData([]);
  };
  const Resetallocation = async () => {
    setLoading(true);
    const config = {
      method: "POST",
      url: RESET_MODIFYALLOCATION,
      headers: authHeader(),
      params: { portfolio_id: porfolioId },
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading();
    }
  };
  const Saveallocation = async () => {
    setLoading(true);
    const config = {
      method: "POST",
      url: SAVE_MODIFYALLOCATION,
      headers: authHeader(),
      params: { portfolio_id: porfolioId },
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chartdetails) {
      delete chartdetails.dataset;
      delete chartdetails.type;
      let chartValue = [];
      //let cashValue=(chartValue- minusvalue);
      let tot = 0;
      Object.keys(chartdetails).map((record, index) => {
        //let valCont = parseInt(chartdetails[record] * 100);
        let valCont = chartdetails[record];
        //console.log(colorSet[record], "colorSet[record]");
        if (valCont > 0) {
          tot += valCont;
          chartValue.push({
            name: record,
            value: valCont,
            fill: colorSet[record]
              ? colorSet[record]
              : "#" + (Math.random().toString(16) + "00000").slice(2, 8),
          });
        }
      });
      let bal = 100 - tot;
      // if (bal > 0) {
      //   chartValue.push({
      //     name: "Cash",
      //     value: bal,
      //     fill:
      //       "#" +
      //       (colorSet["Cash"]
      //         ? colorSet["Cash"]
      //         : (Math.random().toString(16) + "00000").slice(2, 8)),
      //   });
      // }
      console.log(bal, "eeeeeeeeeeeeeee");
      setpievalue(chartValue);
    }
  }, [chartdetails]);

  useEffect(() => {
    if (chartdetails2) {
      delete chartdetails2.dataset;
      delete chartdetails2.type;

      let chartValue2 = [];
      let tot2 = 0;
      Object.keys(chartdetails2).map((record, index) => {
        //let valCont = parseInt(chartdetails2[record] * 100);
        let valCont = chartdetails2[record];
        if (valCont > 0) {
          tot2 += valCont;
          chartValue2.push({
            name: record,
            value: valCont,
            fill: colorSet[record]
              ? colorSet[record]
              : "#" + (Math.random().toString(16) + "00000").slice(2, 8),
          });
        }
      });
      let bals = 100 - tot2;
      // if (!bals && !filter) {
      //   console.log(bals, "hhhhhhhhhhhhhhhhhhhhhhh");
      //   chartValue2.push({
      //     name: "Cash",
      //     value: bals,

      //     fill:
      //       "#" +
      //       (colorSet["Cash"]
      //         ? colorSet["Cash"]
      //         : (Math.random().toString(16) + "00000").slice(2, 8)),
      //   });
      // }
      setpievalue2(chartValue2);
    }
  }, [chartdetails2]);

  const RemoveLowestValue = () => {
    //setvaluechange();
    let tot = 0;
    let doll = 0;
    assetData.map((record, index) => {
      var per = record.bond_suggested_allocation_percentage
        ? parseFloat(record.bond_suggested_allocation_percentage)
        : 0;
      if (record.bond_taken == 1 && per * 100 > lowestValue) {
        record.bond_taken = 1;
        tot += record.bond_suggested_allocation_percentage
          ? parseFloat(record.bond_suggested_allocation_percentage)
          : 0;
        doll += record.bond_suggested_allocation_dollar
          ? parseFloat(
              record.bond_suggested_allocation_dollar
                .toString()
                .replaceAll("$", "")
                .replaceAll(",", "")
            )
          : 0;
      } else {
        record.bond_taken = 0;
      }
    });
    setpercentage(tot);
    setdollar(doll);

    tot = 0;
    doll = 0;
    fundData.map((record, index) => {
      var per = record.funds_suggested_allocations_percentage
        ? parseFloat(record.funds_suggested_allocations_percentage)
        : 0;
      if (record.funds_taken == 1 && per * 100 > lowestValue) {
        record.funds_taken = 1;
        tot += record.funds_suggested_allocations_percentage
          ? parseFloat(record.funds_suggested_allocations_percentage)
          : 0;
        doll += record?.funds_suggested_allocation_dollar
          ? parseFloat(
              record?.funds_suggested_allocation_dollar
                .toString()
                .replaceAll("$", "")
                .replaceAll(",", "")
            )
          : 0;
      } else {
        record.funds_taken = 0;
      }
    });
    setpercentagefund(tot);
    setdollarfund(doll);

    tot = 0;
    doll = 0;
    stockData.map((record, index) => {
      var per = record.stock_suggested_allocations_percentage
        ? parseFloat(record.stock_suggested_allocations_percentage)
        : 0;
      if (record.stock_taken == 1 && per * 100 > lowestValue) {
        record.stock_taken = 1;
        tot += record.stock_suggested_allocations_percentage
          ? parseFloat(record.stock_suggested_allocations_percentage)
          : 0;
        doll += record?.stock_suggested_allocation_dollar
          ? parseFloat(
              record?.stock_suggested_allocation_dollar
                .toString()
                .replaceAll("$", "")
                .replaceAll(",", "")
            )
          : 0;
      } else {
        record.stock_taken = 0;
      }
    });
    setpercentagestock(tot);
    setdollarstock(doll);
  };

  useEffect(() => {
    let totper =
      parseFloat(percentage) +
      parseFloat(percentagefund) +
      parseFloat(percentagestock);
    settotalpercentage(totper);
    console.log(totper,'eeeeeeeeeeeeeeeeeeeee')
  }, [percentage, totalpercentage, percentagestock, percentagefund]);

  console.log(dollar, "dollar + dollarfund + dollarstock");

  useEffect(() => {
    let totdol = dollar + dollarfund + dollarstock;
    settotaldollar(totdol);
  }, [dollar, dollarfund, dollarstock]);

  useEffect(() => {
    let totalper;
    if (totaldollar) {
      totalper = eqvalue - totaldollar;

      setminusvalue(totalper);
    }
  }, [totaldollar, eqvalue]);

  // useEffect(() => {

  //   if ( Math.round(totalpercentage*100) > 100) {
  //     Swal.fire({
  //       title: "Current Allocation Exceeds Capital To Be Invested !",
  //       text: "Please Reduce Allocation To Some Assets.",
  //       type: "warning",
  //       showCancelButton: true,
  //       showConfirmButton: false,

  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",

  //       cancelButtonText: "Cancel",
  //     }).then((result) => {
  //       if (result.value) {
  //       }
  //     });
  //   }
  // }, [totalpercentage]);

  useEffect(() => {
    getAssetBreakdown();
    getchart("assettype");
  }, []);

  useEffect(() => {
    getAssetBreakdown();
    getchart("assettype");
  }, [porfolioId]);

  useEffect(() => {
    settotaleqvalue(eqvalue);
  }, [eqvalue]);

  // useEffect(() => {
  //   if (morethanhundred == true) {
  //     Swal.fire({
  //       title: "Suggested Allocation value is high?",
  //       text: "Percentage value is lessthan hundred!",
  //       type: "warning",
  //       showCancelButton: true,
  //       showConfirmButton: false,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       cancelButtonText: "Cancel",
  //     }).then((result) => {
  //       if (result.value) {
  //       }
  //     });
  //   }
  // }, [morethanhundred == true]);

  // useEffect(() => {
  //   if (dollarmorethanhundred == true) {
  //     Swal.fire({
  //       title: "Suggested Allocation Dollar is high?",
  //       text: "Dollar value is Higher than Client Equlity!",
  //       type: "warning",
  //       showCancelButton: true,
  //       showConfirmButton: false,

  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",

  //       cancelButtonText: "Cancel",
  //     }).then((result) => {
  //       if (result.value) {
  //       }
  //     });
  //   }
  // }, [dollarmorethanhundred == true]);

  useEffect(() => {
    getchart2(filterType);
  }, [filter, filterBy, filterType]);
  useEffect(() => {
    //setFilter('');
  }, [filterType]);

  const getchart = async (type) => {
    const config = {
      method: "GET",
      url: PIE_CHART,
      headers: authHeader(),
      params: {
        type: type,
        portfolio_id: porfolioId,
      },
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      setchartdetails(data);
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
    }
  };
  const getchart2 = async (type) => {
    const config = {
      method: "GET",
      url: filter ? PIE_CHART_COMP : PIE_CHART,
      headers: authHeader(),
      params: {
        type: type,
        portfolio_id: porfolioId,
        filter,
        filterby: filterBy,
      },
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      setchartdetails2(data);
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
    }
  };

  const makePieActive1 = (_, index) => {
    if (!dataClicked) setactiveIndex(index);
  };

  const makePieActive2 = (_, index) => {
    setactiveIndex2(index);
  };

  const getPortfolio = async () => {
    const config = {
      method: "GET",
      url: PORTFOLIO_GET + props.porfolioId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioLists(data.portfolio.setClientPortfolioLists);
      seteqvalue(data.portfolio.portfolio_core_dollar);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPortfolio();
  }, []);

  const saveAllocation = async () => {
    setLoading(true);
    const config = {
      method: "POST",
      url: SAVE_ALLOCATION,
      headers: authHeader(),
      data: {
        portfolio_id: porfolioId,
        data: {
          asset_bond: assetData,
          asset_stock: stockData,
          asset_funds: fundData,
        },
      },
    };

    try {
      const response = await API(config);
      const { status } = response.data;
      const { data = [] } = response.data;
      setversionid(data.portfolio_id);
      setidafteredit("2");
      if (status === "Success") {
        Swal.fire(getAl("success", "Allocations Saved Successfully"));
        console.log(data.portfolio_id, "data.portfolio_id");
        window.location.href = "/detail-portfolio/summary/" + data.portfolio_id;
      }
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-sm-4 mt-5">
      <div>
        <Row>
          {/*} <Col sm="4" className="align-self-center">
        <div className="chart_left">
      <Row>
        <Col xs="9">
         <p className="text_16_51">Expected Return</p>
        </Col>
        <Col xs="3" className="text-right">
         <p className="text_16_51">6%</p>
        </Col>
        <Col xs="9">
         <p className="text_16_51">Expected Drawdown</p>
        </Col>
        <Col xs="3" className="text-right">
         <p className="text_16_51">20%</p>
        </Col>
        <Col xs="9">
         <p className="text_16_51 mb-0">Cash Flow</p>
        </Col>
        <Col xs="3" className="text-right">
         <p className="text_16_51 mb-0">2%</p>
        </Col>
      </Row>
      </div>
      </Col>  */}
          {filter && (
            <>
              <Col xs="10"></Col>
              <Col xs="2" className="popup_radio">
                <Button
                  className="chart_reset_btn"
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  {" "}
                  Reset{" "}
                </Button>
              </Col>
            </>
          )}
          <Col xs="6" className="chart_flow text-center">
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggleSector}
              className="chart_flow text-center"
            >
              <DropdownToggle caret>{type}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    getchart("assettype");
                    setFilterBy("assettype");
                    settype("Asset");
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  Asset
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    getchart("strategy");
                    setFilterBy("strategy");
                    settype("Strategy");
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  Strategy
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    getchart("currency");
                    setFilterBy("currency");
                    settype("Currency");
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  Currency
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    getchart("geo");
                    setFilterBy("geo");
                    settype("Geography");
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  Geography
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    getchart("sector");
                    setFilterBy("sector");
                    settype("Sector");
                    setDataClicked(false);
                    setFilter("");
                  }}
                >
                  Sector
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <PieChart width={550} height={450}>
              <Pie
                data={pievalue}
                fill="#228B22"
                activeIndex={activeIndex}
                //activeShape={(props) => dataClicked ? renderActiveShapeClicked(props) : renderActiveShape(props)}
                activeShape={renderActiveShape}
                onMouseEnter={makePieActive1}
                onClick={(e) => {
                  setFilter(e.name);
                  //                 const names = Object.keys(chartdetails)
                  //   .filter((key,value) => )
                  //   .reduce((obj, key) => {
                  //       return Object.assign(obj, {
                  //         [key]: chartdetails[key]
                  //       });
                  // }, {});
                  //console.log(chartdetails.filter(stu => stu > 0),'chartdetails.filter(stu => stu > 0)')
                  const asArray = Object.entries(chartdetails);

                  var filtered = {};
                  asArray
                    .filter(([key, value]) => value > 0)
                    .map((obj, key) => {
                      console.log(obj, key);
                      return Object.assign(filtered, {
                        [obj[0]]: obj[1],
                      });
                    }, {});

                  setactiveIndex(
                    Object.keys(filtered).findIndex((p) => p == e.name)
                  );
                  setDataClicked(true);
                }}
                label
              >
                {pievalue.map((entry, index) => (
                  <Cell key={entry.id} />
                ))}
              </Pie>
              <Legend
                dat
                a={pievalue}
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                onClick={makePieActive1}
              />
            </PieChart>
          </Col>

          <Col xs="6" className="chart_flow text-center">
            <ButtonDropdown
              isOpen={dropdownOpen2}
              toggle={toggleSector2}
              className="frt"
            >
              <DropdownToggle caret>{types}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setFilterType("assettype");
                    settypes("Asset");
                  }}
                >
                  Asset
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setFilterType("strategy");
                    settypes("Strategy");
                  }}
                >
                  Strategy
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setFilterType("currency");
                    settypes("Currency");
                  }}
                >
                  Currency
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setFilterType("geo");
                    settypes("Geography");
                  }}
                >
                  Geography
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setFilterType("sector");
                    settypes("Sector");
                  }}
                >
                  Sector
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <PieChart style={{ margin: "auto" }} width={550} height={450}>
              <Pie
                data={pievalue2}
                fill="#228B22"
                nameKey="name"
                activeIndex={activeIndex2}
                activeShape={renderActiveShape}
                label={true}
                labelLine={true}
                cursor="pointer"
                onMouseEnter={makePieActive2}
              />{" "}
              {pievalue2.map((entrys, index) => (
                <Cell key={entrys.id} />
              ))}
              <Legend data={pievalue2} activeIndex={activeIndex} />
            </PieChart>
          </Col>
          {clientPortfolioLists != 0 && (
            <>
              <Col xs="8"></Col>
            </>
          )}
          <Col xs="12">
            <div className="table_trans_line mb-4">
              <Table striped hover className="single_table_trans mb-0">
                <thead>
                  <tr>
                    <th>Capital To Be Invested</th>
                    <th> Current Allocation($)</th>
                    <th> Current Allocation(%)</th>
                    <th>Un Allocated Cash </th>
                  </tr>
                </thead>
                <tbody>
                  {(() => {
                    if (clientPortfolioLists != 0) {
                      return (
                        <tr>
                          <td>
                            <NumberFormat
                              thousandSeparator={true}
                              value={eqvalue}
                              displayType="text"
                              prefix="$"
                            />
                          </td>
                          {console.log(
                            totaldollar,
                            "totaldollartotaldollartotaldollar"
                          )}
                          <td>
                            {" "}
                            <NumberFormat
                              className="green-text text-right"
                              value={totaldollar.toFixed(0)}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="$"
                            />
                          </td>
                          <td>{(totalpercentage * 100).toFixed(2) + "%"} </td>
                            
                          <td>
                            {/*{console.log(minusvalue >= 0 ? minusvalue.toFixed(2) : 0  ,'wwwwwwwwwwwwwwwwww')} */}
                            <NumberFormat
                              thousandSeparator={true}
                              value={
                                minusvalue >= 0 ? minusvalue.toFixed(2) : 0
                              }
                              displayType="text"
                              prefix="$"
                            />
                            
                          </td>
                        </tr>
                      );
                    }
                  })()}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col xs="10"></Col>
          <Col xs="2">
            {/* <Button
              className="w-100"
              color="primary"
              style={{ margin: "0 10px" }}
              onClick={() => {
                setgroupselected(true);
                setgroupselection(true);
              }}
            >
              Select Group
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="text-center">
            {loading && <CustomLoader loading={loading} />}
          </Col>
          <Col xs="6" className="text-left">
            {Math.round(totalpercentage * 100) > 100 && (
              <div className="current_class p-3">
                <h4 className="p-0">
                  Current Allocation Exceeds Capital To Be Invested !
                </h4>
                <p className="mb-0">Please Reduce Allocation To Some Assets.</p>
              </div>
            )}
          </Col>
          <Col xs="6" className="text-right p-3">
            {allocation == true && (
              <Button
                className="asset-remove mr-3"
                onClick={() => {
                  setModalrecom(true);
                }}
              >
                Recommended List
              </Button>
            )}

            {allocation == true && (
              <Button
                className="asset-remove "
                onClick={() => {
                  setModals(true);
                }}
              >
                Remove Small Allocation
              </Button>
            )}
          </Col>
        </Row>
        <CardBody className="p-0 mt-3">
          <Nav tabs className="break_tab">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Bonds
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Stocks
              </NavLink>
            </NavItem>{" "}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                External Funds
              </NavLink>
            </NavItem>
          </Nav>
         <div className="relative">
        {activeTab != "3" && <span className="pl-5 span_absolute">
              Last Updated On :{" "}
              {moment(
                clientPortfolioLists?.portfolio?.portfolio_updatedon
              ).format("YYYY/MM/DD")}
            </span>}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Bonds
                  Flags={Flags}
                  setselectedbondid={setselectedbondid}
                  groupselected={groupselected}
                  setpercentage={setpercentage}
                  setdollar={setdollar}
                  eqvalue={eqvalue}
                  setCheckBoxModify={setCheckBoxModify}
                  checkBoxModify={checkBoxModify}
                  showSelectAll={showSelectAll}
                  setShowSelectAll={setShowSelectAll}
                  setAllocationBond={setAllocationBond}
                  setModifyBond={setModifyBond}
                  ModifyBond={ModifyBond}
                  allocationBond={allocationBond}
                  setdollarmorethanhundred={setdollarmorethanhundred}
                  setmorethanhundred={setmorethanhundred}
                  setTotper={setTotper}
                  assetData={assetData}
                  setViewBond={setViewBond}
                  ViewBond={ViewBond}
                  stockData={stockData}
                  modalrecom={modalrecom}
                  setModalrecom={setModalrecom}
                  AddRemovechange={AddRemovechange}
                  fundData={fundData}
                  loading={loading}
                  lastupdated={lastupdated}
                  allocation={allocation}
                  setAllocation={setAllocation}
                  porfolioId={porfolioId}
                  setEditActive={setEditActive}
                  EditActive={EditActive}
                  recommendedBondList={recommendedBondList}
                />
              </TabPane>
              {console.log(recommendedBondList, "uuuuuuuuuuuuuuuuuuuuuuuuuuu")}
              <TabPane tabId="2">
                <Stocks
                  Flags={Flags}
                  setselectedbondid={setselectedbondid}
                  groupselected={groupselected}
                  setpercentagestock={setpercentagestock}
                  setdollarstock={setdollarstock}
                  eqvalue={eqvalue}
                  setdollarmorethanhundred={setdollarmorethanhundred}
                  setmorethanhundred={setmorethanhundred}
                  assetData={assetData}
                  stockData={stockData}
                  fundData={fundData}
                  loading={loading}
                  lastupdated={lastupdated}
                  allocation={allocation}
                  porfolioId={porfolioId}
                  recommenedStockList={recommenedStockList}
                />
              </TabPane>
              <TabPane tabId="3">
                <Funds
                  Flags={Flags}
                  setselectedbondid={setselectedbondid}
                  groupselected={groupselected}
                  setpercentagefund={setpercentagefund}
                  setdollarfund={setdollarfund}
                  eqvalue={eqvalue}
                  setdollarmorethanhundred={setdollarmorethanhundred}
                  setmorethanhundred={setmorethanhundred}
                  assetData={assetData}
                  stockData={stockData}
                  fundData={fundData}
                  loading={loading}
                  lastupdated={fundLastUpdated}
                  allocation={allocation}
                  porfolioId={porfolioId}
                  recommenedFundList={recommenedFundList}
                />
              </TabPane>
            </TabContent>
          </div>
        </CardBody>

        <Modal isOpen={modals} toggle={toggles} size="small">
          <div className="modalwidth py-5 redi_7">
            <div className="Remove-btn text-left px-3 mb-3">
              <h3 className="one_eight ">Specify your lowest value</h3>
            </div>
            <Row className="Remove-btn">
              <NumberFormat
                className="green-text form-control w_90"
                placeholder={0.1}
                onValueChange={(values) => {
                  const { value, floatValue } = values;
                  setLowestValue(parseFloat(floatValue));
                }}
                value={lowestValue}
              />
            </Row>
            <div className="Remove-btn">
              <Button
                className="Bond-save"
                color="primary"
                style={{ margin: "0 10px" }}
                onClick={() => {
                  RemoveLowestValue();
                  setModals(false);
                }}
              >
                Update
              </Button>
              <Button
                onClick={() => {
                  setModals(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>

        <Modal isOpen={modalrecom} toggle={togglerecom} size="xl">
          <Recommended
            RemoveLowestValue={RemoveLowestValue}
            setRecommendedStockist={setRecommendedStockist}
            setRecommendedFundList={setRecommendedFundList}
            setModalrecom={setModalrecom}
            setRecommendedBondList={setRecommendedBondList}
            porfolioId={porfolioId}
            assetData={assetData}
            stockData={stockData}
            setStockData={setStockData}
            fundData={fundData}
            setAssetData={setAssetData}
            setAddRemovechange={setAddRemovechange}
          />
        </Modal>

        {allocation == true && (
          <>
            <Row>
              {Math.round(totalpercentage * 100) <= 100 && (
                <Col sm={6}>
                  <Button
                    className="w-100"
                    color="primary"
                    style={{ margin: "0 10px" }}
                    onClick={() => {
                      saveAllocation();
                      setAllocation(false);
                    }}
                  >
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.867"
                      height="15.823"
                      viewBox="0 0 11.867 15.823"
                    >
                      <path
                        id="Icon_awesome-file-pdf"
                        data-name="Icon awesome-file-pdf"
                        d="M5.621,7.915A2.982,2.982,0,0,1,5.56,6.465C5.819,6.465,5.795,7.605,5.621,7.915ZM5.569,9.373a14.26,14.26,0,0,1-.878,1.938,11.38,11.38,0,0,1,1.944-.677A4,4,0,0,1,5.569,9.373ZM2.661,13.23c0,.025.408-.167,1.079-1.242A4.271,4.271,0,0,0,2.661,13.23Zm5-8.285h4.2V15.081a.74.74,0,0,1-.742.742H.742A.74.74,0,0,1,0,15.081V.742A.74.74,0,0,1,.742,0H6.923V4.2A.744.744,0,0,0,7.664,4.945Zm-.247,5.309A3.1,3.1,0,0,1,6.1,8.591a4.442,4.442,0,0,0,.192-1.984A.774.774,0,0,0,4.812,6.4a5.139,5.139,0,0,0,.25,2.38A29.019,29.019,0,0,1,3.8,11.428s0,0-.006,0c-.838.43-2.275,1.375-1.684,2.1a.96.96,0,0,0,.664.309c.553,0,1.1-.556,1.888-1.91A17.615,17.615,0,0,1,7.1,11.215a4.683,4.683,0,0,0,1.978.6.8.8,0,0,0,.609-1.341c-.43-.42-1.678-.3-2.275-.223Zm4.234-7.009L8.622.216A.741.741,0,0,0,8.1,0H7.911V3.956h3.956V3.767A.739.739,0,0,0,11.651,3.245Zm-2.29,7.89c.127-.083-.077-.368-1.323-.278C9.185,11.345,9.361,11.135,9.361,11.135Z"
                        fill="#fff"
                      />
                    </svg>
                    Save
                  </Button>
                </Col>
              )}

              <Col sm={6}>
                <Button
                  className="w-100"
                  color="primary"
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    setAllocation(false);
                    assetReset();
                    getAssetBreakdown();
                    setAllocationBond(false);
                    setCheckBoxModify(false);
                    setShowSelectAll(false);
                  }}
                >
                  <svg
                    className="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.867"
                    height="15.823"
                    viewBox="0 0 11.867 15.823"
                  >
                    <path
                      id="Icon_awesome-file-pdf"
                      data-name="Icon awesome-file-pdf"
                      d="M5.621,7.915A2.982,2.982,0,0,1,5.56,6.465C5.819,6.465,5.795,7.605,5.621,7.915ZM5.569,9.373a14.26,14.26,0,0,1-.878,1.938,11.38,11.38,0,0,1,1.944-.677A4,4,0,0,1,5.569,9.373ZM2.661,13.23c0,.025.408-.167,1.079-1.242A4.271,4.271,0,0,0,2.661,13.23Zm5-8.285h4.2V15.081a.74.74,0,0,1-.742.742H.742A.74.74,0,0,1,0,15.081V.742A.74.74,0,0,1,.742,0H6.923V4.2A.744.744,0,0,0,7.664,4.945Zm-.247,5.309A3.1,3.1,0,0,1,6.1,8.591a4.442,4.442,0,0,0,.192-1.984A.774.774,0,0,0,4.812,6.4a5.139,5.139,0,0,0,.25,2.38A29.019,29.019,0,0,1,3.8,11.428s0,0-.006,0c-.838.43-2.275,1.375-1.684,2.1a.96.96,0,0,0,.664.309c.553,0,1.1-.556,1.888-1.91A17.615,17.615,0,0,1,7.1,11.215a4.683,4.683,0,0,0,1.978.6.8.8,0,0,0,.609-1.341c-.43-.42-1.678-.3-2.275-.223Zm4.234-7.009L8.622.216A.741.741,0,0,0,8.1,0H7.911V3.956h3.956V3.767A.739.739,0,0,0,11.651,3.245Zm-2.29,7.89c.127-.083-.077-.368-1.323-.278C9.185,11.345,9.361,11.135,9.361,11.135Z"
                      fill="#fff"
                    />
                  </svg>
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
}
