import React, { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
  Collapse,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const currencyOptions = [
  {
    name: "portfolio_usd",
    label: "USD",
    value: 1,
  },
  // {
  //   name: "portfolio_eur",
  //   label: "EUR",
  //   value: 2,
  // },
  // {
  //   name: "portfolio_gbp",
  //   label: "GBP",
  //   value: 3,
  // },
  // {
  //   name: "portfolio_aud",
  //   label: "AUD",
  //   value: 4,
  // },
  // {
  //   name: "portfolio_nzd",
  //   label: "NZD",
  //   value: 5,
  // },
  // {
  //   name: "portfolio_cad",
  //   label: "CAD",
  //   value: 6,
  // },
  // {
  //   name: "portfolio_chf",
  //   label: "CHF",
  //   value: 7,
  // },
  // {
  //   name: "portfolio_nok",
  //   label: "NOK",
  //   value: 8,
  // },
  // {
  //   name: "portfolio_sek",
  //   label: "SEK",
  //   value: 9,
  // },
];

export default function CurrencyConstrainsts(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const toggle = () => setIsOpen(!isOpen);

  const {
    formValues = {},
    handleChange,
    handleBlur,
    touched,
    errors,
    Flag,
    constraintsFlag,
    setFieldValue
  } = props;
  const {
    portfolio_id,
    portfolio_usd_status = false,
    portfolio_eur_status = false,
    portfolio_gbp_status = false,
    portfolio_jpy_status = false,
    portfolio_aud_status = false,
    portfolio_nzd_status = false,
    portfolio_cad_status = false,
    portfolio_chf_status = false,
    portfolio_nok_status = false,
    portfolio_sek_status = false,
    portfolio_usd_max,
    portfolio_usd_min,
    portfolio_eur_min,
    portfolio_eur_max,
    portfolio_gbp_min,
    portfolio_gbp_max,
    portfolio_jpy_min,
    portfolio_jpy_max,
    portfolio_aud_min,
    portfolio_aud_max,
    portfolio_nzd_max,
    portfolio_nzd_min,
    portfolio_cad_min,
    portfolio_cad_max,
    portfolio_chf_min,
    portfolio_chf_max,
    portfolio_nok_min,
    portfolio_nok_max,
    portfolio_sek_min,
    portfolio_sek_max,
  } = formValues;

  const handleMultiSelectChange = (selectedOptions, e) => {
    let { action, option = {}, target = { name: "", value: "" } } = e;
    let { name } = option;
    setSelectedOption(selectedOptions);
    e.target = { name: "", value: "" };
    if (action == "select-option") {
      e.target.name = `${name}_status`;
      e.target.value = true;
      setFieldValue(`${name}_min`, 0);
      setFieldValue(`${name}_max`, 100);
      handleChange(e);
    } else {
      setFieldValue(`${name}_status`, false);
      setFieldValue(`${name}_min`, 0);
      setFieldValue(`${name}_max`, 0);

      target.name = `${name}_status`;
      target.value = false;
      handleChange(e);
    }
  };
  const handleChangeFun = (e) => {
    const currencyId = e.currentTarget.dataset.currency_id;
    let remove1 = selectedOption.filter(
      (record) => record.value !== parseInt(currencyId)
    );
    let removed = selectedOption.find(
      (record) => record.value == parseInt(currencyId)
    );
    
    if(removed){
      setFieldValue(`${removed.name}_status`, false);
      setFieldValue(`${removed.name}_min`, 0);
      setFieldValue(`${removed.name}_max`, 0);
    }
    setSelectedOption(remove1);
    handleChange(e);
  };
  useEffect(() => {
    let selOptions = [];
    if (portfolio_usd_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 1
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_eur_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 2
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_gbp_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 3
      );
      selOptions.push(filteredOption);
    }
    // if (portfolio_jpy_status) {
    //   let filteredOption = currencyOptions.find(
    //     (currency) => currency.value === 4
    //   );
    //   selOptions.push(filteredOption);
    // }
    if (portfolio_aud_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 4
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_nzd_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 5
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_cad_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 6
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_chf_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 7
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_nok_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 8
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_sek_status) {
      let filteredOption = currencyOptions.find(
        (currency) => currency.value === 9
      );
      selOptions.push(filteredOption);
    }
    setSelectedOption(selOptions);
  }, [portfolio_id]);
  return (
    <div className="my-collapse">
      <div className="collapse-title">
        <h4 onClick={toggle}>
          <span className="collapse-plus">
            <img src={isOpen ? minus : plus} />
          </span>
          Currency Constraints <span className="informicon" id="assid69"><FaInfoCircle/></span>
          <UncontrolledTooltip placement="right" target="assid69">
          Currencies limits that the portfolio needs to have.

      </UncontrolledTooltip>
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <ReactMultiSelectCheckboxes
          name="sectorMultiselect"
          options={currencyOptions}
          width="100%"
          onChange={handleMultiSelectChange}
          value={selectedOption}
          isDisabled={Flag ? true : constraintsFlag ? false : ""}
        />
        <br />

        {selectedOption &&
          selectedOption.map((sector) => {
            let max_name = sector.name + "_max";
            let min_name = sector.name + "_min";
            return (
              <FormGroup row className="item-center">
                <Col lg={4}>
                  <CustomInput
                    type="checkbox"
                    name={`${sector.name}_status`}
                    id={`${sector.name}_status`}
                    data-currency_id={sector.value}
                    onChange={handleChangeFun}
                    value={eval(`${sector.name}_status`)}
                    checked={eval(`${sector.name}_status`)}
                    label={sector.label}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                  />
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="text"
                          name={`${sector.name}_min`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={eval(`${sector.name}_min`)}
                          placeholder="0"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Min (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[min_name] && touched[min_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[min_name] && touched[min_name]
                          ? errors[min_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="text"
                          name={`${sector.name}_max`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={eval(`${sector.name}_max`)}
                          placeholder="100"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Max (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[max_name] && touched[max_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[max_name] && touched[max_name]
                          ? errors[max_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            );
          })}
      </Collapse>
    </div>
  );
}
