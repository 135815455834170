import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  CardTitle,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomTableHeader from "../../common/CustomTableHeader";
import swal from "sweetalert2";
import Swal from "sweetalert2";
import CustomTableLoader from "../../common/CustomTableLoader";
import CustomEmptyTableRecord from "../../common/CustomEmptyTableRecord";
import {
  ASSET_DELETE_URL,
  ASSET_MANAGEMENT_URL,
  BOND_INDEX,
  STOCK_FUNDS_URL,
  BOND_DELETE_PATH,
  ASSET_SUMMARY_URL,
} from "../../common/urls";
//import {   }  from "../../common/Mainfunctions";
import NumberFormat from "react-number-format";
import { getAlertToast, authHeader } from "../../common/Mainfunctions";
import API from "../../redux/API";
import { FaEye, FaSleigh, FaTrash } from "react-icons/fa";
import classnames from "classnames";
import moment from "moment";
export default function Recommended(props) {
  const {
    //loading,
    assetData,
    stockData,
    fundData,
    setAssetData,
    allocation,
    porfolioId,
    setRecommendedStockist,
    setRecommendedFundList,
    setRecommendedBondList,
    setModalrecom,
  } = props;
  const [type, settype] = useState("");
  const [types, settypes] = useState("");
  const [selectedBond, setSelectedBond] = useState("");
  const [allocationBond, setAllocationBond] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bondList, setBondList] = useState([]);
  const [selectBond, setselectBond] = useState("");
  const viewToggle = () => setViewModal(!viewModal);
  const [selectStock, setselectStock] = useState("");
  const [Bond, setBond] = useState([]);
  const [bondChekced, setBondChekced] = useState("");
  const [stockChecked, setStockChecked] = useState("");
  const [FundData, setFundData] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [modals, setModals] = useState(false);
  const [totalrecords, setTotalRecords] = useState(0);
  const toggles = () => setModals(!modals);
  const [assetlist, setAssetList] = useState([]);
  const [currentBondIndex, setCurrentBondIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [stock, setstock] = useState([]);
  const [fund, setfund] = useState([]);
  const [currentpage, setCurrentPage] = useState(0);
  const [rowperpage, setRowPerPage] = useState(10);
  const getAl = (
    type = "success",
    types = "success",
    text = "",
    timer = 5000
  ) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    types: types,
    showConfirmButton: false,
    timer: timer,
  });

  const tableColumns = [
    { title: "S.No" },
    { title: "Fund Name" },
    { title: "Asset Class" },
    { title: "Strategy" },
    { title: "Geographical Focus" },
    { title: "Fund Size (million)" },
    { title: "Inception Date" },
    { title: "Currency" },
    { title: "Status" },
    { title: "Perfomance Fee" },
    { title: "Management Fee" },
    { title: "Minimum Investment" },
    { title: "Auditor" },
    { title: "Custodian" },
    { title: "Administrator" },
    { title: "Notice Period" },
    { title: "Lock Up Period (Months)" },
    { title: "Redemption Frequency" },
    { title: "Expected Return" },
    { title: "Max Weightage" },
    { title: "Cash Flow Yield" },
    { title: "Asset Industry Sector" },
    { title: "Asset Upside  Potential" },
    { title: "Last Refreshed" },
  ];
  const tableColumnsBond = [
    { title: "" },
    { title: "Name of Bond" },
    { title: "ISIN" },
    { title: "Currency" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Bond Rank" },
    { title: "Current Price" },
    { title: "Coupon" },
    { title: "YTW" },
    { title: "Modified duration" },
    { title: "Maturity of bonds" },
    { title: "Call Date of Bonds" },
    { title: "Rating" },
    { title: "Geography" },
    { title: "Sector" },
    { title: "Action" },
  ];
  const tableColumnsstock = [
    { title: "" },
    { title: "S.No" },
    { title: " Name of Stocks" },
    { title: "Bloomberg Ticker" },
    { title: "Currency" },
    { title: "Suggested Allocation $" },
    { title: "Suggested Allocation %" },
    { title: "Current Price" },
    { title: "LC Target Price (next 12 months)" },
    { title: "Upside Potential (%)" },
    { title: "Dividend Yield" },
    { title: "Geography	" },
    { title: "Sector" },
  ];
  const tableColumnsFund = [
    { title: "" },
    { title: "S.No" },
    { title: "Name of Funds" },
    { title: " Asset class" },
    { title: "Strategy" },
    { title: "ISIN" },
    { title: "Currency" },
    { title: "Suggested Allocation $" },
    { title: "Suggested Allocation %" },
    { title: "Expected Return" },
    { title: "Expected volatility" },
    { title: "Mangement Fee" },
    { title: "Performance Fee" },
    { title: "Sharpe Since inception	" },
    { title: "Correlation to MSCI AC World Index" },
    { title: "Dividend Yield	" },
    { title: "Geography	" },
    { title: "Max drawdown	" },
    { title: "Redemption frequency	" },
    { title: "Notice period	" },
    { title: "Last Price Updated	" },
  ];
  const checkedAll = (e, index) => {
    setisChecked(true);
    let bondsCopy = assetData;
    bondsCopy[index].bond_data_list.map((check) => {
      console.log(check, "checkcheckcheckcheckcheckcheck");
      check.bond_taken = e.target.checked ? "1" : "0";
      if (e.target.checked) {
        bondsCopy[index].bond_taken = "1";
      } else {
        bondsCopy[index].bond_taken = "0";
      }
      // if(check.bond_taken=="yes"){
      //    record.bond_taken="yes"
      // }

      // setSelectedBond(check.bond_taken);
    });
    setAssetData(bondsCopy);
    setisChecked(false);
  };

  const checkedchildAll = (e, index, ind) => {
    setisChecked(true);
    var isChildSelected = true;
    let bondCopy = assetData;
    if (e.target.checked) {
      bondCopy[index].bond_taken = "1";
      bondCopy[index].bond_data_list[ind].bond_taken = "1";
    } else {
      bondCopy[index].bond_data_list[ind].bond_taken = "0";
      bondCopy[index].bond_data_list.map((check) => {
        if (check.bond_taken == "1") {
          isChildSelected = true;
        }
      });
      if (isChildSelected) {
        bondCopy[index].bond_taken = "1";
      } else {
        bondCopy[index].bond_taken = "0";
      }
    }
    setisChecked(false);
    setAssetData(bondCopy);
  };
  console.log(
    assetData,
    "bond_data_listbond_data_listbond_data_listbond_data_list"
  );

  // const checkedDollarAll=(e,index,ind)=>{
  //   setisChecked(true);
  //   var isChildSelected = true;
  //   let bondCopy = assetData;
  //   if(e.target.checked){
  //     bondCopy[index].bond_suggested_allocation_dollar = bondCopy[index].bond_suggested_allocation_dollar;
  //     bondCopy[index].bond_suggested_allocation_percentage = bondCopy[index].bond_suggested_allocation_percentage;
  //   }else{
  //     bondCopy[index].bond_data_list[ind].bond_taken = "0";
  //     bondCopy[index].bond_data_list.map((check)=>{
  //         if(check.bond_taken == "1" ){
  //           isChildSelected = true;
  //         }
  //     });
  //     if(isChildSelected){
  //       bondCopy[index].bond_taken = "1";
  //     }else{
  //       bondCopy[index].bond_taken = "0";
  //     }
  //   }
  //   setisChecked(false);
  //   setAssetData(bondCopy);
  // };
  // const checkedStockchildAll=(e,index)=>{
  //   setisChecked(true);
  //   let stockCopy = stockData;
  //   if(e.target.checked){
  //     stockCopy[index].stock_taken = "1";
  //   }else{
  //     stockCopy[index].stock_taken = "0";
  //   }
  //   setisChecked(false);
  //   setStockData(stockCopy);
  // };

  const assetBondTableColumns = [
    { title: "Name of Bond" },
    { title: "ISIN" },
    { title: "Currency" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Bond Rank" },
    { title: "Current Price" },
    { title: "Coupon" },
    { title: "YTW" },
    { title: "Modified duration" },
    { title: "Maturity of bonds" },
    { title: "Call Date of Bonds" },
    { title: "Rating" },
    { title: "Geography" },
    { title: "Sector" },
  ];
  // const handleSelectAllEvents=()=>{
  //   selectedBond.bond_data_list
  // }

  useEffect(() => {
    if (allocationBond == false) {
      settypes("text");
    } else {
      settypes("input");
    }
  }, [allocationBond]);
  useEffect(() => {
    if (allocation == false) {
      settype("text");
    } else {
      settype("input");
    }
  }, [allocation]);
  const getBondManagementList = async () => {
    console.log(currentpage, "wwwwww");
    const config = {
      method: "GET",
      url: BOND_INDEX,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setBondList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (e) => {
    e.stopPropagation();
    let asset_id = parseInt(e.currentTarget.dataset.id);
    swal
      .fire({
        title: "Are you sure?",
        text: "Do you want to delete this asset?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          deleteAsset(asset_id);
        }
      });
  };
  const deleteAsset = async (asset_id) => {
    const config = {
      method: "GET",
      url: BOND_DELETE_PATH + "/" + asset_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "success") {
        getBondManagementList();

        Swal.fire(getAlertToast("Success", "Asset deleted Successfully"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getBondManagementList();
    //getAssetManagementList();
  }, [currentpage]);
  // const handleSelectEvent = (selectedid) => {
  //   if (!selectedfund.includes(selectedid)) {
  //     setselectedfund((prevSelected) => [...prevSelected, selectedid]);
  //   } else {
  //     setselectedfund((prevSelected) =>
  //       prevSelected.filter((id) => id !== selectedid)
  //     );
  //   }
  // };
  // const bondModify = () => {
  //   if (setAllocationBond == true) {
  //     setAllocationBond(true);
  //   } else {
  //     setAllocationBond(false);
  //   }
  // };
  const togglesrec = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  console.log(porfolioId, "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");

  // const getstockfunds = async () => {
  //   const config = {
  //     method: "GET",
  //     url:STOCK_FUNDS_URL ,
  //     headers: authHeader(),
  //     params: {
  //       portfolio_id: porfolioId,
  //     },
  //   };
  //   try {
  //     const response = await API(config);
  //     let {data } = response.data;
  //     // setbond(data.bonds);
  //     setBond(data.bonds)
  //     setstock(data.stocks);
  //     setfund(data.funds);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getstockfunds();
  // }, []);

  const getAssetManagementList = async () => {
    console.log(currentpage, "wwwwww");
    const config = {
      method: "GET",
      url: ASSET_MANAGEMENT_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setAssetList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssetManagementList();
  }, [currentpage]);

  const addStockList = () => {
    //     let stockOptions = [];
    //         stock.map((eve)=>{
    //           if(eve.stock_taken=="1"){
    //             stockOptions.push(eve);
    //           }

    //  })
    setRecommendedStockist(Math.floor(Math.random() * 100));
    //  setRecommendedStockist(stockOptions)
  };

  const addfundsList = () => {
    // let fundOptions = [];
    //     fund.map((eve)=>{
    //       if(eve.funds_taken=="1"){
    //         fundOptions.push(eve);
    //       }
    setRecommendedFundList(Math.floor(Math.random() * 100));
  };

  const addbondsList = () => {
    setRecommendedBondList(Math.floor(Math.random() * 100));
  };

  return (
    <div className="p-3">
      <Row>
        <Col sm={9}>
          <div className="relative">
           {activeTab != "3" && <span className="date_absolute">
              Last Updated On :{" "}
              {moment(assetData?.portfolio?.portfolio_updatedon).format(
                "YYYY/MM/DD"
              )}
            </span>}
          </div>
          <Nav tabs className="break_tab">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  togglesrec("1");
                }}
              >
                Bonds
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  togglesrec("2");
                }}
              >
                Stocks
              </NavLink>
            </NavItem>{" "}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  togglesrec("3");
                }}
              >
                External Funds
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col sm={3} className="text-right">
          <button
            className="add_asset btn-primary btn px-5"
            onClick={() => {
              addbondsList();
              addStockList();
              addfundsList();
              // {console.log(assetData,'assetDataassetDataassetDataassetDataassetDataassetData')}
              // if(classnames({ active: activeTab === "3" })){

              // }
              // if(classnames({ active: activeTab === "2" })){

              // }
              // if(classnames({ active: activeTab === "1" })){
              //  //

              // }
              setModalrecom(false);
              //setAddRemovechange(true);
              //RemoveLowestValue();
            }}
          >
            Add
          </button>
        </Col>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Table hover responsive>
            <thead>
              <tr>
                <th></th>
                <th>Action</th>
                <th>S.NO</th>
                <th>Name</th>
                <th>Suggested Allocation ($)</th>
                <th>Suggested Allocation (%)</th>
                <th>Rating</th>
                <th>Geography</th>
                <th>Sector</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                <Fragment>
                  {assetData.length > 0
                    ? assetData.map((record, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                {!isChecked && (
                                  <input
                                    type="checkbox"
                                    name={`status_${index}`}
                                    onChange={(e) => {
                                      checkedAll(e, index);
                                    }}
                                    defaultChecked={
                                      record.bond_taken == "1" ? true : false
                                    }
                                    // onClick={() =>
                                    //   setCurrentBondIndex(
                                    //     currentBondIndex == record.bond_name
                                    //       ? 0
                                    //       : record.bond_name
                                    //   )
                                    // }
                                  />
                                )}
                              </td>
                              <td>
                                <Button
                                  color=""
                                  size="sm"
                                  className="actionBtn"
                                  onClick={() =>
                                    setCurrentBondIndex(
                                      currentBondIndex == record.bond_name
                                        ? 0
                                        : record.bond_name
                                    )
                                  }
                                >
                                  <FaEye></FaEye>
                                </Button>
                              </td>
                              <td>{rowperpage * currentpage + (index + 1)}</td>
                              <td>{record.bond_name}</td>
                              <td>
                                {parseFloat(
                                  record.bond_suggested_allocation_dollar
                                    .toString()
                                    .replaceAll("$", "")
                                    .replaceAll(",", "")
                                ).toFixed(1)}
                              </td>
                              <td>
                                {(
                                  record.bond_suggested_allocation_percentage *
                                  100
                                ).toFixed(1)}
                              </td>
                              <td>{record.bond_bloomberg_composite_ranking}</td>
                              <td>{record.bond_investment_geography}</td>
                              <td>{record.bond_industry_focus}</td>
                             
                            </tr>
                            {currentBondIndex == record.bond_name && (
                              <tr>
                                <td colSpan={5}>
                                  <Table hover responsive className="">
                                    <CustomTableHeader
                                      tableColumns={tableColumnsBond}
                                    />
                                    <tbody>
                                      {!loading ? (
                                        <Fragment>
                                          {record.bond_data_list.length > 0 ? (
                                            record.bond_data_list.map(
                                              (bondSet, ind) => {
                                                //const isEventSelected = selectedfund.includes(record.bond_id);
                                                const {
                                                  bond_id,
                                                  bond_name,
                                                  bond_isin,
                                                  bond_currency,
                                                  bond_rank,
                                                  bond_suggested_allocation_dollar,
                                                  bond_suggested_allocation_percentage,
                                                  bond_current_price,
                                                  bond_coupon,
                                                  bond_yield_worst,
                                                  bond_modified_duration,
                                                  bond_maturity_date,
                                                  bond_call_date,
                                                  bond_bloomberg_composite_ranking,
                                                  bond_investment_geography,
                                                  bond_industry_focus,
                                                } = bondSet;
                                                return (
                                                  <tr key={ind}>
                                                    <td>
                                                      {/* {!isChecked &&( */}
                                                      <input
                                                        type="checkbox"
                                                        name={`status_${index}_${ind}`}
                                                        defaultChecked={
                                                          bondSet.bond_taken ==
                                                          "1"
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) =>
                                                          checkedchildAll(
                                                            e,
                                                            index,
                                                            ind
                                                          )
                                                        }
                                                        // onChange={(e)=>{
                                                        //   bondSet.bond_taken =e.target.checked ? "yes" : "no"
                                                        //   setselectBond(bondSet.bond_taken);
                                                        //   // checkboxbond();

                                                        //   console.log(bondSet.bond_taken,'StockDataStockDataStockDataStockData')
                                                        // }}
                                                      />
                                                      {/* )}   */}
                                                    </td>
                                                    <td>{bond_name}</td>
                                                    <td>{bond_isin}</td>
                                                    <td>{bond_currency}</td>
                                                    <td>
                                                      {parseFloat(
                                                        bond_suggested_allocation_dollar
                                                          .toString()
                                                          .replaceAll("$", "")
                                                          .replaceAll(",", "")
                                                      ).toFixed(1)}
                                                    </td>
                                                    <td>
                                                      {(
                                                        bond_suggested_allocation_percentage *
                                                        100
                                                      ).toFixed(1)}{" "}
                                                    </td>
                                                    <td>{bond_rank}</td>
                                                    <td>
                                                      {bond_current_price}
                                                    </td>
                                                    <td>{bond_coupon}</td>
                                                    <td>{bond_yield_worst}</td>
                                                    <td>
                                                      {bond_modified_duration}
                                                    </td>
                                                    <td>
                                                      {bond_maturity_date}
                                                    </td>
                                                    <td>{bond_call_date}</td>
                                                    <td>
                                                      {
                                                        bond_bloomberg_composite_ranking
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        bond_investment_geography
                                                      }
                                                    </td>
                                                    <td>
                                                      {bond_industry_focus}
                                                    </td>

                                                    <td>
                                                      {" "}
                                                      <Button
                                                        data-id={bond_id}
                                                        color=""
                                                        size="sm"
                                                        className="actionBtn"
                                                        onClick={onClickDelete}
                                                      >
                                                        <FaTrash></FaTrash>
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <CustomEmptyTableRecord
                                              columnsCount={tableColumns.length}
                                            />
                                          )}
                                        </Fragment>
                                      ) : (
                                        <CustomTableLoader
                                          columnsCount={tableColumns.length}
                                        />
                                      )}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })
                    : ""}
                </Fragment>
              ) : (
                <CustomTableLoader columnsCount={tableColumns.length} />
              )}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="2">
          <Table hover responsive>
            <CustomTableHeader tableColumnsstock={tableColumnsstock} />
            <tbody>
              {!loading ? (
                <Fragment>
                  {stockData.length > 0 ? (
                    stockData.map((stocks, index) => {
                      //const isEventSelected = selectedfund.includes(record.bond_id);
                      const {
                        id,
                        asset_fundname,
                        asset_class,
                        asset_strategy,
                        asset_geo_focus,
                        asset_fund_size,
                        asset_inceptiondate,
                        asset_currency,
                        asset_status,
                        asset_auditor,
                        asset_custodian,
                        asset_administrator,
                        asset_noticeperiod,
                        asset_lockupperiod,
                        asset_management_fee,
                        asset_minimum_investment,
                        asset_performance_fee,
                        asset_expected_returns,
                        cash_flow_yield,
                        asset_industry_sector,
                        asset_upside_potential,
                        asset_createdon,
                        max_weightage,
                        asset_redemptionperiod,
                      } = stocks;
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              name={`status_${index}`}
                              defaultChecked={
                                stocks.stock_taken == "1" ? true : false
                              }
                              // onChange={(e) => checkedStockchildAll(e,index)}
                              onChange={(e) => {
                                stocks.stock_taken = e.target.checked
                                  ? "1"
                                  : "0";
                              }}
                            />
                          </td>
                          <td>{rowperpage * currentpage + (index + 1)}</td>
                          <td>{stocks.stock_name}</td>
                          <td>{stocks.stock_bloomberg_ticker}</td>
                          <td>{stocks.stock_currency}</td>
                          <td>
                            {stocks.stock_suggested_allocation_dollar
                              ? stocks.stock_suggested_allocation_dollar
                              : ""}
                          </td>
                          <td>
                            {(
                              stocks.stock_suggested_allocations_percentage *
                              100
                            ).toFixed(1)}
                          </td>
                          <td>{stocks.stock_current_price}</td>
                          <td>{stocks.stock_lc_current_price}</td>
                          <td>
                            {(stocks.stock_upside_potential * 100).toFixed(1) +
                              "%"}
                          </td>
                          <td>
                            {stocks.stock_dividend_yield
                              ? stocks.stock_dividend_yield
                              : "-"}
                          </td>
                          <td>{stocks.stock_investment_geography}</td>
                          <td>{stocks.stock_industry_focus}</td>
                          {/* <td>{stocks.asset_status}</td>
                            <td>{numToPercentage(stocks.asset_performance_fee)}</td>
                            <td>{numToPercentage(stocks.asset_management_fee)}</td>
                            <td><NumberFormat thousandSeparator={true}  value= {stocks.asset_minimum_investment} displayType={'text'} /></td>
              
                            <td>{asset_auditor}</td>
                            <td>{asset_custodian}</td>
                            <td>{asset_administrator}</td>
                            <td>{asset_noticeperiod}</td>
                            <td>{asset_lockupperiod}</td>
                            <td>{asset_redemptionperiod}</td>
                            <td>{numToPercentage(asset_expected_returns)}</td>
                            <td>{numToPercentage(max_weightage)}</td>
                            <td>{numToPercentage(cash_flow_yield)}</td>
                            <td>{asset_industry_sector}</td>
                            <td>{numToPercentage(asset_upside_potential)}</td>
                            <td style={{ whiteSpace: "pre" }}>
                              {asset_createdon}
                            </td> */}
                          <td style={{ display: "flex" }}>
                            {/* <div> */}
                            {/* <Button
                                data-id={id}
                                color=""
                                size="sm"
                                className="actionBtn"
                                onClick={viewRecord}
                              >
                                <FaEye></FaEye>
                              </Button> */}

                            {/* <Button
                                data-id={id}
                                color=""
                                size="sm"
                                className="actionBtn"
                                onClick={onClickDelete}
                              >
                                <FaTrash></FaTrash>
                              </Button> */}

                            {/* </div> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <CustomEmptyTableRecord
                      columnsCount={tableColumnsstock.length}
                    />
                  )}
                </Fragment>
              ) : (
                <CustomTableLoader columnsCount={tableColumnsstock.length} />
              )}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="3">
          <Table hover responsive>
            <CustomTableHeader tableColumnsstock={tableColumnsFund} />
            <tbody>
              {!loading ? (
                <Fragment>
                  {fundData.length > 0 ? (
                    fundData.map((funds, index) => {
                      //const isEventSelected = selectedfund.includes(record.bond_id);
                      const {
                        id,
                        asset_fundname,
                        asset_class,
                        asset_strategy,
                        asset_geo_focus,
                        asset_fund_size,
                        asset_inceptiondate,
                        asset_currency,
                        asset_status,
                        asset_auditor,
                        asset_custodian,
                        asset_administrator,
                        asset_noticeperiod,
                        asset_lockupperiod,
                        asset_management_fee,
                        asset_minimum_investment,
                        asset_performance_fee,
                        asset_expected_returns,
                        cash_flow_yield,
                        asset_industry_sector,
                        asset_upside_potential,
                        asset_createdon,
                        max_weightage,
                        asset_redemptionperiod,
                      } = funds;
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              name={`status_${index}`}
                              defaultChecked={
                                funds.funds_taken == "1" ? true : false
                              }
                              onChange={(e) => {
                                funds.funds_taken = e.target.checked
                                  ? "1"
                                  : "0";
                              }}
                            />
                          </td>
                          <td>{rowperpage * currentpage + (index + 1)}</td>
                          <td>{funds.funds_name}</td>
                          <td>{funds.funds_asset_type}</td>
                          <td>{funds.funds_strategy}</td>
                          <td>{funds.funds_isin}</td>
                          <td>{funds.funds_currency}</td>
                          <td>
                            {parseFloat(
                              funds.funds_suggested_allocation_dollar
                                .toString()
                                .replaceAll("$", "")
                                .replaceAll(",", "")
                            ).toFixed(1)}
                          </td>
                          <td>
                            {(
                              funds.funds_suggested_allocations_percentage * 100
                            ).toFixed(1)}
                          </td>
                          <td>
                            {(
                              funds.funds_annual_return_inception * 100
                            ).toFixed(1) + "%"}
                          </td>
                          <td>
                            {(
                              funds.funds_annual_volatility_inception * 100
                            ).toFixed(1) + "%"}
                          </td>
                          <td>
                            {(funds.funds_management_fee * 100).toFixed(1) +
                              "%"}
                          </td>
                          <td>
                            {funds.funds_performance_fee
                              ? parseFloat(funds.funds_performance_fee).toFixed(
                                  1
                                ) + "%"
                              : "-"}
                          </td>
                          <td>{funds.funds_annual_sharpe_ratio}</td>
                          <td>{funds.funds_correlation_MSCI_ACWI}</td>
                          <td>{funds.funds_dividend_yield}</td>
                          <td>{funds.funds_investment_geography}</td>
                          <td>
                            {parseFloat(funds.funds_max_drawdown).toFixed(1) +
                            "%"
                              ? parseFloat(funds.funds_max_drawdown).toFixed(
                                  1
                                ) + "%"
                              : ""}
                          </td>
                          <td>{funds.funds_redemption_period}</td>
                          <td>{funds.funds_notice_period}</td>
                          <td>{funds.sta_a_last_return_date}</td>
                          {/* <td>{stocks.asset_status}</td>
                            <td>{numToPercentage(stocks.asset_performance_fee)}</td>
                            <td>{numToPercentage(stocks.asset_management_fee)}</td>
                            <td><NumberFormat thousandSeparator={true}  value= {stocks.asset_minimum_investment} displayType={'text'} /></td>
              
                            <td>{asset_auditor}</td>
                            <td>{asset_custodian}</td>
                            <td>{asset_administrator}</td>
                            <td>{asset_noticeperiod}</td>
                            <td>{asset_lockupperiod}</td>
                            <td>{asset_redemptionperiod}</td>
                            <td>{numToPercentage(asset_expected_returns)}</td>
                            <td>{numToPercentage(max_weightage)}</td>
                            <td>{numToPercentage(cash_flow_yield)}</td>
                            <td>{asset_industry_sector}</td>
                            <td>{numToPercentage(asset_upside_potential)}</td>
                            <td style={{ whiteSpace: "pre" }}>
                              {asset_createdon}
                            </td> */}
                          <td style={{ display: "flex" }}>
                            {/* <div> */}
                            {/* <Button
                                data-id={id}
                                color=""
                                size="sm"
                                className="actionBtn"
                                onClick={viewRecord}
                              >
                                <FaEye></FaEye>
                              </Button> */}

                            {/* <Button
                                data-id={id}
                                color=""
                                size="sm"
                                className="actionBtn"
                                onClick={onClickDelete}
                              >
                                <FaTrash></FaTrash>
                              </Button> */}

                            {/* </div> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <CustomEmptyTableRecord
                      columnsCount={tableColumnsstock.length}
                    />
                  )}
                </Fragment>
              ) : (
                <CustomTableLoader columnsCount={tableColumnsstock.length} />
              )}
            </tbody>
          </Table>
        </TabPane>
      </TabContent>
    </div>
  );
}
