import React, { Fragment, useState, useEffect, useRef } from "react";
import Sidebar from "../layout/Sidebar";
import Axios from "axios";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  InputGroup,
  UncontrolledDropdown,
  InputGroupAddon,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import {
  ADD_CLIENT_ROUTE,
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  SETTINGS_ROUTE,
  LIST_PORTFOLIO,
} from "../common/RoutePaths";
import Listofport from "./Listofport";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import { useDispatch } from "react-redux";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getRmID,
} from "../common/Mainfunctions";
import { FormGroup } from "@material-ui/core";
import {
  FaSortDown,
  FaCaretRight,
  FaEye,
  FaEdit,
  FaUser,
  FaCreativeCommonsBy,
  FaCheckCircle,
  FaTimesCircle,
  FaTrash,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa";
import {
  BENCHMARK_STATUS_URL,
  CLIENT_DELETE_URL,
  CLIENT_LIST,
  PORTFOLIO_ACCEPT_URL,
  GET_CLIENT_PORTFOLIOS,
  PERMISSION,
  
} from "../common/urls";
import SelectClient from "./selectClient";
import API from "../redux/API";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomTableHeader from "../common/CustomTableHeader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import { debounce } from "underscore";
import AssignClient from "./assignClient";
import RMrole from "./RMrole";
import Swal from "sweetalert2";

import { Modal, ModalHeader, ModalBody, ModalFooter, Col } from "reactstrap";
import { AiOutlinePlusCircle, AiOutlineBell } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { green } from "@material-ui/core/colors";

import {
  Tooltip,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from "reactstrap";
import {
  getDisplayName,
  getProfileImage,
  getProfileName,
  getRole,
  logOutFun,
} from "../common/Mainfunctions";
import Row from "reactstrap/lib/Row";

var ourRequest;
export default function Client(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectClientModal, setSelectClientModal] = useState(false);
  const selectClientToggle = () => setSelectClientModal(!selectClientModal);
  const [assignClientModal, setAssignClientModal] = useState(false);
  const [permission, setpermission] = useState("");
  const assignClientToggle = () => setAssignClientModal(!assignClientModal);

  const [assignRMroleModal, setAssignRMroleModal] = useState(false);
  const assignRMroleToggle = () => setAssignRMroleModal(!assignRMroleModal);
  const [clist, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedClient, setClientId] = useState(0);
  const [selectedportfolio, setPortfolioId] = useState(0);
  const [selectedRm, setSelectedRm] = useState(0);
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [showloader, setShowloader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedPorfolioId, setSelectedPortfolioId] = useState("");
  const [clientPortfolioList, setClientPortfolioList] = useState([]);
  const [portfolioerror, setPortfoliError] = useState("");
  // const [benchmarkStatusFlag, setBenchStatus] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [intervalTime, setIntervalTime] = useState(0);
  const [clientData, setClientData] = useState(0);
  const [nextnextPage, setnextnextPage] = useState([]);
  const [responce] = useState();

  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleport = () => setDropdownOpen((dropdownOpen) => !dropdownOpen);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggleport1 = () => setDropdownOpen1((dropdownOpen1) => !dropdownOpen1);
  const [dropFlag, settingDropDown] = useState(false);
  const [modal, setModal] = useState(false);
  const toggles = () => setModal(!modal);
  const [RMassign,setRMassign]=useState("");
  const [ICassign,setICassign]=useState("");
  const logOutClick = () => {
    logOutFun(history);
  };
  useEffect(()=>{
    clist.map((event)=>{
      setRMassign(event.client_rm_id);
    })
  });
  useEffect(()=>{
    clist.map((eve)=>{
      console.log(eve.client_ic_id,'eveeveeveeveeveeveeveeveeveeveeveeve')
       setICassign(eve.client_ic_id);
    })
  });
  useEffect(() => {
    console.log(params, "sssssssssssss");
    if (params.page >= 0) {
      setCurrentPage(parseInt(params.page));
    } else {
      setCurrentPage(0);
    }
  }, [params.page]);

  const setFlag = () => {
    settingDropDown(true);
  };
  const clienttableColumns = [
    { title: "S.No" },
    { title: "RM Name" },
    { title: "Client Name" },
    { title: "Email" },
    { title: "Phone Number" },
    { title: "Onboarded" },
    { title: "List of Portfolios" },
  ];
  const tablePortfolioColumns = [
    { title: "S.No" },
    { title: "Portfolio Name" },
    { title: "Currency" },
    { title: "Client Equity" },
    { title: "Leverage" },
    { title: "Date of Creation" },
    { title: "Status" },
    { title: "Actions" },
  ];

  console.log(search,'searchsearchsearchsearch')

  const getClientList = async (search) => {
    if (ourRequest?.token) {
      ourRequest.cancel();
      console.log(currentpage, ourRequest?.token, "ourRequest?.token");
      ourRequest = null;
    } else {
      ourRequest = Axios.CancelToken.source();
    }
    //alert(ourRequest?.token)

    setLoading(true);
    setList([]);

    const config = {
      method: "GET",
      url: CLIENT_LIST,
      headers: authHeader(),
      cancelToken: ourRequest?.token,
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      ourRequest = null;
      setList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      ourRequest = null;
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onHandleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };
  const delaySearch = useRef(
    debounce((search) => getClientList(search), 500)
  ).current;

  useEffect(() => {
    delaySearch(search);
  }, [search]);

  
  const onPageChange = (page) => {
    //setCurrentPage(page - 1);
    history.push(`/list-client/${page - 1}`);
  };
  const toggleset = (event) => {
    const { cid } = event.currentTarget.dataset;
    if (parseInt(cid) === parseInt(selectedClient)) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
    }
    setClientId(cid);
  };
  const toggleset1 = (event) => {
    const { pid } = event.currentTarget.dataset;
    if (parseInt(pid) === parseInt(selectedportfolio)) {
      setIsOpen1(!isOpen1);
    } else {
      setIsOpen1(true);
    }
    setPortfolioId(pid);
  };
  const onClearfun = (e) => {
    setSearch("");
  };

  const submitCallBack = (e) => {
    selectClientToggle(false);
    getClientList();
  };

  const assignSubmitCallBack = (e) => {
    assignClientToggle(false);
    getClientList();
  };
  const assignRMSubmitCallBack = (e) => {
    assignRMroleToggle(false);
    getClientList();
  };

  // const addNewPortfolio = (e) => {
  //   if (benchmarkStatusFlag === "valid") {
  //     setSelectedPortfolioId("");
  //     selectClientToggle();
  //   } else {
  //     Swal.fire(getAlert("error", "Contact admin"));
  //   }

  // };

  useEffect(() => {
    console.log(currentpage, "mmmmmmmmmmmmm");
    //setClientData(currentpage);
    getClientList();
  }, [currentpage]);
  // useEffect(() => {
  //   console.log(setClientData,'vvvvvvvvvvv')
  //   setClientData(currentpage);
  // }, [setClientData]);

  const assignToggle = (event) => {
    const portfolioId = event.currentTarget.dataset.portfolioid;
    const cid = event.currentTarget.dataset.cid;
    setSelectedPortfolioId(portfolioId);
    setClientId(cid);
    selectClientToggle();
  };

  const onClickAssignClient = (event) => {
    const rm_id = event.currentTarget.dataset.rmid;
    const cid = event.currentTarget.dataset.cid;
    console.log(event, "kkkkkkkkkkkkkkkkkkkkkkkkk");
    console.log(event.currentTarget.dataset, "ppppppppppppppppppppppppppppp");
    setClientId(cid);
    setSelectedRm(rm_id);
    assignClientToggle();
  };

  const onClickAssignRMrole = (event) => {
    const rm_id = event.currentTarget.dataset.rmid;
    const cid = event.currentTarget.dataset.cid;
    setClientId(cid);
    setSelectedRm(rm_id);
    assignRMroleToggle();
  };
  const acceptPortfolio = async (portfolioID) => {
    const config = {
      method: "GET",
      url: PORTFOLIO_ACCEPT_URL + "/" + portfolioID + "/1",
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status } = response.data;
      if (status == "success") {
        getClientList();
        Swal.fire(getAlertToast("Success", "Portfolio accepted Successfully"));
      } else {
        Swal.fire(getAlertToast("error", "Portfolio cannot accept"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const getClientPortfolio = async () => {
  //   if (selectedClient == 0) return false;
  //   const config = {
  //     method: "GET",
  //     url: GET_CLIENT_PORTFOLIOS + selectedClient + 7,
  //     headers: authHeader(),
  //   };
  //   try {
  //     const response = await API(config);
  //     let { status, data } = response.data;
  //     console.log(data, status, "datadata");
  //     if (status == "Success") {
  //       setClientPortfolioList(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setShowloader(false);
  //   }
  // };

  const onClickAccept = (e) => {
    e.stopPropagation();
    let portfolioId = parseInt(e.currentTarget.dataset.portfolioid);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this portfolio?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        acceptPortfolio(portfolioId);
      }
    });
  };
  const deleteClient = async (clientId) => {
    // let list = [];
    // const clientId = event?.currentTarget?.dataset?.cid;
    // console.log(event,'clistclistclistclistclistclist');
    // list = clist.filter((option) => option.portfolio == "");
    // console.log(list);
    // const client = list.filter((record) => record.client_id == clientId);
    // console.log(client);
    const config = {
      method: "DELETE",
      url: CLIENT_DELETE_URL + clientId,
      headers: authHeader(),
    };
    console.log(clientId, "clientIdclientIdclientIdclientId");
    try {
      const response = await API(config);
      const { data } = response.data;
      if (response.status == "error") {
        Swal.fire(getAlert("error", data));
      } else {
        Swal.fire(getAlertToast("Success", data));
        getClientList();
      }

      /*if (client.length > 0) {
        const response = await API(config);
        Swal.fire(getAlertToast("Success", "Client deleted Successfully"));
        getClientList();
        console.log(response);
      } else {
        Swal.fire(getAlert("error", "Client has portfolio can't delete"));
      }*/
    } catch (error) {
      Swal.fire(getAlert("error", error));
    }
  };
  const onClickDelete = (event) => {
    // e.stopPropagation();
    //let clientId = parseInt(e.currentTarget.dataset.cid);
    let list = [];
    const clientId = event?.currentTarget?.dataset?.cid;
    console.log(event, "clistclistclistclistclistclist");
    list = clist.filter((option) => option.portfolio == "");
    console.log(list);
    const client = list.filter((record) => record.client_id == clientId);
    console.log(client);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Delete this Client?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        console.log(
          result,
          "clientIdclientIdclientIdclientIdclientIdclientIdclientId"
        );
        deleteClient(clientId);
      }
    });
  };
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);

  const benchmarkStatus = async () => {
    const config = {
      method: "GET",
      url: BENCHMARK_STATUS_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      //console.log(response.data,'0099888990000099889900');
      if (data === "valid") {
        setSelectedPortfolioId("");
        selectClientToggle();
      } else {
        Swal.fire(getAlert("error", "Contact admin"));
      }
      // setBenchStatus(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setClientPortfolioList([]);
    //getClientPortfolio();
    clearInterval(intervalTime);
    //setIntervalTime(setInterval(() => getClientPortfolio(), 20000));
  }, [selectedClient]);

  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper">
          <div className="bg_f2 pt-3 pb-3 pl-3 pr-3">
            <Row>
              <Col xl={4} md={12}>
                <div className="btn-right">
                  <FormGroup>
                    <Input
                      type="text"
                      name="content"
                      value={search}
                      placeholder="search"
                      onChange={onHandleChange}
                    />
                  </FormGroup>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="searchBtnTable"
                      color="link"
                      onClick={onClearfun}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.415"
                        height="8.415"
                        viewBox="0 0 8.415 8.415"
                      >
                        <path
                          d="M16.415,8.736,15.679,8l-3.471,3.471L8.736,8,8,8.736l3.471,3.471L8,15.679l.736.736,3.471-3.471,3.471,3.471.736-.736-3.471-3.471Z"
                          transform="translate(-8 -8)"
                          fill="#c43f57"
                        />
                      </svg>
                    </Button>
                  </InputGroupAddon>
                </div>
              </Col>
              <Col xl={8} md={8} className="text-xl-right mt-md-3 mt-xl-0">
                <UncontrolledDropdown
                  setActiveFromChild
                  className="d-inline-block my-3 my-sm-0"
                >
                  <DropdownToggle tag="a" className="nav-link color_ac" caret>
                    <img
                      className="avatar"
                      // src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      src={
                        getProfileImage() ||
                        "https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      }
                    />{" "}
                    <span>{getDisplayName()}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a">
                      <Link className="cus-link " to={SETTINGS_ROUTE}>
                        Settings
                      </Link>
                    </DropdownItem>

                    <DropdownItem
                      tag="a"
                      onClick={logOutClick}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Button
                  onClick={benchmarkStatus}
                  color="primary"
                  className="mr-3"
                >
                  <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                  Add New Portfolio
                </Button>
                <Button color="primary">
                  <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                  <Link className="cus-link" to={ADD_CLIENT_ROUTE}>
                    Add New Client
                  </Link>
                </Button>
              </Col>
            </Row>
          </div>
          <Container fluid className="">
            <Card className="min-screenheight st_card">
              <CardTitle className="p-0 mt-4">
                <span className="list_cli">List of Clients</span>
              </CardTitle>

              <Table hover responsive>
                <CustomTableHeader tableColumns={clienttableColumns} />
                <tbody>
                  {!loading ? (
                    <Fragment>
                      {clist && clist.length > 0 ? (
                        clist.map((record, index) => {
                          const {
                            client_id,
                            client_name,
                            client_email,
                            client_contactno,
                            client_onboard,
                            rm_name,
                            client_ic_id,

                            portfolio = [],
                            client_rm_id,
                          } = record;

                          return (
                            <Fragment key={`client_key_${index}`}>
                              <tr>
                                <td>
                                  {rowperpage * currentpage + (index + 1)}
                                </td>
                                <td>{rm_name}</td>
                                <td>{client_name}</td>
                                <td>{client_email}</td>
                                <td>{client_contactno}</td>
                                <td>{client_onboard}</td>
                                
                                <td>
                                  <Link
                                    className="cus-link"
                                    to={`/List-portfolio/${client_id}/0/${currentpage}`}
                                  >
                                    <Button
                                      className="actionBtn"
                                      color=""
                                      size="sm"
                                      data-cid={client_id}
                                      onClick={toggleset}
                                    >
                                      {parseInt(selectedClient) ===
                                      parseInt(client_id) ? (
                                        <>
                                          <FaEye></FaEye>
                                          <span className="ml-2">
                                            Portfolios
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <FaEye></FaEye>
                                          <span className="ml-2">
                                            Portfolios
                                          </span>
                                        </>
                                      )}
                                    </Button>
                                  </Link>
                                  {client_ic_id != "0"   && (
                                    <>
                                      {permission.user_permission !=
                                            "arm" &&permission.user_permission !=
                                            "ic"  &&  (
                                        <Button
                                          color=""
                                          size="sm"
                                          className="actionBtn"
                                          //  id={`client_${client_id}`}
                                          onClick={onClickAssignClient}
                                          data-rmid={client_ic_id}
                                          data-cid={client_id}
                                        >
                                          <>
                                            <FaUser></FaUser>
                                            <span className="ml-2" id={`tool_${index}`}>
                                              Change IC
                                            </span>
                                          </>
                                          <UncontrolledTooltip
                                            placement="right"
                                            target={`tool_${index}`}
                                            //target={`client_${client_id}`}
                                          >
                                            Change IC
                                          </UncontrolledTooltip>
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {client_ic_id == "0" && (
                                    <>
                                      {permission.user_permission != "arm"  && permission.user_permission != "ic" &&(
                                        <Button
                                          color=""
                                          size="sm"
                                          className="actionBtn"
                                          //  id={`client_${client_id}`}
                                          onClick={onClickAssignClient}
                                          data-rmid={client_ic_id}
                                          data-cid={client_id}
                                        >
                                          <>
                                            <FaUser></FaUser>
                                            <span className="ml-2" id={`tool_${index}`}>
                                              Assign IC
                                            </span>
                                          </>
                                          <UncontrolledTooltip
                                            placement="right"
                                            target={`tool_${index}`}
                                            //target={`client_${client_id}`}
                                          >
                                            Assign IC
                                          </UncontrolledTooltip>
                                        </Button>
                                      )}
                                    </>
                                  )}
                                 
                                    
                                      {client_rm_id == "0" && (
                                        <>
                                          {permission.user_permission !=
                                            "arm" &&permission.user_permission !=
                                            "ic"  &&permission.user_permission !=
                                            "rm"   && (  
                                            <Button
                                              color=""
                                              size="sm"
                                              className="actionBtn"
                                              //id={`client_${client_id}`}
                                              onClick={onClickAssignRMrole}
                                              data-rmid={client_rm_id}
                                              data-cid={client_id}
                                            >
                                              <>
                                                <FaCreativeCommonsBy></FaCreativeCommonsBy>
                                                <span
                                                  className="ml-2"
                                                  id={`tool1_${index}`}
                                                >
                                                  Assign RM
                                                </span>
                                              </>
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`tool1_${index}`}
                                                //target={`client_${client_id}`}
                                              >
                                                Assign RM
                                              </UncontrolledTooltip>
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    
                                 

                                    {client_rm_id == "0" && (
                                        <>
                                     {(permission.user_permission =="rm" && (
                                            <Button
                                              color=""
                                              size="sm"
                                              className="actionBtn"
                                              //id={`client_${client_id}`}
                                              onClick={onClickAssignRMrole}
                                              data-rmid={client_rm_id}
                                              data-cid={client_id}
                                            >
                                              <>
                                                <FaCreativeCommonsBy></FaCreativeCommonsBy>
                                                <span
                                                  className="ml-2"
                                                  id={`tool1_${index}`}
                                                >
                                                  Assign ARM
                                                </span>
                                              </>
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`tool1_${index}`}
                                                //target={`client_${client_id}`}
                                              >
                                                Assign ARM
                                              </UncontrolledTooltip>
                                            </Button>
                                        )
                                  )}
                                  </>
                                    )}
                                  
                                      {client_rm_id != "0" && (
                                        <>
                                          {permission.user_permission !=
                                            "arm" &&permission.user_permission !=
                                            "ic"   && permission.user_permission !=
                                            "rm"   &&(
                                            <Button
                                              color=""
                                              size="sm"
                                              className="actionBtn"
                                              //id={`client_${client_id}`}
                                              onClick={onClickAssignRMrole}
                                              data-rmid={client_rm_id}
                                              data-cid={client_id}
                                            >
                                              <>
                                                <FaCreativeCommonsBy></FaCreativeCommonsBy>
                                                <span
                                                  className="ml-2"
                                                  id={`tool1_${index}`}
                                                >
                                                  Change RM
                                                </span>
                                              </>
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`tool1_${index}`}
                                                //target={`client_${client_id}`}
                                              >
                                                Change RM
                                              </UncontrolledTooltip>
                                            </Button>
                                          )}
                                        </>
                                      )}
                                   

                                  {client_rm_id != "0" && (
                                        <>
                                  {(permission.user_permission =="rm" && (
                                            <Button
                                              color=""
                                              size="sm"
                                              className="actionBtn"
                                              //id={`client_${client_id}`}
                                              onClick={onClickAssignRMrole}
                                              data-rmid={client_rm_id}
                                              data-cid={client_id}
                                            >
                                              <>
                                                <FaCreativeCommonsBy></FaCreativeCommonsBy>
                                                <span
                                                  className="ml-2"
                                                  id={`tool1_${index}`}
                                                >
                                                  Change ARM
                                                </span>
                                              </>
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`tool1_${index}`}
                                                //target={`client_${client_id}`}
                                              >
                                                Change ARM
                                              </UncontrolledTooltip>
                                            </Button>
                                            )
                                  )}
                                  </>
                                  )}
                                  <Button
                                    color=""
                                    size="sm"
                                    className="actionBtn"
                                    onClick={onClickDelete}
                                    data-cid={client_id}
                                  >
                                    <>
                                      <FaTrash></FaTrash>
                                      <span className="ml-2">Delete</span>
                                    </>
                                  </Button>
                                </td>
                              </tr>

                              <tr className="collabse-tr">
                                <td colSpan="7" className="collabse-td"></td>
                              </tr>
                            </Fragment>
                          );
                        })
                      ) : (
                        <CustomEmptyTableRecord
                          columnsCount={clienttableColumns.length}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <CustomTableLoader
                      columnsCount={clienttableColumns.length}
                    />
                  )}
                </tbody>
              </Table>

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={currentpage + 1}
                  itemsCountPerPage={rowperpage}
                  totalItemsCount={totalrecords}
                  onChange={onPageChange}
                />
              </div>
            </Card>
          </Container>
        </div>
        {selectClientModal && (
          <SelectClient
            selectClientModal={setSelectClientModal}
            selectClientToggle={selectClientToggle}
            client_id={selectedClient}
            portfolio_id={selectedPorfolioId}
            submitCallBack={submitCallBack}
          />
        )}
        {console.log(ICassign,'selectedClientselectedClientselectedClient')}
        {assignClientModal && (
          <AssignClient
            assignClientModal={setAssignClientModal}
            assignClientToggle={assignClientToggle}
            client_id={selectedClient}
            rm_id={selectedRm}
            client_ic_id={ICassign}
            submitCallBack={assignSubmitCallBack}
          />
        )}
        {assignRMroleModal && (
          <RMrole
            assignRMroleModal={setAssignRMroleModal}
            assignRMroleToggle={assignRMroleToggle}
            client_id={selectedClient}
            rm_id={selectedRm}
            client_rm_id={RMassign}
            submitCallBack={assignRMSubmitCallBack}
          />
        )}
        
        <Dropdown isOpen={dropdownOpen} toggle={toggleport} size="sm" color="">
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            variant="success"
          >
            <FaEdit></FaEdit>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a">
              <Link
                className="cus-link "
                to={{
                  pathname: PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + "2",
                  state: {
                    leverageFlag: true,
                    constraintFlag: false,
                  },
                }}
              >
                Add Leverage
              </Link>
            </DropdownItem>
            <DropdownItem tag="a">
              <Link
                className="cus-link "
                to={{
                  pathname: PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + "2",
                  state: {
                    leverageFlag: false,
                    constraintFlag: true,
                  },
                }}
              >
                Modify Constraints
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Fragment>

      <Modal isOpen={modal} toggle={toggles} size="xl">
        <div className="modalwidth">
          <ModalHeader toggle={toggles}>Error Detail</ModalHeader>
          <ModalBody className="bodywidth">
            <pre style={{ padding: `10px` }}>{portfolioerror}</pre>
          </ModalBody>
          <ModalFooter className="footerwidth">
            <Button color="secondary" onClick={toggles}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}
