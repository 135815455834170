import React from "react";
import CustomLoader from "./CustomLoader";
export default function CustomTableLoader({ columnsCount }) {
  return (
    <tr>
      <td colSpan={columnsCount}>
        <center>
          <CustomLoader loading={true} />
        </center>
      </td>
      
    </tr>
  );
}
