import React, { Fragment } from "react";
import loaderComponent from "../../images/loader.gif";
export default function CustomLoader({ loading }) {
  return (
    <Fragment>
      {loading && (
        <img alt="loading" src={loaderComponent} style={{ width: "25px", height: "25px" }} />
      )}
       
    </Fragment>
  );
}
