import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import {
  ASSIGN_RM_URL,
  USER_LIST_URL,
  IC_LIST,
  ARM_LIST_URL,
  PERMISSION,
  RM_LIST_URL,
  RM_USER_URL,
  CLIENT_LIST,
  RM_EDIT_URL,
  ASSIGN_ARM_URL,
} from "../common/urls";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getRmID,
} from "../common/Mainfunctions";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import API from "../redux/API";
import { useDispatch } from "react-redux";
import Axios from "axios";
import Swal from "sweetalert2";

var ourRequest;
export default function RMrole(props) {
  const {
    assignRMroleModal = false,
    assignRMroleToggle,
    client_id,
    rm_id,
    client_rm_id,
    submitCallBack,
  } = props;
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [adminlist, setadminList] = useState("");
  const [permission, setpermission] = useState("");
  const [rmId, setRmId] = useState(rm_id);
  const [rmuserList, setrmuserList] = useState("");
  const [empty, setempty] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectidRM, setselectidRM] = useState("");
  const [armselectid, setArmselectid] = useState(rm_id);
  const [adminselectid, setAdminselectid] = useState("");
  const [superAdminselectid, setSuperAdminselectid] = useState("");
  const [rowperpage, setRowPerPage] = useState(10000);
  const [ARMlist, setARMList] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [list, setList] = useState("");
  const [RMlist, setRmList] = useState([]);
  const [selectID,setselectID]=useState("");

  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: USER_LIST_URL,
      headers: authHeader(),
      params: {
        searchterm: search,
        role: "rm",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      setClientNameList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onRadioChange = (e) => {
    const { value } = e.target;
    setArmselectid(value);
  };
  const onRadioChangeRM = (e) => {
    const { value } = e.target;
    setselectidRM(value);
  };
  const onClickSubmit = (e) => {
    if (rmId === 0) {
      Swal.fire(getAlert("error", "Please select Rm!!!"));
    } else {
      assignRMroleToggle();
    }
  };
  console.log(selectidRM, "armselectidarmselectidarmselectidarmselectid");

useEffect(()=>{
if(permission.user_permission!="rm"){
  setselectID(armselectid)
}
else{
  setselectID(selectidRM)
  console.log(selectidRM,'iiiiiiiiiiiiiiiiiiiiiiii')
}
},[selectidRM,armselectid,selectID]);
  const getAssignARMList = async (e) => {
    const config = {
      method: "GET",
      url: ASSIGN_RM_URL,
      headers: authHeader(),
      data: { rm_id: selectID },
      params: {
        //arm_id: selectID,
        rm_id:selectID,
        client_id: client_id,
      },
    };
    console.log(selectID,'wwwwwwwwwwwwwwwwwwwwwwwwwwww')
    try {
      setLoading(true);
      const response = await API(config);
      const { status, data } = response.data;

      if (status === "Success") {
        Swal.fire(getAlertToast("success", data));
        submitCallBack();
      } else if (status === "Error") {
        Swal.fire(getAlert("error", "Failed to Update"));
        submitCallBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // const getAssignRMList = async (e) => {
  //   const config = {
  //     method: "GET",
  //     url: ASSIGN_ARM_URL,
  //     headers: authHeader(),
  //     data: { arm_id: rmselectid },
  //     params: {
  //       arm_id: rmselectid,
  //       client_id: client_id,
  //     },
  //   };
  //   console.log(rmselectid,'rmselectidrmselectid')
  //   try {
  //     setLoading(true);
  //     const response = await API(config);
  //     const { status, data } = response.data;

  //     if (status === "Success") {
  //       Swal.fire(getAlertToast("success", data));
  //       submitCallBack();
  //     } else if (status === "Error") {
  //       Swal.fire(getAlert("error", "Failed to Update"));
  //       submitCallBack();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const delaySearch = useRef(
  //   debounce((search) => getClientNameList(search), 500)
  // ).current;

  useEffect(() => {
    // delaySearch(search);
    getClientNameList(search);
  }, [search]);

  useEffect(() => {
    getClientNameList();
  }, []);

  useEffect(() => {
    getClientNameList();
  }, []);

  useEffect(() => {
    getRmList();
  }, []);
  const getClientList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: ARM_LIST_URL + getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setARMList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClientList();
  }, [getRmID]);

  const getRmList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: IC_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setList(data);
      console.log(data, "pppppppppppppppppppppppppppppp");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearFun = () => {
    setSearch("");
  };
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);

  const getRMUSERList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RM_LIST_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      let List = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setadminList(List);
      console.log(
        List,
        "RM_LIST_URLRM_LIST_URLRM_LIST_URLRM_LIST_URLRM_LIST_URL"
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRMUSERList();
  }, []);


  const getRMClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RM_USER_URL + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setrmuserList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
const RMDetails = async () => {
    const config = {
      method: "GET",
      url: RM_EDIT_URL + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      const { client = [] } = data;
      let selclient = [];
      data.arm.map((record) => {
        selclient.push({ label: record.email, value: record.id });
      });
      setSelectedOption(selclient);
      // setClient(selclient);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    RMDetails();
  },[]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (rmuserList.length > 0) {
        setempty("2");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [rmuserList]);

  useEffect(() => {
    getRMClientList();
  }, [getRmID]);

  const getClientRMList = async (search) => {
    if (ourRequest?.token) {
      ourRequest.cancel();
      console.log(currentpage, ourRequest?.token, "ourRequest?.token");
      ourRequest = null;
    } else {
      ourRequest = Axios.CancelToken.source();
    }
    //alert(ourRequest?.token)

    setLoading(true);
    setRmList([]);

    const config = {
      method: "GET",
      url: CLIENT_LIST,
      headers: authHeader(),
      cancelToken: ourRequest?.token,
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };

    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      ourRequest = null;
      let RM = data.map((e, i) => ({
            value: e.client_rm_id,
            }));
      setRmList(RM);

      setTotalRecords(totalrecords);
    } catch (error) {
      ourRequest = null;
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getClientRMList();
  },[]);
  return (
    
    <Modal isOpen={assignRMroleModal} className="">

             {permission.user_permission != "rm" ?
            (armselectid <=0  ?<ModalHeader>Assign RM</ModalHeader> :
              <ModalHeader>Change RM</ModalHeader>): 
              (armselectid <=0  ?<ModalHeader>Assign ARM</ModalHeader> :
              <ModalHeader>Change ARM</ModalHeader>)} 
      <ModalBody>
       
        <div className="table-scroll">
          <Table hover>
            <tbody>
            {!loading ? (permission.user_permission=="rm"?
                <Fragment>
                  {selectedOption&& selectedOption.length > 0 ? (
                    selectedOption.map((record, index) => {
                      const { label, value } = record;
                      return (
                        <tr
                          key={value}
                          onClick={() => {
                            setselectidRM(value)}}
                        >
                          {empty != "1" && (
                            <>
                              <th style={{ width: "35px" }}>{index + 1}</th>
                              <td>{label} </td>
                              
                              <td style={{ width: "35px" }}>

                                <Input
                                  className="modelradio"
                                  type="radio"
                                  name="selectidRM"
                                  value={value}
                                  checked={selectidRM == value ? true : false}
                                  onChange={onRadioChangeRM}
                                  style={{ position: "relative" }}
                                />
                                {
                            console.log(selectidRM,'recordrecordrecordrecordrecordrecord')}
                              </td>
                            </>
                          )}
                          {empty == "1" && (
                            <>
                              <CustomEmptyTableRecord />
                            </>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <CustomTableLoader columnsCount={2} />
                  )}
                </Fragment>:
                <Fragment>
                {rmuserList && rmuserList.length > 0 ? (
                  rmuserList.map((record, index) => {
                    const { user_id, user_name } = record;
                    console.log(user_id,'Change RMChange RMChange RMChange RM')
                    return (
                      <tr
                        key={user_id}
                        onClick={() => setArmselectid(user_id)}
                      >
                        {empty != "1" && (
                          <>
                            <th style={{ width: "35px" }}>{index + 1}</th>
                            <td>{user_name} </td>
                            
                            <td style={{ width: "35px" }}>
                              <Input
                                className="modelradio"
                                type="radio"
                                name="armselectid"
                                value={user_id}
                                checked={armselectid == user_id ? true : false}
                                onChange={()=>{
                                  console.log(armselectid,'armselectidarmselectidarmselectidarmselectid')
                                  onRadioChange()}}
                                style={{ position: "relative" }}
                              />
                              
                            </td>
                          </>
                        )}
                        {empty == "1" && (
                          <>
                            <CustomEmptyTableRecord />
                          </>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <CustomTableLoader columnsCount={2} />
                )}
               
              </Fragment>
              ) : (
                <CustomTableLoader columnsCount={2} />
              )}
             
            </tbody>
          </Table>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button onClick={assignRMroleToggle} outline color="primary">
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => {
            getAssignARMList();
          }}
        >
          Assign
        </Button>
      </ModalFooter>
    </Modal>
  );
}
