import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import { PORTFOLIO_INPUTS_CREATE_ROUTE } from "../common/RoutePaths";
import { CLIENT_LIST_ALL, PORTFOLIO_ASSIGN_URL } from "../common/urls";
import {
  authHeader,
  getRmID,
  getAlert,
  getAlertToast,
} from "../common/Mainfunctions";
import API from "../redux/API";
import debounce from "underscore";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

export default function SelectClient(props) {
  const {
    selectClientModal = false,
    selectClientToggle,
    portfolio_id,
    client_id,
    submitCallBack,
  } = props;
  const history = useHistory();
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [clientId, setClientId] = useState(0);

  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: CLIENT_LIST_ALL,
      headers: authHeader(),
      params: {
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data;
      if (portfolio_id) {
        clientList = data.filter(
          (obj) => obj.client_id !== parseInt(client_id)
        );
      }
      setClientNameList(clientList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const onHandleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onRadioChange = (e) => {
    const { value } = e.target;
    setClientId(value);
  };

  const onClickSubmit = (e) => {
    if (clientId === 0) {
      Swal.fire(getAlert("error", "Please select client!!!"));
    } else {
      localStorage.setItem("SELECTED_CLIENT_ID", clientId);
      selectClientToggle();
      history.push(PORTFOLIO_INPUTS_CREATE_ROUTE);
    }
  };
  const assignToClient = async (e) => {
    const config = {
      method: "PUT",
      url: PORTFOLIO_ASSIGN_URL + "/" + portfolio_id,
      headers: authHeader(),
      data: { portfolio_user: clientId },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { status, data } = response.data;

      if (status === "Success") {
        Swal.fire(getAlertToast("success", data));
        submitCallBack();
      } else if (status === "Error") {
        Swal.fire(getAlert("error", "Failed to Update"));
        submitCallBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // const delaySearch = useRef(
  //   debounce((search) => getClientNameList(search), 500)
  // ).current;

  useEffect(() => {
    // delaySearch(search);
    getClientNameList(search);
  }, [search]);

  useEffect(() => {
    getClientNameList();
  }, []);

  const clearFun = () => {
    setSearch("");
  };
  return (
    <Modal isOpen={selectClientModal} toggle={selectClientToggle} className="">
      <ModalHeader toggle={selectClientToggle}>Select Client</ModalHeader>
      <ModalBody>
        <div className="mt-2 mb-2 p-2">
          <InputGroup>
            <Input
              type="text"
              name="content"
              value={search}
              placeholder="search"
              onChange={onHandleChange}
            />

            <InputGroupAddon addonType="append">
              <Button className="searchBtn" color="link" onClick={clearFun}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.415"
                  height="8.415"
                  viewBox="0 0 8.415 8.415"
                >
                  <path
                    d="M16.415,8.736,15.679,8l-3.471,3.471L8.736,8,8,8.736l3.471,3.471L8,15.679l.736.736,3.471-3.471,3.471,3.471.736-.736-3.471-3.471Z"
                    transform="translate(-8 -8)"
                    fill="#c43f57"
                  />
                </svg>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="table-scroll">
          <Table hover>
            <tbody>
              {!loading ? (
                <Fragment>
                  {clientNameList && clientNameList.length > 0 ? (
                    clientNameList.map((record, index) => {
                      return (
                        <tr key={record.client_id} onClick={() => setClientId(record.client_id)}>
                          <th style={{ width: "35px" }}>{index + 1}</th>
                          <td>{record.client_name}</td>
                          <td style={{ width: "35px" }}>
                            <Input
                              className="modelradio popup_radio"
                              type="radio"
                              name="clientId"
                              checked={clientId == record.client_id? true : false}
                              value={clientId}
                              onChange={onRadioChange}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <CustomEmptyTableRecord columnsCount={2} />
                  )}
                </Fragment>
              ) : (
                <CustomTableLoader columnsCount={2} />
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={selectClientToggle} outline color="primary">
          Close
        </Button>
        <Button
          color="primary"
          onClick={portfolio_id ? assignToClient : onClickSubmit}
        >
          {portfolio_id ? "Assign" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
