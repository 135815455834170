import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import CustomLoader from "../common/CustomLoader";

export default function ViewAsset(props) {
  const { modal, toggle, selectAsset } = props;

  const [loading] = useState(false);

  const {
    asset_fundname,
    asset_manager,
    asset_class,
    asset_strategy,
    asset_administrator,
    asset_auditor,
    asset_createdon,
    asset_currency,
    asset_custodian,
    asset_fund_size,
    asset_geo_focus,
    asset_inceptiondate,
    asset_lockupperiod,
    asset_management_fee,
    asset_minimum_investment,
    asset_noticeperiod,
    asset_performance_fee,
    asset_expected_returns,
    asset_redemptionperiod,
    asset_status,
  } = selectAsset;

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className=""
        // style={{ maxWidth: "500px" }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Asset Details</ModalHeader>
        <ModalBody className="details">
          {loading ? (
            <CustomLoader />
          ) : (
            <Row>
              <Col className="pl-5 pr-5">
                <div className="kt-widget__content">
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Fund Name:</span>
                    <span href="#" className="kt-widget__data">
                      {asset_fundname || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Manager: </span>
                    <span href="#" className="kt-widget__data">
                      {asset_manager || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Asset Class:</span>
                    <span className="kt-widget__data">
                      {asset_class || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Strategy:</span>
                    <span href="#" className="kt-widget__data">
                      {asset_strategy || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">
                      Geographical Focus:
                    </span>
                    <span href="#" className="kt-widget__data">
                      {" "}
                      {asset_geo_focus || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Fund Size:</span>
                    <span className="kt-widget__data">
                      {asset_fund_size || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Inception Date:</span>
                    <span className="kt-widget__data">
                      {asset_inceptiondate || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Currency:</span>
                    <span className="kt-widget__data">
                      {asset_currency || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Status:</span>
                    <span className="kt-widget__data">
                      {asset_status || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Auditor:</span>
                    <span className="kt-widget__data">
                      {asset_auditor || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Custodian:</span>
                    <span className="kt-widget__data">
                      {asset_custodian || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Administrator:</span>
                    <span className="kt-widget__data">
                      {asset_administrator || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Notice Period:</span>
                    <span className="kt-widget__data">
                      {asset_noticeperiod || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">
                      Lockup Period(Months):
                    </span>
                    <span className="kt-widget__data">
                      {asset_lockupperiod || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Management Fee:</span>
                    <span className="kt-widget__data">
                      {asset_management_fee || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">
                      Minimum Investment:
                    </span>
                    <span className="kt-widget__data">
                      {asset_minimum_investment || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Performance Fee:</span>
                    <span className="kt-widget__data">
                      {asset_performance_fee || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Expected Return:</span>
                    <span className="kt-widget__data">
                      {asset_expected_returns || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">
                      Redemption Notice Period(Months):
                    </span>
                    <span className="kt-widget__data">
                      {asset_redemptionperiod || "N/A"}
                    </span>
                  </div>
                  <div className="kt-widget__info mb-2">
                    <span className="kt-widget__label">Last Refreshed On:</span>
                    <span className="kt-widget__data">
                      {asset_createdon || "N/A"}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
