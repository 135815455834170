import React, { Fragment, useState } from "react";
import {
  Input,
  FormFeedback,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import Slider from "@material-ui/core/Slider";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from "recharts";

import { BiArrowBack } from "react-icons/bi";
import { UncontrolledTooltip } from "reactstrap";
import { useEffect } from "react";
import API from "../redux/API";
import { GET_CASH_FLOW } from "../common/urls";
import { authHeader } from "../common/Mainfunctions";


export default function OptimisationInputs(props) {
  const {
    formValues = {},
    errors,
    touched,
    handleChange,
    Flag,
    constraintsFlag,
    setFieldValue,
  } = props;

  const {
    portfolio_id = "",
    portfolio_expect_annualreturn = 0,
    portfolio_expect_maxdrawdown = 10,
    // portfolio_cashflow_yield = cash_flow_threshold,
    portfolio_liquidity = "",
    cash_flow_threshold,
    portfolio_equities_min,
    portfolio_equities_max,
    portfolio_equities_directholding_min,
    portfolio_equities_directholding_max,
    portfolio_fixedincome_min,
    portfolio_fixedincome_max,
    portfolio_fixedincome_directholding_min,
    portfolio_fixedincome_directholding_max,
  } = formValues;

  const [activeIndex, setActiveIndex] = useState(0);
  const [cashflow, setCashflow] = useState([]);
  const [exeedCashFlow, setExeedCashFlow] = useState(0);
  const [eqpercentage, seteqpercentage] = useState(100);

  function valuetext(value) {
    return value;
  }

  const marks1 = [
    {
      value: 3,
      label: "3%",
    },

    {
      value: 12,
      label: "12%",
    },
  ];

  const marks2 = [
    {
      value: 3,
      label: "3%",
    },

    {
      value: 50,
      label: "50%",
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const { payload: payloadData = {} } = payload[0];
      const { ar = 3, md = 3 } = payloadData;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Annual Return : ${ar}%`}</p>
          <p className="label">{`Maximum Drawdown : ${md}%`}</p>
        </div>
      );
    }

    return null;
  };

  const correlation = [
    { ar: 3, md: 3 },
    { ar: 3.5, md: 4 },
    { ar: 4, md: 5 },
    { ar: 4.5, md: 7 },
    { ar: 5, md: 8 },
    { ar: 5.5, md: 9 },
    { ar: 6, md: 11 },
    { ar: 6.5, md: 13 },
    { ar: 7, md: 14 },
    { ar: 7.5, md: 17 },
    { ar: 8, md: 20 },
    { ar: 8.5, md: 23 },
    { ar: 9, md: 26 },
    { ar: 9.5, md: 29 },
    { ar: 10, md: 32 },
    { ar: 10.5, md: 36 },
    { ar: 11, md: 40 },
    { ar: 11.5, md: 44 },
    { ar: 12, md: 48 },
  ];
  const imposeMinMax = (el) => {
    if (el.target.value != "") {
      if (el.target.value < el.target.min) {
        el.target.value = el.target.min;
      }
      if (parseFloat(el.target.value) > parseFloat(el.target.max)) {
        setExeedCashFlow(1);
        el.target.value = parseFloat(el.target.max).toFixed(3);
      } else {
        setExeedCashFlow(0);
      }
    }
  };

  useEffect(() => {
    var ranges = [
      "0-0",
      "1-6",
      "7-11",
      "12-17",
      "18-22",
      "23-28",
      "29-33",
      "34-39",
      "40-44",
      "45-50",
      "51-56",
      "57-61",
      "62-67",
      "68-72",
      "73-78",
      "79-83",
      "84-89",
      "90-94",
      "95-100",
    ];
    ranges.map(function (range) {
      var values = range.split("-");
      if (eqpercentage < values[0] && values[1] <= eqpercentage) {
        //
      }
    });
  }, [eqpercentage]);

  useEffect(() => {
    let equity =
      (portfolio_equities_max / 100) *
      (portfolio_equities_directholding_max / 100);

    let fixed =
      (portfolio_fixedincome_max / 100) *
      (portfolio_fixedincome_directholding_max / 100);

    let finalcal = (fixed + equity) * 100;
    finalcal = finalcal > 100 ? 100 : finalcal;
    var ranges = [
      "0-0",
      "1-6",
      "7-11",
      "12-17",
      "18-22",
      "23-28",
      "29-33",
      "34-39",
      "40-44",
      "45-50",
      "51-56",
      "57-61",
      "62-67",
      "68-72",
      "73-78",
      "79-83",
      "84-89",
      "90-94",
      "95-100",
    ];
    ranges.map(function (range) {
      //console.log('Range Check---',finalcal)
      var values = range.split("-");

      if (finalcal > values[0] && finalcal <= values[1]) {
        //console.log('Range Check--- Confirm')
        //console.log(values[1])
        seteqpercentage(values[1]);

        setFieldValue(
          "portfolio_expect_maxdrawdown",
          items[portfolio_expect_annualreturn][values[1]]
        );
      }
    });
    
  }, [
    portfolio_equities_max,
    portfolio_equities_directholding_max,
    portfolio_fixedincome_max,
    portfolio_fixedincome_directholding_max,
    portfolio_equities_min,
    portfolio_equities_directholding_min,
    portfolio_fixedincome_min,
    portfolio_fixedincome_directholding_min,
  ]);

  const handleExpectedAnnualReturnSliderChange = (event, newValue) => {
    event.target.name = "portfolio_expect_annualreturn";
    event.target.value = newValue;
    handleChange(event);
    event.target.name = "portfolio_expect_maxdrawdown";
    let dragitem = items[newValue][eqpercentage];

    let currentCorl = correlation.find(
      (corl) => corl.ar === parseFloat(newValue)
    );
    let { md } = currentCorl;
    event.target.value = dragitem;
    handleChange(event);
    let index = correlation.findIndex(
      (corl) => corl.ar === parseFloat(newValue)
    );
    setActiveIndex(index);
  };

  const handleExpectedMaxDrawdownSliderChange = (event, newValue) => {
    event.target.name = "portfolio_expect_maxdrawdown";
    event.target.value = newValue;
    handleChange(event);
    let dragitem = items_[eqpercentage][newValue];

    event.target.name = "portfolio_expect_annualreturn";
    let currentCorl = correlation.find(
      (corl) =>
        corl.md === parseFloat(newValue) || corl.md > parseFloat(newValue)
    );
    console.error(currentCorl);
    let { ar = 0 } = currentCorl || {};
    if (ar != 0) {
      event.target.value = dragitem;
      handleChange(event);
    }
    let index = correlation.findIndex((corl) => corl.ar === ar);
    setActiveIndex(index);
  };

  // const handleExpectedAnnualReturnSliderChange = (event, newValue) => {
  //   event.target.name = "portfolio_expect_annualreturn";
  //   event.target.value = newValue;
  //   handleChange(event);
  //   event.target.name = "portfolio_expect_maxdrawdown";
  //   let currentCorl = correlation.find(
  //     (corl) => corl.ar === parseFloat(newValue)
  //   );
  //   let { md } = currentCorl;
  //   event.target.value = md;
  //   handleChange(event);
  //   let index = correlation.findIndex(
  //     (corl) => corl.ar === parseFloat(newValue)
  //   );
  //   setActiveIndex(index);
  // };

  // const handleExpectedMaxDrawdownSliderChange = (event, newValue) => {
  //   event.target.name = "portfolio_expect_maxdrawdown";
  //   event.target.value = newValue;
  //   handleChange(event);
  //   event.target.name = "portfolio_expect_annualreturn";
  //   let currentCorl = correlation.find(
  //     (corl) =>
  //       corl.md === parseFloat(newValue) || corl.md > parseFloat(newValue)
  //   );
  //   console.error(currentCorl);
  //   let { ar = 0 } = currentCorl || {};
  //   if (ar != 0) {
  //     event.target.value = ar;
  //     handleChange(event);
  //   }
  //   let index = correlation.findIndex((corl) => corl.ar === ar);
  //   setActiveIndex(index);
  // };

  useEffect(() => {
    console.log(portfolio_expect_annualreturn, "uhbhuuyiuiuhiuhiuiiuiuiuiuuiy");
  });
  const maxcashflow = async () => {
    const config = {
      method: "GET",
      url: GET_CASH_FLOW,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setCashflow(response.data?.cash_flow_threshold);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    maxcashflow();
  }, []);

  useEffect(() => {
    if (portfolio_id && portfolio_expect_annualreturn) {
      let index = correlation.findIndex(
        (corl) => corl.ar === portfolio_expect_annualreturn
      );
      setActiveIndex(index);
    }
  }, [portfolio_id]);
  const items_ = {
    100: {
      3: 3,
      4: 3,
      5: 3,
      6: 3,
      7: 3,
      8: 3,
      9: 3,
      10: 3,
      11: 3.5,
      12: 3.5,
      13: 4,
      14: 4,
      15: 4.5,
      16: 4.5,
      17: 4.5,
      18: 5,
      19: 5,
      20: 5.5,
      21: 5.5,
      22: 6,
      23: 6,
      24: 6.5,
      25: 6.5,
      26: 6.5,
      27: 7,
      28: 7,
      29: 7.5,
      30: 7.5,
      31: 8,
      32: 8,
      33: 8.5,
      34: 8.5,
      35: 9,
      36: 9,
      37: 9,
      38: 9.5,
      39: 9.5,
      40: 10,
      41: 10,
      42: 10.5,
      43: 10.5,
      44: 11,
      45: 11,
      46: 11,
      47: 11.5,
      48: 11.5,
      49: 12,
      50: 12,
    },
  };
  const items = {
    3: {
      0: 5,
      6: 5,
      11: 6,
      17: 6,
      22: 6,
      28: 6,
      33: 7,
      39: 7,
      44: 7,
      50: 8,
      56: 8,
      61: 8,
      67: 8,
      72: 9,
      78: 9,
      83: 9,
      89: 9,
      94: 10,
      100: 10,
    },
    3.5: {
      0: 6,
      6: 6,
      11: 6,
      17: 7,
      22: 7,
      28: 7,
      33: 8,
      39: 8,
      44: 9,
      50: 9,
      56: 9,
      61: 10,
      67: 10,
      72: 10,
      78: 11,
      83: 11,
      89: 11,
      94: 12,
      100: 12,
    },
    4: {
      0: 6,
      6: 7,
      11: 7,
      17: 8,
      22: 8,
      28: 8,
      33: 9,
      39: 9,
      44: 10,
      50: 10,
      56: 11,
      61: 11,
      67: 12,
      72: 12,
      78: 13,
      83: 13,
      89: 14,
      94: 14,
      100: 14,
    },
    4.5: {
      0: 7,
      6: 7,
      11: 8,
      17: 8,
      22: 9,
      28: 9,
      33: 10,
      39: 11,
      44: 11,
      50: 12,
      56: 12,
      61: 13,
      67: 13,
      72: 14,
      78: 14,
      83: 15,
      89: 16,
      94: 16,
      100: 17,
    },
    5: {
      0: 7,
      6: 8,
      11: 9,
      17: 9,
      22: 10,
      28: 10,
      33: 11,
      39: 12,
      44: 12,
      50: 13,
      56: 14,
      61: 14,
      67: 15,
      72: 16,
      78: 16,
      83: 17,
      89: 18,
      94: 18,
      100: 19,
    },
    5.5: {
      0: 8,
      6: 9,
      11: 9,
      17: 10,
      22: 11,
      28: 11,
      33: 12,
      39: 13,
      44: 14,
      50: 14,
      56: 15,
      61: 16,
      67: 17,
      72: 17,
      78: 18,
      83: 19,
      89: 20,
      94: 20,
      100: 21,
    },
    6: {
      0: 8,
      6: 9,
      11: 10,
      17: 11,
      22: 12,
      28: 13,
      33: 13,
      39: 14,
      44: 15,
      50: 16,
      56: 17,
      61: 18,
      67: 18,
      72: 19,
      78: 20,
      83: 21,
      89: 22,
      94: 23,
      100: 23,
    },
    6.5: {
      0: 9,
      6: 10,
      11: 11,
      17: 12,
      22: 13,
      28: 14,
      33: 14,
      39: 15,
      44: 16,
      50: 17,
      56: 18,
      61: 19,
      67: 20,
      72: 21,
      78: 22,
      83: 23,
      89: 24,
      94: 25,
      100: 26,
    },
    7: {
      0: 9,
      6: 10,
      11: 11,
      17: 13,
      22: 14,
      28: 15,
      33: 16,
      39: 17,
      44: 18,
      50: 19,
      56: 20,
      61: 21,
      67: 22,
      72: 23,
      78: 24,
      83: 25,
      89: 26,
      94: 27,
      100: 28,
    },
    7.5: {
      0: 10,
      6: 11,
      11: 12,
      17: 13,
      22: 14,
      28: 16,
      33: 17,
      39: 18,
      44: 19,
      50: 20,
      56: 21,
      61: 22,
      67: 23,
      72: 24,
      78: 26,
      83: 27,
      89: 28,
      94: 29,
      100: 30,
    },
    8: {
      0: 11,
      6: 12,
      11: 13,
      17: 15,
      22: 16,
      28: 17,
      33: 18,
      39: 19,
      44: 20,
      50: 22,
      56: 23,
      61: 24,
      67: 25,
      72: 26,
      78: 28,
      83: 29,
      89: 30,
      94: 31,
      100: 32,
    },
    8.5: {
      0: 12,
      6: 13,
      11: 15,
      17: 16,
      22: 17,
      28: 18,
      33: 20,
      39: 21,
      44: 22,
      50: 23,
      56: 25,
      61: 26,
      67: 27,
      72: 28,
      78: 30,
      83: 31,
      89: 32,
      94: 33,
      100: 34,
    },
    9: {
      0: 13,
      6: 15,
      11: 16,
      17: 17,
      22: 19,
      28: 20,
      33: 21,
      39: 22,
      44: 24,
      50: 25,
      56: 26,
      61: 28,
      67: 29,
      72: 30,
      78: 31,
      83: 33,
      89: 34,
      94: 35,
      100: 37,
    },
    9.5: {
      0: 14,
      6: 16,
      11: 17,
      17: 19,
      22: 20,
      28: 21,
      33: 23,
      39: 24,
      44: 25,
      50: 27,
      56: 28,
      61: 29,
      67: 31,
      72: 32,
      78: 33,
      83: 35,
      89: 36,
      94: 38,
      100: 39,
    },
    10: {
      0: 16,
      6: 17,
      11: 18,
      17: 20,
      22: 21,
      28: 23,
      33: 24,
      39: 25,
      44: 27,
      50: 28,
      56: 30,
      61: 31,
      67: 33,
      72: 34,
      78: 35,
      83: 37,
      89: 38,
      94: 40,
      100: 41,
    },
    10.5: {
      0: 17,
      6: 18,
      11: 20,
      17: 21,
      22: 23,
      28: 24,
      33: 26,
      39: 27,
      44: 29,
      50: 30,
      56: 31,
      61: 33,
      67: 34,
      72: 36,
      78: 37,
      83: 39,
      89: 40,
      94: 42,
      100: 43,
    },
    11: {
      0: 18,
      6: 19,
      11: 21,
      17: 22,
      22: 24,
      28: 25,
      33: 27,
      39: 29,
      44: 30,
      50: 32,
      56: 33,
      61: 35,
      67: 36,
      72: 38,
      78: 39,
      83: 41,
      89: 42,
      94: 44,
      100: 46,
    },
    11.5: {
      0: 19,
      6: 20,
      11: 22,
      17: 24,
      22: 25,
      28: 27,
      33: 29,
      39: 30,
      44: 32,
      50: 33,
      56: 35,
      61: 37,
      67: 38,
      72: 40,
      78: 41,
      83: 43,
      89: 45,
      94: 46,
      100: 48,
    },
    12: {
      0: 20,
      6: 22,
      11: 23,
      17: 25,
      22: 27,
      28: 28,
      33: 30,
      39: 32,
      44: 33,
      50: 35,
      56: 37,
      61: 38,
      67: 40,
      72: 42,
      78: 43,
      83: 45,
      89: 47,
      94: 48,
      100: 50,
    },
  };

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label lg={5}>
              Expected annual return{" "}
              <span className="informicon" id="assid11">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid11">
                Average yearly returns wanted; higher returns come with higher
                risks. Investment horizon should at least be 3 years or more.
              </UncontrolledTooltip>
            </Label>
            <Col lg={7}>
              <Slider
                data-name="portfolio_expect_annualreturn"
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                valueLabelDisplay="on"
                marks={marks1}
                step={0.5}
                min={3}
                max={12}
                onChange={handleExpectedAnnualReturnSliderChange}
                value={portfolio_expect_annualreturn}
                disabled={Flag ? true : constraintsFlag ? false : ""}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg={5}>
              {" "}
              Expected Max Drawdown{" "}
              <span className="informicon" id="assid12">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid12">
                A measurement of risk, the number shown here is the lowest
                possible Max DD based on a unconstrained diversified portfolio,
                actual expected DD could be higher because of the constrains
                set.
              </UncontrolledTooltip>
            </Label>
            <Col lg={7}>
              <Slider
                data-name="portfolio_expect_maxdrawdown"
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                valueLabelDisplay="on"
                marks={marks2}
                min={3}
                max={50}
                onChange={handleExpectedMaxDrawdownSliderChange}
                value={portfolio_expect_maxdrawdown}
                disabled={Flag ? true : constraintsFlag ? true : "true"}
              />
            </Col>
          </FormGroup>

          {/* <FormGroup row>
            {/* <Label lg={5} className="required">
              Cash flow Yield
              <span className="informicon" id="assid13">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid13">
                Max possible yearly cash income from coupons, dividends and/or
                distributions. Actual yield will be affected by constrains.
              </UncontrolledTooltip>
            </Label> 
            <Col lg={7}>
               <InputGroup>
               <Input
                  type="number"
                  max={cashflow}
                  min={0}
                  name="portfolio_cashflow_yield"
                  id="portfolio_cashflow_yield"
                  placeholder="0"
                  onKeyUp={imposeMinMax}
                  invalid={
                    errors.portfolio_cashflow_yield &&
                    touched.portfolio_cashflow_yield
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  value={portfolio_cashflow_yield}
                  disabled={Flag ? true : constraintsFlag ? false : ""}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                /> 
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon> 
              </InputGroup>
              {exeedCashFlow > 0 && (
                <p>
                  <small>Max Cash flow Yield : {cashflow.toFixed(3)}%</small>
                </p>
              )}
              <FormFeedback
                style={{
                  display:
                    errors.portfolio_cashflow_yield &&
                    touched.portfolio_cashflow_yield
                      ? "block"
                      : "none",
                }}
              >
                {errors.portfolio_cashflow_yield &&
                touched.portfolio_cashflow_yield
                  ? errors.portfolio_cashflow_yield
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup> */}

          <FormGroup row>
            <Label lg={5} className="required">
              Liquidity (Monthly){" "}
              <span className="informicon" id="assid14">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid14">
                Minimum percentage of the portfolio that could be liquidated in
                a month or less.
              </UncontrolledTooltip>
            </Label>
            <Col lg={7}>
              <InputGroup>
                <Input
                  type="text"
                  name="portfolio_liquidity"
                  id="portfolio_liquidity"
                  invalid={
                    errors.portfolio_liquidity && touched.portfolio_liquidity
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  value={portfolio_liquidity}
                  placeholder="0"
                  disabled={Flag ? true : constraintsFlag ? false : ""}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormFeedback
                style={{
                  display:
                    errors.portfolio_liquidity && touched.portfolio_liquidity
                      ? "block"
                      : "none",
                }}
              >
                {errors.portfolio_liquidity && touched.portfolio_liquidity
                  ? errors.portfolio_liquidity
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup>
        </Col>
        <Col md={6}>
          <Row>
            <BarChart width={400} height={370} data={correlation}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="ar "
                name="Annual Return  "
                label={{
                  value: "Annual Return ",
                  angle: 0,
                  position: "insideBottom",
                }}
              />
              <YAxis
                dataKey="md"
                name="Maximum Drawdown "
                label={{
                  value: "Maximum Drawdown ",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="md" name="Maximum Drawdown ">
                {correlation.map((entry, index) => (
                  <Cell
                    cursor="pointer "
                    fill={index === activeIndex ? "#c03e55" : "#f7e7ea"}
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
            </BarChart>
          </Row>
        </Col>
      </Row>
      
    </Fragment>
  );
}
