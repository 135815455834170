import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
  Collapse,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap";
import plus from "../../../images/plus.svg";
import { FaInfoCircle } from "react-icons/fa";
import minus from "../../../images/minus.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function AssetLevel(props) {
  const [selectedOption, setSelectedOption] = useState([]);
  const [assetLevelLoaded, setAssetLevelLoaded] = useState(false);

  const [assetOptions, setAssetOption] = useState([
    {
      label: "Cash",
      value: 1,
      name: "portfolio_cash",
      tooltip: "Including cash and cash equivalent, e.g. money market ETFs",
      disabled: true,
    },
    {
      label: "Equities",
      value: 2,
      name: "portfolio_equities",
      tooltip: "Including single name stocks and equity ETFs",
    },
    {
      label: "Fixed Income",
      value: 3,
      name: "portfolio_fixedincome",
      tooltip: "Including single name bonds and bond ETFs",
    },
    {
      label: "Alternatives",
      value: 4,
      name: "portfolio_alternative",
      tooltip:
        "All other non-conventional assets, e.g. gold, commodities, unit trusts, hedge funds etc.",
    },
  ]);
  //let portfolio_cash_min, portfolio_cash_max, portfolio_equities_min, portfolio_equities_max, portfolio_fixedincome_min, portfolio_fixedincome_max, portfolio_alternative_min, portfolio_alternative_max, portfolio_equities_directholding_min, portfolio_equities_directholding_max, portfolio_fixedincome_directholding_min, portfolio_fixedincome_directholding_max, portfolio_alternative_directholding_min, portfolio_alternative_directholding_max;
  const {
    formValues = {},
    handleChange,
    touched,
    errors,
    handleBlur,
    toggle,
    isOpen,
    Flag,
    constraintsFlag,
    setFieldValue,
    tabIndex
  } = props;

  const {
    portfolio_id,
    portfolio_cash_min,
    portfolio_cash_max,
    portfolio_equities_min,
    portfolio_equities_max,
    portfolio_fixedincome_min,
    portfolio_fixedincome_max,
    portfolio_alternative_min,
    portfolio_alternative_max,
    portfolio_equities_directholding_min,
    portfolio_equities_directholding_max,
    portfolio_fixedincome_directholding_min,
    portfolio_fixedincome_directholding_max,
    portfolio_alternative_directholding_min,
    portfolio_alternative_directholding_max,
    portfolio_cash_status = false,
    portfolio_alternative_status = false,
    portfolio_equities_status = false,
    portfolio_fixedincome_status = false,
    portfolio_expect_maxdrawdown
  } = formValues;

  const handleMultiSelectChange = (selectedOptions, e) => {
    let { action, option = {}, target = { name: "", value: "" } } = e;
    let { name } = option;
    setSelectedOption(selectedOptions);

    e.target = { name: "", value: "" };
    if (action == "select-option") {
      e.target.name = `${name}_status`;
      e.target.value = true;
      setFieldValue(`${name}_min`, 0);
      setFieldValue(`${name}_max`, 100);
      if (name != "cash") {
        setFieldValue(`${name}_directholding_min`, 0);
        setFieldValue(`${name}_directholding_max`, 100);
      }
      handleChange(e);
    } else {
      setFieldValue(`${name}_status`, false);
      setFieldValue(`${name}_min`, 0);
      setFieldValue(`${name}_max`, 0);
      if (name != "cash") {
        setFieldValue(`${name}_directholding_min`, 0);
        setFieldValue(`${name}_directholding_max`, 0);
      }
      target.name = `${name}_status`;
      target.value = false;
      handleChange(e);
    }
  };
  const handleChangeFun = (e) => {
    const id = e.currentTarget.dataset.asset_id;

    let remove1 = selectedOption.filter(
      (record) => record.value !== parseInt(id)
    );

    let removed = selectedOption.find((record) => record.value == parseInt(id));

    if (removed) {
      setFieldValue(`${removed.name}_status`, false);
      setFieldValue(`${removed.name}_min`, 0);
      setFieldValue(`${removed.name}_max`, 0);
    }

    setSelectedOption(remove1);
    handleChange(e);
  };
  useEffect(() => {
    let selOptions = [];
    if (portfolio_cash_status) {
      console.log(portfolio_cash_status, "222222222222222222222222");
      let filteredOption = assetOptions.find((asset) => asset.value === 1);
      selOptions.push(filteredOption);
    }
    if (portfolio_equities_status) {
      let filteredOption = assetOptions.find((asset) => asset.value === 2);
      selOptions.push(filteredOption);
    }
    if (portfolio_fixedincome_status) {
      let filteredOption = assetOptions.find((asset) => asset.value === 3);
      selOptions.push(filteredOption);
    }
    if (portfolio_alternative_status) {
      let filteredOption = assetOptions.find((asset) => asset.value === 4);
      selOptions.push(filteredOption);
    }
    setSelectedOption(selOptions);

    console.log(selectedOption, "selectedOption");
  }, [portfolio_id]);
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const useHasChanged= (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }
  const hasVal1Changed = useHasChanged(portfolio_expect_maxdrawdown)
  
  useEffect( () => {
    if(tabIndex == 2 && hasVal1Changed ){
      toast.dismiss();
      toast("Max Expected Drawdown has changed based on your constraints for direct holdings");
    }
  })

    useEffect( () => {
      setAssetLevelLoaded(true)
    },[])
  return (
    <div className="my-collapse">
      <div className="collapse-title">
        <h4 onClick={toggle}>
          <span className="collapse-plus">
            <img src={isOpen ? minus : plus} />
          </span>
          Asset Level
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <br />
        <div>
          <ReactMultiSelectCheckboxes
            options={assetOptions}
            width="100%"
            onChange={handleMultiSelectChange}
            value={selectedOption}
            isDisabled={Flag ? true : constraintsFlag ? false : ""}
          />
        </div>
        <br />
        {selectedOption &&
          selectedOption.map((element, index) => {
            return (
              <Fragment key={`portfolio_${index}`}>
                <FormGroup row className="item-center">
                  <Col lg={4}>
                    <CustomInput
                      key={Math.random()}
                      type="checkbox"
                      name={`${element.name}_status`}
                      id={`${element.name}_status`}
                      data-asset_id={element.value}
                      onChange={handleChangeFun}
                      value={eval(`${element.name}_status`)}
                      defaultChecked={eval(`${element.name}_status`)}
                      label={element.label}
                      disabled={Flag ? true : constraintsFlag ? false : ""}
                    ></CustomInput>
                    <span className="informicon" id="assid55">
                      <FaInfoCircle />
                    </span>
                    <UncontrolledTooltip placement="right" target="assid55">
                      {element.tooltip}
                    </UncontrolledTooltip>
                  </Col>
                  <Col lg={8}>
                    <Row>
                      <Col lg={6}>
                        <InputGroup>
                          <Input
                            type="text"
                            name={`${element.name}_min`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={eval(`${element.name}_min`)}
                            placeholder="0"
                            disabled={
                              constraintsFlag || !portfolio_id ? false : true
                            }
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>Min (%)</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <FormFeedback
                          style={{
                            display:
                              errors[`${element.name}_min`] &&
                              touched[`${element.name}_min`]
                                ? "block"
                                : "none",
                          }}
                        >
                          {errors[`${element.name}_min`] &&
                          touched[`${element.name}_min`]
                            ? errors[`${element.name}_min`]
                            : ""}
                        </FormFeedback>
                      </Col>
                      <Col lg={6}>
                        <InputGroup>
                          <Input
                            type="text"
                            name={`${element.name}_max`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={eval(`${element.name}_max`)}
                            placeholder="100"
                            disabled={
                              constraintsFlag || !portfolio_id ? false : true
                            }
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>Max (%)</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        <FormFeedback
                          style={{
                            display:
                              errors[`${element.name}_max`] &&
                              touched[`${element.name}_max`]
                                ? "block"
                                : "none",
                          }}
                        >
                          {errors[`${element.name}_max`] &&
                          touched[`${element.name}_max`]
                            ? errors[`${element.name}_max`]
                            : ""}
                        </FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
                {!(element.label === "Cash") ? (
                  <div className="direct-holdings">
                    <FormGroup row>
                      <Label className="text-end" lg={4}>
                        {element.label} Direct holdings
                      </Label>
                      <Col lg={8}>
                        <Row>
                          <Col lg={6}>
                            <InputGroup>
                              <Input
                                type="text"
                                name={`${element.name}_directholding_min`}
                                onChange={handleChange}
                                value={eval(
                                  `${element.name}_directholding_min`
                                )}
                                placeholder="0"
                                disabled={
                                  constraintsFlag || !portfolio_id
                                    ? false
                                    : true
                                }
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>Min (%)</InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col lg={6}>
                            <InputGroup>
                              <Input
                                type="text"
                                name={`${element.name}_directholding_max`}
                                onChange={handleChange}
                                value={eval(
                                  `${element.name}_directholding_max`
                                )}
                                placeholder="100"
                                disabled={
                                  constraintsFlag || !portfolio_id
                                    ? false
                                    : true
                                }
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>Max (%)</InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
              </Fragment>
            );
          })}
      </Collapse>
      <ToastContainer autoClose={2000} position="top-center" style={{ width: "600px", maxWidth: "100%" }} />
    </div>
  );
}
