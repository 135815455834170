import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import AddClient from "./components/client/AddClient";
import PortfolioInputs from "./components/portfolio/PortfolioInputs";
import Client from "./components/client/index";
import PageNotFound from "./components/PageNotFount";
import Signin from "./components/Signin";

import Summary from "./components/Summary";
import Settings from "./components/profile/Settings";
import AssetsManagement from "./components/Asset/AssetsManagement";
import OnBoarding from "./components/Asset/OnBoarding";
import User from "./components/user/index";
import AddUser from "./components/user/AddUser";
import {
  SIGNIN_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
  INITIAL_ROUTE,
  ADD_CLIENT_ROUTE,
  SUMMARY_ROUTE,
  SETTINGS_ROUTE,
  ASSETS_MANAGEMENT_ROUTE,
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  PORTFOLIO_INPUTS_EDIT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  ADD_NEW_USER,
  ON_BOARDING,
  LIST_PORTFOLIO,
  CREATE_LIST,
  ALERT_TABS,
  PDF_DOWNLOAD,
  CLIENT_LIST,
  USER_LIST,
} from "./components/common/RoutePaths";
import { PublicRoute, PrivateRoute } from "./components/common/Mainfunctions";
import { Provider } from "react-redux";
import ConfigureStore from "./components/redux/ConfigureStore";
import Listofport from "./components/client/Listofport";
import Alerttabs from "./components/client/Alerttabs";
import CreateIndex from "./components/Asset/CreateIndex";
const store = ConfigureStore();

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path={SIGNIN_ROUTE} component={Signin} />
          <PrivateRoute exact path={INITIAL_ROUTE} component={Client} />
          <PrivateRoute exact path={LIST_PORTFOLIO} component={Listofport} />
          <PrivateRoute exact path={ALERT_TABS} component={Alerttabs} />
          <PrivateRoute exact path={ADD_CLIENT_ROUTE} component={AddClient} />
          <PrivateRoute exact path={CREATE_LIST} component={CreateIndex} />
          <PrivateRoute exact path={CLIENT_LIST} component={Client} />
          <PrivateRoute
            path={PORTFOLIO_INPUTS_EDIT_ROUTE}
            component={PortfolioInputs}
          />
          <PrivateRoute
            path={PORTFOLIO_INPUTS_CREATE_ROUTE}
            component={PortfolioInputs}
          />

          <PrivateRoute exact path={SUMMARY_ROUTE} component={Summary} />
          <Route exact path={ON_BOARDING} component={OnBoarding} />
          <Route exact path={SETTINGS_ROUTE} component={Settings} />
          <Route
            exact
            path={ASSETS_MANAGEMENT_ROUTE}
            component={AssetsManagement}
          />
          <Route
            exact
            path={`${USER_MANAGEMENT_ROUTE}/:page?`}
            component={User}
          />
          <Route exact path={ADD_NEW_USER} component={AddUser} />
          <Route exact path={PAGE_NOT_FOUND_ROUTE} component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
