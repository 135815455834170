import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Col,
  CustomInput,
  Collapse,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const sectorOptions = [
  {
    name: "portfolio_esc_realestate",
    label: "Exposure to REITs / Real Estate",
    value: 1,
  },
  {
    name: "portfolio_esc_it",
    label: "Exposure to Information Technology",
    value: 2,
  },
  {
    name: "portfolio_esc_healthcare",
    label: "Exposure to Health Care",
    value: 3,
  },
  {
    name: "portfolio_esc_financials",
    label: "Exposure to Financials",
    value: 4,
  },
  {
    name: "portfolio_esc_discretionary",
    label: "Exposure to Consumer Discretionary",
    value: 5,
  },
  {
    name: "portfolio_esc_comservice",
    label: "Exposure to Communication Services",
    value: 6,
  },
  {
    name: "portfolio_esc_industrials",
    label: "Exposure to Industrials",
    value: 7,
  },
  {
    name: "portfolio_esc_staples",
    label: "Exposure to Consumer Staples",
    value: 8,
  },
  {
    name: "portfolio_esc_energy",
    label: "Exposure to Consumer Energy",
    value: 9,
  },
  {
    name: "portfolio_esc_utilities",
    label: "Exposure to Consumer Utilities",
    value: 10,
  },
  {
    name: "portfolio_esc_materials",
    label: "Exposure to Consumer Materials",
    value: 11,
  },
];

export default function EquitiesSectorConstraints(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const toggle = () => setIsOpen(!isOpen);
  const {
    formValues = {},
    handleChange,
    handleBlur,
    touched,
    errors,
    Flag,
    constraintsFlag,
  } = props;
  const {
    portfolio_id,
    portfolio_esc_viceindustries_status = true,
    portfolio_esc_viceindustries_min = "",
    portfolio_esc_viceindustries_max = "",
    portfolio_esc_realestate_status = true,
    portfolio_esc_realestate_min = "",
    portfolio_esc_realestate_max = "",
    portfolio_esc_it_status = true,
    portfolio_esc_it_min = "",
    portfolio_esc_it_max = "",
    portfolio_esc_healthcare_status = true,
    portfolio_esc_healthcare_min = "",
    portfolio_esc_healthcare_max = "",
    portfolio_esc_financials_status = true,
    portfolio_esc_financials_min = "",
    portfolio_esc_financials_max = "",
    portfolio_esc_discretionary_status = true,
    portfolio_esc_discretionary_min = "",
    portfolio_esc_discretionary_max = "",
    portfolio_esc_comservice_status = true,
    portfolio_esc_comservice_min = "",
    portfolio_esc_comservice_max = "",
    portfolio_esc_industrials_status = true,
    portfolio_esc_industrials_min = "",
    portfolio_esc_industrials_max = "",
    portfolio_esc_staples_status = true,
    portfolio_esc_staples_min = "",
    portfolio_esc_staples_max = "",
    portfolio_esc_energy_status = true,
    portfolio_esc_energy_min = "",
    portfolio_esc_energy_max = "",
    portfolio_esc_utilities_status = true,
    portfolio_esc_utilities_min = "",
    portfolio_esc_utilities_max = "",
    portfolio_esc_materials_status = true,
    portfolio_esc_materials_min = "",
    portfolio_esc_materials_max = "",
  } = formValues;

  const handleMultiSelectChange = (selectedOptions, e) => {
    let { action, option = {}, target = { name: "", value: "" } } = e;
    let { name } = option;
    setSelectedOption(selectedOptions);
    e.target = { name: "", value: "" };
    if (action == "select-option") {
      e.target.name = `${name}_status`;
      e.target.value = true;
      handleChange(e);
    } else {
      target.name = `${name}_status`;
      target.value = false;
      handleChange(e);
    }
  };
  useEffect(() => {
    let selOptions = [];
    if (portfolio_esc_realestate_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 1);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_it_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 2);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_healthcare_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 3);
      selOptions.push(filteredOption);
    }

    if (portfolio_esc_financials_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 4);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_discretionary_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 5);
      selOptions.push(filteredOption);
    }

    if (portfolio_esc_comservice_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 6);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_industrials_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 7);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_staples_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 8);
      selOptions.push(filteredOption);
    }

    if (portfolio_esc_energy_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 9);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_utilities_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 10);
      selOptions.push(filteredOption);
    }
    if (portfolio_esc_materials_status) {
      let filteredOption = sectorOptions.find((sector) => sector.value === 11);
      selOptions.push(filteredOption);
    }
    setSelectedOption(selOptions);
  }, [portfolio_id]);
  const handleChangeFun = (e) => {
    const equitiesId = e.currentTarget.dataset.equties_id;
    let remove1 = selectedOption.filter(
      (record) => record.value !== parseInt(equitiesId)
    );
    setSelectedOption(remove1);
    handleChange(e);
  };
  return (
    <div className="equities-constraints">
      <div className="collapse-title">
        <h4 onClick={toggle}>
          <span className="collapse-plus">
            <img src={isOpen ? minus : plus} />
          </span>
          Equities Sector Constraints
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <FormGroup row className="item-center mt-4">
          <Col lg={4}>
            <div className="item-center">
              {portfolio_esc_viceindustries_status}
              <CustomInput
                //key={Math.random()}
                type="checkbox"
                id="portfolio_esc_viceindustries_status"
                onChange={handleChange}
                name="portfolio_esc_viceindustries_status"
                //value={portfolio_esc_viceindustries_status}
                label="Exposure to Vice Industries 7"
                defaultChecked={
                  portfolio_esc_viceindustries_status == 1 ? true : false
                }
                disabled={Flag ? true : constraintsFlag ? false : ""}
              />
              <span className="informicon" id="assid67">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid67">
                Including alcohol, tobacco, the military industrial complex,
                illicit drugs and gambling.
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col lg={8}>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_esc_viceindustries_min"
                    onChange={handleChange}
                    value={portfolio_esc_viceindustries_min}
                    placeholder="0"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Min (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_esc_viceindustries_max"
                    onChange={handleChange}
                    value={portfolio_esc_viceindustries_max}
                    placeholder="100"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Max (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </FormGroup>
        <ReactMultiSelectCheckboxes
          name="sectorMultiselect"
          options={sectorOptions}
          width="100%"
          onChange={handleMultiSelectChange}
          value={selectedOption}
          isDisabled={Flag ? true : constraintsFlag ? false : ""}
        />
        <br />

        {selectedOption &&
          selectedOption.map((sector) => {
            let max_name = sector.name + "_max";
            let min_name = sector.name + "_min";
            return (
              <FormGroup row className="item-center">
                <Col lg={4}>
                  <CustomInput
                    type="checkbox"
                    name={`${sector.name}_status`}
                    id={`${sector.name}_status`}
                    data-equties_id={sector.value}
                    onChange={handleChangeFun}
                    value={eval(`${sector.name}_status`)}
                    checked={eval(`${sector.name}_status`)}
                    label={sector.label}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                  />
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="text"
                          name={`${sector.name}_min`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={eval(`${sector.name}_min`)}
                          placeholder="0"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Min (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[min_name] && touched[min_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[min_name] && touched[min_name]
                          ? errors[min_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="text"
                          name={`${sector.name}_max`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={eval(`${sector.name}_max`)}
                          placeholder="100"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Max (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[max_name] && touched[max_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[max_name] && touched[max_name]
                          ? errors[max_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            );
          })}
      </Collapse>
    </div>
  );
}
