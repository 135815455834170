import React, { Fragment, useEffect, useState } from "react";
import {
  Input,
  FormFeedback,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import * as Yup from "yup";
import { g10Currencies, authHeader, getRmID } from "../common/Mainfunctions";
import {
  COSTOFBORROW_LIST,
  ARM_LIST_URL,
  PERMISSION,
  RM_USER_URL,
  IC_LIST,
} from "../common/urls";
import API from "../redux/API";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import { UncontrolledTooltip } from "reactstrap";

export default function AssetInputs(props) {
  const {
    formValues = {},
    errors,
    touched,
    handleChange,
    Flag,
    values,
    constraintsFlag,
  } = props;
  const {
    portfolio_id = "",
    portfolio_name = "",
    portfolio_currency = "",
    portfolio_RMuser = "",
    portfolio_ICuser = "",
    portfolio_clientequity = "",
    portfolio_costofborrowing = "",
    portfolio_costofborrowing_benchmark = "",
    portfolio_core_percentage = "",
    portfolio_leverage = "",
    portfolio_shariahcomplaint = false,
    portfolio_coreunit = "$",
  } = formValues;
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const [RMlist, setRMList] = useState([]);
  const [IClist, setICList] = useState([]);

  const [RMemail, setRMemail] = useState("");
  const [permission, setpermission] = useState("");
  const [RMuser, setRMuser] = useState("");
  const [SelectUser, setSelectUser] = useState("");
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [rmuserList, setrmuserList] = useState([]);

  const validationSchema = Yup.object({
    portfolio_clientequity: Yup.string()
      .trim()
      .required(
        "portfolio is too small to have a diversified portfolio of at least 20 assets"
      )
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  });
  const [loading, setLoading] = useState(false);
  const [costofborrowList, setCostofborrowList] = useState([]);
  const getCostofborrowList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: COSTOFBORROW_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;
      setCostofborrowList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCostofborrowList();
  }, []);

  const numberWithComma = (event) => {
    handleChange(event);

    setValue(
      event.target.value
        .toString()
        // Keep only digits and decimal points:
        .replace(/[^\d.]/g, "")
        // Remove duplicated decimal point, if one exists:
        .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")
        // Keep only two digits past the decimal point:
        .replace(/\.(\d{2})\d+/, ".$1")
        // Add thousands separators:
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  };

  const [equityValue, setEquityValue] = useState();

  const numberWithComma1 = (event) => {
    setEquityValue(
      event.target.value
        .toString()
        // Keep only digits and decimal points:
        .replace(/[^\d.]/g, "")
        // Remove duplicated decimal point, if one exists:
        .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")
        // Keep only two digits past the decimal point:
        .replace(/\.(\d{2})\d+/, ".$1")
        // Add thousands separators:
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    handleChange(event);
  };
  let port;
  let portfolio_core_calculation =
    (portfolio_core_percentage.toString().replace(/,/g, "") / 100) *
    portfolio_clientequity.toString().replace(/,/g, "");
  if (portfolio_coreunit == "%") {
    port = portfolio_core_percentage;
  } else {
    port = portfolio_core_percentage
      .toString()

      .replace(/[^\d.]/g, "")

      .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

      .replace(/\.(\d{2})\d+/, ".$1")

      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  console.log("bench", portfolio_costofborrowing_benchmark);
  const handleChangeFun = (e) => {
    console.log("call", e.target.values, e.target.name);
  };
  const getClientList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: ARM_LIST_URL + getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setRMList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClientList();
  }, [getRmID]);

  const getICList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: IC_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setICList(data);
      console.log(data, "pppppppppppppppppppppppppppppp");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getICList();
  }, []);

  useEffect(() => {
    RMlist.map((event) => {
      setRMuser(event.role);
      setRMemail(event.email);
    });
  }, [RMlist]);

  const getRMClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RM_USER_URL + getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setrmuserList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRMClientList();
  }, [getRmID]);

  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);
  return (
    <Fragment>
      <div className="custom_s_width">
        <FormGroup row>
          <Label lg={4} className="required custom_s_width">
            Portfolio name
          </Label>
          <Col lg={8}>
            <Input
              type="text"
              name="portfolio_name"
              id="portfolio_name"
              placeholder="Enter portfolio name"
              invalid={
                errors.portfolio_name && touched.portfolio_name ? true : false
              }
              onChange={handleChange}
              value={portfolio_name}
              disabled={Flag ? true : constraintsFlag ? true : ""}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            <FormFeedback>
              {errors.portfolio_name && touched.portfolio_name
                ? errors.portfolio_name
                : ""}
            </FormFeedback>
          </Col>
        </FormGroup>
        <div className="form-control.is-invalid">
          <FormGroup row>
            <Label lg={4} className="required">
              Currency{" "}
              <span className="informicon" id="assid1">
                {" "}
                <FaInfoCircle />{" "}
              </span>
              <UncontrolledTooltip placement="right" target="assid1">
                Base currency of the portfolio; investments in other currencies
                will be converted to the base for the return calculation.
              </UncontrolledTooltip>
            </Label>
            <Col lg={8} className="custom_s_width">
              <div style={{ position: "relative" }}>
                <div className="dropdownbox hoverd_box">
                  <Input
                    className="form-control.is-invalid"
                    type="select"
                    name="portfolio_currency"
                    value={portfolio_currency}
                    placeholder="select currency"
                    invalid={
                      errors.portfolio_currency && touched.portfolio_currency
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  >
                    <option value="" disabled>
                      Please select currency
                    </option>
                    {g10Currencies.map((currecny) => (
                      <option value={currecny} key={currecny}>
                        {currecny}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
              <FormFeedback>
                {errors.portfolio_currency && touched.portfolio_currency
                  ? errors.portfolio_currency
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup>
        </div>
        <FormGroup row>
          <Label lg={4} className="required">
            Client Equity{" "}
            <span className="informicon" id="assid2">
              <FaInfoCircle />
            </span>
            <UncontrolledTooltip placement="right" target="assid2">
              Total net assets from client
            </UncontrolledTooltip>
          </Label>
          <Col lg={8} className="custom_s_width">
            <InputGroup>
              <Input
                type="text"
                name="portfolio_clientequity"
                id="portfolio_clientequity"
                placeholder="0"
                invalid={
                  errors.portfolio_clientequity &&
                  touched.portfolio_clientequity
                    ? true
                    : false
                }
                onChange={numberWithComma1}
                value={
                  equityValue
                    ? equityValue
                    : portfolio_clientequity
                        .toString()

                        .replace(/[^\d.]/g, "")

                        .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

                        .replace(/\.(\d{2})\d+/, ".$1")

                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                disabled={Flag ? true : constraintsFlag ? false : ""}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <FormFeedback
              style={{
                display:
                  errors.portfolio_clientequity &&
                  touched.portfolio_clientequity
                    ? "block"
                    : "none",
              }}
            >
              {errors.portfolio_clientequity && touched.portfolio_clientequity
                ? errors.portfolio_clientequity
                : ""}
            </FormFeedback>
          </Col>
        </FormGroup>
        {/* {permission?.user_permission == "arm" && (
          <FormGroup row>
            <Label lg={4} className="required">
              RM User{" "}
              <span className="informicon" id="assid1">
                {" "}
                <FaInfoCircle />{" "}
              </span>
              <UncontrolledTooltip placement="right" target="assid1">
                {/* Base currency of the portfolio; investments in other currencies
                will be converted to the base for the return calculation. 
              </UncontrolledTooltip>
            </Label>
            <Col lg={8} className="custom_s_width">
              <div style={{ position: "relative" }}>
                <div className="dropdownbox hoverd_box">
                  <Input
                    className="form-control.is-invalid"
                    type="select"
                    name="portfolio_RMuser"
                    placeholder="select RM User"
                    value={portfolio_RMuser}
                    invalid={
                      errors.portfolio_RMuser && touched.portfolio_RMuser
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    // onKeyPress={(e) => {
                    //   e.key === "Enter" && e.preventDefault();
                    // }}
                  >
                    <option value="" disabled>
                      Please select RM User
                    </option>
                    {RMlist.map((user) => {
                      console.log(
                        user.rm_id,
                        "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww"
                      );
                      return <option value={user.rm_id}>{user.rm_name}</option>;
                    })}
                  </Input>
                  {console.log(
                    portfolio_RMuser,
                    "user_permissionuser_permission"
                  )}
                </div>
              </div>
              <FormFeedback>
                {errors.portfolio_RMuser && touched.portfolio_RMuser
                  ? errors.portfolio_RMuser
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup>
        )} */}

        {/* {permission?.user_permission == "arm" && (
          <FormGroup row>
            <Label lg={4} className="required">
              IC User{" "}
              <span className="informicon" id="assid1">
                {" "}
                <FaInfoCircle />{" "}
              </span>
              <UncontrolledTooltip placement="right" target="assid1">
                {/* Base currency of the portfolio; investments in other currencies
                will be converted to the base for the return calculation. 
              </UncontrolledTooltip>
            </Label>
            <Col lg={8} className="custom_s_width">
              <div style={{ position: "relative" }}>
                <div className="dropdownbox hoverd_box">
                  <Input
                    className="form-control.is-invalid"
                    type="select"
                    name="portfolio_RMuser"
                    placeholder="select IC User"
                    value={portfolio_RMuser}
                    invalid={
                      errors.portfolio_RMuser && touched.portfolio_RMuser
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    // onKeyPress={(e) => {
                    //   e.key === "Enter" && e.preventDefault();
                    // }}
                  >
                    <option value="" disabled>
                      Please select IC User
                    </option>
                    {IClist.map((user) => {
                      console.log(user.id, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
                      return <option value={user.id}>{user.email}</option>;
                    })}
                  </Input>
                  {console.log(
                    portfolio_RMuser,
                    "user_permissionuser_permission"
                  )}
                </div>
              </div>
              <FormFeedback>
                {errors.portfolio_RMuser && touched.portfolio_RMuser
                  ? errors.portfolio_RMuser
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup>
        )} */}

        <FormGroup row>
          <Label lg={4} className="required">
            Core{" "}
            <span className="informicon" id="assid3">
              <FaInfoCircle />
            </span>
            <UncontrolledTooltip placement="right" target="assid3">
              Percentage/amount of the net asset to be invested for the purpose
              of this portfolio
            </UncontrolledTooltip>
          </Label>
          <Col lg={8} className="custom_s_width">
            <InputGroup>
              <Input
                type="text"
                name="portfolio_core_percentage"
                id="portfolio_core_percentage"
                placeholder="0"
                invalid={
                  errors.portfolio_core_percentage &&
                  touched.portfolio_core_percentage
                    ? true
                    : false
                }
                onChange={numberWithComma}
                value={port}
                // value={
                //   value
                //     ? value
                //     : portfolio_coreunit == "$"
                //     ? portfolio_core_percentage
                //         .toString()

                //         .replace(/[^\d.]/g, "")

                //         .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

                //         .replace(/\.(\d{2})\d+/, ".$1")

                //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                //     : portfolio_core_percentage
                // }
                disabled={Flag ? true : constraintsFlag ? false : ""}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <Input
                    className="setstyle"
                    type="select"
                    name="portfolio_coreunit"
                    onChange={handleChange}
                    value={portfolio_coreunit}
                  >
                    <option className="use" value="$">
                      $
                    </option>
                    <option className="use" value="%">
                      %
                    </option>
                  </Input>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {portfolio_coreunit == "%"
              ? `Core($) will be $${portfolio_core_calculation
                  .toString()

                  .replace(/[^\d.]/g, "")

                  .replace(/^(\d*\.)(.*)\.(.*)$/, "$1$2$3")

                  .replace(/\.(\d{2})\d+/, ".$1")

                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              : ""}
            <p></p>
            <FormFeedback
              style={{
                display:
                  errors.portfolio_core_percentage &&
                  touched.portfolio_core_percentage
                    ? "block"
                    : "none",
              }}
            >
              {errors.portfolio_core_percentage &&
              touched.portfolio_core_percentage
                ? errors.portfolio_core_percentage
                : ""}
            </FormFeedback>
            {portfolio_core_calculation < 4000000 &&
              portfolio_coreunit == "%" && (
                <p style={{ color: "red" }}>
                  {`The recommended portfolio size is ${portfolio_clientequity} $. Please note that the investment amount is currently too small to construct a diversified portfolio of at least 20 assets`}{" "}
                </p>
              )}
          </Col>
        </FormGroup>

        {/*<Label lg={3} className={portfolio_id ? "required" : ""}>
          Cost of borrowing (Benchmark)
        </Label>*/}
        {/* <Col lg={9}>
          <Input
            type="select"
            name="portfolio_costofborrowing_benchmark"
            placeholder="select COB Benchmark"
            invalid={
              errors.portfolio_costofborrowing_benchmark &&
              touched.portfolio_costofborrowing_benchmark
                ? true
                : false
            }
            onChange={handleChange}
            disabled={Flag ? false : true}
            value={portfolio_costofborrowing_benchmark}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          >
            <option value="" disabled>
              Please select COB
            </option>
            {costofborrowList &&
              costofborrowList.map((cob) => (
                <option value={cob.id} key={cob.id}>
                  {cob.cost_of_borrowing_benchmark}
                </option>
              ))}
          </Input>
          <FormFeedback>
            {errors.portfolio_costofborrowing_benchmark &&
            touched.portfolio_costofborrowing_benchmark
              ? errors.portfolio_costofborrowing_benchmark
              : ""}
          </FormFeedback>
        </Col>
            </FormGroup>*/}

        {Flag ? (
          <Fragment>
            <FormGroup row>
              <Label
                lg={4}
                className={
                  portfolio_id && constraintsFlag
                    ? ""
                    : portfolio_id
                    ? "required"
                    : ""
                }
              >
                Cost of borrowing
                <span className="informicon" id="assid4">
                  <FaInfoCircle />
                </span>
                <UncontrolledTooltip placement="right" target="assid4">
                  Interest rate for taking bank loans
                </UncontrolledTooltip>
              </Label>
              <Col lg={8} className="custom_s_width">
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_costofborrowing"
                    id="portfolio_costofborrowing"
                    placeholder="0"
                    invalid={
                      errors.portfolio_costofborrowing &&
                      touched.portfolio_costofborrowing
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    value={portfolio_costofborrowing}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>bps</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      errors.portfolio_costofborrowing &&
                      touched.portfolio_costofborrowing
                        ? "block"
                        : "none",
                  }}
                >
                  {errors.portfolio_costofborrowing &&
                  touched.portfolio_costofborrowing
                    ? errors.portfolio_costofborrowing
                    : ""}
                </FormFeedback>
              </Col>
            </FormGroup>
          </Fragment>
        ) : (
          ""
        )}
        {Flag ? (
          <FormGroup row>
            <Label
              lg={4}
              className={
                portfolio_id && constraintsFlag
                  ? ""
                  : portfolio_id
                  ? "required"
                  : ""
              }
            >
              Leverage
              <span className="informicon" id="assid7">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid7">
                Leverage amplifies the possible returns but will also amplify
                the risks to an even larger extent.
              </UncontrolledTooltip>
            </Label>
            <Col lg={8} className="custom_s_width">
              <InputGroup>
                <Input
                  type="tel"
                  name="portfolio_leverage"
                  id="portfolio_leverage"
                  placeholder="0"
                  invalid={
                    errors.portfolio_leverage && touched.portfolio_leverage
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  value={portfolio_leverage}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormFeedback
                style={{
                  display:
                    errors.portfolio_leverage && touched.portfolio_leverage
                      ? "block"
                      : "none",
                }}
              >
                {errors.portfolio_leverage && touched.portfolio_leverage
                  ? errors.portfolio_leverage
                  : ""}
              </FormFeedback>
            </Col>
          </FormGroup>
        ) : (
          ""
        )}
        {/*<FormGroup row>
        {/*<Label lg={3}>Shariah Compliant</Label>
        <Col lg={9} className="item-center">
          <CustomInput
            type="checkbox"
            name="portfolio_shariahcomplaint"
            id="exampleCustomCheckbox"
            onChange={handleChange}
            value={portfolio_shariahcomplaint}
            checked={portfolio_shariahcomplaint}
            disabled={Flag ? true : constraintsFlag ? false : ""}
          />
        </Col>*/}
      </div>
    </Fragment>
  );
}
