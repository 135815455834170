import * as types from '../actions/ActionTypes';
import { arrayInitial } from '../InitialStore';

export default function getAllMessages(
  state = arrayInitial,
  action
) {
  const { type, payload } = action;
  switch (type) {
  case types.CLIENT_LISTING:
    return payload;
  default:
    return state;
  }
}
