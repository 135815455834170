import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, CardTitle, Button, Table } from "reactstrap";
import CustomTableHeader from "../../common/CustomTableHeader";
import CustomTableLoader from "../../common/CustomTableLoader";
import CustomEmptyTableRecord from "../../common/CustomEmptyTableRecord";
import { numToPercentage } from "../../common/Mainfunctions";
import NumberFormat from "react-number-format";
import API from "../../redux/API";
import Swal from "sweetalert2";
import { SAVE_ALLOCATION } from "../../common/urls";

export default function Funds(props) {
  const {
    loading,
    assetData,
    stockData,
    fundData,
    lastupdated,
    allocation,
    porfolioId,
    setdollarmorethanhundred,
    eqvalue,
    setmorethanhundred,
    setpercentagefund,
    setdollarfund,
    groupselected,
    setselectedbondid,
    recommenedFundList,
    Flags,
  } = props;
  const [type, settype] = useState("");
  const [totaldollar, settotaldollar] = useState(0);
  const [totalpercentage, settotalpercentage] = useState(0);
  const [showFundData, setShowFundData] = useState(false);
  const [changedpercentage, setchangedpercentage] = useState(1);
  const [changedDallar, setchangedDallar] = useState(1);
  const [selectedfund, setselectedfund] = useState([]);
  const getAl = (type = "success", text = "", timer = 5000) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    showConfirmButton: false,
    timer: timer,
  });
  const fundtableColumns = [
    { title: "Name of Funds" },
    { title: "Asset class" },
    { title: "Strategy" },
    { title: "ISIN" },
    { title: "Currency" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Expected Return" },
    { title: "Expected volatility" },
    { title: "Mangement Fee" },
    { title: "Performance Fee" },
    { title: "Sharpe Since inception" },
    { title: "Correlation to MSCI AC World Index" },
    { title: "Dividend Yield" },
    { title: "Geography" },
    { title: "Max drawdown" },
    { title: "Redemption frequency" },
    { title: "Notice period" },
    { title: "Last Price Updated " },
  ];
  useEffect(() => {
    if (allocation == false) {
      settype("text");
    } else {
      settype("input");
    }
  }, [allocation]);

  useEffect(() => {
    if (Flags == false) {
      settype("text");
      // setAllocation(false);
      // setAllocationBond(false);
    } else {
      settype("input");
      // setAllocation(true);
      // setAllocationBond(true);
    }
  }, [Flags]);

  useEffect(() => {
    console.log(selectedfund);
  }, [selectedfund]);
 
  useEffect(() => {
    setselectedbondid(selectedfund);
  }, [selectedfund]);
 
  const handleSelectEvent = (selectedid) => {
    if (!selectedfund.includes(selectedid)) {
      setselectedfund((prevSelected) => [...prevSelected, selectedid]);
    } else {
      setselectedfund((prevSelected) =>
        prevSelected.filter((id) => id !== selectedid)
      );
    }
  };
  
  const checkPercentage = () => {
    let tot = 0;
    let doll = 0;
    fundData.map((record, index) => {
      if(record.funds_taken==1){
      
      console.log(record.funds_suggested_allocations_percentage);
      tot += record.funds_suggested_allocations_percentage
        ? parseFloat(record.funds_suggested_allocations_percentage)
        : 0;
      doll += record?.funds_suggested_allocation_dollar
        ? parseFloat(
            record?.funds_suggested_allocation_dollar
              .toString()
              .replaceAll("$", "")
              .replaceAll(",", "")
          )
        : 0;
      };
      console.log(record.funds_suggested_allocations_percentage, "123654789");
    });
    setpercentagefund(tot);
    setdollarfund(doll);
    console.log(tot, "@@@@@@@@@@@@@@@@@@@@@");
    // setdollarfund(doll);
    // setpercentagefund(tot);
    //alert(tot)
    if (tot * 100 > 100) {
      setmorethanhundred(true);
    } else {
      setmorethanhundred(false);
    }
    if (doll > eqvalue) {
      setdollarmorethanhundred(true);
    }
  };
  useEffect(() => {
    checkPercentage();
  }, [fundData]);
  
  useEffect(() => {
    if(recommenedFundList){
      checkPercentage();
    }
    // const merged = recommenedFundList.reduce((arr, item) => {
    //   setShowFundData(true);
    //   arr.push(item);
    //   return arr;
    // }, fundData);
  }, [recommenedFundList]);
  useEffect(() => {
    let tot = 0;
    let doll = 0;
    fundData.map((record, index) => {
      tot += record?.funds_suggested_allocations_percentage
        ? parseFloat(record.funds_suggested_allocations_percentage)
        : 0;
      doll += record?.funds_suggested_allocation_dollar
        ? parseFloat(
            record?.funds_suggested_allocation_dollar
              .toString()
              .replaceAll("$", "")
              .replaceAll(",", "")
          )
        : 0;
    });
    setdollarfund(doll);
    setpercentagefund(tot);
    //settotaldollar(doll);
    //settotalpercentage(tot);
    console.log(tot, "$$$$$$$$$$$$$$");
  }, [fundData]);

  /* Used To Rerender Component */
  useEffect(() => {
    if (changedpercentage == null) {
      setchangedpercentage(1);
    }
  }, [changedpercentage]);
  useEffect(() => {
    if (changedDallar == null) {
      setchangedDallar(1);
    }
  }, [changedDallar]);
  return (
    <div>
      <Row className="mt-4">
        <Col xl="12">
          {/* <CardTitle>External Funds <span className="bondcard"></span></CardTitle>*/}
          <div className="margin_minus stock_div">
            <Table
              hover
              responsive
              className="break_table fund_table stock_scroll"
            >
              <thead>
                <tr>
                  {groupselected == true && (
                    <th style={{ minWidth: "100px", maxWidth: "100%" }}>
                      Select
                    </th>
                  )}
                  <th style={{ minWidth: "195px", maxWidth: "100%" }}>
                    Name of Funds
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Asset class
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Strategy
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>ISIN</th>
                  <th style={{ minWidth: "100px", maxWidth: "100%" }}>
                    Currency
                  </th>
                  <th style={{ minWidth: "180px", maxWidth: "100%" }}>
                    Suggested Allocation $
                  </th>
                  <th style={{ minWidth: "180px", maxWidth: "100%" }}>
                    Suggested Allocation %
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Expected Return
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Expected volatility
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Mangement Fee
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Performance Fee
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Sharpe Since inception
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Correlation to MSCI AC World Index
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Dividend Yield
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Geography
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Max drawdown
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Redemption frequency
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Notice period
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Last Price Updated{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="d-block pr-1 mr-0 fix_auto">
                {!loading ? (
                  <Fragment>
                    {fundData.length > 0 ? (
                      fundData.map((record, index) => {
                        const isEventSelected = selectedfund.includes(
                          record.funds_id
                        );
                        // console.log(record, "jujujujujujujujujujujj");
                        const {
                          funds_name,
                          funds_asset_type,
                          funds_strategy,
                          funds_isin,
                          funds_currency,
                          funds_suggested_allocations_percentage,
                          funds_suggested_allocation_dollar,
                          funds_annual_return_inception,
                          funds_annual_volatility_inception,
                          funds_annual_sharpe_ratio,
                          funds_management_fee,
                          funds_performance_fee,
                          funds_correlation_MSCI_ACWI,
                          funds_dividend_yield,
                          funds_investment_geography,
                          funds_max_drawdown,
                          funds_redemption_period,
                          funds_notice_period,
                          sta_a_last_return_date,
                        } = record;
                        return (
                          <>
                            {record.funds_taken == 1 ? (
                              <tr key={index + 1}>
                                {groupselected == true && (
                                  <td
                                    style={{
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id="html"
                                      checked={isEventSelected}
                                      onChange={() =>
                                        handleSelectEvent(record.funds_id)
                                      }
                                    />
                                  </td>
                                )}
                                <td style={{ minWidth: "195px", width: "11%" }}>
                                  {funds_name}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_asset_type}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_strategy}
                                </td>
                                <td style={{ minWidth: "140px", width: "11%" }}>
                                  {funds_isin}
                                </td>
                                <td style={{ minWidth: "100px", width: "11%" }}>
                                  {funds_currency}
                                </td>

                                <td style={{ minWidth: "180px", width: "11%" }}>
                                  {" "}
                                  {changedDallar && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={parseFloat(
                                        funds_suggested_allocation_dollar?.toString()
                                          .replaceAll("$", "")
                                          .replaceAll(",", "")
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.funds_suggested_allocation_dollar =
                                          value.floatValue;
                                        record.funds_suggested_allocations_percentage =
                                        (value?.floatValue ? value?.floatValue : 0) / eqvalue;
                                        setchangedpercentage(null);
                                        checkPercentage();
                                      }}
                                      prefix={"$"}
                                    />
                                  )}
                                </td>

                                <td style={{ minWidth: "180px", width: "11%" }}>
                                  {changedpercentage && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={(
                                        funds_suggested_allocations_percentage *
                                        100
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.funds_suggested_allocations_percentage =
                                          value.floatValue / 100;
                                        record.funds_suggested_allocation_dollar =
                                          (value.floatValue / 100) * eqvalue;
                                        console.log(eqvalue, "4444444444444");
                                        //setchangedpercentage(null);
                                        checkPercentage();
                                        setchangedDallar(null);
                                      }}
                                      suffix={"%"}
                                    />
                                  )}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {(
                                    funds_annual_return_inception * 100
                                  ).toFixed(1) + "%"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {(
                                    funds_annual_volatility_inception * 100
                                  ).toFixed(1) + "%"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {(funds_management_fee * 100).toFixed(1) +
                                    "%"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_performance_fee
                                    ? parseFloat(funds_performance_fee).toFixed(
                                        1
                                      ) + "%"
                                    : "-"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_annual_sharpe_ratio}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_correlation_MSCI_ACWI}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_dividend_yield}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_investment_geography}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {parseFloat(funds_max_drawdown).toFixed(1)+
                                    "%" ? parseFloat(funds_max_drawdown).toFixed(1) +
                                    "%" : "-" }
                                </td>

                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_redemption_period}
                                </td>

                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {funds_notice_period}
                                </td>
                                <td style={{ minWidth: "100px", width: "11%" }}>
                                  {sta_a_last_return_date}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    ) : (
                      <CustomEmptyTableRecord
                        columnsCount={fundtableColumns.length}
                      />
                    )}
                  </Fragment>
                ) : (
                  <CustomTableLoader columnsCount={fundtableColumns.length} />
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
}
