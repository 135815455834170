import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { LOGIN_URL, SSO_LOGIN_URL } from "./common/urls";
import { INITIAL_ROUTE, ON_BOARDING } from "./common/RoutePaths";
import logo from "../images/logo1.png";
import { useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAlertToast, getAlert } from "./common/Mainfunctions";
import API from "./redux/API";
import MicrosoftLogin from "react-microsoft-login";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Signin() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isPasswordType, setPasswordInputType] = useState(true);
  const authHandler = async (err, data) => {
    console.log(err, data);
    if (!err) {
      const config = {
        method: "POST",
        url: SSO_LOGIN_URL,
        data: { azure_token: data.accessToken },
      };

      try {
        const response = await API(config);
        const { status, data } = response.data;
        const { token, rm_id, mail, role, name, image, user = [] } = data;
        const { displayName, mobilePhone } = user;
        if (status === "Success") {
          localStorage.setItem("AUTH_TOKEN", token);
          localStorage.setItem("RMID", rm_id);
          localStorage.setItem("ROLE", role);
          localStorage.setItem("PROFILE_NAME", name);
          localStorage.setItem("DISPLAY_NAME", user);
          localStorage.setItem("MAIL", mail);
          localStorage.setItem("PROFILE_IMAGE", image);
          localStorage.setItem("MOBILE_NUMBER", mobilePhone);
          Swal.fire(getAlertToast("Success", "Login Successfully"));
          history.push(ON_BOARDING);
        }
        console.log(mail, "xxxxxxxxxxxxxxxxxxxxxxxxxxxx");
      } catch (error) {
        Swal.fire(getAlert("error", "User not in our system"));
      } finally {
        setLoading(false);
      }
    }
  };
  //schema

  const validationSchema = Yup.object({
    email: Yup.string().trim().required("Email is required"),
    password: Yup.string().trim().required("Password is required"),
  });

  const togglePasswordType = () => {
    setPasswordInputType(!isPasswordType);
  };

  const saveFormValues = async (values) => {
    setLoading(true);
    const { email, password } = values;
    const config = {
      method: "POST",
      url: LOGIN_URL,
      data: { email, password },
    };

    try {
      const response = await API(config);
      const { status, data } = response.data;
      const { token, rm_id, role, name, image, user = [] } = data;
      const { displayName, mail, mobilePhone } = user;
      if (status === "Success") {
        localStorage.setItem("AUTH_TOKEN", token);
        localStorage.setItem("RMID", rm_id);
        localStorage.setItem("ROLE", role);
        localStorage.setItem("PROFILE_NAME", name);
        localStorage.setItem("DISPLAY_NAME", displayName);
        localStorage.setItem("MAIL", mail);
        localStorage.setItem("PROFILE_IMAGE", image);
        localStorage.setItem("MOBILE_NUMBER", mobilePhone);
        Swal.fire(getAlertToast("Success", "Login Successfully"));
        history.push(INITIAL_ROUTE);
      }
    } catch (error) {
      Swal.fire(getAlert("error", "Invalid Credentials"));
    } finally {
      setLoading(false);
    }
  };

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  return (
    <div className="signBg">
      <Container fluid>
        <Row>
          <Col lg={{ size: 4, offset: 7 }}>
            <div className="signForm-bg text-center">
              <img alt="logo" src={logo} className="w-50 m-auto d-block" />

              <Form form="true" onSubmit={handleSubmit}>
                {/*<FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    invalid={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <FormFeedback>
                    {errors.email && touched.email ? errors.email : ""}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <Input
                      type={isPasswordType ? "password" : "text"}
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      invalid={
                        errors.password && touched.password ? true : false
                      }
                      onChange={handleChange}
                      value={values.password}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={togglePasswordType}
                        style={{ cursor: "pointer" }}
                      >
                        {isPasswordType ? (
                          <FaEyeSlash></FaEyeSlash>
                        ) : (
                          <FaEye></FaEye>
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormFeedback>
                      {errors.password && touched.password
                        ? errors.password
                        : ""}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                        
                <Button
                  type="submit"
                  color="primary"
                  className="m-auto d-block pl-5 pr-5 pt-2 pb-2 signBtn"
                  disabled={loading ? true : false}
                >
                  {loading && <Spinner color="light" />}
                  Sign in                       
                </Button>*/}
                <MicrosoftLogin
                  clientId="6807fd39-ae44-42dc-ab85-5b971ababa73"
                  tenantUrl="https://login.microsoftonline.com/c24167bc-ce54-479c-b126-59e68ab033ea"
                  //redirectUri="http://localhost:3000/signin"
                  redirectUri="https://navigator.lighthouse-canton.com/signin"
                  authCallback={authHandler}
                >
                  <Button
                    type="button"
                    color="primary"
                    className="m-auto d-block pl-5 pr-5 pt-2 pb-2 signBtn"
                  >
                    Login
                  </Button>
                </MicrosoftLogin>
                {/* https://dev-navigator.lighthouse-canton.com/signin*/}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
