export const INITIAL_ROUTE = "/";
export const CLIENT_LIST = "/list-client/:page?";
export const SIGNIN_ROUTE = "/signin";
export const ADD_CLIENT_ROUTE = "/add-client";
export const PORTFOLIO_INPUTS_CREATE_ROUTE = "/portfolio-input";
export const PORTFOLIO_INPUTS_EDIT_ROUTE = "/portfolio-input/:id";
export const SUMMARY_ROUTE = "/detail-portfolio/summary/:id";
export const SETTINGS_ROUTE = "/settings";
export const ASSETS_MANAGEMENT_ROUTE = "/assets-management";
export const USER_MANAGEMENT_ROUTE = "/user-management";
export const ADD_NEW_USER = "/add-user";
export const PAGE_NOT_FOUND_ROUTE = "*";
export const LIST_PORTFOLIO = "/List-portfolio/:id/:page?/:clientpage?";
export const ALERT_TABS = "/Alert-tabs";
export const ON_BOARDING = "/onboarding";
export const BOND_INDEX = "/bondindex/list";
export const CREATE_LIST = "/create-list";
export const BOND_DELETE_PATH ="bonds/delete";
export const BOND_BULK_UPLOAD_URL ="bonds/bulkupload";