import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import { ASSET_BULKUPLOAD_URL } from "../common/urls";
import API from "../redux/API";
import Swal from "sweetalert2";
import { getAlert, authfileHeader } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";

export default function BulkUpload(props) {
  const { modal, toggle, successCallBack } = props;
  const [loading, setLoading] = useState(false);
 
  const [formData, setFormData] = useState({
    status: 1,
    file: "",
    fileName: "No file chosen",
  });

  const saveFormformData = async () => {
    const { status, file, fileName } = formData;
    if (fileName === "No file chosen") {
      Swal.fire(getAlert("error", "Please select file!!!"));
    } else {
      const fileData = new FormData();
      fileData.append("status", status);
      fileData.append("file", file);
      console.log("filedata", fileData);
      setLoading(true);
      const config = {
        method: "POST",
        url: ASSET_BULKUPLOAD_URL,
        headers: authfileHeader(),
        data: fileData,
      };

      try {
        const response = await API(config);
        const { status } = response.data;

        if (status === "Success") {
          Swal.fire(getAlert("success", "Assets Imported Successfully"));
          successCallBack();
        }
      } catch (error) {
        console.log(error);
        Swal.fire(getAlert("error", "Invalid Data"));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRadioChange = (e) => {
    //console.log(e.target,'99999999999')
    const { value } = e.target;
    setFormData({ ...formData, status: value });
  };

  const handleFileChange = (e) => {
    const { files = [] } = e.target;
    setFormData({ ...formData, file: files[0], fileName: files[0].name });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="">
      <Form>
        <ModalHeader toggle={toggle}>Bulk Upload</ModalHeader>
        <ModalBody className="p-3">
          <FormGroup tag="fieldset">
            <Label>Import Options</Label>
            <FormGroup check>
              <Label check>
                  <Input
                    type="radio"
                    name="status"
                    value={1}
                    checked={formData.status == 1 ? true : false}
                    onChange={handleRadioChange}
                  />
                Clear and Add New
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="status"
                  value={2}
                  onChange={handleRadioChange}
                  checked={formData.status == 2 ? true : false}
                />
                Add with Existing
              </Label>
            </FormGroup>
          </FormGroup>

          <div class="file-upload">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.477"
              height="39.303"
              viewBox="0 0 29.477 39.303"
            >
              <path
                id="Icon_awesome-file-upload"
                data-name="Icon awesome-file-upload"
                d="M17.195,10.44V0H1.842A1.838,1.838,0,0,0,0,1.842V37.46A1.838,1.838,0,0,0,1.842,39.3H27.635a1.838,1.838,0,0,0,1.842-1.842V12.282H19.037A1.848,1.848,0,0,1,17.195,10.44Zm5,16.582h-5v6.141a1.228,1.228,0,0,1-1.228,1.228H13.51a1.228,1.228,0,0,1-1.228-1.228V27.021h-5a1.228,1.228,0,0,1-.865-2.1l7.4-7.346a1.309,1.309,0,0,1,1.845,0l7.4,7.346A1.228,1.228,0,0,1,22.2,27.021ZM28.94,8.06,21.425.537A1.841,1.841,0,0,0,20.12,0h-.468V9.826h9.826V9.357A1.836,1.836,0,0,0,28.94,8.06Z"
                fill="#c43e56"
              />
            </svg>
            <label for="upload" class="file-upload__label">
              file upload button
            </label>
            <input
              id="upload"
              class="file-upload__input"
              type="file"
              name="file-upload"
              onChange={handleFileChange}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
          <div>
            <Label>{formData.fileName}</Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={saveFormformData}>
            {loading && <CustomLoader loading={loading ? true : false} />}
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
