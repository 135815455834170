import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  Table,
  NavItem,
  NavLink,
  Container,
  Button,
  Row,
  Col,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Form,
  Label,
} from "reactstrap";
import classnames from "classnames";
import { BiArrowBack } from "react-icons/bi";
import Sidebar from "./layout/Sidebar";
import AssetBreakdown from "./summary/AssetBreakdown";
import Constraints from "./summary/Constraints";
import AllocationChart from "./summary/AllocationChart";
import PerformanceSummary from "./summary/PerformanceSummary";
import RiskSummary from "./summary/RiskSummary";
import {
  EXPORT_EXCEL_URL,
  PORTFOLIO_ACCEPT_URL,
  PORTFOLIO_GET,
  SHARE_PORTFOLIO,
  CREATE_GROUP,
} from "./common/urls";
import API from "./redux/API";
import { authHeader, getAlert, getAlertToast } from "./common/Mainfunctions";
import {
  FaFilter,
  FaSortDown,
  FaCaretRight,
  FaEye,
  FaEdit,
  FaUser,
  FaCheckCircle,
  FaTimesCircle,
  FaTrash,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa";
import { Input } from "@material-ui/core";
import Swal from "sweetalert2";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  PORTFOLIO_INPUTS_EDIT_ROUTE,
  SETTINGS_ROUTE,
} from "./common/RoutePaths";
import AddUser from "./user/AddUser";
import AddLeverage from "./summary/AddLeverage";

export default function Summary(props) {
  const { setEditActive, EditActive } = props;

  let Flags;

  if (props.location.state) {
    let { editActiveFlag } = props.location.state;
    Flags = editActiveFlag;

    // update_type = constraintsFlag ? "change_constraint" : "modify_leverage";
  }

  const [activeTab, setActiveTab] = useState("1");
  const [excelStatus, setExcelStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [excelFileUrl, setExcelFileUrl] = useState("");
  const [clientPortfolioList, setClientPortfolioList] = useState([]);
  const [type, settype] = useState("");
  const {
    match: { params },
  } = props;
  const [porfolioId, setPortfolioId] = useState(params.id);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const initialFilterValues = {
    year: "",
    month: "",
  };
  const [filter, setFilter] = useState(initialFilterValues);
  const [token, settoken] = useState("");
  const filterToggle = () => setIsFilterOpen(!isFilterOpen);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  // const [ConstraintsFlag, setFlag] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpens, setOpen] = useState(false);
  const [ModifyBond, setModifyBond] = useState(false);
  const [allocationBond, setAllocationBond] = useState(false);
  const [checkBoxModify,setCheckBoxModify]=useState(false);
  const [showSelectAll, setShowSelectAll] = useState(false);
  const toggle1 = () => setDropdownOpen((dropdownOpen) => !dropdownOpen);
  const [addLeverageModal, setAddLeverageModal] = useState(false);
  const [portfoli, setPortfoli] = useState([]);
  const selectLeverageToggle = () => setAddLeverageModal(!addLeverageModal);
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);
  const [versionid, setversionid] = useState("");
  //const [clientPortfolioVersion, setclientPortfolioVersion] = useState([]);
  const [readchange, setreadchange] = useState("");
  const [allocation, setAllocation] = useState(false);
  const [statusreturn, setstatusreturn] = useState("");
  const [clientid, setclientid] = useState("1");
  const [groupselection, setgroupselection] = useState(false);
  const [selectedbondid, setselectedbondid] = useState(0);
  const [groupname, setgroupname] = useState("");
  const [groupvalue, setgroupvalue] = useState(-1);
  const [totaleqvalue, settotaleqvalue] = useState("");

  const creategroup = async () => {
    const config = {
      method: "POST",
      url: CREATE_GROUP,
      headers: authHeader(),
      data: {
        bondindex_name: groupname,
        bondindex_asset: selectedbondid,
        bondindex_maxweightage: groupvalue,
      },
    };
    try {
      const response = await API(config);
      Swal.fire(getAlertToast("Success", "Group created Successfully"));
      setgroupselection(false);
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    settoken(token);
  }, []);
  useEffect(() => {
    setPortfolioId(params.id);
  }, [params.id]);

  const downloadExcel = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: EXPORT_EXCEL_URL + "/" + porfolioId,
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      const { data = [] } = response.data;
      const { filepath = "", portfolio_excel = 0 } = data;
      setExcelFileUrl(filepath);
      setExcelStatus(portfolio_excel);
      console.error(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const shareuser = async () => {
    const config = {
      method: "GET",
      url: SHARE_PORTFOLIO + porfolioId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);

      Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      setLoading(false);
    }
  };
  // const ConstraintsFlagSet = () => {
  //   console.log("selected");
  //   setFlag(true);
  // };
  useEffect(() => {
    downloadExcel();
  }, []);

  const createbondindex = async () => {
    const config = {
      method: "GET",
      url: PORTFOLIO_GET + porfolioId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioLists(data.portfolio);
    } catch (error) {
      console.log(error);
      setClientPortfolioLists("");
    }
  };

  useEffect(() => {
    console.log(
      clientPortfolioLists?.portfolio?.portfolio_updatedon,
      "userfoffffffffffffffffffffffffffffffffffffrce"
    );
  }, [clientPortfolioLists]);

  const { year, month } = filter;
  //Onchange filter Field
  const changeFilterField = ({ target: { name, value } }) => {
    setFilter({ ...filter, [name]: value });
  };
  const history = useHistory();

  const getPortfolio = async () => {
    setreadchange();
    setPortfolioId(params.id);
    const config = {
      method: "GET",
      url: PORTFOLIO_GET + porfolioId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioLists(data);
      setclientid(data.portfolio.client.client_id);
      setstatusreturn(data.portfolio.portfolio_status);
      console.log(data.portfolio.client.client_id, "ppoooopppp");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPortfolio(params.id);
  }, [params.id]);

  const onClickconfirm = (e) => {
    //  e.stopPropagation();
    //  let user_id = parseInt(e.currentTarget.dataset.id);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to share this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        shareuser();
      }
    });
  };

  useEffect(() => {
    if (groupvalue >= totaleqvalue) {
      ({
        title: "Reduce value?",
        text: "Value is higher than client equity?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }.then((result) => {
        if (result.value) {
          shareuser();
        }
      }));
    }
  }, [totaleqvalue, groupvalue]);

  const acceptPortfolio = async () => {
    const config = {
      method: "GET",
      url: PORTFOLIO_ACCEPT_URL + "/" + porfolioId + "/1",
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status } = response.data;
      if (status == "success") {
        downloadExcel();
        Swal.fire(getAlertToast("Success", "Portfolio accepted Successfully"));
      } else {
        Swal.fire(getAlertToast("error", "Portfolio cannot accept"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickAccept = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this portfolio?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        acceptPortfolio();
      }
    });
  };
  const toggles = () => setOpen(!dropdownOpens);
  //const toggles = () => setOpen((dropdownOpens) => !dropdownOpens);
  return (
    <div className="bg-white">
      <div className="sidebarCont bg_26">
        <Sidebar />
      </div>
      <div className="contentWrapper bg-white">
        <div className="bg_d6 p-3">
          <Row className="">
            <Col sm="12">
              <a
                className="text_16_c4"
                //onClick={() => history.push(`/list-portfolio/${clientid}`)}
                onClick={() => history.goBack()}
              >
                <BiArrowBack></BiArrowBack> Back
              </a>
            </Col>
          </Row>
        </div>
        <Container fluid className="mt-4 bg-white">
          <div className="portfolio-summary">
            <div className="table_trans_line mb-4">
              <Table striped hover className="single_table_trans mb-0">
                <thead>
                  <tr>
                    <th>Potfolio Name</th>
                    <th>Client Name</th>
                    <th>Created On</th>
                    <th>RM Name</th>
                    <th>Version </th>
                  </tr>
                </thead>
                <tbody>
                  {(() => {
                    if (clientPortfolioLists != 0) {
                      return (
                        <tr>
                          <td>
                            {clientPortfolioLists.portfolio.portfolio_name}
                          </td>
                          <td>
                            {clientPortfolioLists.portfolio.client.client_name}
                          </td>
                          <td>
                            {clientPortfolioLists.portfolio.portfolio_createdon}
                          </td>
                          <td>{clientPortfolioLists.portfolio.rm_name}</td>

                          <td>
                            {clientPortfolioLists.portfolio.portfolio_excel ==
                            1 ? (
                              clientPortfolioLists.portfolio.portfolio_version
                            ) : (
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => {
                                  setversionid("");
                                  setreadchange("");
                                  window.location.href = `/detail-portfolio/summary/${e.target.value}`;
                                }}
                              >
                                {/* <option>Select type of version </option> */}
                                {clientPortfolioLists?.portfolio?.version_set.map(
                                  (record, index) => {
                                    return (
                                      <>
                                        <option
                                          selected={
                                            record.portfolio_id == porfolioId
                                          }
                                          value={record.portfolio_id}
                                        >
                                          {record.version}
                                        </option>
                                      </>
                                    );
                                  }
                                )}
                              </select>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })()}
                </tbody>
              </Table>
            </div>
            <div className="summaryTopPosition">
              <Nav tabs className="w-100 summary_tabs">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Constraints
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Performance Summary
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Risk Summary
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Asset Breakdown
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            {isFilterOpen && (
              <Row form className="mt-1">
                <Col md={3}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="year"
                      placeholder="Year"
                      onChange={changeFilterField}
                      value={year}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="month"
                      placeholder="Enter month"
                      onChange={changeFilterField}
                      value={month}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Constraints
                  porfolioId={porfolioId}
                  filter={filter}
                  readchange={readchange}
                  setreadchange={setreadchange}
                />
              </TabPane>
              <TabPane tabId="2">
                <PerformanceSummary
                  statusreturn={statusreturn}
                  porfolioId={porfolioId}
                  filter={filter}
                />
              </TabPane>
              <TabPane tabId="3">
                <RiskSummary
                  statusreturn={statusreturn}
                  porfolioId={porfolioId}
                />
              </TabPane>
              <TabPane tabId="4">
                <AssetBreakdown
                  settotaleqvalue={settotaleqvalue}
                  setselectedbondid={setselectedbondid}
                  setgroupselection={setgroupselection}
                  setAllocation={setAllocation}
                  checkBoxModify={checkBoxModify}
                  showSelectAll={showSelectAll}
                  allocationBond={allocationBond}
                  ModifyBond={ModifyBond}
                  setModifyBond={setModifyBond}
                  setAllocationBond={setAllocationBond}
                  setCheckBoxModify={setCheckBoxModify}
                  setShowSelectAll={setShowSelectAll}
                  porfolioId={porfolioId}
                  Flags={Flags}
                  fundLastUpdated={
                    clientPortfolioLists.portfolio?.sta_a_last_return_date
                  }
                  lastupdated={
                    clientPortfolioLists.portfolio?.last_price_update
                  }
                  allocation={allocation}
                />
              </TabPane>
              {/* <TabPane tabId="5">
              <AllocationChart porfolioId={porfolioId} />
            </TabPane> */}
            </TabContent>
          </div>
          <div className="pb-3 pl-5 pr-5 pt-3">
            <Row>
              {groupselection == true && (
                <>
                  <Col sm={4}>
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">Enter Group Name</Label>
                        <Input
                          className="rclass form-control form_border_0"
                          type="text"
                          name="email"
                          id="exampleEmail"
                          placeholder="Enter Group Name"
                          onChange={(e) => {
                            setgroupname(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col sm={4}>
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">Enter Group Amount</Label>
                        <Input
                          className="rclass form-control form_border_0"
                          type="number"
                          name="email"
                          id="exampleEmail"
                          placeholder="Enter value"
                          onChange={(e) => {
                            setgroupvalue(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <Button
                      color="primary"
                      className="actionBtn w-100"
                      onClick={creategroup}
                    >
                      Crete Group
                    </Button>
                  </Col>
                </>
              )}
              {groupselection == false && (
                <>
                  {allocation == false && (
                    <>
                      <Col sm={6}>
                        <div>
                          {excelStatus == 0 && (
                            <Button
                              color="primary"
                              className="actionBtn w-100"
                              onClick={onClickAccept}
                            >
                              Accept
                            </Button>
                          )}
                        </div>
                      </Col>

                      <Col sm={6}>
                        {excelStatus == 0 && (
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle1}
                            color="primary"
                            className="w-100 "
                          >
                            <DropdownToggle
                              variant="success"
                              className="w-100 line_btn"
                              caret
                            >
                              Modify
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* <DropdownItem tag="a">
                                <Link
                                  className="cus-link "
                                  to={{
                                    pathname:
                                      PORTFOLIO_INPUTS_CREATE_ROUTE +
                                      "/" +
                                      porfolioId,
                                    state: {
                                      leverageFlag: true,
                                      constraintFlag: false,
                                      allocationFlag: false,
                                    },
                                  }}
                                >
                                  Add Leverage
                                </Link>
                              </DropdownItem> */}
                              <DropdownItem tag="a">
                                <Link
                                  className="cus-link "
                                  to={{
                                    pathname:
                                      PORTFOLIO_INPUTS_CREATE_ROUTE +
                                      "/" +
                                      porfolioId,
                                    state: {
                                      leverageFlag: false,
                                      constraintFlag: true,
                                      allocationFlag: false,
                                    },
                                  }}
                                >
                                  Modify Constraints
                                </Link>
                              </DropdownItem>
                              <DropdownItem
                                tag="a"
                                onClick={() => setAllocation(true)}
                              >
                                Modify Allocation
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </Col>
                    </>
                  )}
                </>
              )}

              {allocation == false && (
                <>
                  {excelStatus != 0 && (
                    <>
                      <Col sm={4}>
                        <ButtonDropdown
                          className="w-100 nclr sk"
                          style={{ margin: "0 10px" }}
                          isOpen={dropdownOpens}
                          toggle={toggles}
                        >
                          <DropdownToggle caret className="nclr">
                            Documents
                          </DropdownToggle>

                          <DropdownMenu className="w-100 ">
                            <h6 className="nclr">Download</h6>

                            <DropdownItem>
                              <a
                                href={excelFileUrl}
                                style={{ display: "block" }}
                              >
                                {" "}
                                EXCEL{" "}
                              </a>
                            </DropdownItem>

                            <DropdownItem>
                              <a
                                target="blank"
                                href={`https://navigator-api.lighthouse-canton.com/lighthouse_pdf/download.php?id=${porfolioId}&token=${token}&draft=${"1"}`}
                                style={{ display: "block" }}
                              >
                                {" "}
                                PDF{" "}
                              </a>
                            </DropdownItem>
                            <h6 className="nshare">Share</h6>
                            <DropdownItem>
                              <a
                                href={excelFileUrl}
                                style={{ display: "block" }}
                                onClick={onClickconfirm}
                              >
                                {/* <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.867"
                            height="15.823"
                            viewBox="0 0 11.867 15.823"
                          > */}
                                {/* <path
                              id="Icon_awesome-file-pdf"
                              data-name="Icon awesome-file-pdf"
                              d="M5.621,7.915A2.982,2.982,0,0,1,5.56,6.465C5.819,6.465,5.795,7.605,5.621,7.915ZM5.569,9.373a14.26,14.26,0,0,1-.878,1.938,11.38,11.38,0,0,1,1.944-.677A4,4,0,0,1,5.569,9.373ZM2.661,13.23c0,.025.408-.167,1.079-1.242A4.271,4.271,0,0,0,2.661,13.23Zm5-8.285h4.2V15.081a.74.74,0,0,1-.742.742H.742A.74.74,0,0,1,0,15.081V.742A.74.74,0,0,1,.742,0H6.923V4.2A.744.744,0,0,0,7.664,4.945Zm-.247,5.309A3.1,3.1,0,0,1,6.1,8.591a4.442,4.442,0,0,0,.192-1.984A.774.774,0,0,0,4.812,6.4a5.139,5.139,0,0,0,.25,2.38A29.019,29.019,0,0,1,3.8,11.428s0,0-.006,0c-.838.43-2.275,1.375-1.684,2.1a.96.96,0,0,0,.664.309c.553,0,1.1-.556,1.888-1.91A17.615,17.615,0,0,1,7.1,11.215a4.683,4.683,0,0,0,1.978.6.8.8,0,0,0,.609-1.341c-.43-.42-1.678-.3-2.275-.223Zm4.234-7.009L8.622.216A.741.741,0,0,0,8.1,0H7.911V3.956h3.956V3.767A.739.739,0,0,0,11.651,3.245Zm-2.29,7.89c.127-.083-.077-.368-1.323-.278C9.185,11.345,9.361,11.135,9.361,11.135Z"
                              fill="#fff"
                            /> */}
                                {/* </svg> */}
                                Portfolio
                              </a>
                            </DropdownItem>
                            <DropdownItem>
                              <a
                                href={excelFileUrl}
                                style={{ display: "block" }}
                              >
                                {" "}
                                Transaction Sheet{" "}
                              </a>
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                        {/* <a href={excelFileUrl} download>
                <Button color="primary" className="w-100" style={{ margin: "0 10px" }}>
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.867"
                  height="15.823"
                  viewBox="0 0 11.867 15.823"
                >
                    <path
                      id="Icon_simple-microsoftexcel"
                      data-name="Icon simple-microsoftexcel"
                      d="M18.726,2.621H12.334V3.8h1.884V5.675H12.334v.6h1.884V8.145H12.334v.613h1.884v1.772H12.334v.71h1.884v1.776H12.334v.71h1.884v1.786H12.334v1.3h6.392c.1-.03.185-.149.253-.356a1.743,1.743,0,0,0,.1-.506V2.837c0-.1-.035-.163-.1-.184A.875.875,0,0,0,18.726,2.621ZM17.9,15.511H14.83V13.727H17.9v1.785Zm0-2.495H14.83V11.24H17.9Zm0-2.487H14.83V8.766H17.9V10.53Zm0-2.385H14.83V6.273H17.9V8.145h0Zm0-2.48H14.83V3.8H17.9v1.86ZM0,2.326V17.114l11.258,1.947V.329L0,2.332ZM6.673,13.513q-.064-.174-.6-1.481c-.357-.871-.572-1.379-.635-1.523h-.02L4.207,13.382l-1.614-.109L4.508,9.7,2.755,6.118,4.4,6.031l1.088,2.8H5.51L6.738,5.9l1.7-.107L6.414,9.669,8.5,13.618l-1.828-.107Z"
                      transform="translate(0 -0.328)"
                      fill="#fff"
                    />
                  </svg>
                  EXCEL
                </Button>
              </a> */}
                      </Col>
                      {excelStatus != 1 && (
                        <>
                          <Col sm={4} className="">
                            <Dropdown
                              isOpen={dropdownOpen}
                              toggle={toggle1}
                              color="primary"
                              className="w-100 "
                            >
                              <DropdownToggle
                                variant="success"
                                className="w-100 line_btn"
                                caret
                              >
                                Modify
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem tag="a">
                                <Link
                                  className="cus-link "
                                  to={{
                                    pathname:
                                      PORTFOLIO_INPUTS_CREATE_ROUTE +
                                      "/" +
                                      porfolioId,
                                    state: {
                                      leverageFlag: true,
                                      constraintFlag: false,
                                      allocationFlag: false,
                                    },
                                  }}
                                >
                                  Add Leverage
                                </Link>
                              </DropdownItem> */}
                                <DropdownItem tag="a">
                                  <Link
                                    className="cus-link "
                                    to={{
                                      pathname:
                                        PORTFOLIO_INPUTS_CREATE_ROUTE +
                                        "/" +
                                        porfolioId,
                                      state: {
                                        leverageFlag: false,
                                        constraintFlag: true,
                                        allocationFlag: false,
                                      },
                                    }}
                                  >
                                    Modify Constraints
                                  </Link>
                                </DropdownItem>
                                <DropdownItem
                                  tag="a"
                                  to={{ state: { editActiveFlag: true } }}
                                  onClick={() => {
                                    setAllocation(true);
                                    setAllocationBond(true);
                                    setCheckBoxModify(true);
                                    setShowSelectAll(true);
                                    setModifyBond(true);
                                  }}
                                >
                                  Modify Allocation
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </>
                      )}

                      {excelStatus != 1 && (
                        <>
                          <Col sm={4}>
                            <div>
                              <Button
                                color="primary"
                                className="actionBtn w-100"
                                onClick={onClickAccept}
                              >
                                Accept
                              </Button>
                            </div>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
