import React, { useState, Fragment, useEffect } from "react";
import classnames from "classnames";
import Sidebar from "../layout/Sidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  TabContent,
  TabPane,
} from "reactstrap";

import { BiArrowBack } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import UserDetails from "./UserDetails";
import ChangePassword from "./ChangePassword";
import AdminstratorLevelVariables from "./AdministratorLevelVariables";
import BenchMarks from "./BenchMarks";
import { authHeader, getRole, getRmID } from "../common/Mainfunctions";
import { BENCHMARK_LIST_URL, PERMISSION } from "../common/urls";
import API from "../redux/API";

const useStyles = makeStyles((theme) => ({}));

export default function Settings() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("1");
  const [per, setper] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [formData, setFormData] = useState({
    benchmark1: "",
    benchmark2: "",
  });
  const benchmarkList = async () => {
    const config = {
      method: "GET",
      url: BENCHMARK_LIST_URL,
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      const { data = [] } = response.data;
      const { benchmark1, benchmark2 } = data;

      setFormData({
        ...formData,
        benchmark1: benchmark1,
        benchmark2: benchmark2,
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    benchmarkList();
  }, []);

  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setper(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);
  return (
    <Fragment>
      <div className="bg-white">
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper bg-white">
          <div className="bg_d6 p-3">
            <Row className="">
              {/* <Col sm="12">
                <a
                  className="text_16_c4"
                  //onClick={() => history.push(`/list-portfolio/${clientid}`)}
                  //onClick={() => history.goBack()}
                >
                  <BiArrowBack></BiArrowBack>
                  Back
                </a>
              </Col> */}
            </Row>
          </div>
          <Container fluid className="mt-4 bg-white">
            <div className="min-screenheight">
              <CardTitle className="p-0">Settings</CardTitle>
              <CardBody className="p-0">
                <Row className="mt-3">
                  <Col md="4" lg="3">
                    <div className="settingTab">
                      {per.user_details == "yes" && (
                        <Button
                          color="link"
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1");
                          }}
                          block
                        >
                          User Details
                        </Button>
                      )}

                      <Button
                        color="link"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                        block
                      >
                        Change Password
                      </Button>

                      <Fragment>
                        {per.variables == "yes" && (
                          <Button
                            color="link"
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggle("3");
                            }}
                            block
                          >
                            Administrator Level Variables
                          </Button>
                        )}
                        {per.benchmarks == "yes" && (
                          <Button
                            color="link"
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggle("4");
                            }}
                            block
                          >
                            Benchmarks
                          </Button>
                        )}
                        {console.log(per, "SettingsSettingsSettingsSettings")}
                      </Fragment>
                    </div>
                  </Col>
                  <Col md="8" lg="9">
                    <div className="mb-5 valueBox">
                      <TabContent className="mt-5" activeTab={activeTab}>
                        <TabPane tabId="1">
                          <UserDetails />
                        </TabPane>
                        <TabPane tabId="2">
                          <ChangePassword />
                        </TabPane>

                        <TabPane tabId="3">
                          <AdminstratorLevelVariables />
                        </TabPane>

                        <TabPane tabId="4">
                          <BenchMarks formData={formData} />
                        </TabPane>
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}
