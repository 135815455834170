import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import { ASSIGN_IC, USER_LIST_URL, RM_LIST, IC_LIST } from "../common/urls";
import { authHeader, getAlert, getAlertToast } from "../common/Mainfunctions";
import API from "../redux/API";
import Swal from "sweetalert2";

export default function AssignClient(props) {
  const {
    assignClientModal = false,
    assignClientToggle,
    client_id,
    rm_id,
    submitCallBack,
    client_ic_id,
    ICassign,
  } = props;
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [list, setList] = useState("");
  const [rmId, setRmId] = useState(rm_id);
  const [rmselectid, setrmselectid] = useState("");
  
console.log(client_ic_id,'qqqqqqqqqqqqqqqqqqqq')
  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: USER_LIST_URL,
      headers: authHeader(),
      params: {
        searchterm: search,
        role: "rm",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      setClientNameList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onRadioChange = (e) => {
    const { value } = e.target;
    setRmId(value);
  };

  const onClickSubmit = (e) => {
    if (rmId === 0) {
      Swal.fire(getAlert("error", "Please select Rm!!!"));
    } else {
      assignClientToggle();
      assignToRm();
    }
  };
  const assignToRm = async (e) => {
    const config = {
      method: "GET",
      url: ASSIGN_IC,
      headers: authHeader(),
      data: { rm_id: rmselectid },
      params: {
        client_id: client_id,
        ic_id: rmId,
      },
    };
    console.log(client_id,'777777777777777777777777')
    try {
      setLoading(true);
      const response = await API(config);
      const { status, data } = response.data;

      if (status === "Success") {
        Swal.fire(getAlertToast("success", data));
        submitCallBack();
      } else if (status === "Error") {
        Swal.fire(getAlert("error", "Failed to Update"));
        submitCallBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // const delaySearch = useRef(
  //   debounce((search) => getClientNameList(search), 500)
  // ).current;

  useEffect(() => {
    // delaySearch(search);
    getClientNameList(search);
  }, [search]);

  useEffect(() => {
    getClientNameList();
  }, []);

  useEffect(() => {
    getRmList();
  }, []);

  const getRmList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: IC_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearFun = () => {
    setSearch("");
  };
  
  console.log(rmId,'client_rm_idclient_rm_idclient_rm_id')
  return (
    <Modal isOpen={assignClientModal} className="">
            {rmId <=0 ?<ModalHeader>Assign IC</ModalHeader>:
            <ModalHeader>Change IC</ModalHeader>}

      <ModalBody>
        {/* <div className="mt-2 mb-2 p-2">
          <InputGroup>
            <Input
              type="text"
              name="content"
              value={search}
              placeholder="search"
              onChange={onHandleChange}
            />

            <InputGroupAddon addonType="append">
              <Button className="searchBtn" color="link" onClick={clearFun}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.415"
                  height="8.415"
                  viewBox="0 0 8.415 8.415"
                >
                  <path
                    d="M16.415,8.736,15.679,8l-3.471,3.471L8.736,8,8,8.736l3.471,3.471L8,15.679l.736.736,3.471-3.471,3.471,3.471.736-.736-3.471-3.471Z"
                    transform="translate(-8 -8)"
                    fill="#c43f57"
                  />
                </svg>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div> */}
        <div className="table-scroll">
          <Table hover>
            <tbody>
              {!loading ? (
                <Fragment>
                  {list && list.length > 0 ? (
                    list.map((record, index) => {
                      const { id, email } = record;
                      return (
                        <tr key={id} onClick={() => setRmId(id)}>
                          <th style={{ width: "35px" }}>{index + 1}</th>

                          <td>{email} </td>
                          <td style={{ width: "35px" }}>
                            <Input
                              className="modelradio"
                              type="radio"
                              name="rmId"
                              value={id}
                              checked={rmId == id ? true : false}
                              onChange={onRadioChange}
                              style={{ position: "relative" }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <CustomEmptyTableRecord columnsCount={2} />
                  )}
                </Fragment>
              ) : (
                <CustomTableLoader columnsCount={2} />
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={assignClientToggle} outline color="primary">
          Close
        </Button>
        <Button color="primary" onClick={assignToRm}>
          Assign
        </Button>
      </ModalFooter>
    </Modal>
  );
}
