import React, { Fragment, useState, useEffect, useRef } from "react";
import Sidebar from "../layout/Sidebar";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  InputGroup,
  UncontrolledDropdown,
  InputGroupAddon,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import Pagination from "react-js-pagination";
import Portfolioversion from "./Portfolioversion";
import { Link } from "react-router-dom";
import {
  ADD_CLIENT_ROUTE,
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  SETTINGS_ROUTE,
} from "../common/RoutePaths";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getRmID,
  getDisplayName,
  getProfileImage,
  getProfileName,
  getRole,
  logOutFun,
} from "../common/Mainfunctions";

import { FormGroup, TextField } from "@material-ui/core";
import {
  FaSortDown,
  FaCaretRight,
  FaEye,
  FaEdit,
  FaAngleDown,
  FaUser,
  FaCheckCircle,
  FaTimesCircle,
  FaTrash,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa";
import {
  BENCHMARK_STATUS_URL,
  CLIENT_DELETE_URL,
  PORTFOLIO_DELETE,
  CLIENT_LIST,
  PORTFOLIO_ACCEPT_URL,
  GET_CLIENT_PORTFOLIOS,
  PORTFOLIO_ASSIGN_URL,
  PORTFOLIO_GET,
  CLIENT_INFO,
  PORTFOLIO_VERSION,
  PERMISSION,
} from "../common/urls";
import SelectClient from "./selectClient";
import API from "../redux/API";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomTableHeader from "../common/CustomTableHeader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import { debounce } from "underscore";
import AssignClient from "./assignClient";
import Swal from "sweetalert2";

import { Modal, ModalHeader, ModalBody, ModalFooter, Col } from "reactstrap";
import { AiOutlinePlusCircle, AiOutlineBell } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { FaReply } from "react-icons/fa";
import NumberFormat from "react-number-format";

import { green } from "@material-ui/core/colors";

import {
  Tooltip,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from "reactstrap";
import Row from "reactstrap/lib/Row";
export default function Listofport(props) {
  const { setAllocation, setAllocationBond } = props;
  const dispatch = useDispatch();
  const [selectClientModal, setSelectClientModal] = useState(false);
  const selectClientToggle = () => setSelectClientModal(!selectClientModal);
  const [assignClientModal, setAssignClientModal] = useState(false);
  const assignClientToggle = () => setAssignClientModal(!assignClientModal);
  const [clist, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedClient, setClientId] = useState(0);
  const [selectedportfolio, setPortfolioId] = useState(0);
  const [selectedRm, setSelectedRm] = useState(0);
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [nextPage, setnextPage] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [showloader, setShowloader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedPorfolioId, setSelectedPortfolioId] = useState("");
  const [clientPortfolioList, setClientPortfolioList] = useState([]);
  const [portfolioerror, setPortfoliError] = useState("");
  const [clientportfolioversion, setClientPortfolioVersion] = useState([]);

  const [sum, setsum] = useState("");

  const {
    match: { params },
  } = props;
  const history = useHistory();
  // const [benchmarkStatusFlag, setBenchStatus] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const [version, setversion] = useState(params.version);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [intervalTime, setIntervalTime] = useState(0);
  const [listPortData, setListPortData] = useState(0);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleport = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggleport1 = () => setDropdownOpen1((dropdownOpen1) => !dropdownOpen1);
  const [dropFlag, settingDropDown] = useState(false);
  const [modal, setModal] = useState(false);
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);
  const [permission, setpermission] = useState("");
  const [porfolioI, setPortfolioI] = useState(params.id);
  const [clientdetails, setClientdetails] = useState({});
  const [isOpen3, setIsOpen3] = React.useState(false);
  const [versionid, setversionid] = useState("");
  const [childversionid, setchildversionid] = useState("");
  const [token, settoken] = useState("");
  const [clientpage, setClientpage] = useState(0);
  // const [EditActive,setEditActive]=useState(false);
  const toggles3 = () => setIsOpen3(!isOpen3);

  const toggles = () => setModal(!modal);

  const setFlag = () => {
    settingDropDown(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    settoken(token);
  }, []);

  const clienttableColumns = [
    { title: "S.No" },
    { title: "RM Name" },
    { title: "Client Name" },
    { title: "Email" },
    { title: "Phone Number" },
    { title: "Onboarded" },
    { title: "List of Portfolios" },
  ];
  const tablePortfolioColumns = [
    { title: "Version #" },
    { title: "Portfolio Name" },
    { title: "Currency" },
    { title: "Client Equity" },
    { title: "Leverage" },
    { title: "Date of Creation" },
    { title: "Status" },
    { title: "Actions" },
  ];

  const onClickSubmit = (e) => {
    localStorage.setItem("SELECTED_CLIENT_ID", porfolioI);
    selectClientToggle();
    history.push(PORTFOLIO_INPUTS_CREATE_ROUTE);
  };

  useEffect(() => {
    console.log(params.page, "sssssssssssss");
    if (params.page >= 0) {
      // alert(params.page);
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  useEffect(() => {
    if (params.clientpage) {
      // alert(params.page);
      setClientpage(parseInt(params.clientpage));
      console.log(parseInt(params.clientpage),'parseInt(params.clientpage)parseInt(params.clientpage)parseInt(params.clientpage)')
    }
  }, [params.clientpage]);

  // useEffect(() => {
  //   getPortfolio();
  //   //console.log(clientPortfolioLists,"tgggtttttlll")
  // }, []);

  const getPortfolioversion = async (versionport) => {
    setClientPortfolioVersion([]);
    const config = {
      method: "GET",
      url: PORTFOLIO_VERSION + "/" + porfolioI + "/" + versionport,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioVersion(data);
    } catch (error) {
      console.log(error);
      setClientPortfolioVersion([]);
    }
  };

  useEffect(() => {
    //getPortfolioversion();
  }, [params.id]);

  
  console.log(clientPortfolioList, "clientddddddddddddddddddddddddddddd");
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data.user_permission);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, []);

  const getclientdetails = async () => {
    const config = {
      method: "GET",
      url: CLIENT_INFO + "/" + porfolioI,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientdetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const assignToClient = async (e) => {
    const config = {
      method: "PUT",
      url: PORTFOLIO_ASSIGN_URL + "/" + porfolioI,
      headers: authHeader(),
      data: { portfolio_user: selectedClient },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { status, data } = response.data;

      if (status === "Success") {
        Swal.fire(getAlertToast("success", data));
        submitCallBack();
      } else if (status === "Error") {
        Swal.fire(getAlert("error", "Failed to Update"));
        submitCallBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getclientdetails();
    //console.log(clientPortfolioLists,"tgggtttttlll")
  }, []);

  

  const logOutClick = () => {
    logOutFun(history);
  };
  const onPageChange = (page) => {
    //setCurrentPage(page - 1);
    history.push(`/List-portfolio/${porfolioI}/${page - 1}/${clientpage}`);
  };
  const toggleset = (event) => {
    const { cid } = event.currentTarget.dataset;
    if (parseInt(cid) === parseInt(selectedClient)) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
    }
    setClientId(cid);
  };
  
  const onClickDelete = (portfolio_id) => {
   /// e.stopPropagation();
    //let asset_id = parseInt(e.currentTarget.dataset.id);
    swal
      .fire({
        title: "Are you sure?",
        text: "Do you want to delete this Portfolio?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          portfolio_delete(portfolio_id);
        }
      });
  };

  const portfolio_delete = async (portfolio_id) => {
    const config = {
      method: "GET",
      url: PORTFOLIO_DELETE + portfolio_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status } = response.data;
      if (status == "success") {
        getClientPortfolio();
        Swal.fire(getAlertToast("Success", "Portfolio Deleted Successfully"));
      } else {
        Swal.fire(getAlertToast("error", "Portfolio cannot Deleted"));
      }
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  const toggleset1 = (event) => {
    //console.log(event?.currentTarget?.dataset,'event?.currentTarget?.dataset')

    if (event?.currentTarget?.dataset?.pid) {
      const { pid } = event?.currentTarget?.dataset;
      if (parseInt(pid) === parseInt(selectedportfolio)) {
        setIsOpen1(!isOpen1);
      } else {
        setIsOpen1(true);
      }
      setPortfolioId(pid);
    } else {
      setIsOpen1(false);
    }
  };
  const onClearfun = (e) => {
    setSearch("");
  };

  const submitCallBack = (e) => {
    selectClientToggle(false);
    getClientPortfolio();
  };

  const assignSubmitCallBack = (e) => {
    assignClientToggle(false);
    getClientPortfolio();
  };

  // const addNewPortfolio = (e) => {
  //   if (benchmarkStatusFlag === "valid") {
  //     setSelectedPortfolioId("");
  //     selectClientToggle();
  //   } else {
  //     Swal.fire(getAlert("error", "Contact admin"));
  //   }

  // };

  useEffect(() => {
    getClientPortfolio();
  }, [currentpage]);

  useEffect((event) => {
    if (event) {
      const cid = event?.currentTarget?.dataset.cid;
      setClientId(cid);
    }
  }, []);

  const assignToggle = (event) => {
    const portfolioId = event.currentTarget.dataset.portfolioid;
    const cid = event.currentTarget.dataset.cid;
    setSelectedPortfolioId(portfolioId);
    setClientId(cid);
    selectClientToggle();
  };

  const onClickAssignClient = (event) => {
    const rm_id = event.currentTarget.dataset.rmid;
    const cid = event.currentTarget.dataset.cid;
    setClientId(cid);
    getClientPortfolio();
    setSelectedRm(rm_id);
    assignClientToggle();
  };

  const acceptPortfolio = async (portfolioID) => {
    const config = {
      method: "GET",
      url: PORTFOLIO_ACCEPT_URL + "/" + portfolioID + "/1",
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status } = response.data;
      if (status == "success") {
        getClientPortfolio();
        Swal.fire(getAlertToast("Success", "Portfolio accepted Successfully"));
      } else {
        Swal.fire(getAlertToast("error", "Portfolio cannot accept"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      getPortfolioversion();
      getClientPortfolio();
      setversionid(false);
    }
  };

  const getClientPortfolio = async (search) => {
    setShowloader(true);
    if (props.id == 0) return false;
    const config = {
      method: "GET",
      url: GET_CLIENT_PORTFOLIOS + params.id,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { status, data=[], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      setTotalRecords(totalrecords);
      if (status == "Success") {
        setClientPortfolioList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowloader(false);
    }
  };
  const onHandleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };
  const delaySearch = useRef(
    debounce((search) => getClientPortfolio(search), 500)
  ).current;

  useEffect(() => {
    delaySearch(search);
  }, [search]);

  const onClickAccept = (e) => {
    e.stopPropagation();
    let portfolioId = parseInt(e.currentTarget.dataset.portfolioid);
    console.log(e.currentTarget.dataset.portfolioid, "tttttttttttttttttttt");
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this portfolio? Other Version Will Be Deleted ! ",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        acceptPortfolio(portfolioId);
      }
    });
  };

  const benchmarkStatus = async () => {
    const config = {
      method: "GET",
      url: BENCHMARK_STATUS_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      console.log(data);
      if (data === "valid") {
        setSelectedPortfolioId("");
        selectClientToggle();
      } else {
        Swal.fire(getAlert("error", "Contact admin"));
      }
      // setBenchStatus(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setClientPortfolioList([]);
    // getClientPortfolio();
    clearInterval(intervalTime);
    //setIntervalTime(setInterval(() => getClientPortfolio(), 20000));
  }, [selectedClient]);
  //
  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper">
          <div className="bg_f2 pt-3 pb-3 pl-3 pr-3">
            <Row>
              <Col xl={4} md={12}>
                <div className="btn-right">
                  <FormGroup>
                    <Input
                      type="text"
                      name="content"
                      value={search}
                      placeholder="search"
                      onChange={onHandleChange}
                    />
                  </FormGroup>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="searchBtnTable"
                      color="link"
                      onClick={onClearfun}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.415"
                        height="8.415"
                        viewBox="0 0 8.415 8.415"
                      >
                        <path
                          d="M16.415,8.736,15.679,8l-3.471,3.471L8.736,8,8,8.736l3.471,3.471L8,15.679l.736.736,3.471-3.471,3.471,3.471.736-.736-3.471-3.471Z"
                          transform="translate(-8 -8)"
                          fill="#c43f57"
                        />
                      </svg>
                    </Button>
                  </InputGroupAddon>
                </div>
              </Col>
              <Col xl={8} md={12} className="text-xl-right mt-3 mt-xl-0">
                <UncontrolledDropdown
                  setActiveFromChild
                  className="d-inline-block mb-3 mb-sm-0"
                >
                  <DropdownToggle tag="a" className="nav-link color_ac" caret>
                    <img
                      className="avatar"
                      // src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      src={
                        getProfileImage() ||
                        "https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      }
                    />{" "}
                    <span>{getDisplayName()}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag="a"
                      onClick={logOutClick}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {porfolioI && (
                  <Button
                    onClick={onClickSubmit}
                    color="primary"
                    className="mr-sm-3 mr-1"
                  >
                    <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                    Add New Portfolio
                  </Button>
                )}

                {!porfolioI && (
                  <Button
                    onClick={benchmarkStatus}
                    color="primary"
                    className="mr-sm-3 mr-1"
                  >
                    <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                    Add New Portfolio
                  </Button>
                )}

                <Button color="primary">
                  <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                  <Link className="cus-link" to={ADD_CLIENT_ROUTE}>
                    Add New Client
                  </Link>
                </Button>
              </Col>
            </Row>
          </div>
          <Container fluid className="p-sm-0">
            <Card className="min-screenheight st_card">
              <div className="bg_d6 px-3 py-2">
                <Row className="">
                  <Col xl="11" md={8} xs={8} className="align-self-center">
                    <CardTitle className="p-0">List of Portfolios</CardTitle>
                  </Col>
                  <Col
                    xl="1"
                    md={4}
                    xs={4}
                    className="align-self-center text-right"
                  >
                    <div
                      onClick={() => history.push(`/list-client/${clientpage}`)}
                    >
                      <a className="text_16_c4">
                        <FaReply></FaReply> Back
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <Table hover responsive className="mt-4">
                <div className="tablecollabse-card px-3 bg-white">
                  <div className="table_trans_line mb-4">
                    <Table striped hover className="single_table_trans mb-0">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>RM Name</th>
                          <th>Created On</th>
                          <th>Email</th>
                          <th>Contact No</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{clientdetails.client_name}</td>
                          <td>
                            {clientdetails.rm_name ? clientdetails.rm_name : ""}
                          </td>
                          <td>{clientdetails.client_createdon}</td>
                          <td>{clientdetails.client_email}</td>
                          <td>{clientdetails.client_contactno}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <hr style={{ margin: 0 }} />
                  <Table hover className="">
                    <CustomTableHeader tableColumns={tablePortfolioColumns} />

                    <tbody>
                      {!showloader ? (
                        <Fragment>
                          {clientPortfolioList.length > 0 ? (
                            clientPortfolioList.map((record, index1) => {
                              const {
                                portfolio_id,
                                portfolio_version,
                                portfolio_name,
                                portfolio_clientequity,
                                portfolio_currency,
                                portfolio_createdon,
                                portfolio_leverage,
                                portfolio_status,
                                portfolio_excel,
                                portfolio_error,
                                exe_status,
                                child_count,
                                queue,
                              } = record;

                              return (
                                <>
                                  <tr key={`portfolio_key1_${index1}`}>
                                    <td>{portfolio_version}</td>
                                    <td>{portfolio_name}</td>
                                    <td>{portfolio_currency}</td>
                                    <td>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        value={portfolio_clientequity}
                                        displayType={"text"}
                                      />
                                    </td>
                                    <td>{portfolio_leverage}</td>
                                    <td>{portfolio_createdon}</td>

                                    {(() => {
                                      if (portfolio_status == "failed") {
                                        return (
                                          <td
                                            onClick={() => {
                                              setPortfoliError(portfolio_error);
                                              toggles();
                                            }}
                                            id={`errortooltip_${portfolio_id}`}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {portfolio_status}
                                            {(() => {
                                              if (exe_status == 0) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Success
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 1) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Some error in parameter or
                                                    during
                                                    import/export/optimisation
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 2) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Aborted by user
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 3) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Issue with db connection
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 4) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Optimization failed
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 100) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Unexpected/unspecified error
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                          </td>
                                        );
                                      } else if (
                                        portfolio_status ==
                                        "failed-previous-success"
                                      ) {
                                        return (
                                          <td
                                            onClick={() => {
                                              setPortfoliError(portfolio_error);
                                              toggles();
                                            }}
                                          >
                                            <span className="failed">
                                              failed
                                            </span>
                                          </td>
                                        );
                                      } else if (
                                        portfolio_status ==
                                        "failed-previous-failed"
                                      ) {
                                        return (
                                          <td
                                            className="failed"
                                            id={`errortooltip_${portfolio_id}`}
                                          >
                                            <div
                                              onClick={() => {
                                                setPortfoliError(
                                                  portfolio_error
                                                );
                                                toggles();
                                              }}
                                            >
                                              <span className="failed">
                                                failed
                                              </span>
                                            </div>
                                            {(() => {
                                              if (exe_status == 0) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Success
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                            {(() => {
                                              if (exe_status == 1) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Some error in parameter or
                                                    during
                                                    import/export/optimisation
                                                  </UncontrolledTooltip>
                                                );
                                              } else if (exe_status == 2) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Aborted by user
                                                  </UncontrolledTooltip>
                                                );
                                              } else if (exe_status == 3) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Issue with db connection
                                                  </UncontrolledTooltip>
                                                );
                                              } else if (exe_status == 4) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Optimization failed
                                                  </UncontrolledTooltip>
                                                );
                                              } else if (exe_status == 100) {
                                                return (
                                                  <UncontrolledTooltip
                                                    placement="right"
                                                    target={`errortooltip_${portfolio_id}`}
                                                  >
                                                    Unexpected/unspecified error
                                                  </UncontrolledTooltip>
                                                );
                                              }
                                            })()}
                                          </td>
                                        );
                                      } else if (
                                        portfolio_status == "processing"
                                      ) {
                                        return (
                                          <td>
                                            {portfolio_status}{" "}
                                            {queue > 0 && (
                                              <small>(Queue # {queue})</small>
                                            )}
                                          </td>
                                        );
                                      } else {
                                        return <td>{portfolio_status}</td>;
                                      }
                                    })()}
                                    <td className="table-btn-section edit_class">
                                      {(() => {
                                        if (
                                          portfolio_status == "success" ||
                                          portfolio_status ==
                                            "failed-previous-success"
                                        ) {
                                          return (
                                            <>
                                              {child_count > 0 && portfolio_excel != 1 &&(
                                                <span
                                                  className="rns"
                                                  onClick={() => {
                                                    getPortfolioversion(portfolio_id);
                                                    setversionid(
                                                      versionid == portfolio_id
                                                        ? 0
                                                        : portfolio_id
                                                    );
                                                    setchildversionid(
                                                      portfolio_id
                                                    );
                                                  }}
                                                >
                                                  <FaAngleDown className="ps4 gt"></FaAngleDown>
                                                  Version
                                                </span>
                                              )}
                                              <Fragment>
                                                {portfolio_excel != 1 && (
                                                  <Dropdown
                                                    // isOpen={`${dropdownOpen}+${
                                                    //   index + 1
                                                    // }`}
                                                    toggle={toggleset1}
                                                    isOpen={
                                                      isOpen1 &&
                                                      parseInt(
                                                        selectedportfolio
                                                      ) ===
                                                        parseInt(portfolio_id)
                                                    }
                                                    size="sm"
                                                    color=""
                                                    data-pid={portfolio_id}
                                                    onClick={toggleset1}
                                                  >
                                                    <DropdownToggle
                                                      className="pr-2"
                                                      tag="span"
                                                      data-toggle="dropdown"
                                                      aria-expanded={
                                                        dropdownOpen
                                                      }
                                                      variant="success"
                                                    >
                                                      <>
                                                        <FaEdit className="rns mr-2"></FaEdit>
                                                        <span className="rns">
                                                          Modify
                                                        </span>
                                                      </>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                      {/* <DropdownItem tag="a">
                                                        <Link
                                                          className="cus-link "
                                                          to={{
                                                            pathname:
                                                              PORTFOLIO_INPUTS_CREATE_ROUTE +
                                                              "/" +
                                                              portfolio_id,
                                                            state: {
                                                              leverageFlag: true,
                                                              constraintFlag: false,
                                                            },
                                                          }}
                                                        >
                                                          Add Leverage
                                                        </Link>
                                                      </DropdownItem> */}
                                                      <DropdownItem tag="a">
                                                        <Link
                                                          className="cus-link "
                                                          to={{
                                                            pathname:
                                                              PORTFOLIO_INPUTS_CREATE_ROUTE +
                                                              "/" +
                                                              portfolio_id,
                                                            state: {
                                                              leverageFlag: false,
                                                              constraintFlag: true,
                                                            },
                                                          }}
                                                        >
                                                          Modify Constraints
                                                        </Link>
                                                      </DropdownItem>

                                                      <DropdownItem tag="a">
                                                        <Link
                                                          className="cus-link "
                                                          to={{
                                                            pathname:
                                                              "/detail-portfolio/summary" +
                                                              "/" +
                                                              portfolio_id,
                                                            state: {
                                                              editActiveFlag: true,
                                                            },
                                                          }}
                                                        >
                                                          Modify Allocation
                                                        </Link>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </Dropdown>
                                                )}
                                                <Button
                                                  color=""
                                                  size="sm"
                                                  className="actionBtn text-left"
                                                  data-portfolioid={
                                                    portfolio_id
                                                  }
                                                >
                                                  <Link
                                                    className="cus-link"
                                                    to={{
                                                      pathname: `/detail-portfolio/summary/${portfolio_id}`,
                                                      state: {
                                                        editActiveFlag: false,
                                                      },
                                                    }}
                                                  >
                                                    <>
                                                      <FaEye className="mr-2"></FaEye>
                                                      <span>View</span>
                                                    </>
                                                  </Link>
                                                </Button>
                                                {permission != "arm" && permission != "rm" &&permission != "ic" && (
                                                <>                                                
                                                <Button
                                                  color=""
                                                  size="sm"
                                                  className="actionBtn text-left"
                                                 
                                                  onClick={()=>onClickDelete(portfolio_id)}
                                                >
                                                    <>
                                                      <FaTrash className="mr-2"></FaTrash>
                                                      <span>Delete</span>
                                                    </>
                                                </Button>
                                                </>
                                                          )}
                                                {portfolio_excel != 1 && (
                                                  <>
                                                    <Button
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn text-left"
                                                    >
                                                      <>
                                                        
                                                        <a
                                                          target="_blank"
                                                          href={`https://navigator-api.lighthouse-canton.com/lighthouse_pdf/download.php?id=${portfolio_id}&token=${token}&draft=1`}
                                                        ><FaFileExcel className="mr-2" />
                                                          <span>Draft</span>
                                                        </a>
                                                      </>
                                                    </Button>
                                                    <Button
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                    ></Button>
                                                  </>
                                                )}

                                                {portfolio_excel == 1 && (
                                                  <>
                                                    <Button
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                    >
                                                      <>
                                                        <a
                                                          target="_blank"
                                                          href={`https://navigator-api.lighthouse-canton.com/lighthouse_pdf/download.php?id=${portfolio_id}&token=${token} `}
                                                        > <FaFileExcel />
                                                          <span className="ml-2">
                                                            View PDF
                                                          </span>
                                                        </a>
                                                      </>
                                                    </Button>
                                                    <Button
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                    ></Button>
                                                  </>
                                                )}

                                                {portfolio_excel == 1 ? (
                                                  <Button
                                                    color=""
                                                    size="sm"
                                                    className="actionBtn"
                                                  >
                                                    <FaCheckCircle
                                                      style={{
                                                        fill: "green",
                                                      }}
                                                    ></FaCheckCircle>
                                                  </Button>
                                                ) : (
                                                  <Fragment>
                                                    {/* <Button
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                    >
                                                      <FaTimesCircle></FaTimesCircle>
                                                    </Button> */}
                                                    <Button
                                                      color="primary"
                                                      size="sm"
                                                      className="actionBtn"
                                                      data-portfolioid={
                                                        portfolio_id
                                                      }
                                                      onClick={onClickAccept}
                                                    >
                                                      Accept
                                                    </Button>
                                                  </Fragment>
                                                )}
                                              </Fragment>
                                            </>
                                          );
                                        } else if (
                                          portfolio_status == "failed"
                                        ) 
                                         {
                                          return (
                                            <>
                          {/*......................Failed...................*/}
                                              {child_count > 0 && (
                                                <span
                                                  className="rns"
                                                  onClick={() => {
                                                    getPortfolioversion(
                                                      portfolio_id
                                                    );
                                                    setversionid(
                                                      versionid == portfolio_id
                                                        ? 0
                                                        : portfolio_id
                                                    );
                                                    setchildversionid(
                                                      portfolio_id
                                                    );
                                                  }}
                                                >
                                                  <FaAngleDown className="ps4 gt"></FaAngleDown>
                                                  Version
                                                </span>
                                              )}
                                              <Dropdown
                                                // isOpen={`${dropdownOpen}+${
                                                //   index + 1
                                                // }`}
                                                toggle={`${toggleport}+${
                                                  index1 + 1
                                                }`}
                                                isOpen={
                                                  isOpen1 &&
                                                  parseInt(
                                                    selectedportfolio
                                                  ) === parseInt(portfolio_id)
                                                }
                                                size="sm"
                                                color=""
                                                data-pid={portfolio_id}
                                                onClick={toggleset1}
                                              >
                                                <DropdownToggle
                                                  tag="span"
                                                  data-toggle="dropdown"
                                                  aria-expanded={dropdownOpen}
                                                  variant="success"
                                                >
                                                  <>
                                                    <FaEdit className="rns"></FaEdit>
                                                    <span className="ml-2 rns">
                                                      Modify
                                                    </span>
                                                  </>
                                                </DropdownToggle>
                                               
                                                <DropdownMenu>
                                                  {/* <DropdownItem tag="a">
                                                    <Link
                                                      className="cus-link "
                                                      to={{
                                                        pathname:
                                                          PORTFOLIO_INPUTS_CREATE_ROUTE +
                                                          "/" +
                                                          portfolio_id,
                                                        state: {
                                                          leverageFlag: true,
                                                          constraintFlag: false,
                                                        },
                                                      }}
                                                    >
                                                      Add Leverage
                                                    </Link>
                                                  </DropdownItem> */}
                                                  <DropdownItem tag="a">
                                                    <Link
                                                      className="cus-link "
                                                      to={{
                                                        pathname:
                                                          PORTFOLIO_INPUTS_CREATE_ROUTE +
                                                          "/" +
                                                          portfolio_id,
                                                        state: {
                                                          leverageFlag: false,
                                                          constraintFlag: true,
                                                        },
                                                      }}
                                                    >
                                                      Modify Constraints
                                                    </Link>
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                              {permission != "arm" && permission != "rm" &&permission != "ic" && (
                                                <>                                                
                                                <Button
                                                  color=""
                                                  size="sm"
                                                  className="actionBtn text-left"
                                                 
                                                  onClick={()=>onClickDelete(portfolio_id)}
                                                >
                                                    <>
                                                      <FaTrash className="mr-2"></FaTrash>
                                                      <span>Delete</span>
                                                    </>
                                                </Button>
                                                </>
                                                          )}
                                            </>
                                          );
                                        }
                                      })()}
                                    </td>
                                  </tr>
                                  {versionid == portfolio_id && (
                                    <tr>
                                      <td colSpan="8" bgcolor="#f7f7f7">
                                        <table className="table">
                                          <thead>
                                            <th colSpan="8">Other Versions</th>
                                          </thead>
                                          <CustomTableHeader
                                            tableColumns={tablePortfolioColumns}
                                          />
                                          <tbody>
                                            {clientportfolioversion ? (
                                              clientportfolioversion.map(
                                                (version, index1) => {
                                                  console.log(
                                                    clientportfolioversion,
                                                    "tttttttttttttttttttttttttttttttttttt"
                                                  );
                                                  const {
                                                    portfolio_id,
                                                    portfolio_name,
                                                    portfolio_clientequity,
                                                    portfolio_currency,
                                                    portfolio_createdon,
                                                    portfolio_leverage,
                                                    portfolio_status,
                                                    portfolio_excel,
                                                    portfolio_error,
                                                    exe_status,
                                                    queue,
                                                  } = version;

                                                  console.log(portfolio_id,'portfolio_deleteportfolio_deleteportfolio_delete')
                                                  return (
                                                    <>
                                                      <Portfolioversion
                                                        setchildversionid={
                                                          setchildversionid
                                                        }
                                                        version={version}
                                                        id={portfolio_id}
                                                        index1={index1}
                                                        getPortfolioversion={
                                                          getPortfolioversion
                                                        }
                                                        getClientPortfolio={
                                                          getClientPortfolio
                                                        }
                                                        setversionid={
                                                          setversionid
                                                        }
                                                      ></Portfolioversion>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <CustomEmptyTableRecord
                                                columnsCount={
                                                  tablePortfolioColumns.length
                                                }
                                              />
                                            )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <CustomEmptyTableRecord
                              columnsCount={tablePortfolioColumns.length}
                            />
                          )}
                        </Fragment>
                      ) : (
                        <CustomTableLoader showloader={false} />
                      )}
                    </tbody>
                  </Table>
                </div>
              </Table>

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={currentpage + 1}
                  itemsCountPerPage={rowperpage}
                  totalItemsCount={totalrecords}
                  onChange={onPageChange}
                />
              </div>
            </Card>
          </Container>
        </div>
        {/* {selectClientModal && (
          <SelectClient
            selectClientModal={setSelectClientModal}
            selectClientToggle={selectClientToggle}
            client_id={selectedClient}
            portfolio_id={selectedPorfolioId}
            submitCallBack={submitCallBack}
          />
        )} */}
        {assignClientModal && (
          <AssignClient
            assignClientModal={setAssignClientModal}
            assignClientToggle={assignClientToggle}
            client_id={selectedClient}
            rm_id={selectedRm}
            submitCallBack={assignSubmitCallBack}
          />
        )}
        <Dropdown isOpen={dropdownOpen} toggle={toggleport} size="sm" color="">
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            variant="success"
          >
            <FaEdit></FaEdit>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a">
              <Link
                className="cus-link "
                to={{
                  pathname: PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + "2",
                  state: {
                    leverageFlag: true,
                    constraintFlag: false,
                  },
                }}
              >
                Add Leverage
              </Link>
            </DropdownItem>
            <DropdownItem tag="a">
              <Link
                className="cus-link "
                to={{
                  pathname: PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + "2",
                  state: {
                    leverageFlag: false,
                    constraintFlag: true,
                  },
                }}
              >
                Modify Constraints
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Fragment>

      <Modal isOpen={modal} toggle={toggles} size="xl">
        {console.log(modal, "&&&&&&&&&&&&&&&&&")}
        <div className="modalwidth">
          <ModalHeader toggle={toggles}>Error Detail</ModalHeader>
          <ModalBody className="bodywidth">
            <pre style={{ padding: `10px` }}>{portfolioerror}</pre>
          </ModalBody>
          <ModalFooter className="footerwidth">
            <Button color="secondary" onClick={toggles}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}
