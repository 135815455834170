import React, { useState } from "react";
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import {
  EXPORT_EXCEL_URL,
  PORTFOLIO_ACCEPT_URL,
  PORTFOLIO_GET,
} from "../common/urls";
import API from "../redux/API";
import { authHeader, getAlert, getAlertToast } from "../common/Mainfunctions";
import { UncontrolledCollapse } from "reactstrap";
import NumberFormat from "react-number-format";

import { useEffect } from "react";

export default function Constraints(props) {
  const { readchange, setreadchange, porfolioId } = props;

  const [portfoli, setPortfoli] = useState([]);
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);

  useEffect(() => {
    getPortfolio(porfolioId);
  }, [porfolioId]);

  useEffect(() => {
    getPortfolio(porfolioId);
  }, []);

  const getPortfolio = async () => {
    const config = {
      method: "GET",
      url: PORTFOLIO_GET + porfolioId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioLists(data.portfolio);
    } catch (error) {
      console.log(error);
      setClientPortfolioLists("");
    }
  };

  return (
    <div className="mt-4 pt-4 px-sm-4  pb-0">
      <h5 className="text_16_51 pl-3">Portfolio Summary</h5>
      <div className="cardconst pt-0">
        <div className="overflow_hight Con_hight pr-sm-5 table-responsive">
          <Row>
            <Col sm="6" className="">
              <div className="portfolio-details m-0">
                <h5>Asset Inputs</h5>
                {clientPortfolioLists.portfolio_name != 0 && (
                  <ul className="mt-3">
                    <li>Portfolio name</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_name}
                        {console.log(
                          clientPortfolioLists.portfolio_name,
                          "eeeeeeeeeeeeeeeeeeeeeeeeeeee"
                        )}
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_currency != 0 && (
                  <ul>
                    <li>Currency</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_currency}
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_clientequity != 0 && (
                  <ul>
                    <li>Client equity</li>
                    <li>
                      <span className="hu">
                        <NumberFormat
                          thousandSeparator={true}
                          value={clientPortfolioLists.portfolio_clientequity}
                          displayType={"text"}
                          prefix="$"
                        />
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_costofborrowing != null && (
                  <ul>
                    <li>Cost of Borrowing</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_costofborrowing} bps
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_coreunit != 0 && (
                  <ul>
                    <li>Core</li>
                    <li>
                      <span className="hu">
                        <NumberFormat
                          thousandSeparator={true}
                          value={clientPortfolioLists.portfolio_core_dollar}
                          displayType={"text"}
                          prefix="$"
                        />
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_leverage != null && (
                  <ul>
                    <li>Leverage</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_leverage}
                      </span>
                    </li>
                  </ul>
                )}

                <ul>
                  <li>Shariah Compliant</li>
                  <li>
                    <span className="hu">
                      {clientPortfolioLists.portfolio_shariahcomplaint
                        ? "Yes"
                        : "No"}
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="6" className="">
              <div className="portfolio-details m-0">
                <h5>Portfolio Optimisation Inputs</h5>

                {clientPortfolioLists.portfolio_expect_annualreturn != 0 && (
                  <ul>
                    <li>Expected annual return </li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_expect_annualreturn}%
                      </span>
                    </li>
                  </ul>
                )}
                {clientPortfolioLists.portfolio_expect_maxdrawdown != 0 && (
                  <ul>
                    <li> Min Expected Drawdown</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_expect_maxdrawdown}%
                      </span>
                    </li>
                  </ul>
                )}
                {/* {clientPortfolioLists.portfolio_cashflow_yield != 0 && (
                  <ul>
                    <li>Cash flow Yield </li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_cashflow_yield}%
                      </span>
                    </li>
                  </ul>
                )} */}
                {clientPortfolioLists.portfolio_liquidity != 0 && (
                  <ul>
                    <li>Liquidity (Monthly)</li>
                    <li>
                      <span className="hu">
                        {clientPortfolioLists.portfolio_liquidity}%
                        {console.log(
                          clientPortfolioLists.portfolio_liquidity,
                          "44444444444444444"
                        )}
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </Col>
           
                {(clientPortfolioLists.portfolio_cash_status == 1 ||
                  clientPortfolioLists.portfolio_fixedincome_status == 1 ||
                  clientPortfolioLists.portfolio_equities_status == 1 ||
                  clientPortfolioLists.portfolio_alternative_status == 1 ||
                  clientPortfolioLists.portfolio_asia_status == 1 ||
                  clientPortfolioLists.portfolio_americas_status == 1 ||
                  clientPortfolioLists.portfolio_ec_uk_status == 1 ||
                  clientPortfolioLists.portfolio_emerging_markets_status == 1 ||
                  clientPortfolioLists.portfolio_global1_status == 1 ||
                  clientPortfolioLists.portfolio_global2_status == 1 ||
                  clientPortfolioLists.portfolio_mena_status == 1 ||
                  clientPortfolioLists.portfolio_exposure_status != 0 ||
                  clientPortfolioLists.portfolio_exposure2_status != 0 ||
                  clientPortfolioLists.portfolio_credit_status != 0 ||
                  clientPortfolioLists.portfolio_financialscoco_status != 0 ||
                  clientPortfolioLists.portfolio_credit_status != 0 ||
                  clientPortfolioLists.portfolio_viceindustries_status != 0 ||
                  clientPortfolioLists.portfolio_realestate_status != 0 ||
                  clientPortfolioLists.portfolio_it_status != 0 ||
                  clientPortfolioLists.portfolio_healthcare_status != 0 ||
                  clientPortfolioLists.portfolio_finland_status != 0 ||
                  clientPortfolioLists.portfolio_comservice_status != 0 ||
                  clientPortfolioLists.portfolio_discretionary_status != 0 ||
                  clientPortfolioLists.portfolio_industrials_status != 0 ||
                  clientPortfolioLists.portfolio_staples_status != 0 ||
                  clientPortfolioLists.portfolio_energy_status != 0 ||
                  clientPortfolioLists.portfolio_utilities_status != 0 ||
                  clientPortfolioLists.portfolio_materials_status != 0 ||
                  clientPortfolioLists.portfolio_usd_status != 0 ||
                  clientPortfolioLists.portfolio_eur_status != 0 ||
                  clientPortfolioLists.portfolio_gbp_status != 0 ||
                  clientPortfolioLists.portfolio_jpy_status != 0 ||
                  clientPortfolioLists.portfolio_aud_status != 0 ||
                  clientPortfolioLists.portfolio_nzd_status != 0 ||
                  clientPortfolioLists.portfolio_cad_status != 0 ||
                  clientPortfolioLists.portfolio_chf_status != 0 ||
                  clientPortfolioLists.portfolio_nok_status != 0 ||
                  clientPortfolioLists.portfolio_sek_status != 0) && (
                    <Col sm="12" className="portfolio-details">
                    <div className="padingconst">
                  <h5>Portfolio Advanced Constraints</h5>
                

                {(clientPortfolioLists.portfolio_cash_status == 1 ||
                  clientPortfolioLists.portfolio_fixedincome_status == 1 ||
                  clientPortfolioLists.portfolio_equities_status == 1 ||
                  clientPortfolioLists.portfolio_alternative_status == 1) && (
                  <ul className="multi-sublist">
                    <li>Asset Level</li>
                    <li>
                      {clientPortfolioLists.portfolio_cash_status == 1 && (
                        <span className="hu">
                          Cash (
                          {clientPortfolioLists.portfolio_cash_min
                            ? clientPortfolioLists.portfolio_cash_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_cash_max
                            ? clientPortfolioLists.portfolio_cash_max
                            : 0}
                          % )
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_fixedincome_status ==
                        1 && (
                        <span className="hu">
                          Fixed Income (
                          {clientPortfolioLists.portfolio_fixedincome_min
                            ? clientPortfolioLists.portfolio_fixedincome_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_fixedincome_max
                            ? clientPortfolioLists.portfolio_fixedincome_max
                            : 0}
                          % ) <br></br>
                          Fixed Income Direct holdingss(
                          {clientPortfolioLists.portfolio_fixedincome_directholding_min
                            ? clientPortfolioLists.portfolio_fixedincome_directholding_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_fixedincome_directholding_max
                            ? clientPortfolioLists.portfolio_fixedincome_directholding_max
                            : 0}
                          % )
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_equities_status == 1 && (
                        <span className="hu">
                          Equities (
                          {clientPortfolioLists.portfolio_equities_min
                            ? clientPortfolioLists.portfolio_equities_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_equities_max
                            ? clientPortfolioLists.portfolio_equities_max
                            : 0}
                          % ) <br></br>
                          Equities Direct holdings(
                          {clientPortfolioLists.portfolio_equities_directholding_min
                            ? clientPortfolioLists.portfolio_equities_directholding_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_equities_directholding_max
                            ? clientPortfolioLists.portfolio_equities_directholding_max
                            : 0}
                          % )
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_alternative_status ==
                        1 && (
                        <span className="hu">
                          Alternatives (
                          {clientPortfolioLists.portfolio_alternative_min
                            ? clientPortfolioLists.portfolio_alternative_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_alternative_max
                            ? clientPortfolioLists.portfolio_alternative_max
                            : 0}
                          % ) <br></br>
                          Alternatives Direct holdings(
                          {clientPortfolioLists.portfolio_alternative_directholding_min
                            ? clientPortfolioLists.portfolio_alternative_directholding_min
                            : 0}
                          % -{" "}
                          {clientPortfolioLists.portfolio_alternative_directholding_max
                            ? clientPortfolioLists.portfolio_alternative_directholding_max
                            : 0}
                          % )
                        </span>
                      )}
                    </li>
                  </ul>
                )}

                {(clientPortfolioLists.portfolio_asia_status == 1 ||
                  clientPortfolioLists.portfolio_americas_status == 1 ||
                  clientPortfolioLists.portfolio_ec_uk_status == 1 ||
                  clientPortfolioLists.portfolio_emerging_markets_status == 1 ||
                  clientPortfolioLists.portfolio_global1_status == 1 ||
                  clientPortfolioLists.portfolio_global2_status == 1 ||
                  clientPortfolioLists.portfolio_mena_status == 1) && (
                  <ul className="multi-sublist">
                    <li>Regional</li>
                    <li>
                      {clientPortfolioLists.portfolio_asia_status == 1 && (
                        <>
                          <span>
                            Asia Pacific(
                            {clientPortfolioLists.portfolio_asia_min
                              ? clientPortfolioLists.portfolio_asia_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_asia_max
                              ? clientPortfolioLists.portfolio_asia_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_americas_status == 1 && (
                        <>
                          <span>
                            Americas(
                            {clientPortfolioLists.portfolio_americas_min
                              ? clientPortfolioLists.portfolio_americas_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_americas_max
                              ? clientPortfolioLists.portfolio_americas_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_ec_uk_status == 1 && (
                        <>
                          <span>
                            E.U & U.K(
                            {clientPortfolioLists.portfolio_ec_uk_min
                              ? clientPortfolioLists.portfolio_ec_uk_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_ec_uk_max
                              ? clientPortfolioLists.portfolio_ec_uk_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_emerging_markets_status ==
                        1 && (
                        <>
                          <span>
                            Emerging Markets(
                            {clientPortfolioLists.portfolio_emerging_markets_min
                              ? clientPortfolioLists.portfolio_emerging_markets_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_emerging_markets_max
                              ? clientPortfolioLists.portfolio_emerging_markets_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_global1_status == 1 && (
                        <>
                          <span>
                            Global(
                            {clientPortfolioLists.portfolio_global1_min
                              ? clientPortfolioLists.portfolio_global1_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_global1_max
                              ? clientPortfolioLists.portfolio_global1_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_mena_status == 1 && (
                        <>
                          <span>
                            MENA(
                            {clientPortfolioLists.portfolio_mena_min
                              ? clientPortfolioLists.portfolio_mena_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_mena_max
                              ? clientPortfolioLists.portfolio_mena_max
                              : 0}
                            % )
                          </span>
                        </>
                      )}
                    </li>
                  </ul>
                )}

                {(clientPortfolioLists.portfolio_exposure_status != 0 ||
                  clientPortfolioLists.portfolio_exposure2_status != 0 ||
                  clientPortfolioLists.portfolio_credit_status != 0 ||
                  clientPortfolioLists.portfolio_financialscoco_status != 0 ||
                  clientPortfolioLists.portfolio_credit_status != 0) && (
                  <ul className="multi-sublist">
                    <li>Rating Constraints</li>
                    <li>
                      {" "}
                      {clientPortfolioLists.portfolio_exposure_status != 0 && (
                        <>
                          <span>
                            IG (
                            {clientPortfolioLists.portfolio_exposure_min
                              ? clientPortfolioLists.portfolio_exposure_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_exposure_max
                              ? clientPortfolioLists.portfolio_exposure_max
                              : 0}
                            %)
                          </span>
                        </>
                      )}{" "}
                    </li>
                    <li>
                      {clientPortfolioLists.portfolio_exposure2_status != 0 && (
                        <>
                          <span>
                            HY (
                            {clientPortfolioLists.portfolio_exposure2_min
                              ? clientPortfolioLists.portfolio_exposure2_min
                              : 0}
                            %-
                            {clientPortfolioLists.portfolio_exposure2_max
                              ? clientPortfolioLists.portfolio_exposure2_max
                              : 0}
                            %)
                          </span>
                        </>
                      )}
                    </li>

                    <li>
                      {/* {clientPortfolioLists.lc_exposure_name != "" && (
                <span>Exposure - {clientPortfolioLists.lc_exposure_name}</span>
              )} */}
                      {clientPortfolioLists.portfolio_financialscoco_status !=
                        0 && (
                        <span>
                          Ex to Financials (
                          {clientPortfolioLists.portfolio_financialscoco_min
                            ? clientPortfolioLists.portfolio_financialscoco_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_financialscoco_max
                            ? clientPortfolioLists.portfolio_financialscoco_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_credit_status != 0 && (
                        <span>
                          Ex to Structured (
                          {clientPortfolioLists.portfolio_credit_min
                            ? clientPortfolioLists.portfolio_credit_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_credit_max
                            ? clientPortfolioLists.portfolio_credit_max
                            : 0}
                          %)
                        </span>
                      )}
                    </li>
                  </ul>
                )}

                {(clientPortfolioLists.portfolio_viceindustries_status != 0 ||
                  clientPortfolioLists.portfolio_realestate_status != 0 ||
                  clientPortfolioLists.portfolio_it_status != 0 ||
                  clientPortfolioLists.portfolio_healthcare_status != 0 ||
                  clientPortfolioLists.portfolio_finland_status != 0 ||
                  clientPortfolioLists.portfolio_comservice_status != 0 ||
                  clientPortfolioLists.portfolio_discretionary_status != 0 ||
                  clientPortfolioLists.portfolio_industrials_status != 0 ||
                  clientPortfolioLists.portfolio_staples_status != 0 ||
                  clientPortfolioLists.portfolio_energy_status != 0 ||
                  clientPortfolioLists.portfolio_utilities_status != 0 ||
                  clientPortfolioLists.portfolio_materials_status != 0) && (
                  <ul className="multi-sublist">
                    <li>Sector Constraints</li>
                    <li>
                      {clientPortfolioLists.portfolio_viceindustries_status !=
                        0 && (
                        <span className="hu">
                          Ex to Vice Industries (
                          {clientPortfolioLists.portfolio_viceindustries_min
                            ? clientPortfolioLists.portfolio_viceindustries_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_viceindustries_max
                            ? clientPortfolioLists.portfolio_viceindustries_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_realestate_status !=
                        0 && (
                        <span className="hu">
                          Ex to REITs / Real ..(
                          {clientPortfolioLists.portfolio_realestate_min
                            ? clientPortfolioLists.portfolio_realestate_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_realestate_max
                            ? clientPortfolioLists.portfolio_realestate_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_it_status != 0 && (
                        <span className="hu">
                          Ex Information T...(
                          {clientPortfolioLists.portfolio_it_min
                            ? clientPortfolioLists.portfolio_it_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_it_max
                            ? clientPortfolioLists.portfolio_it_max
                            : 0}
                          %)
                        </span>
                      )}
                      {/* <a id="more2">+9 more</a>*/}
                      {/* <UncontrolledCollapse toggler="#more2">*/}
                      {clientPortfolioLists.portfolio_healthcare_status !=
                        0 && (
                        <span className="hu">
                          Exposure to Health Care(
                          {clientPortfolioLists.portfolio_healthcare_min
                            ? clientPortfolioLists.portfolio_healthcare_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_healthcare_max
                            ? clientPortfolioLists.portfolio_healthcare_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_finland_status != 0 && (
                        <span className="hu">
                          Exposure to Financials (
                          {clientPortfolioLists.portfolio_financials_min
                            ? clientPortfolioLists.portfolio_financials_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_financials_max
                            ? clientPortfolioLists.portfolio_financials_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_comservice_status !=
                        0 && (
                        <span className="hu">
                          Exposure to Communication Services (
                          {clientPortfolioLists.portfolio_comservice_min
                            ? clientPortfolioLists.portfolio_comservice_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_comservice_max
                            ? clientPortfolioLists.portfolio_comservice_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_discretionary_status !=
                        0 && (
                        <span className="hu">
                          Exposure to Consumer Discretionary (
                          {clientPortfolioLists.portfolio_discretionary_min
                            ? clientPortfolioLists.portfolio_discretionary_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_discretionary_max
                            ? clientPortfolioLists.portfolio_discretionary_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_industrials_status !=
                        0 && (
                        <span className="hu">
                          Exposure to Industrials (
                          {clientPortfolioLists.portfolio_industrials_min
                            ? clientPortfolioLists.portfolio_industrials_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_industrials_max
                            ? clientPortfolioLists.portfolio_industrials_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_staples_status != 0 && (
                        <span className="hu">
                          Exposure to Consumer Staples (
                          {clientPortfolioLists.portfolio_staples_min
                            ? clientPortfolioLists.portfolio_staples_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_staples_max
                            ? clientPortfolioLists.portfolio_staples_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_energy_status != 0 && (
                        <span className="hu">
                          Exposure to Consumer Energy(
                          {clientPortfolioLists.portfolio_energy_min
                            ? clientPortfolioLists.portfolio_energy_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_energy_max
                            ? clientPortfolioLists.portfolio_energy_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_utilities_status != 0 && (
                        <span className="hu">
                          Exposure to Consumer Utilities (
                          {clientPortfolioLists.portfolio_utilities_min
                            ? clientPortfolioLists.portfolio_utilities_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_utilities_max
                            ? clientPortfolioLists.portfolio_utilities_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_materials_status != 0 && (
                        <span className="hu">
                          Exposure to Consumer Materials (
                          {clientPortfolioLists.portfolio_materials_min
                            ? clientPortfolioLists.portfolio_materials_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_materials_max
                            ? clientPortfolioLists.portfolio_materials_max
                            : 0}
                          %)
                        </span>
                      )}
                      {/*</UncontrolledCollapse>*/}
                    </li>
                  </ul>
                )}

                {(clientPortfolioLists.portfolio_usd_status != 0 ||
                  clientPortfolioLists.portfolio_eur_status != 0 ||
                  clientPortfolioLists.portfolio_gbp_status != 0 ||
                  clientPortfolioLists.portfolio_jpy_status != 0 ||
                  clientPortfolioLists.portfolio_aud_status != 0 ||
                  clientPortfolioLists.portfolio_nzd_status != 0 ||
                  clientPortfolioLists.portfolio_cad_status != 0 ||
                  clientPortfolioLists.portfolio_chf_status != 0 ||
                  clientPortfolioLists.portfolio_nok_status != 0 ||
                  clientPortfolioLists.portfolio_sek_status != 0) && (
                  <ul className="multi-sublist">
                    <li>Currency Constraints</li>
                    <li>
                      {" "}
                      {clientPortfolioLists.portfolio_usd_status != 0 && (
                        <span className="hu">
                          USD (
                          {clientPortfolioLists.portfolio_usd_min
                            ? clientPortfolioLists.portfolio_usd_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_usd_max
                            ? clientPortfolioLists.portfolio_usd_max
                            : 0}
                          %)
                        </span>
                      )}
                      {/* {clientPortfolioLists.portfolio_eur_status != 0 && (
                        <span className="hu">
                          EUR(
                          {clientPortfolioLists.portfolio_eur_min
                            ? clientPortfolioLists.portfolio_eur_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_eur_max
                            ? clientPortfolioLists.portfolio_eur_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_gbp_status != 0 && (
                        <span className="hu">
                          GBP(
                          {clientPortfolioLists.portfolio_gbp_min
                            ? clientPortfolioLists.portfolio_gbp_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_gbp_max
                            ? clientPortfolioLists.portfolio_gbp_max
                            : 0}
                          %)
                        </span>
                      )}
                      {/*  <a id="more3">+7 more</a>
                      {/* <UncontrolledCollapse toggler="#more3">
                      {clientPortfolioLists.portfolio_jpy_status != 0 && (
                        <span className="hu">
                          JPY (
                          {clientPortfolioLists.portfolio_jpy_min
                            ? clientPortfolioLists.portfolio_jpy_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_jpy_max
                            ? clientPortfolioLists.portfolio_jpy_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_aud_status != 0 && (
                        <span className="hu">
                          AUD (
                          {clientPortfolioLists.portfolio_aud_min
                            ? clientPortfolioLists.portfolio_aud_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_aud_max
                            ? clientPortfolioLists.portfolio_aud_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_nzd_status != 0 && (
                        <span className="hu">
                          NZD (
                          {clientPortfolioLists.portfolio_nzd_min
                            ? clientPortfolioLists.portfolio_nzd_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_nzd_max
                            ? clientPortfolioLists.portfolio_nzd_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_cad_status != 0 && (
                        <span className="hu">
                          CAD (
                          {clientPortfolioLists.portfolio_cad_min
                            ? clientPortfolioLists.portfolio_cad_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_cad_max
                            ? clientPortfolioLists.portfolio_cad_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_chf_status != 0 && (
                        <span className="hu">
                          CHF (
                          {clientPortfolioLists.portfolio_chf_min
                            ? clientPortfolioLists.portfolio_chf_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_chf_max
                            ? clientPortfolioLists.portfolio_chf_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_nok_status != 0 && (
                        <span className="hu">
                          NOK (
                          {clientPortfolioLists.portfolio_nok_min
                            ? clientPortfolioLists.portfolio_nok_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_nok_max
                            ? clientPortfolioLists.portfolio_nok_max
                            : 0}
                          %)
                        </span>
                      )}
                      {clientPortfolioLists.portfolio_sek_status != 0 && (
                        <span className="hu">
                          SEK (
                          {clientPortfolioLists.portfolio_sek_min
                            ? clientPortfolioLists.portfolio_sek_min
                            : 0}
                          %-
                          {clientPortfolioLists.portfolio_sek_max
                            ? clientPortfolioLists.portfolio_sek_max
                            : 0}
                          %)
                        </span>
                      )} */}
                      {/*</UncontrolledCollapse>*/}
                    </li>
                  </ul>
                )}
              </div>
            </Col>)}
          </Row>
        </div>
      </div>
    </div>
  );
}
