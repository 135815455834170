import React, { useState, Fragment, useEffect, useFormik } from "react";
import Sidebar from "../layout/Sidebar";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Button,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  UncontrolledDropdown,
  Dropdown,
} from "reactstrap";
import CustomTableHeader from "../common/CustomTableHeader";
import moment from "moment";
import {
  ASSET_MANAGEMENT_URL,
  CREATE_INDEX_URL,
  ASSET_DELETE_URL,
  CREATE_GROUP,
  BOND_INDEX,
  BOND_DELETE_PATH,
} from "../common/urls";
import { AiOutlinePlusCircle, AiOutlineBell } from "react-icons/ai";
import NumberFormat from "react-number-format";
import CustomLoader from "../common/CustomLoader";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Form,
  Label,
  Input,
} from "reactstrap";
import {
  authHeader,
  getAlertToast,
  numToPercentage,
} from "../common/Mainfunctions";
import API from "../redux/API";
import { FormGroup } from "@material-ui/core";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import Pagination from "react-js-pagination";
import AddNewAsset from "./AddNewAsset";
import BondBulkUpload from "./BondBulkUpload";
import { FaEye, FaTrash } from "react-icons/fa";
import swal from "sweetalert2";
import Swal from "sweetalert2";
import ViewAsset from "./ViewAsset";
import { getAlert } from "../common/Mainfunctions";

export default function CreateIndex(props) {
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [bondList, setBondList] = useState([]);
  const [bondListvalue, setBondListvalue] = useState(0);

  const [loading, setLoading] = useState(true);
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [selectAsset, setSelectedAsset] = useState([]);
  const addToggle = () => setAddModal(!addModal);
  const bulkUploadToggle = () => setBulkUploadModal(!bulkUploadModal);
  const viewToggle = () => setViewModal(!viewModal);
  const [modals, setmodals] = useState(false);
  const [modal, setmodal] = useState(false);
  const [isEventSelected] = useState();
  const { toggle, successCallBack } = props;
  const [currentBondIndex, setCurrentBondIndex] = useState(0);
  const [indexName, setIndexName] = useState("");
  const [bond_index] = useState("");
  const [indexMaxWeight, setIndexMaxWeight] = useState(0);
  const [indexexpectedreturns, setIndexExpectedReturns] = useState("");

  const addSuccessCallBack = () => {
    addToggle();

    //getAssetManagementList();
  };

  const toggles = () => {
    setmodals(!modals);
  };

  const [selectedfund, setselectedfund] = useState([]);

  const bulkUploadSuccessCallBack = () => {
    bulkUploadToggle();
    //getAssetManagementList();
  };
  const viewSuccessCallBack = () => {
    viewToggle();
    //getAssetManagementList();
  };

  const tableColumns = [
    { title: "Name of Bond " },
    { title: "ISIN" },
    { title: "Currency " },
    // { title: "Allocation ($)" },
    // { title: "Allocation (%)" },
    { title: "Bond Rank" },
    { title: "Current Price" },
    { title: "Coupon" },
    { title: "YTW" },
    { title: "Modified Duration" },
    { title: "Call Date of Bonds" },
    { title: "Rating " },
    { title: "Geography " },
    { title: "Sector " },
    { title: "Action " },
    // { title: "Notice Period" },
    // { title: "Lock Up Period (Months)" },
    // { title: "Redemption Frequency" },
    // { title: "Expected Return" },
    // { title: "Max Weightage" },
    // { title: "Cash Flow Yield" },
    // { title: "Asset Industry Sector" },
    // { title: "Asset Upside  Potential" },
    // { title: "Last Refreshed" },
    // { title: "Actions" },
  ];

  const getBondManagementList = async () => {
    const config = {
      method: "GET",
      url: BOND_INDEX,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setBondList(data);
      setBondListvalue(response.data.lastdate);
      console.log(response.data.lastdate, "kkkkkkkkkkkkkkkkkkk");

      setTotalRecords(totalrecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAssetManagementList = async () => {
    const config = {
      method: "GET",
      url: ASSET_MANAGEMENT_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setAssetList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBondManagementList();
    //getAssetManagementList();
  }, [currentpage]);

  const getAssetBondList = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      method: "POST",
      url: CREATE_GROUP,
      headers: authHeader(),
      data: {
        bondindex_name: indexName,
        bondindex_asset: selectedfund,
        bondindex_maxweightage: indexMaxWeight,
        bondindex_expectedreturns: indexexpectedreturns,
      },
    };

    try {
      const response = await API(config);
      const { status } = response.data;

      if (status === "Success") {
        Swal.fire(getAlert("success", "Asset added Successfully"));
        //successCallBack();
      }
    } catch (error) {
      console.log(error, "rrrrrrrr");
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page - 1);
  };
  const handleSelectEvent = (selectedid) => {
    console.log(selectedfund, selectedid, "lllllllllll");
    if (!selectedfund.includes(selectedid)) {
      setselectedfund((prevSelected) => [...prevSelected, selectedid]);
    } else {
      setselectedfund((prevSelected) =>
        prevSelected.filter((id) => id !== selectedid)
      );
    }
  };
  const deleteAsset = async (asset_id) => {
    const config = {
      method: "GET",
      url: BOND_DELETE_PATH + "/" + asset_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "success") {
        getBondManagementList();

        Swal.fire(getAlertToast("Success", "Asset deleted Successfully"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const viewRecord = (event) => {
    let id = parseInt(event.currentTarget.dataset.id);
    setAssetId(id);
    viewToggle();
    let filteredOption = assetList.find((element) => element.id === id);

    setSelectedAsset(filteredOption);
  };

  const onClickDelete = (e) => {
    e.stopPropagation();
    let asset_id = parseInt(e.currentTarget.dataset.id);
    swal
      .fire({
        title: "Are you sure?",
        text: "Do you want to delete this asset?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          deleteAsset(asset_id);
        }
      });
  };

  return (
    <div className="bg-white">
      <div className="sidebarCont bg_26">
        <Sidebar />
      </div>
      <div className="contentWrapper">
        <div className="bg_f2 pt-3 pb-3 pl-3 pr-3">
          <Row>
            <Col sm={6}>
              <div className="btn-right"></div>
            </Col>
            <Col sm={6} className="text-right">
              <UncontrolledDropdown
                setActiveFromChild
                className="d-inline-block"
              >
                <DropdownToggle tag="a" className="nav-link color_ac" caret>
                  <img
                    className="avatar"
                    src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  />
                  <span>Solstium</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem> Logout </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
        <Container fluid className="">
          <Row>
            <Col sm={12}></Col>
            <Col sm={12}></Col>
          </Row>
          <Card className="min-screenheight st_card">
            <CardTitle className="p-0 mt-4 flex-container">
              <span className="list_cli">Bond Management</span>

              <span className="pl-5">
                Last Updated On : {moment(bondListvalue).format("YYYY/MM/DD")}
              </span>

              <div className="btn-right odr_1">
                {/* <Button onClick={toggles} color="primary" className="mt-0">
                  Create Index
                </Button> */}

                <Button
                  onClick={bulkUploadToggle}
                  color="primary"
                  className="mt-0"
                >
                  Bulk Upload
                </Button>

                {/* <Button onClick={addToggle} color="primary" className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.867"
                    height="16.867"
                    viewBox="0 0 16.867 16.867"
                  >
                    <g
                      id="Icon_feather-plus"
                      data-name="Icon feather-plus"
                      transform="translate(1.5 1.5)"
                    >
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M18,7.5V21.367"
                        transform="translate(-11.067 -7.5)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M7.5,18H21.367"
                        transform="translate(-7.5 -11.067)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                  Add New Assets
                </Button> */}
              </div>
            </CardTitle>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th> Name</th>
                  {/* <th>Max Weightage</th>
                  <th>Expected Returns</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {bondList.length > 0
                  ? bondList.map((record, index) => {
                      console.log(bondList, "ggggggggggggggggggggggggggg");
                      return (
                        <>
                          <tr>
                            <td>{rowperpage * currentpage + (index + 1)}</td>
                            <td>{record.bond_index}</td>
                            {/* <td>{record.bi_maxweightage}</td>
                            <td>{record.bi_asset_expected_returns}</td> */}
                            <td>
                              <Button
                                //data-id={id}
                                color=""
                                size="sm"
                                className="actionBtn"
                                onClick={() =>
                                  setCurrentBondIndex(
                                    currentBondIndex == record.bond_index
                                      ? 0
                                      : record.bond_index
                                  )
                                }
                              >
                                <FaEye></FaEye>
                              </Button>
                            </td>
                          </tr>
                          {currentBondIndex == record.bond_index && (
                            <tr>
                              <td colSpan={5}>
                                <Table hover responsive className="">
                                  <CustomTableHeader
                                    tableColumns={tableColumns}
                                  />
                                  <tbody>
                                    {!loading ? (
                                      <Fragment>
                                        {record.bond_list.length > 0 ? (
                                          record.bond_list.map(
                                            (bondSet, index) => {
                                              console.log(
                                                record.bond_list,
                                                "2323232323232323232323232"
                                              );
                                              //const isEventSelected = selectedfund.includes(record.bond_id);
                                              const {
                                                bond_id,
                                                bond_name,
                                                bond_isin,
                                                bond_currency,
                                                bond_rank,
                                                asset_inceptiondate,
                                                bond_ask_price,
                                                bond_coupon,
                                                bond_rating_classification,
                                                bond_ytw,
                                                bond_sector,
                                                bond_duration,
                                                bond_maturity_date,
                                                bond_country,
                                              } = bondSet;
                                              return (
                                                <tr key={index}>
                                                  <td>{bond_name}</td>
                                                  <td>{bond_isin}</td>
                                                  <td>{bond_currency}</td>
                                                  <td>{bond_rank} </td>
                                                  <td>{parseFloat(bond_ask_price).toFixed(2)}</td>
                                                  <td>{parseFloat(bond_coupon).toFixed(2)}</td>
                                                  <td>{parseFloat(bond_ytw).toFixed(2)} </td>
                                                  <td> {parseFloat(bond_duration).toFixed(2)} </td>
                                                  <td>
                                                    {" "}
                                                    {bond_maturity_date !=
                                                    "0000-00-00" ? (
                                                      <td>
                                                        {bond_maturity_date}{" "}
                                                      </td>
                                                    ) : (
                                                      " "
                                                    )}{" "}
                                                  </td>
                                                  <td>
                                                    {bond_rating_classification}
                                                  </td>
                                                  <td>{bond_country}</td>
                                                  <td>{bond_sector}</td>
                                                  {/* <td>{asset_noticeperiod}</td>
                                                  <td>{asset_lockupperiod}</td>
                                                  <td>
                                                    {asset_redemptionperiod}
                                                  </td>
                                                  <td>
                                                    {numToPercentage(
                                                      asset_expected_returns
                                                    )}
                                                  </td>
                                                  <td>
                                                    {numToPercentage(
                                                      max_weightage
                                                    )}
                                                  </td>
                                                  <td>
                                                    {numToPercentage(
                                                      cash_flow_yield
                                                    )}
                                                  </td>
                                                  <td>
                                                    {asset_industry_sector}
                                                  </td>
                                                  <td>
                                                    {numToPercentage(
                                                      asset_upside_potential
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      whiteSpace: "pre",
                                                    }}
                                                  >
                                                    {asset_createdon}
                                                  </td>
                                                  <td
                                                    style={{ display: "flex" }}
                                                  >
                                                    <Button
                                                      data-id={id}
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                      onClick={viewRecord}
                                                    >
                                                      <FaEye></FaEye>
                                                    </Button>*/}

                                                  <td>
                                                    {" "}
                                                    <Button
                                                      data-id={bond_id}
                                                      color=""
                                                      size="sm"
                                                      className="actionBtn"
                                                      onClick={onClickDelete}
                                                    >
                                                      <FaTrash></FaTrash>
                                                    </Button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <CustomEmptyTableRecord
                                            columnsCount={tableColumns.length}
                                          />
                                        )}
                                      </Fragment>
                                    ) : (
                                      <CustomTableLoader
                                        columnsCount={tableColumns.length}
                                      />
                                    )}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
            <div className="d-flex flex-row py-4 justify-content-end">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={currentpage + 1}
                itemsCountPerPage={rowperpage}
                totalItemsCount={totalrecords}
                onChange={onPageChange}
              />
            </div>
          </Card>

          <Modal isOpen={modals} toggle={toggles} className="">
            <Form form="true" onSubmit={getAssetBondList}>
              <ModalHeader toggle={toggles}> Create Index</ModalHeader>
              <ModalBody className="p-3">
                <FormGroup>
                  <Label for="asset_bondname">Bond Name</Label>
                  <Input
                    type="text"
                    name="asset_bondname"
                    id="asset_bondname"
                    placeholder="Enter Bond Name"
                    onChange={(e) => setIndexName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="asset_maxWeightage">Max Weightage</Label>
                  <Input
                    type="text"
                    name="asset_maxWeightage"
                    id="asset_maxWeightage"
                    placeholder="Enter Max Weightage"
                    onChange={(e) => setIndexMaxWeight(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="asset_expectedreturns">Expected Returns</Label>
                  <Input
                    type="text"
                    name="asset_expectedreturns"
                    id="asset_expectedreturns"
                    placeholder="Enter Expected Returns"
                    onChange={(e) => setIndexExpectedReturns(e.target.value)}
                  />
                </FormGroup>
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ marginRight: "20px" }}>S.No</th>

                      <th style={{ textAlign: "left", width: "300px" }}>
                        Fund Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      <Fragment>
                        {assetList.length > 0 ? (
                          assetList.map((record, index) => {
                            const { id, asset_fundname } = record;
                            return (
                              <tr key={index + 1}>
                                <td
                                  style={{
                                    minWidth: "100px",
                                    maxWidth: "100px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id="html"
                                    checked={isEventSelected}
                                    onChange={() =>
                                      handleSelectEvent(record.id)
                                    }
                                  />
                                </td>
                                <td>
                                  {rowperpage * currentpage + (index + 1)}
                                </td>

                                <td>{asset_fundname}</td>

                                <td style={{ display: "flex" }}></td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggles}>
                  Cancel
                </Button>
                <Button color="primary">
                  {/* {loading && <CustomLoader loading={loading ? true : false} />} */}
                  Create
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
        {/* {addModal && (
          <AddNewAsset
            modal={addModal}
            toggle={addToggle}
            successCallBack={addSuccessCallBack}
          />
        )} */}
      </div>
      {bulkUploadModal && (
        <BondBulkUpload
          modal={bulkUploadModal}
          toggle={bulkUploadToggle}
          successCallBack={bulkUploadSuccessCallBack}
        />
      )}
      {viewModal && (
        <ViewAsset
          modal={viewModal}
          toggle={viewToggle}
          successCallBack={viewSuccessCallBack}
          id={assetId}
          selectAsset={selectAsset}
        />
      )}
    </div>
  );
}
