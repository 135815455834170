import React, { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
  Collapse,
  FormFeedback,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { UncontrolledTooltip } from "reactstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
const ProductOptions = [
  {
    name: "exposure",
    label: "IG",
    value: 1,
    min: 0,
    max: 100,
  },
  {
    name: "exposure2",
    label: "HY",
    value: 2,
    min: 0,
    max: 100,
  },
];
export default function RatingAndProductConstraints(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [selectedOption, setSelectedOption] = useState("");
  let statusVariable;
  const {
    formValues = {},
    handleChange,
    exposureList = [],
    handleBlur,
    expoValue = [],
    setExpoValue,
    touched,
    errors,
    Flag,
    setFieldValue,
    constraintsFlag,
  } = props;

  let {
    portfolio_id,
    portfolio_exposure_IG = false,
    portfolio_exposure_HY = false,
    portfolio_exposure_status = true,
    portfolio_exposure_value,
    portfolio_exposure_min,
    portfolio_exposure_max,
    portfolio_exposure2_status = true,
    portfolio_exposure2_value,
    portfolio_exposure2_min,
    portfolio_exposure2_max,
    portfolio_financialscoco_status = false,
    portfolio_financialscoco_min,
    portfolio_financialscoco_max,
    portfolio_credit_status = false,
    portfolio_credit_min,
    portfolio_credit_max,
  } = formValues;

  const handleMultiSelectChange = async (selectedOptions, e) => {
    let { action, option = {}, target = { name: "", value: "" } } = e;
    let { name } = option;
    
    e.target = { name: "", value: "" };
    if (action == "select-option") {
      e.target.name = `portfolio_${name}_status`;
      e.target.value = true;
      await setFieldValue(`portfolio_${name}_min`, 0);
      await setFieldValue(`portfolio_${name}_max`, 100);
      handleChange(e);
    } else {
      await setFieldValue(`portfolio_${name}_status`, false);
      await setFieldValue(`portfolio_${name}_min`, 0);
      await setFieldValue(`portfolio_${name}_max`, 0);

      console.log(formValues, "formValues");

      target.name = `portfolio_${name}_status`;
      target.value = false;
      handleChange(e);
      //console.log(target.value,'kkkkkkkkkkkkkkkkkkkkkk')
    }
    setSelectedOption(selectedOptions);
  };
  const handleChangeFun = (e) => {
    const id = e.currentTarget.dataset.exposure_id;

    let remove1 = selectedOption.filter(
      (record) => record.value !== parseInt(id)
    );

    let removed = selectedOption.find((record) => record.value == parseInt(id));

    if (removed) {
      setFieldValue(`portfolio_${removed.name}_status`, false);
      setFieldValue(`portfolio_${removed.name}_min`, 0);
      setFieldValue(`portfolio_${removed.name}_max`, 0);
    }

    setSelectedOption(remove1);
    handleChange(e);
  };
  useEffect(() => {
    let selOptions = [];
    if (portfolio_exposure_status) {
      let filteredOption = ProductOptions.find(
        (currency) => currency.value === 1
      );
      selOptions.push(filteredOption);
    }
    if (portfolio_exposure2_status) {
      let filteredOption = ProductOptions.find(
        (currency) => currency.value === 2
      );
      selOptions.push(filteredOption);
    }

    setSelectedOption(selOptions);
  }, [portfolio_id]);
  //const portfolio_exposure_status_ = 1;
  return (
    <div className="my-collapse">
      <div className="collapse-title">
        <h4 onClick={toggle}>
          <span className="collapse-plus">
            <img src={isOpen ? minus : plus} />
          </span>
          Rating and Product Constraints (For Fixed Income Only)
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <ReactMultiSelectCheckboxes
          name="sectorMultiselect"
          //options={currencyOptions}
          width="100%"
          onChange={handleMultiSelectChange}
          value={selectedOption}
          options={ProductOptions}
          isDisabled={Flag ? true : constraintsFlag ? false : ""}
        />
        <br />
        {selectedOption &&
          selectedOption.map((exposure) => {
            statusVariable = {
              [`portfolio_${exposure.name}_status`]: [
                `portfolio_${exposure.name}_status`,
              ]
                ? [`portfolio_${exposure.name}_status`]
                : "",
            };

            let max_name = "portfolio_" + exposure.name + "_max";
            let min_name = "portfolio_" + exposure.name + "_min";
            return (
              <FormGroup row className="item-center mt-4">
                <Col lg={4}>
                  <div className="item-center">
                    <CustomInput
                      key={Math.random()}
                      type="checkbox"
                      name={statusVariable[`portfolio_${exposure.name}_status`]}
                      id={statusVariable[`portfolio_${exposure.name}_status`]}
                      onChange={handleChangeFun}
                      value={
                        statusVariable[`portfolio_${exposure.name}_status`]
                      }
                      data-exposure_id={exposure.value}
                      defaultChecked={
                        eval(`portfolio_${exposure.name}_status`) == 1
                          ? true
                          : false
                      }
                      label={exposure.label}
                      disabled={Flag ? true : constraintsFlag ? false : ""}
                    />
                    {/* <span className="informicon" id="assid8"><FaInfoCircle/></span>
                                        <UncontrolledTooltip placement="right" target="assid8">
                                        Ratings indicate the likelihood of default of the bonds. BBB- and above are considered investment grade (less likely to default); BB+ and below are considered high yield (junk bonds) with higher probability of default.

                    </UncontrolledTooltip> */}

                    {/* <Input
                type="select"
                name="portfolio_exposure_value"
                id="portfolio_exposure_value"
                onChange={handleChange}
                value={portfolio_exposure_value}
                disabled={Flag ? true : constraintsFlag ? false : ""}
              >{console.log(portfolio_exposure_value,)}
         
              
                {/* <option value="">Select exposure</option>
         
                {exposureList &&
                  exposureList.length > 0 &&
                  exposureList.map((exposure) => (
                    <option
                      value={exposure.lc_exposure_id}
                      key={exposure.lc_exposure_id}
                    >
                      {exposure.lc_exposure_name}
                    </option>
                  ))}
                       
              </Input>  */}
                  </div>
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="text"
                          name={`portfolio_${exposure.name}_min`}
                          id={`portfolio_${exposure.name}_min`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-exposureid={exposure.name}
                          value={eval(`portfolio_${exposure.name}_min`)}
                          placeholder="0"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Min (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[min_name] && touched[min_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[min_name] && touched[min_name]
                          ? errors[min_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          min={exposure.min}
                          type="text"
                          name={`portfolio_${exposure.name}_max`}
                          id={`portfolio_${exposure.name}_max`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-exposureid={exposure.value}
                          value={eval(`portfolio_${exposure.name}_max`)}
                          placeholder="100"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Max (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[max_name] && touched[max_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[max_name] && touched[max_name]
                          ? errors[max_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            );
          })}
        <FormGroup row className="item-center">
          {/* <Col lg={4}>
            <CustomInput
              type="checkbox"
              name="portfolio_financialscoco_status"
              id="portfolio_financialscoco_status"
              onChange={(e)=>{ 
                if(e.target.checked){
                  setFieldValue(`portfolio_financialscoco_min`, 0);
                  setFieldValue(`portfolio_financialscoco_max`, 100);
                }else{
                  setFieldValue(`portfolio_financialscoco_min`, 0);
                  setFieldValue(`portfolio_financialscoco_max`, 100);
                }
                handleChange(e)
              }}
              value={portfolio_financialscoco_status}
              label="Exposure to Financials (Cocos)"
              checked={portfolio_financialscoco_status}
              disabled={Flag ? true : constraintsFlag ? false : ""}
            />{" "}
            <span className="informicon" id="assid9">
              <FaInfoCircle />
            </span>
            <UncontrolledTooltip placement="right" target="assid9">
              Contingent convertibles (CoCos) are a debt instrument issued by
              European financial institutions that have a specific strike price
              that once breached, can convert the bond into equity or stock.
              These instruments are more volatile than traditional bonds.
            </UncontrolledTooltip>
          </Col> */}
          {/* <Col lg={8}>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_financialscoco_min"
                    id="portfolio_financialscoco_min"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={portfolio_financialscoco_min}
                    placeholder="0"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Min (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      errors.portfolio_financialscoco_min &&
                      touched.portfolio_financialscoco_min
                        ? "block"
                        : "none",
                  }}
                >
                  {errors.portfolio_financialscoco_min &&
                  touched.portfolio_financialscoco_min
                    ? errors.portfolio_financialscoco_min
                    : ""}
                </FormFeedback>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_financialscoco_max"
                    id="portfolio_financialscoco_max"
                    onChange={handleChange}
                    value={portfolio_financialscoco_max}
                    onBlur={handleBlur}
                    placeholder="100"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Max (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      errors.portfolio_financialscoco_max &&
                      touched.portfolio_financialscoco_max
                        ? "block"
                        : "none",
                  }}
                >
                  {errors.portfolio_financialscoco_max &&
                  touched.portfolio_financialscoco_max
                    ? errors.portfolio_financialscoco_max
                    : ""}
                </FormFeedback>
              </Col>
            </Row>
          </Col> */}
        </FormGroup>

        {/* <FormGroup row className="item-center">
          <Col lg={4}>
            <CustomInput
              type="checkbox"
              name="portfolio_credit_status"
              id="portfolio_credit_status"
              //onChange={handleChangeFun}
              onChange={(e)=>{ 
                if(e.target.checked){
                  setFieldValue(`portfolio_credit_min`, 0);
                  setFieldValue(`portfolio_credit_max`, 100);
                }else{
                  setFieldValue(`portfolio_credit_min`, 0);
                  setFieldValue(`portfolio_credit_max`, 100);
                }
                handleChangeFun(e)
              }}
              value={portfolio_credit_status}
              label="Exposure to Structured Credit"
              checked={portfolio_credit_status}
              disabled={Flag ? true : constraintsFlag ? false : ""}
            >
              <span className="informicon" id="assid16">
                <FaInfoCircle />
              </span>
              <UncontrolledTooltip placement="right" target="assid16">
                Structured credit involves pooling similar debt obligations and
                securitzing the underlying cash flows. Suitable for
                professional/accredit investors due to its complexity.
              </UncontrolledTooltip>
            </CustomInput>
          </Col>
          <Col lg={8}>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_credit_min"
                    id="portfolio_credit_min"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={portfolio_credit_min}
                    placeholder="0"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Min (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      errors.portfolio_credit_min &&
                      touched.portfolio_credit_min
                        ? "block"
                        : "none",
                  }}
                >
                  {errors.portfolio_credit_min && touched.portfolio_credit_min
                    ? errors.portfolio_credit_min
                    : ""}
                </FormFeedback>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <Input
                    type="text"
                    name="portfolio_credit_max"
                    id="portfolio_credit_max"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={portfolio_credit_max}
                    placeholder="100"
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Max (%)</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      errors.portfolio_credit_max &&
                      touched.portfolio_credit_max
                        ? "block"
                        : "none",
                  }}
                >
                  {errors.portfolio_credit_max && touched.portfolio_credit_max
                    ? errors.portfolio_credit_max
                    : ""}
                </FormFeedback>
              </Col>
            </Row>
          </Col>
        </FormGroup> */}
      </Collapse>
    </div>
  );
}
