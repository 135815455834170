import React, { useState } from "react";
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import {
  ScatterChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Label,
  Scatter,
  Tooltip,
  ComposedChart,
  Area,
} from "recharts";
import API from "../redux/API";
import { RISK_SUMMARY_URL } from "../common/urls";
import { useEffect } from "react";
import { authHeader } from "../common/Mainfunctions";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";

export default function RiskSummary(props) {
  const { porfolioId, statusreturn } = props;
  const [data, setGraphData] = useState([]);
  const [comparisionData, setComparisionData] = useState([]);
  const [portfolioName, setPortfolioName] = useState("");
  const [xaxis, setXaxis] = useState("");
  const [yaxis, setYaxis] = useState("");
  const [minValue, setMinValue] = useState(-0.5);
  const [maxValue, setMaxValue] = useState(-100);
  const [loading, setLoading] = useState(true);

  const [compMinValue, setCompMinValue] = useState(0);
  const [compMaxValue, setCompMaxValue] = useState(-100);
  const [compMaxChartValue, setCompMaxChartValue] = useState(25);
  const [chartHeight, setChartHeight] = useState(500);
  const ref = React.createRef();

  const getRiskSummary = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RISK_SUMMARY_URL,
      headers: authHeader(),
      params: { id: porfolioId },
    };

    try {
      const response = await API(config);
      const { data = [] } = response.data;
      console.log(data,'ttttttttttttttttttttt')
      const {
        drawdown_analysis = [],
        drawdown_comparision = [],
        portfolio_name,
        x_axis,
        y_axis,
      } = data; 
      //setGraphData(drawdown_analysis);
      setComparisionData(drawdown_comparision);
      setPortfolioName(portfolio_name);
      setYaxis(y_axis);
      setXaxis(x_axis);
      let min = minValue;
      let max = maxValue;

      let graphData = [];
      drawdown_analysis.map((analysis, i) => {
        graphData.push({ "50%_globalequities_50%_ig": analysis["50%_globalequities_50%_ig"] * 100, drawdown_date_x: analysis.drawdown_date_x, lc_balanced_portfolio: analysis['lc_balanced_portfolio']* 100,msci_acwi_index: analysis['msci_acwi_index'] * 100})
        if (
          parseFloat(analysis["50%_globalequities_50%_ig"]) < min &&
          analysis["50%_globalequities_50%_ig"]
        ) {
          min = parseFloat(analysis["50%_globalequities_50%_ig"]);
        }
        if (
          parseFloat(analysis["lc_balanced_portfolio"]) < min &&
          analysis["lc_balanced_portfolio"]
        ) {
          min = parseFloat(analysis["lc_balanced_portfolio"]);
        }
        if (
          parseFloat(analysis["msci_acwi_index"]) < min &&
          analysis["msci_acwi_index"]
        ) {
          min = parseFloat(analysis["msci_acwi_index"]*100);
        }
        
        if (
          analysis["50%_globalequities_50%_ig"] > max &&
          analysis["50%_globalequities_50%_ig"]
        ) {
          max = parseFloat(analysis["50%_globalequities_50%_ig"]*100);
        }
        if (
          analysis["lc_balanced_portfolio"] > max &&
          analysis["lc_balanced_portfolio"]
        ) {
          max = parseFloat(analysis["lc_balanced_portfolio"]*100);
        }
        if (analysis["msci_acwi_index"] > max && analysis["msci_acwi_index"]) {
          max = parseFloat(analysis["msci_acwi_index"]);
        }     
      });
      setGraphData(graphData);
      var convertMinValue = min;
      var convertMaxValue = max;

      setMinValue(parseFloat((convertMinValue*100).toFixed(2)));
      setMaxValue(parseFloat((convertMaxValue*100).toFixed(2)));
      //setMinValue(-0.3);
      //setMaxValue(0.2);

      let compMin = compMinValue;
      let compMax = compMaxValue;

      drawdown_comparision.map((comparision, i) => {
        comparision.map((initialComp, i) => {
          if (
            parseFloat(initialComp["drawdown_comparision_x"]) < compMin &&
            initialComp["drawdown_comparision_x"]
          ) {
            compMin = parseFloat(initialComp["drawdown_comparision_x"]);
          }

          if (
            initialComp["drawdown_comparision_y"] > compMax &&
            initialComp["drawdown_comparision_y"]
          ) {
            compMax = parseFloat(initialComp["drawdown_comparision_y"]);
          }
        });
      });

      setCompMaxChartValue(compMax);
      var convertCompMinValue = compMin + compMin * 0.2;
      var convertCompMaxValue = 0; //compMax + ( compMax * .2)

      setCompMinValue(parseFloat(convertCompMinValue.toFixed(2)));
      setCompMaxValue(parseFloat(convertCompMaxValue.toFixed(2)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(minValue,Math.round(maxValue/50)*50,'555555555555')
    getRiskSummary();
  }, [porfolioId]);

  useEffect(() => {
    if (
      document.body.clientWidth >= 1200 &&
      document.body.clientWidth <= 1300
    ) {
      setChartHeight(527);
    }
  }, [minValue, maxValue, porfolioId]);

  return (
    <div className="overflow_hight per_hight pl-4 pr-4 mt-5">
    
      {statusreturn != "processing" ? (
        <>
          <Row>
            <Col xl="6">
              <Card>
                <CardTitle className="p-0">
                  <p className="text_16_58 my-2 pl-2">
                    Drawdown Analysis of portfolio Benchmarks
                  </p>
                  <hr className="m-0"></hr>
                </CardTitle>
                <CardBody>
                  <div style={{ width: "100%", height: `${chartHeight}px` }}>
                    <ResponsiveContainer>
                      <ComposedChart data={data}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="drawdown_date_x" />
                        <YAxis
                          type="number"
                          interval={""}
                          allowDataOverflow={true}
                          tickCount={6}
                          //domain={[minValue, maxValue]}
                          domain={[minValue,Math.round(maxValue/50)*50]}
                          unit="%"
                        />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          name={portfolioName}
                          dataKey="lc_balanced_portfolio"
                          fill="#8884d8"
                          stroke="#8884d8"
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          name={xaxis}
                          dataKey="msci_acwi_index"
                          stroke="#82ca9d"
                          dot={false}
                          strokeWidth={2}
                         
                        />
                        <Line
                          type="monotone"
                          name={yaxis}
                          dataKey="50%_globalequities_50%_ig"
                          stroke="red"
                          dot={false}
                          strokeWidth={2}
                         
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6">
              <Card>
                <CardTitle className="p-0">
                  <p className="text_16_58 my-2 pl-2">
                    Return to Drawdown Comparison Chart of Portfolio vs
                    Benchmarks
                  </p>
                  <hr className="m-0"></hr>
                </CardTitle>
                <CardBody>
                  <div style={{ width: "100%", height: 500 }}>
                    <ResponsiveContainer>
                      <ScatterChart
                        margin={{ top: 20, right: 20, bottom: 10, left: 10 }}
                      >
                        <CartesianGrid />
                        <XAxis
                          type="number"
                          dataKey="drawdown_comparision_x"
                          name="Max Drawdown"
                          domain={[compMinValue, compMaxValue]}
                          unit="%"
                        >
                          <Label
                            value="Max Drawdown"
                            offset={0}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          type="number"
                          dataKey="drawdown_comparision_y"
                          name="Annualized Return"
                          domain={[0, compMaxChartValue]}
                          orientation="right"
                          unit="%"
                        >
                          <Label
                            value="Annualized Return"
                            angle={-90}
                            position="right"
                          />
                        </YAxis>
                        <Legend />

                        <Scatter
                          name={portfolioName}
                          data={comparisionData[0]}
                          fill="#8884d8"
                        />
                        <Scatter
                          name={xaxis}
                          data={comparisionData[1]}
                          fill="#82ca9d"
                        />
                        <Scatter
                          name={yaxis}
                          data={comparisionData[2]}
                          fill="red"
                        />
                        <Tooltip position={{ x: "auto", y: "auto" }} />
                      </ScatterChart>
                    </ResponsiveContainer>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ): <p>Data is being Re-Generated...</p>}
    </div>
  );
}
