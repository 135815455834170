import React, { useState, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { CHANGE_PASSWORD } from "../common/urls";
import {
  authHeader,
  getAlertToast,
  getAlert,
  getRmID,
  logOutFun,
} from "../common/Mainfunctions";
import API from "../redux/API";
import Swal from "sweetalert2";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomLoader from "../common/CustomLoader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import InputGroup from "reactstrap/lib/InputGroup";
import { useHistory } from "react-router-dom";

export default function ChangePassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isPasswordType, setPasswordInputType] = useState(true);
  const [isPasswordType1, setPasswordInputType1] = useState(true);
  const [isPasswordType2, setPasswordInputType2] = useState(true);

  const togglePasswordType = () => {
    setPasswordInputType(!isPasswordType);
  };
  const togglePasswordType1 = () => {
    setPasswordInputType1(!isPasswordType1);
  };
  const togglePasswordType2 = () => {
    setPasswordInputType2(!isPasswordType2);
  };
  const validationSchema = Yup.object({
    current_password: Yup.string().trim().required("Old Password Required"),
    new_password: Yup.string().trim().required("New Password Required"),
    new_confirm_password: Yup.string()
      .required("Confirm password is required")
      .test("passwords-match", "Password mismatch", function (value) {
        return this.parent.new_password === value;
      }),
  });
  const updatePassword = async (values) => {
    setLoading(true);
    const config = {
      method: "POST",
      url: CHANGE_PASSWORD + getRmID(),
      headers: authHeader(),
      data: values,
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status) {
        Swal.fire(getAlertToast("Success", "Password Updated Successfully"));
        logOutFun(history);
      } else {
        Swal.fire(getAlert("error", "The given data was invalid"));
      }
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "The given data was invalid"));
    } finally {
      setLoading(false);
    }
  };
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      new_confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      updatePassword(values);
    },
  });

  return (
    <Fragment>
      <Row>
        <Col md="12" md={{ size: 6, offset: 3 }}>
          <Form form="true" onSubmit={handleSubmit} className="mt-3">
            <FormGroup>
              <Label for="current_password">Old Password</Label>
              <InputGroup>
                <Input
                  type={isPasswordType ? "password" : "text"}
                  name="current_password"
                  id="current_password"
                  placeholder="Enter old password"
                  invalid={
                    errors.current_password && touched.current_password
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  value={values.client_firstname}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={togglePasswordType}
                    style={{ cursor: "pointer" }}
                  >
                    {isPasswordType ? (
                      <FaEyeSlash></FaEyeSlash>
                    ) : (
                      <FaEye></FaEye>
                    )}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.current_password && touched.current_password
                    ? errors.current_password
                    : ""}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="new_password">New password</Label>
              <InputGroup>
                <Input
                  type={isPasswordType1 ? "password" : "text"}
                  name="new_password"
                  id="new_password"
                  placeholder="Enter new password"
                  invalid={
                    errors.new_password && touched.new_password ? true : false
                  }
                  onChange={handleChange}
                  value={values.new_password}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={togglePasswordType1}
                    style={{ cursor: "pointer" }}
                  >
                    {isPasswordType1 ? (
                      <FaEyeSlash></FaEyeSlash>
                    ) : (
                      <FaEye></FaEye>
                    )}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.new_password && touched.new_password
                    ? errors.new_password
                    : ""}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="new_confirm_password">Confirm password</Label>
              <InputGroup>
                <Input
                  type={isPasswordType2 ? "password" : "text"}
                  name="new_confirm_password"
                  id="new_confirm_password"
                  placeholder="Enter confirm name"
                  invalid={
                    errors.new_confirm_password && touched.new_confirm_password
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  value={values.new_confirm_password}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={togglePasswordType2}
                    style={{ cursor: "pointer" }}
                  >
                    {isPasswordType2 ? (
                      <FaEyeSlash></FaEyeSlash>
                    ) : (
                      <FaEye></FaEye>
                    )}
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback>
                  {errors.new_confirm_password && touched.new_confirm_password
                    ? errors.new_confirm_password
                    : ""}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <Button color="primary" className="d-block m-auto" type="submit">
              {loading && <CustomLoader loading={loading} />}
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}
