import React, { useState, useEffect } from "react";
import {
  Collapse,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { REGION_LIST } from "../../common/urls";
import API from "../../redux/API";
import { authHeader } from "../../common/Mainfunctions";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const formatGroupLabel = (data) => {
  return (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );
};

export default function Regional(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regOptions, setRegOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  let statusVariable;
  const toggle = () => setIsOpen(!isOpen);
  const {
    formValues,
    handleChange,
    regValues = [],
    setRegValues,
    touched,
    errors,
    handleBlur,
    Flag,
    constraintsFlag,
  } = props;
  const { portfolio_id } = formValues;

  const getRegionList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: REGION_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [] } = response.data;

      let regOptions = data.map((e, i) => ({
        label: e.lc_country_name,
        value: e.lc_country_id,
      }));
      // .filter((regOpt) => regOpt.lc_country_mid_status == "true")

      // .map((e, i) => ({
      //   label: e.lc_country_name,
      //   options: data
      //     // .filter(
      //     //   (regOpt) =>
      //     //     regOpt.lc_country_mid_status == "false" &&
      //     //     regOpt.lc_parent_id == e.lc_country_id
      //     // )
      //     .map((e, i) => ({
      //       label: e.lc_country_name,
      //       value: e.lc_country_id,
      //     })),
      // })
      // );
      setRegOptions(regOptions);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRegionList();
  }, []);

  useEffect(() => {
    let selOptions = regValues.map((e, i) => ({
      label: e.country_name,
      value: e.country_id,
    }));
    setSelectedOption(selOptions);
  }, [portfolio_id]);

  const handleMultiSelectChange = (selectedOptions, e) => {
    let { action, option = {} } = e;
    let { value, label } = option;
    console.log("action", action, e);
    if (action == "set-value") {
      let selOptions = [];
      if (selectedOptions.length > 0) {
        selectedOptions.map((record) => {
          let isExist = regValues.filter(
            (reg) => reg.country_id == record.value
          );
          if (isExist && isExist.length == 0) {
            selOptions.push({
              country_id: record.value,
              min: "",
              max: "",
              country_name: record.label,
            });
            setRegValues(selOptions);
          } else {
            selOptions.push({
              country_id: record.value,
              min: "",
              max: "",
              country_name: record.label,
            });
            setRegValues(selOptions);
          }
        });
      } else {
        setRegValues(selectedOptions);
      }
    } else if (action == "select-option") {
      let isExist = regValues.filter((reg) => reg.country_id == value);
      if (isExist && isExist.length == 0) {
        setRegValues([
          ...regValues,
          { country_id: value, min: 0, max: 100, country_name: label },
        ]);
      }
    } else {
      let remove = regValues.filter((reg) => reg.country_id !== value);

      setRegValues(remove);
    }
    setSelectedOption(selectedOptions);
  };

  const handleRegionMinChange = (e) => {
    let { dataset, value } = e.target;
    let { regionid } = dataset;
    regValues.find((reg) => reg.country_id == regionid).min = value;
    setRegValues([...regValues]);
  };

  const handleRegionMaxChange = (e) => {
    let { dataset, value } = e.target;
    let { regionid } = dataset;
    regValues.find((reg) => reg.country_id == regionid).max = value;
    setRegValues([...regValues]);
    console.log(value, "kkkkkkkkkkkkkkkkkkkkkkk");
  };

  const handleChangeFun = (e) => {
    const value = e.currentTarget.dataset.nid;
    let remove = regValues.filter((reg) => reg.country_id !== parseInt(value));
    setRegValues(remove);

    let remove1 = selectedOption.filter(
      (element) => element.value !== parseInt(value)
    );
    setSelectedOption(remove1);

    handleChange(e);
  };
  return (
    <div className="my-collapse">
      <div className="collapse-title">
        <h4 onClick={toggle}>
          <span className="collapse-plus">
            <img src={isOpen ? minus : plus} />
          </span>
          Regional{" "}
          <span className="informicon" id="assid34">
            <FaInfoCircle />
          </span>
          <UncontrolledTooltip placement="right" target="assid34">
            Regional limits that the portfolio needs to have
          </UncontrolledTooltip>
        </h4>
      </div>

      <Collapse isOpen={isOpen}>
        <br />
        <div>
          <ReactMultiSelectCheckboxes
            options={regOptions}
            groupHeading={formatGroupLabel}
            // formatGroupLabel={formatGroupLabel}
            width="100%"
            onChange={handleMultiSelectChange}
            value={selectedOption}
            isDisabled={Flag ? true : constraintsFlag ? false : ""}
          />
        </div>
        <br />
        {regValues &&
          regValues.map((regions) => {
            statusVariable = {
              [`portfolio_${regions.country_id}_status`]: [
                `portfolio_${regions.country_id}_status`,
              ]
                ? [`portfolio_${regions.country_id}_status`]
                : "",
            };

            let max_name = "portfolio_" + regions.country_id + "_max";
            let min_name = "portfolio_" + regions.country_id + "_min";
            return (
              <FormGroup row className="item-center">
                <Col lg={4}>
                  <CustomInput
                    key={Math.random()}
                    type="checkbox"
                    name={
                      statusVariable[`portfolio_${regions.country_id}_status`]
                    }
                    id={
                      statusVariable[`portfolio_${regions.country_id}_status`]
                    }
                    data-nid={regions.country_id}
                    onChange={handleChangeFun}
                    value={
                      statusVariable[`portfolio_${regions.country_id}_status`]
                    }
                    defaultChecked={
                      statusVariable[`portfolio_${regions.country_id}_status`]
                        ? true
                        : false
                    }
                    label={regions.country_name}
                    disabled={Flag ? true : constraintsFlag ? false : ""}
                  />
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="number"
                          max="100"
                          data-regionid={regions.country_id}
                          name={`portfolio_${regions.country_id}_min`}
                          id={`portfolio_${regions.country_id}_min`}
                          onChange={handleRegionMinChange}
                          onBlur={handleBlur}
                          value={regions.min}
                          placeholder="0"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Min (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[min_name] && touched[min_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[min_name] && touched[min_name]
                          ? errors[min_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                    <Col lg={6}>
                      <InputGroup>
                        <Input
                          type="number"
                          min={regions.min}
                          data-regionid={regions.country_id}
                          name={`portfolio_${regions.country_id}_max`}
                          id={`portfolio_${regions.country_id}_max`}
                          onChange={handleRegionMaxChange}
                          onBlur={handleBlur}
                          value={regions.max}
                          placeholder="100"
                          disabled={Flag ? true : constraintsFlag ? false : ""}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Max (%)</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <FormFeedback
                        style={{
                          display:
                            errors[max_name] && touched[max_name]
                              ? "block"
                              : "none",
                        }}
                      >
                        {errors[max_name] && touched[max_name]
                          ? errors[max_name]
                          : ""}
                      </FormFeedback>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            );
          })}
      </Collapse>
    </div>
  );
}
