import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, CardTitle, Button, Table } from "reactstrap";
import CustomTableHeader from "../../common/CustomTableHeader";
import CustomTableLoader from "../../common/CustomTableLoader";
import CustomEmptyTableRecord from "../../common/CustomEmptyTableRecord";
import { ASSET_DELETE_URL, ASSET_SUMMARY_URL } from "../../common/urls";
//import {   }  from "../../common/Mainfunctions";
import NumberFormat from "react-number-format";
import { getAlertToast, authHeader } from "../../common/Mainfunctions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import API from "../../redux/API";
import { UncontrolledTooltip } from "reactstrap";
import Recommended from "../AssetBreakdown/Recommended";
import Swal from "sweetalert2";
import { SAVE_ALLOCATION } from "../../common/urls";
import { getAlert } from "../../common/Mainfunctions";
import { PieChart, Pie } from "recharts";
import { FaEye, FaTrash } from "react-icons/fa";
import { Checkbox, colors } from "@material-ui/core";
import AssetBreakdown from "../AssetBreakdown";
import { render } from "@testing-library/react";
export default function Bonds(props) {
  const {
    loading,
    assetData,
    stockData,
    fundData,
    lastupdated,
    allocation,
    editActiveFlag,
    porfolioId,
    setmorethanhundred,
    setdollarmorethanhundred,
    eqvalue,
    setpercentage,
    allocationBond,
    setAllocationBond,
    checkBoxModify,
    showSelectAll,
    setShowSelectAll,
    setCheckBoxModify,
    setdollar,
    groupselected,
    setModifyBond,
    ModifyBond,
    AddRemovechange,
    setselectedbondid,
    reloadfun,
    setAllocation,
    setreloadfun,
    recommendedBondList,
    toggle,
    Flags,
  } = props;
  const [types, settypes] = useState("");
  const [type, settype] = useState("");
  const [changedpercentage, setchangedpercentage] = useState(1);
  const [headdollar, setheaddollar] = useState(1);
  const [headpercentage, setheadpercentage] = useState(1);
  const [changedDollar, setchangedDollar] = useState(1);
  const [valuechange, setvaluechange] = useState("");
  const [ViewBond, setViewBond] = useState(false);
  const [totaldollar, settotaldollar] = useState(0);
  const [totalpercentage, settotalpercentage] = useState(0);
  const [modifyAllocation, setModifyAllocation] = useState(false);
  const [modalrecom, setModalrecom] = useState(false);
  const togglerecom = () => setModalrecom(!modalrecom);
  const [selectedfund, setselectedfund] = useState([]);
  const [setLoading] = useState(true);
  const [assetId, setAssetId] = useState(0);
  const viewToggle = () => setViewModal(!viewModal);
  const [viewModal, setViewModal] = useState(false);
  const [modals, setModals] = useState(false);
  const toggles = () => setModals(!modals);
  const [assetList, setAssetList] = useState([]);
  const [BondSector, setBondSector] = useState(0);
  const [selectAsset, setSelectedAsset] = useState([]);
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);
  const [selectedBond, setSelectedBond] = useState([]);
  const [id, setversionid] = useState("");
  const [bondDollar, setBondDollar] = useState(0);
  const [bondShareDollar, setBondShareDollar] = useState(0);
  const [selectedBondIndex, setSelectedBondIndex] = useState(null);
  const [modifyBondIndex, setModifyBondIndex] = useState(null);
  const [unChecked, setUnChecked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxSelect, setCheckboxSelect] = useState("");
  const [changeSelectAll, setchangeSelectAll] = useState(false);
  const [idafteredit, setidafteredit] = useState("1");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCheckboxes,setSelectedCheckboxes]=useState(0);
  const getAl = (
    type = "success",
    types = "success",
    text = "",
    timer = 5000
  ) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    types: types,
    showConfirmButton: false,
    timer: timer,
  });
  const tableColumns = [
    { title: " " },
    { title: "Name" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Rating" },
    { title: "Geography" },
    { title: "Sector" },
  ];

  const checkedAll = (e) => {
    setCheckBoxModify(false);
    selectedBond.bond_data_list.map((check) => {
      if (e.target.checked) {
        check.bond_taken = 1;
       }
       else {
        check.bond_taken = 0;
      }
    });
    setchangedpercentage(null);
    checkBondPercentage();
    setheaddollar(null);
    setheadpercentage(null);
    SingleCheckBondPercentage();
    setchangedDollar(null);
    setTimeout(() => {
      setAllocationBond(true);
      setCheckBoxModify(true);
    }, 500);
  };

  const assetBondTableColumns = [
    {
      title:
        showSelectAll == true? (
          <input
            type="checkbox"
            name="status"
            defaultChecked={true}
            value={setSelectAll}
            onChange={checkedAll}
          />
        ) : (
          ""
        ),
    },
    { title: "Name of Bond" },
    { title: "ISIN" },
    { title: "Currency" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Bond Rank" },
    { title: "Current Price" },
    { title: "Coupon" },
    { title: "YTW" },
    { title: "Modified duration" },
    { title: "Maturity of bonds" },
    { title: "Call Date of Bonds" },
    { title: "Rating" },
    { title: "Geography" },
    { title: "Sector" },
  ];
  // const handleSelectAllEvents=()=>{
  //   selectedBond.bond_data_list
  // }
  useEffect(() => {
    if (allocationBond == false) {
      settypes("text");
    } else {
      settypes("input");
    }
  }, [allocationBond]);

  useEffect(() => {

    if (allocation == false) {
      settype("text");
    } else {
      settype("input");
    }
  }, [allocation]);

  // useEffect(() => {
  //   if (Flags == false) {
  //     settype("text");
  //   } else {
  //     settype("input");
  //   }
  // }, [Flags]);

  useEffect(() => {
    if (Flags == false) {
      settypes("text");
      setAllocation(false);
      setAllocationBond(false);
      setCheckBoxModify(false);
    } else {
      settypes("input");
      setAllocation(true);
      setAllocationBond(true);
      setCheckBoxModify(true);
    }
  }, [Flags]);

  const handleSelectEvent = (selectedid) => {
    if (!selectedfund.includes(selectedid)) {
      setselectedfund((prevSelected) => [...prevSelected, selectedid]);
    } else {
      setselectedfund((prevSelected) =>
        prevSelected.filter((id) => id !== selectedid)
      );
    }
  };
  const bondModify = () => {
    if (setAllocationBond == true) {
      setAllocationBond(true);
      setCheckBoxModify(true);
    } else {
      setAllocationBond(false);
      setCheckBoxModify(false);
    }
  };
  const saveAllocation = async () => {
    const config = {
      method: "POST",
      url: SAVE_ALLOCATION,
      headers: authHeader(),
      data: {
        portfolio_id: porfolioId,
        data: {
          asset_bond: assetData,
          asset_stock: stockData,
          asset_funds: fundData,
        },
      },
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      const { data = [] } = response.data;
      setversionid(data.portfolio_id);
      setidafteredit("2");
      if (status === "Success") {
        Swal.fire(getAl("success", "Allocations Saved Successfully"));
        window.location.reload(
          "/detail-portfolio/summary/" + data.portfolio_id
        );
      }
    } catch (error) {
      console.log(error);
      Swal.fire(getAlert("error", "Invalid Data"));
    } finally {
    }
  };

  const splitBondIndex = (singleBond, index) => {
    let tot = 0;
    let doll = 0;
    let sector= 0;
    let selectedSec = '';
    let singlePercentage = singleBond?.bond_suggested_allocation_percentage
      ? parseFloat(singleBond?.bond_suggested_allocation_percentage)
      : 0;
    let singleDollar = singleBond?.bond_suggested_allocation_dollar
      ? parseFloat(
          singleBond?.bond_suggested_allocation_dollar
            .toString()
            .replaceAll("$", "")
            .replaceAll(",", "")
        )
      : 0;
    let totSelected = singleBond?.bond_data_list?.filter(
      (bond) => bond.bond_taken == 1
    );
    let individualDollar = singleDollar / totSelected?.length;
    let individualPercentage = singlePercentage / totSelected?.length;

    singleBond.bond_data_list.map((bond, index) => {
      if (bond?.bond_taken == 1) {
        sector++;
        selectedSec = bond.bond_industry_focus
        bond.bond_suggested_allocation_percentage =
          individualPercentage?.toFixed(5);
        bond.bond_suggested_allocation_dollar = individualDollar?.toFixed(2);
      }
    });
   
    assetData[index].bond_data_list = singleBond.bond_data_list;
    
  };

  
  const checkPercentage = () => {
    //setvaluechange();
    let tot = 0;
    let doll = 0;
    assetData.map((record, index) => {
      //console.log(record,'1011010101010101010101')
      // const { bond_data_list } = rcb;
      // bond_data_list.map((record, index) => {
        if (record?.bond_taken == 1) {
          tot += record.bond_suggested_allocation_percentage
            ? parseFloat(record.bond_suggested_allocation_percentage)
            : 0;
          doll += record.bond_suggested_allocation_dollar
            ? parseFloat(
                record.bond_suggested_allocation_dollar
                  .toString()
                  .replaceAll("$", "")
                  .replaceAll(",", "")
              )
            : 0;

          setSelectedCheckboxes(document?.querySelectorAll('input[type=checkbox]:checked').length);
      }
      // });
    });
    //settotaldollar(doll);

    //settotalpercentage(tot);
    setpercentage(tot);
    setdollar(doll);
    if (tot * 100 > 100) {
      setmorethanhundred(true);
    } else {
      setmorethanhundred(false);
    }
    if (doll > eqvalue) {
      setdollarmorethanhundred(true);
    }

  };
  
  console.log(selectedCheckboxes,'selectedCheckboxesselectedCheckboxes')
  
  useEffect(()=>{
if(AddRemovechange==true )
  checkPercentage();
  },[AddRemovechange ,selectedCheckboxes ,BondSector]);

  const checkBondPercentage = () => {
    //setvaluechange();
    let tot = 0;
    let doll = 0;
    selectedBond.bond_data_list.map((record, index) => {
      // const { bond_data_list } = rcb;
      // bond_data_list.map((record, index) => {
      tot += record.bond_suggested_allocation_percentage
        ? parseFloat(record.bond_suggested_allocation_percentage)
        : 0;
      doll += record.bond_suggested_allocation_dollar
        ? parseFloat(
            record.bond_suggested_allocation_dollar
              .toString()
              .replaceAll("$", "")
              .replaceAll(",", "")
          )
        : 0;
    });
    var totVal = selectedBond.bond_suggested_allocation_dollar
      ? parseFloat(
          selectedBond.bond_suggested_allocation_dollar
            .toString()
            .replaceAll("$", "")
            .replaceAll(",", "")
        )
      : 0;
    let copyselectedBond = selectedBond;

    setBondDollar(totVal - doll);
    setBondDollar();
    setSelectedBond((prevState) => ({
      ...prevState,
      bond_suggested_allocation_dollar: doll,
      bond_suggested_allocation_percentage: tot,
    }));
    // setchangedDollar(null);setchangedpercentage(null);
    if (doll > selectedBond.bond_suggested_allocation_dollar) {
      setdollarmorethanhundred(true);
    }
  };
 
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const SingleCheckBondPercentage = () => {
    //setvaluechange();
    let tot = 0;
    let doll = 0;
    var sector = 0;
    var selectedSec = [];
    selectedBond.bond_data_list.map((record, index) => {
      // const { bond_data_list } = rcb;
      // bond_data_list.map((record, index) => {
      if (record.bond_taken == 1) {
        sector++;
        selectedSec.push(record.bond_industry_focus)
        tot += record.bond_suggested_allocation_percentage
          ? parseFloat(record.bond_suggested_allocation_percentage)
          : 0;
        doll += record.bond_suggested_allocation_dollar
          ? parseFloat(
              record.bond_suggested_allocation_dollar
                .toString()
                .replaceAll("$", "")
                .replaceAll(",", "")
            )
          : 0;
      }
    });

    if(selectedSec.length > 0 ){
    var unique = selectedSec.filter(onlyUnique);
    if(unique.length > 1){
      selectedSec = 'Diversified';
    }else{
      selectedSec = selectedSec[0]
    }
  }else{
    selectedSec = ''
  }
    console.log(unique); // ['a', 1, 2, '1']



console.log(selectedSector,'ppppppppppppppppppppppppppppppppppppppp')
    var totVal = selectedBond.bond_suggested_allocation_dollar
      ? parseFloat(
          selectedBond.bond_suggested_allocation_dollar
            .toString()
            .replaceAll("$", "")
            .replaceAll(",", "")
        )
      : 0;
    let copyselectedBond = selectedBond;

    setBondDollar(totVal - doll);
    setBondDollar();
    setSelectedBond((prevState) => ({
      ...prevState,
      bond_suggested_allocation_dollar: doll,
      bond_suggested_allocation_percentage: tot,
      bond_industry_focus: selectedSec
    }));
    // setchangedDollar(null);setchangedpercentage(null);
    if (doll > selectedBond.bond_suggested_allocation_dollar) {
      setdollarmorethanhundred(true);
    }
    setchangedpercentage(null);
    setheaddollar(null);
    setheadpercentage(null);
    setchangedDollar(null);
  };

  

  const saveBondPercentage = () => {
    //setvaluechange();
    let tot = 0;
    let doll = 0;
    selectedBond.bond_data_list.map((record, index) => {
      if (record.bond_taken == 1) {
        let perce = record.bond_suggested_allocation_percentage
          ? parseFloat(record.bond_suggested_allocation_percentage)
          : 0;
        if (perce > 0) {
          tot += perce;
          doll += record.bond_suggested_allocation_dollar
            ? parseFloat(
                record.bond_suggested_allocation_dollar
                  .toString()
                  .replaceAll("$", "")
                  .replaceAll(",", "")
              )
            : 0;
        } else {
          record.bond_taken = 0;
        }
      }
      if (record.bond_taken == 0) {
        tot += 0;
        doll += 0;
      }
    });
    var totVal = selectedBond.bond_suggested_allocation_dollar
      ? parseFloat(
          selectedBond.bond_suggested_allocation_dollar
            .toString()
            .replaceAll("$", "")
            .replaceAll(",", "")
        )
      : 0;
    let copyselectedBond = selectedBond;

    setBondDollar(totVal - doll);
    setBondDollar();
    setSelectedBond((prevState) => ({
      ...prevState,
      bond_suggested_allocation_dollar: doll,
      bond_suggested_allocation_percentage: tot,
    }));
    // setchangedDollar(null);setchangedpercentage(null);
    if (doll > selectedBond.bond_suggested_allocation_dollar) {
      setdollarmorethanhundred(true);
    }
  };

       console.log(selectedBond,'ooooooooooooooooooooooooo')
// useEffect(()=>{
//   {selectedBond?.bond_data_list?.map(
//     (bond) => {
//       setBondSector(bond)
//     })}
// },[selectedBond]);
   
  useEffect(() => {
    checkPercentage();
  }, [assetData]);
  useEffect(() => {
    if(recommendedBondList){
      checkPercentage();
    }
    // const merged = recommendedBondList?.reduce((arr, item) => {
    //   setShowSelectAll(true);
    //   //console.log(assetData,item,'assetDataassetData')

    //   const index = assetData.findIndex((el) => el.bond_name == item.bond_name);

    //   if (index == -1) {
    //     arr.push(item);
    //   } else {
    //     item.bond_data_list.map((bondIn) => {
    //       const childIndex = assetData[index]["bond_data_list"].findIndex(
    //         (el) => el.bond_name == bondIn.bond_name
    //       );
    //       if (childIndex == -1) {
    //         assetData[index]["bond_data_list"].push(bondIn);
    //       }
    //     });
    //   }
    //   return arr;
    // }, assetData);
  }, [recommendedBondList]);

  useEffect(() => {
    let tot = 0;
    let doll = 0;
    assetData.map((rcf, index) => {
      const { bond_data_list } = rcf;
      bond_data_list.map((record, index) => {
        tot += parseFloat(record.bond_suggested_allocation_percentage)
          ? parseFloat(record.bond_suggested_allocation_percentage)
          : 0;

        doll += record?.bond_suggested_allocation_dollar
          ? parseFloat(
              record?.bond_suggested_allocation_dollar
                .toString()
                .replaceAll("$", "")
                .replaceAll(",", "")
            )
          : 0;
      });
      //console.log(record?.bond_suggested_allocation_dollar,record?.bond_suggested_allocation_dollar.toString().replaceAll('$',''),"kkkkkkkkk")
    });
    // setpercentage(tot);
    // setdollar(doll);
    settotaldollar(doll);
    settotalpercentage(tot);
    //alert(doll)
    // setpercentage(tot);
    // alert(tot)
    // setdollar(doll);
  }, [assetData]);

  // const addSuccessCallBack = () => {
  //   //addToggle();
  //   getAssetManagementList();
  // };
  // const viewSuccessCallBack = () => {
  //   viewToggle();
  //   getAssetManagementList();
  // };
  const handleChange = (e) => {
    //console.log(e.target,'99999999999')
    // const {bond_taken} = e.target;
  };
  const [formData, setFormData] = useState({
    status: 1,
    file: "",
    fileName: "No file chosen",
  });

 

  useEffect(() => {
    checkPercentage();
  }, [valuechange]);

  useEffect(() => {
    setselectedbondid(selectedfund);
  }, [selectedfund]);
  const deleteAsset = async (asset_id) => {
    const config = {
      method: "DELETE",
      url: ASSET_DELETE_URL + "/" + asset_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "success") {
        //getAssetManagementList();
        Swal.fire(getAlertToast("Success", "Asset deleted Successfully"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  /* Used To Rerender Component */
  useEffect(() => {
    if (changedpercentage == null) {
      setchangedpercentage(1);
    }
  }, [changedpercentage]);
  useEffect(() => {
    if (changedDollar == null) {
      setchangedDollar(1);
    }
  }, [changedDollar]);

  useEffect(() => {
    if (headdollar == null) {
      setheaddollar(1);
    }
    if (headpercentage == null) {
      setheadpercentage(1);
    }
  }, [headdollar, headpercentage]);

  const onClickDelete = (e) => {
    e.stopPropagation();
    let asset_id = parseInt(e.currentTarget.dataset.id);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this asset?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteAsset(asset_id);
      }
    });
  };

  const viewRecord = (event) => {
    let id = parseInt(event.currentTarget.dataset.id);
    setAssetId(id);
    viewToggle();
    let filteredOption = assetList.find((element) => element.id === id);

    setSelectedAsset(filteredOption);
  };

  return (
    <div>
      <Row className="mt-4 ">
        <Col xl="12">
          {/*<CardTitle>Bonds <span className="bondcard">Last Price Update : {lastupdated}</span></CardTitle>*/}

          <div className="margin_minus_bond stock_div">
            <Table hover responsive className="break_table bond-table">
              <CustomTableHeader tableColumns={tableColumns} />
              <tbody>
                <Fragment>
                  {assetData.length > 0
                    ? assetData.map((record, index) => {
                        const isEventSelected = selectedfund.includes(
                          record.bond_id
                        );
                        const {
                          bond_name,
                          bond_isin,
                          bond_currency,
                          bond_suggested_allocation_dollar,
                          bond_suggested_allocation_percentage,
                          bond_bloomberg_composite_ranking,
                          bond_investment_geography,
                          bond_industry_focus,
                        } = record;
                        return (
                          <>
                            {record.bond_taken == 1 ? (
                              <tr key={index + 1}>
                                <td
                                  style={{
                                    minWidth: "140px",
                                    maxWidth: "190px",
                                  }}
                                >
                                  <Button
                                    color=""
                                    size="sm"
                                    className="actionBtn"
                                    // onClick={() =>
                                    //   setCurrentBondIndex(
                                    //     currentBondIndex == record.bond_id ? 0 : record.bond_id
                                    //   )}
                                    onClick={() => {
                                      setModals(true);
                                      setSelectedBond(record);
                                      setSelectedBondIndex(index);
                                      setSelectedSector(record.bond_industry_focus);
                                    }}
                                  >
                                    {/* <FaEye></FaEye> */}
                                    <span className="informicon" id="assid1">
                                      {" "}
                                      <FaEye />{" "}
                                    </span>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="assid1"
                                    >
                                      View & Select Bond
                                    </UncontrolledTooltip>
                                  </Button>
                                </td>
                                <td
                                  style={{
                                    minWidth: "265px",
                                    maxWidth: "190px",
                                  }}
                                >
                                  {bond_name}
                                </td>

                                <td
                                  style={{
                                    minWidth: "180px",
                                    maxWidth: "180px",
                                  }}
                                > 
                                  {" "}
                                  {changedDollar && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={parseFloat(
                                        bond_suggested_allocation_dollar?.toString()
                                          .replaceAll("$", "")
                                          .replaceAll(",", "")
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.bond_suggested_allocation_dollar =
                                          value?.floatValue?.toFixed(2);
                                        record.bond_suggested_allocation_percentage =
                                          ( (value?.floatValue ? value?.floatValue : 0) / eqvalue).toFixed(
                                            2
                                          );
                                        setchangedpercentage(null);
                                        //setchangedDollar(null);
                                        checkPercentage();
                                        splitBondIndex(record, index);
                                      }}
                                      prefix="$"
                                    />
                                  )}
                                </td>

                                <td
                                  style={{
                                    minWidth: "100px",
                                    maxWidth: "180px",
                                  }}
                                >
                                  {changedpercentage && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={(
                                        bond_suggested_allocation_percentage *
                                        100
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.bond_suggested_allocation_percentage =
                                          value.floatValue / 100;
                                        record.bond_suggested_allocation_dollar =
                                          (value.floatValue / 100) * eqvalue;
                                        setchangedDollar(null);
                                        checkPercentage();
                                        splitBondIndex(record, index);
                                      }}
                                      suffix={"%"}
                                    />
                                  )}
                                </td>

                                <td
                                  style={{
                                    minWidth: "180px",
                                    maxWidth: "280px",
                                  }}
                                >
                                  {bond_bloomberg_composite_ranking}
                                </td>
                                <td
                                  style={{
                                    minWidth: "120px",
                                    maxWidth: "120px",
                                  }}
                                >
                                  {bond_investment_geography}
                                </td>
                                <td
                                  style={{
                                    minWidth: "120px",
                                    maxWidth: "120px",
                                  }}
                                >
                                  
                                  {bond_industry_focus}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                  <Modal isOpen={modals} toggle={toggles} size="xl">
                    <div className="modalwidth">
                      <ModalBody className="bodywidth">
                        <ModalHeader
                          className="modalhead bondModel"
                          style={{ width: "100%" }}
                        >
                          <Col style={{ width: "100%" }}>
                            <div className="table_trans_line mb-4">
                              <Table
                                striped
                                hover
                                className="single_table_trans mb-0"
                              >
                                <thead>
                                  <tr>
                                    <th>Name </th>
                                    <th> Suggested Allocation ($)</th>
                                    <th> Suggested Allocation (%)</th>
                                    <th> Current Allocation($)</th>
                                    <th> Current Allocation(%)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        minWidth: "190px",
                                        maxWidth: "190px",
                                      }}
                                    >
                                      {selectedBond.bond_name}
                                    </td>
                                    <td
                                      style={{
                                        minWidth: "180px",
                                        maxWidth: "180px",
                                      }}
                                    >
                                      {" "}
                                      <NumberFormat
                                        className="green-text text-right"
                                        defaultValue={
                                          selectedBond.bond_suggested_allocation_dollar
                                            ? parseFloat(
                                                selectedBond.bond_suggested_allocation_dollar
                                                  .toString()
                                                  .replaceAll("$", "")
                                                  .replaceAll(",", "")
                                              ).toFixed(1)
                                            : ""
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        onValueChange={(value) => {
                                          selectedBond.bond_suggested_allocation_dollar =
                                            value.floatValue.toFixed(2);
                                          selectedBond.bond_suggested_allocation_percentage =
                                            (
                                              value.floatValue / eqvalue
                                            ).toFixed(2);
                                          checkPercentage();
                                          checkBondPercentage();
                                        }}
                                        prefix="$"
                                      />
                                    </td>
                                    <td
                                      style={{
                                        minWidth: "180px",
                                        maxWidth: "180px",
                                      }}
                                    >
                                      <NumberFormat
                                        className="green-text text-right"
                                        defaultValue={(
                                          selectedBond.bond_suggested_allocation_percentage *
                                          100
                                        ).toFixed(1)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        onValueChange={(value) => {
                                          selectedBond.bond_suggested_allocation_percentage =
                                            value.floatValue / 100;
                                          selectedBond.bond_suggested_allocation_dollar =
                                            (value.floatValue / 100) * eqvalue;
                                          checkPercentage();
                                          checkBondPercentage();
                                        }}
                                        suffix={"%"}
                                      />
                                    </td>

                                    <td
                                      style={{
                                        minWidth: "180px",
                                        maxWidth: "180px",
                                      }}
                                    >
                                      {" "}
                                      {headdollar &&
                                      selectedBond?.bond_suggested_allocation_dollar ? (
                                        <NumberFormat
                                          className="green-text text-right"
                                          defaultValue={
                                            selectedBond.bond_suggested_allocation_dollar
                                              ? parseFloat(
                                                  selectedBond.bond_suggested_allocation_dollar
                                                    .toString()
                                                    .replaceAll("$", "")
                                                    .replaceAll(",", "")
                                                ).toFixed(1)
                                              : ""
                                          }
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          onValueChange={(value) => {
                                            selectedBond.bond_suggested_allocation_dollar =
                                              value.floatValue.toFixed(2);
                                            selectedBond.bond_suggested_allocation_percentage =
                                              (
                                                value.floatValue / eqvalue
                                              ).toFixed(2);
                                            checkPercentage();
                                            checkBondPercentage();
                                          }}
                                          prefix="$"
                                        />
                                      ) : (
                                        0
                                      )}
                                    </td>

                                    <td
                                      style={{
                                        minWidth: "180px",
                                        maxWidth: "180px",
                                      }}
                                    >
                                      {headpercentage && (
                                        <NumberFormat
                                          className="green-text text-right"
                                          defaultValue={(
                                            selectedBond.bond_suggested_allocation_percentage *
                                            100
                                          ).toFixed(1)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          onValueChange={(value) => {
                                            selectedBond.bond_suggested_allocation_percentage =
                                              value.floatValue / 100;
                                            selectedBond.bond_suggested_allocation_dollar =
                                              (value.floatValue / 100) *
                                              eqvalue;
                                            checkPercentage();
                                            checkBondPercentage();
                                          }}
                                          suffix={"%"}
                                        />
                                      )}
                                    </td>
                                    {/* <td>
                            <NumberFormat
                              thousandSeparator={false}
                              value={
                                bondDollar >= 0 ? bondDollar.toFixed(1) : 0
                              }
                              displayType="text"
                              prefix="$"
                            />
                          </td> */}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </ModalHeader>
                        <div className="p-3 h_350">
                          <div className="table_shadow">
                            <Table hover responsive className="">
                              <CustomTableHeader
                                tableColumns={assetBondTableColumns}
                              />
                              <tbody>
                                <Fragment>
                                  {selectedBond.bond_data_list ? (
                                    selectedBond.bond_data_list.map(
                                      (bond, index) => {
                                        // console.log(index,'bbbbbbbbbbb')
                                        //const isEventSelected = selectedfund.includes(record.bond_id);
                                        const {
                                          bond_taken,
                                          bond_name,
                                          bond_isin,
                                          bond_currency,
                                          bond_suggested_allocation_dollar,
                                          bond_rank,
                                          bond_suggested_allocation_percentage,
                                          bond_current_price,
                                          bond_coupon,
                                          bond_bloomberg_composite_ranking,
                                          bond_yield_worst,
                                          bond_sector,
                                          bond_modified_duration,
                                          bond_maturity_date,
                                          bond_call_date,
                                          bond_investment_geography,
                                          bond_industry_focus,
                                        } = bond;
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {" "}
                                              {checkBoxModify == true && (
                                                <input
                                                  type="checkbox"
                                                  name="status"
                                                  defaultChecked={
                                                    bond_taken == 1
                                                      ? true
                                                      : false
                                                  }
                                                  value={1}
                                                  onChange={(e) => {
                                                    bond.bond_taken =
                                                      bond.bond_taken ? 0 : 1;
                                                    SingleCheckBondPercentage();
                                                    setBondSector(bond.bond_industry_focus)
                                                  }}
                                                />
                                              )}
                                            </td>
                                            <td>{bond_name}</td>
                                            <td>{bond_isin}</td>
                                            <td>{bond_currency}</td>
                                            <td
                                              style={{
                                                minWidth: "180px",
                                                maxWidth: "180px",
                                              }}
                                            >
                                              {" "}
                                              {changedDollar &&
                                              bond_taken == 1 ? (
                                                <NumberFormat
                                                  className="green-text text-right"
                                                  defaultValue={
                                                    bond_suggested_allocation_dollar
                                                      ? parseFloat(
                                                          bond_suggested_allocation_dollar
                                                            .toString()
                                                            .replaceAll("$", "")
                                                            .replaceAll(",", "")
                                                        ).toFixed(1)
                                                      : ""
                                                  }
                                                  displayType={types}
                                                  thousandSeparator={true}
                                                  setAllocationBond={
                                                    setAllocationBond
                                                  }
                                                  onValueChange={(value) => {
                                                    
                                                    bond.bond_suggested_allocation_dollar =
                                                      value.floatValue;
                                                    bond.bond_suggested_allocation_percentage =
                                                      value.floatValue /
                                                      eqvalue;
                                                    setchangedpercentage(null);
                                                    checkBondPercentage();
                                                    setheaddollar(null);
                                                    setheadpercentage(null);

                                                    //setchangedpercentage(1);
                                                  }}
                                                  prefix="$"
                                                />
                                              ) : (
                                                0
                                              )}
                                            </td>

                                            <td
                                              style={{
                                                minWidth: "180px",
                                                maxWidth: "180px",
                                              }}
                                            >
                                              {changedpercentage &&
                                              bond_taken == 1 ? (
                                                <NumberFormat
                                                  className="green-text text-right"
                                                  defaultValue={(
                                                    parseFloat(
                                                      bond_suggested_allocation_percentage
                                                    ) * 100
                                                  ).toFixed(1)}
                                                  displayType={types}
                                                  setAllocationBond={
                                                    setAllocationBond
                                                  }
                                                  thousandSeparator={true}
                                                  onValueChange={(value) => {
                                                    bond.bond_suggested_allocation_percentage =
                                                      value.floatValue / 100;
                                                    bond.bond_suggested_allocation_dollar =
                                                      value.floatValue
                                                        ? (
                                                            (value.floatValue /
                                                              100) *
                                                            eqvalue
                                                          ).toFixed(2)
                                                        : 0;
                                                    setheaddollar(null);
                                                    setheadpercentage(null);
                                                    setchangedDollar(null);
                                                    checkBondPercentage();
                                                  }}
                                                  suffix={"%"}
                                                />
                                              ) : (
                                                0
                                              )}
                                            </td>
                                            <td>{bond_rank} </td>
                                            <td>{bond_current_price}</td>
                                            <td>{bond_coupon}</td>
                                            <td>{bond_yield_worst} </td>
                                            <td> {parseFloat(bond_modified_duration).toFixed(2)} </td>
                                            <td> {bond_maturity_date} </td>
                                            <td>{bond_call_date}</td>
                                            <td>
                                              {bond_bloomberg_composite_ranking}
                                            </td>
                                            <td>{bond_investment_geography}</td>
                                            <td>{bond_industry_focus}</td>
                                          </tr>
                                        ) 
                                      }
                                    )
                                  ) : (
                                    <CustomEmptyTableRecord
                                      columnsCount={
                                        assetBondTableColumns.length
                                      }
                                    />
                                  )}
                                </Fragment>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter className="footerwidth">
                        {allocationBond == false && (
                          <Button
                            color="secondary"
                            className="bond-modify"
                            onClick={() => {
                              setAllocationBond(true);
                              setShowSelectAll(true);
                              setCheckBoxModify(true);
                            }}
                          >
                            Modify
                          </Button>
                        )}
                        {allocationBond == true && (
                          <>
                            {totalpercentage * 100 < 100 && (
                              <Button
                                className="Bond-save"
                                color="primary"
                                onClick={() => {
                                  {
                                    assetData[selectedBondIndex] = selectedBond;
                                    setModals(false);
                                    setAllocationBond(false);
                                    setCheckBoxModify(false);
                                    //setAllocation(true);
                                    checkPercentage();
                                    setchangedpercentage(null);
                                    setchangedDollar(null);
                                    saveBondPercentage();
                                    setShowSelectAll(false);
                                  }
                                }}
                              >
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11.867"
                                  height="15.823"
                                  viewBox="0 0 11.867 15.823"
                                >
                                  <path
                                    id="Icon_awesome-file-pdf"
                                    data-name="Icon awesome-file-pdf"
                                    d="M5.621,7.915A2.982,2.982,0,0,1,5.56,6.465C5.819,6.465,5.795,7.605,5.621,7.915ZM5.569,9.373a14.26,14.26,0,0,1-.878,1.938,11.38,11.38,0,0,1,1.944-.677A4,4,0,0,1,5.569,9.373ZM2.661,13.23c0,.025.408-.167,1.079-1.242A4.271,4.271,0,0,0,2.661,13.23Zm5-8.285h4.2V15.081a.74.74,0,0,1-.742.742H.742A.74.74,0,0,1,0,15.081V.742A.74.74,0,0,1,.742,0H6.923V4.2A.744.744,0,0,0,7.664,4.945Zm-.247,5.309A3.1,3.1,0,0,1,6.1,8.591a4.442,4.442,0,0,0,.192-1.984A.774.774,0,0,0,4.812,6.4a5.139,5.139,0,0,0,.25,2.38A29.019,29.019,0,0,1,3.8,11.428s0,0-.006,0c-.838.43-2.275,1.375-1.684,2.1a.96.96,0,0,0,.664.309c.553,0,1.1-.556,1.888-1.91A17.615,17.615,0,0,1,7.1,11.215a4.683,4.683,0,0,0,1.978.6.8.8,0,0,0,.609-1.341c-.43-.42-1.678-.3-2.275-.223Zm4.234-7.009L8.622.216A.741.741,0,0,0,8.1,0H7.911V3.956h3.956V3.767A.739.739,0,0,0,11.651,3.245Zm-2.29,7.89c.127-.083-.077-.368-1.323-.278C9.185,11.345,9.361,11.135,9.361,11.135Z"
                                    fill="#fff"
                                  />
                                </svg>
                                Save
                              </Button>
                            )}
                          </>
                        )}
                        <Button
                          className="Bond-cancel"
                          color="primary"
                          onClick={() => {
                            // setAllocationBond(false);
                            // setCheckBoxModify(false);
                            // setShowSelectAll(false);
                            setModals(false);
                            ///checkPercentage();
                          }}
                        >
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.867"
                            height="15.823"
                            viewBox="0 0 11.867 15.823"
                          >
                            <path
                              id="Icon_awesome-file-pdf"
                              data-name="Icon awesome-file-pdf"
                              d="M5.621,7.915A2.982,2.982,0,0,1,5.56,6.465C5.819,6.465,5.795,7.605,5.621,7.915ZM5.569,9.373a14.26,14.26,0,0,1-.878,1.938,11.38,11.38,0,0,1,1.944-.677A4,4,0,0,1,5.569,9.373ZM2.661,13.23c0,.025.408-.167,1.079-1.242A4.271,4.271,0,0,0,2.661,13.23Zm5-8.285h4.2V15.081a.74.74,0,0,1-.742.742H.742A.74.74,0,0,1,0,15.081V.742A.74.74,0,0,1,.742,0H6.923V4.2A.744.744,0,0,0,7.664,4.945Zm-.247,5.309A3.1,3.1,0,0,1,6.1,8.591a4.442,4.442,0,0,0,.192-1.984A.774.774,0,0,0,4.812,6.4a5.139,5.139,0,0,0,.25,2.38A29.019,29.019,0,0,1,3.8,11.428s0,0-.006,0c-.838.43-2.275,1.375-1.684,2.1a.96.96,0,0,0,.664.309c.553,0,1.1-.556,1.888-1.91A17.615,17.615,0,0,1,7.1,11.215a4.683,4.683,0,0,0,1.978.6.8.8,0,0,0,.609-1.341c-.43-.42-1.678-.3-2.275-.223Zm4.234-7.009L8.622.216A.741.741,0,0,0,8.1,0H7.911V3.956h3.956V3.767A.739.739,0,0,0,11.651,3.245Zm-2.29,7.89c.127-.083-.077-.368-1.323-.278C9.185,11.345,9.361,11.135,9.361,11.135Z"
                              fill="#fff"
                            />
                          </svg>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </div>
                  </Modal>
                </Fragment>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>

  );
}
