import React from "react";
import { Route, Redirect } from "react-router-dom";
import { SIGNIN_ROUTE } from "./RoutePaths";

// United States dollar (USD)
// Euro (EUR)
// Pound sterling (GBP)
// Japanese yen (JPY)
// Australian dollar (AUD)
// New Zealand dollar (NZD)
// Canadian dollar (CAD)
// Swiss franc (CHF)
// Norwegian krone (NOK)
// Swedish krona (SEK)

export const g10Currencies = [
  "USD",
];

export function logOutFun(history) {
  localStorage.removeItem("AUTH_TOKEN");
  history.push(SIGNIN_ROUTE);
  console.log(SIGNIN_ROUTE,'llllllllllllllllllllll')
}

export function numToPercentage(number) {
  if (number !== "") {
    let per = (number * 100).toFixed(2);
    return per !== "0.00" ? per + "%" : "0%";
  } else {
    return number;
  }
}
export function numToDollar(number) {
  let per = (number * 0.013378).toFixed(2); // 1 INR = 0.0133780 USD
  return "$" + per;
}
export function numToDigit(number) {
  let per = (number * 1).toFixed(2); // 1 INR = 0.0133780 USD
  return per;
}

export function getSelectedClientID() {
  const clientId = localStorage.getItem("SELECTED_CLIENT_ID");
  return clientId;
}

export function getRole() {
  const role = localStorage.getItem("ROLE");
  return role;
}

export function getProfileName() {
  const profileName = localStorage.getItem("PROFILE_NAME");
  return profileName;
}

export function authHeader() {
  const token = localStorage.getItem("AUTH_TOKEN");
  return { Authorization: "Bearer" + token };
}

export function authfileHeader() {
  const token = localStorage.getItem("AUTH_TOKEN");
  return {
    Authorization: "Bearer" + token,
    "Content-Type": "multipart/form-data",
  };
}

export function getRmID() {
  const RmId = localStorage.getItem("RMID");
  return RmId;
}
export function getDisplayName() {
  const displayName = localStorage.getItem("DISPLAY_NAME");
  return displayName; 
}
export function getMail() {
  const mail = localStorage.getItem("MAIL");
  console.log(mail,'localStorage.getItem("MAIL");localStorage.getItem("MAIL");')
  return mail;
}
export function getProfileImage() {
  const image = localStorage.getItem("PROFILE_IMAGE");
  return image;
}
export function getPhoneNumber() {
  const mobile = localStorage.getItem("MOBILE_NUMBER");
  return mobile;
}
export function PublicRoute({ component, ...rest }) {
  const token = localStorage.getItem("AUTH_TOKEN");
  if (!token) return <Route {...rest} component={component} />;
  else return <Redirect to={{ pathname: "/" }} />;
}
export function PrivateRoute({ component, ...rest }) {
  const token = localStorage.getItem("AUTH_TOKEN");
  if (token) return <Route {...rest} component={component} />;
  else return <Redirect to={"/signin"} />;
}

export const getAlertToast = (type = "success", text = "", timer = 5000) => ({
  toast: true,
  position: "bottom",
  titleText: text,
  type: type,
  showConfirmButton: false,
  timer: timer,
});

export const getAlert = (type = "success", text = "") => ({
  position: "center",
  type: type,
  text: text,
  customClass: {
    confirmButton: "btn-shadow btn btn-primary btn-lg",
  },
  buttonsStyling: false,
  confirmButtonColor: "#000",
});

export const getConfirm = (
  type = "success",
  text = "",
  confirmButtonText = "Yes"
) => ({
  text: text,
  type: type,
  showCancelButton: true,
  confirmButtonColor: "000", //11111111111111111111111
  cancelButtonColor: "#939392",
  confirmButtonText: confirmButtonText,
});
