import React, { Fragment, useState, useEffect, useRef } from "react";
import Sidebar from "../layout/Sidebar";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  UncontrolledDropdown,
  InputGroupAddon,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { AiOutlinePlusCircle, AiOutlineBell } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ADD_NEW_USER } from "../common/RoutePaths";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import { useDispatch } from "react-redux";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getRmID,
  getDisplayName,
  getProfileImage,
  getProfileName,
  getRole,
  logOutFun,
} from "../common/Mainfunctions";
import { FormGroup } from "@material-ui/core";
import { FaEdit, FaTrash } from "react-icons/fa";
import { RM_DELETE_URL, RM_LIST_URL, PERMISSION } from "../common/urls";
import API from "../redux/API";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomTableHeader from "../common/CustomTableHeader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import { debounce } from "underscore";
import Swal from "sweetalert2";
import EditSuperAdmin from "./EditSuperAdmin";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { getByRole } from "@testing-library/react";
export default function User(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const [clist, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [edituserModal, setEditUserModal] = useState(false);
  const selectUserToggle = () => setEditUserModal(!edituserModal);
  const [userId, setUserId] = useState();
  const [permission, setpermission] = useState("");
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [profile, setProfile] = useState();
  const history = useHistory();
  const [userData, setUserData] = useState(0);

  const usertableColumns = [
    { title: "S.No" },
    { title: "Email" },
    { title: "Role" },
    { title: "Actions" },
  ];
  useEffect(() => {
    console.log(params, "hhhhhhhhhhhh");
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const getClientList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: RM_LIST_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      setList(data);
      console.log(data,'setListsetListsetListsetListsetListsetList')
      setTotalRecords(totalrecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onHandleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const delaySearch = useRef(
    debounce((search) => getClientList(search), 500)
  ).current;

  useEffect(() => {
    delaySearch(search);
  }, [search]);

  useEffect(() => {
    console.log(
      permission.user_permission,
      "iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii"
    );
  }, [permission]);

  const onPageChange = (page) => {
    //setCurrentPage(page - 1);
    history.push(`/user-management/${page - 1}`);
  };

  const onClearfun = (e) => {
    setSearch("");
  };
  const logOutClick = () => {
    logOutFun(history);
  };

  useEffect(() => {
    //console.log(currentpage,'eeeeeeeeeee')
    getClientList();
    setUserData(currentpage);
  }, [currentpage]);

  const deleteUser = async (user_id) => {
    const config = {
      method: "DELETE",
      url: RM_DELETE_URL + "/" + user_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      getClientList();
      Swal.fire(getAlertToast("Success", "User deleted Successfully"));
    } catch (error) {
      let { message = "" } = error;
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (e) => {
    e.stopPropagation();
    console.log(e, "wwwwwwwwwwwwwwwwwwwwwwwwwww");
    let user_id = parseInt(e.currentTarget.dataset.id);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteUser(user_id);
      }
    });
  };
  const editRecord = (e) => {
    const { id, email, role, profile } = e.currentTarget.dataset;
    console.log(profile,'profileprofileprofileprofileprofileprofile')
    setUserId(id);
    setEmail(email);
    setRole(role);
    setProfile(profile);

    setEditUserModal(true);
  };
  const submitCallBack = () => {
    setEditUserModal(false);
    getClientList();
  };

  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);
  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper">
          <div className="bg_f2 pt-3 pb-3 pl-3 pr-3">
            <Row>
              <Col sm={4}>
                <div className="btn-right">
                  <FormGroup>
                    <Input
                      type="text"
                      name="content"
                      value={search}
                      placeholder="search"
                      onChange={onHandleChange}
                    />
                  </FormGroup>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="searchBtnTable"
                      color="link"
                      onClick={onClearfun}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.415"
                        height="8.415"
                        viewBox="0 0 8.415 8.415"
                      >
                        <path
                          d="M16.415,8.736,15.679,8l-3.471,3.471L8.736,8,8,8.736l3.471,3.471L8,15.679l.736.736,3.471-3.471,3.471,3.471.736-.736-3.471-3.471Z"
                          transform="translate(-8 -8)"
                          fill="#c43f57"
                        />
                      </svg>
                    </Button>
                  </InputGroupAddon>
                </div>
              </Col>
              <Col sm={8} className="text-sm-right mt-3 mt-sm-0">
                <UncontrolledDropdown
                  setActiveFromChild
                  className="d-inline-block mb-3 mb-sm-0"
                >
                  <DropdownToggle tag="a" className="nav-link color_ac" caret>
                    <img
                      className="avatar"
                      // src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      src={
                        getProfileImage() ||
                        "https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                      }
                    />{" "}
                    <span>{getDisplayName()}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag="a"
                      onClick={logOutClick}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {(permission.user_permission == "super_admin" ||
                  permission.user_permission == "rm") && (
                  <Button color="primary">
                    <AiOutlinePlusCircle className="mr-2"></AiOutlinePlusCircle>
                    <Link className="cus-link" to={ADD_NEW_USER}>
                      Add New User
                    </Link>
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <Container fluid className="">
            <Card className="min-screenheight st_card">
              <CardTitle className="p-0 mt-4">
                <span className="list_cli">User Management</span>
              </CardTitle>

              <Table hover responsive>
                <CustomTableHeader tableColumns={usertableColumns} />
                <tbody>
                  {!loading ? (
                    <Fragment>
                      {clist && clist.length > 0 ? (
                        clist.map((record, index) => {
                          const { email, role, id, profile_picture } = record;
                          console.log(clist,'clistclistclistclistclistclist')
                          return (
                            <Fragment key={`client_key_${index}`}>
                              <tr>
                                <td>
                                  {rowperpage * currentpage + (index + 1)}
                                </td>
                                <td>{email}</td>
                                <td>
                                  {role == "superadmin"
                                    ? "Super Admin"
                                    : role == "admin"
                                    ? "Admin"
                                    : role == "rm"
                                    ? "RM"
                                    : role =="arm" ? "ARM":role}
                                </td>

                                <td>
                                  {getRole() == "superadmin" ? (
                                    <Button
                                      data-id={id}
                                      data-email={email}
                                      data-role={role}
                                      data-profile={profile_picture}
                                      color=""
                                      size="sm"
                                      className="actionBtn"
                                      onClick={editRecord}
                                    >
                                      <FaEdit></FaEdit>
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  <Button
                                    data-id={id}
                                    color=""
                                    size="sm"
                                    className="actionBtn"
                                    onClick={onClickDelete}
                                  >
                                    <FaTrash></FaTrash>
                                  </Button>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })
                      ) : (
                        <CustomEmptyTableRecord
                          columnsCount={usertableColumns.length}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <CustomTableLoader columnsCount={usertableColumns.length} />
                  )}
                </tbody>
              </Table>

              <div className="d-flex flex-row py-4 justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={currentpage + 1}
                  itemsCountPerPage={rowperpage}
                  totalItemsCount={totalrecords}
                  onChange={onPageChange}
                />
              </div>
              <div>
                {edituserModal && (
                  <EditSuperAdmin
                    edituserModal={edituserModal}
                    selectUserToggle={selectUserToggle}
                    userId={userId}
                    email={email}
                    role={role}
                    profile={profile}
                    submitCallBack={submitCallBack}
                  />
                )}
              </div>
            </Card>
          </Container>
        </div>
      </Fragment>
    </div>
  );
}
