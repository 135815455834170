import Axios from "axios";

const API = Axios.create();
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    
    if(error?.response){
      const { status } = error;
      if (status === 401) {
        localStorage.clear();
        if (!window.location.href.includes('signin')) {
          window.location.replace('/signin');
        }
      }
    
      return Promise.reject(error.response.data);
    }else{
      return Promise.reject({"error":"cancelled"});
    }
  }
);
export default API;
