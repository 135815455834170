import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Slider from "@material-ui/core/Slider";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  ADMIN_VARIABLES_LIST_URL,
  ADMIN_VARIABLE_STORE_URL,
} from "../common/urls";
import { authHeader, getAlert, getAlertToast } from "../common/Mainfunctions";
import API from "../redux/API";
import { initial } from "underscore";
import Swal from "sweetalert2";
import CustomLoader from "../common/CustomLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function AdminstratorLevelVariables() {
  const marks = [
    {
      value: 3,
      label: "3%",
    },

    {
      value: 12,
      label: "12%",
    },
  ];
  const marks1 = [
    {
      value: -0,
      label: "-0%",
    },

    {
      value: -25,
      label: "-25%",
    },
  ];
  function valuetext(value) {
    return `${value}°C`;
  }

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelecteDate] = useState();
  const [formData, setFormData] = useState({
    expected_annual_return_min: "",
    expected_annual_return_max: "",
    max_drawdown_min: "",
    max_drawdown_max: "",
    performance_fee: "",
    management_fee: "",
    time_window_start: "",
  });
  const adminVariablesList = async () => {
    const config = {
      method: "GET",
      url: ADMIN_VARIABLES_LIST_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data, status } = response.data;
      const { time_window_start } = data;
      setFormData(data);
      const format = new Date(time_window_start);
      setSelecteDate(format);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    adminVariablesList();
  }, []);
  const validationSchema = Yup.object({
    performance_fee: Yup.string()
      .trim()
      .required("Performance fee is required"),
    management_fee: Yup.string().trim().required("Management fee is required"),
    time_window_start: Yup.string().trim().required("Start is required"),
  });
  const saveFormValues = async (values) => {
    // values.time_window_start = selectedDate;
    const {
      expected_annual_return_max,
      expected_annual_return_min,
      management_fee,
      max_drawdown_max,
      max_drawdown_min,
      performance_fee,
      time_window_start,
    } = values;

    setLoading(true);
    const config = {
      method: "POST",
      url: ADMIN_VARIABLE_STORE_URL,
      headers: authHeader(),
      data: {
        expected_annual_return_max: expected_annual_return_max
          ? expected_annual_return_max
          : "12",
        expected_annual_return_min: expected_annual_return_min
          ? expected_annual_return_min
          : "3",
        management_fee: management_fee,
        max_drawdown_max: max_drawdown_max ? max_drawdown_max : "-25",
        max_drawdown_min: max_drawdown_min ? max_drawdown_min : "-3",
        performance_fee: performance_fee,
        time_window_start: time_window_start,
      },
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status == "Success") {
        Swal.fire(
          getAlertToast("Success", "AdminstratorLevel Variables Updated")
        );
      } else {
        Swal.fire(getAlert("Error", "Failed to update"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: formData,
    enableReinitialize: true,

    validationSchema,
    onSubmit: (values) => {
      saveFormValues(values);
    },
  });

  var currentTime = new Date();
  var maxDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), -1);
  console.log("min", maxDate);

  const handleDateChange = (e) => {
    console.log(e);
    const d = e.getMonth();
    const month = d + 1;
    console.log(d + 1);
    setSelecteDate(e);
    setFormData({
      ...formData,
      time_window_start: e.getFullYear() + "-" + month + "-" + e.getDate(),
    });

    console.log(e.getFullYear() + "-" + month + "-" + e.getDate());
    // handleChange(e);
  };
  return (
    <Fragment>
      <Form form="true" onSubmit={handleSubmit}>
        <Row>
          <Col md="12" md={{ size: 6, offset: 3 }}>
            {/* <FormGroup>
              <Label for="exampleSelect">Select Benchmarks</Label>
              <Input type="select" name="select" id="exampleSelect">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup> */}
            <Label for="expected_annual_return_min">
              Expected Annual Return
            </Label>

            <Row>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="expected_annual_return_min"
                      onChange={handleChange}
                      value={values.expected_annual_return_min}
                      placeholder="0"
                      invalid={
                        errors.expected_annual_return_min &&
                        touched.expected_annual_return_min
                          ? true
                          : false
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Min (%)</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="expected_annual_return_max"
                      onChange={handleChange}
                      value={values.expected_annual_return_max}
                      placeholder="100"
                      invalid={
                        errors.expected_annual_return_max &&
                        touched.expected_annual_return_max
                          ? true
                          : false
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Max (%)</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormFeedback>
                    {errors.expected_annual_return_max &&
                    touched.expected_annual_return_max
                      ? errors.expected_annual_return_max
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Label for="exampleSelect">Max Drawdown</Label>

            <Row>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="max_drawdown_min"
                      onChange={handleChange}
                      value={values.max_drawdown_min}
                      placeholder="0"
                      invalid={
                        errors.benchmark1 && touched.benchmark1 ? true : false
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Min (%)</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="max_drawdown_max"
                      onChange={handleChange}
                      value={values.max_drawdown_max}
                      placeholder="100"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>Max (%)</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormFeedback>
                    {errors.benchmark2 && touched.benchmark2
                      ? errors.benchmark2
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="performance_fee">Performance Fee</Label>
              <Input
                type="text"
                name="performance_fee"
                id="performance_fee"
                placeholder="Enter Performance Fee"
                value={values.performance_fee}
                onChange={handleChange}
                invalid={
                  errors.performance_fee && touched.performance_fee
                    ? true
                    : false
                }
              />
              <FormFeedback>
                {errors.performance_fee && touched.performance_fee
                  ? errors.performance_fee
                  : ""}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="management_fee">Management Fee</Label>
              <Input
                type="text"
                name="management_fee"
                id="management_fee"
                placeholder="Enter Management Fee"
                value={values.management_fee}
                onChange={handleChange}
                invalid={
                  errors.management_fee && touched.management_fee ? true : false
                }
              />
              <FormFeedback>
                {errors.management_fee && touched.management_fee
                  ? errors.management_fee
                  : ""}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="time_window_start">Start Date</Label>
              <Col md="12">
                <DatePicker
                  selected={selectedDate}
                  maxDate={maxDate}
                  name="time_window_start"
                  id="time_window_start"
                  onSelect={handleDateChange}
                  // onChange={handleChange}
                  invalid={
                    errors.time_window_start && touched.time_window_start
                      ? true
                      : false
                  }
                  // value={values.time_window_start}
                />
              </Col>
              {/* <Input
                type="date"
                name="time_window_start"
                id="time_window_start"
                placeholder="date placeholder"
                // minDate="01/01/2007"
                max={maxDate}
                value={values.time_window_start}
                onChange={handleChange}
                invalid={
                  errors.time_window_start && touched.time_window_start
                    ? true
                    : false
                }
              /> */}
              <FormFeedback>
                {errors.time_window_start && touched.time_window_start
                  ? errors.time_window_start
                  : ""}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary" className="d-block m-auto" type="submit">
          {loading && <CustomLoader loading={loading} />}
          Update
        </Button>
      </Form>
    </Fragment>
  );
}
