import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, CardTitle, Button, Table } from "reactstrap";
import CustomTableHeader from "../../common/CustomTableHeader";
import CustomTableLoader from "../../common/CustomTableLoader";
import CustomEmptyTableRecord from "../../common/CustomEmptyTableRecord";
import { numToPercentage, numToDollar } from "../../common/Mainfunctions";
import NumberFormat from "react-number-format";
import API from "../../redux/API";
import Swal from "sweetalert2";
import { SAVE_ALLOCATION } from "../../common/urls";

export default function Stocks(props) {
  const {
    loading,
    assetData,
    stockData,
    fundData,
    lastupdated,
    allocation,
    porfolioId,
    setmorethanhundred,
    setdollarmorethanhundred,
    eqvalue,
    setpercentagestock,
    setdollarstock,
    groupselected,
    setselectedbondid,
    recommenedStockList,
    Flags,
  } = props;
  const [type, settype] = useState("");
  const [totaldollar, settotaldollar] = useState(0);
  const [showStockData, setShowStockData] = useState(false);
  const [totalpercentage, settotalpercentage] = useState(0);
  const [changedpercentage, setchangedpercentage] = useState(1);
  const [selectedfund, setselectedfund] = useState([]);
  const [lowestValue, setLowestValue] = useState("0.1");
  const [changedDallar, setchangedDallar] = useState(1);
  const getAl = (type = "success", text = "", timer = 5000) => ({
    toast: true,
    position: "bottom",
    titleText: text,
    type: type,
    showConfirmButton: false,
    timer: timer,
  });

  const tableColumns = [
    { title: "Name of Stocks" },
    { title: "Bloomberg Ticker" },
    { title: "Currency" },
    { title: "Suggested Allocation ($)" },
    { title: "Suggested Allocation (%)" },
    { title: "Current Price" },
    { title: "LC Target Price (next 12 months)" },
    { title: "Upside Potential (%)" },
    { title: "Dividend Yield" },
    { title: "Geography" },
    { title: "Sector" },
  ];

  useEffect(() => {
    if (allocation == false) {
      settype("text");
    } else {
      settype("input");
    }
  }, [allocation]);

  useEffect(() => {
    if (Flags == false) {
      settype("text");
      // setAllocation(false);
      // setAllocationBond(false);
    } else {
      settype("input");
      // setAllocation(true);
      // setAllocationBond(true);
    }
  }, [Flags]);



  //   useEffect(()=>{
  //   const merged = recommenedStockList.reduce((arr, item) => {
  //     setShowStockData(true);
  //     arr.push(item);
  //     return arr;
  // }, stockData);
  // },[recommenedStockList]);

  // useEffect(()=>{
  //   stockData.push(...recommenedStockList)
  // },[recommenedStockList]);

  const checkPercentage = () => {
    let tot = 0;
    let doll = 0;
    stockData.map((record, index) => {
      console.log(record,"aaaaaaaaaaaaaaaaaaaaaa")
      if(record.stock_taken==1){
      tot += record.stock_suggested_allocations_percentage
        ? parseFloat(record.stock_suggested_allocations_percentage)
        : 0;
      doll += record?.stock_suggested_allocation_dollar
        ? parseFloat(
            record?.stock_suggested_allocation_dollar
              .toString()
              .replaceAll("$", "")
              .replaceAll(",", "")
          )
        : 0;
      };
    });
    setpercentagestock(tot);
    setdollarstock(doll);
    //alert(tot)
    if (tot * 100 > 100) {
      setmorethanhundred(true);
    } else {
      setmorethanhundred(false);
    }
    if (doll > eqvalue) {
      setdollarmorethanhundred(true);
    }
  };
  useEffect(() => {
    console.log(stockData,'ccccccccccccccccccccccccccccc')
    checkPercentage();
  }, [stockData]);

  useEffect(() => {
    console.log(recommenedStockList,'recommenedStockListrecommenedStockList')
    if(recommenedStockList){
      checkPercentage();
    }
    // const merged = recommenedStockList?.reduce((arr, item) => {
    //   setShowStockData(true);
    //   const index = stockData.findIndex(
    //     (el) => el.stock_name == item.stock_name
    //   );
    //   if (index == -1) {
    //     arr.push(item);
    //   }
    //   return arr;
    // }, stockData);
  }, [recommenedStockList]);
  
  useEffect(() => {
    setselectedbondid(selectedfund);
  }, [selectedfund]);

  useEffect(() => {
    let tot = 0;
    let doll = 0;
    stockData.map((record, index) => {
      // console.log( record.stock_suggested_allocations_percentage, "ffff44444444444444444444"  );
      tot += parseFloat(record.stock_suggested_allocations_percentage)
        ? parseFloat(record.stock_suggested_allocations_percentage)
        : 0;
      doll += record?.stock_suggested_allocation_dollar
        ? parseFloat(
            record?.stock_suggested_allocation_dollar
              .toString()
              .replaceAll("$", "")
              .replaceAll(",", "")
          )
        : 0;
      

      //console.log(record?.bond_suggested_allocation_dollar,record?.bond_suggested_allocation_dollar.toString().replaceAll('$',''),"kkkkkkkkk")
    });

    setpercentagestock(tot);
    setdollarstock(doll);

    //console.log(tot, "1111111111111111111111111");
    //console.log(doll, "222222222222222222");
  }, [stockData]);

  /* Used To Rerender Component */
  useEffect(() => {
    if (changedpercentage == null) {
      setchangedpercentage(1);
    }
  }, [changedpercentage]);

  const handleSelectEvent = (selectedid) => {
    if (!selectedfund.includes(selectedid)) {
      setselectedfund((prevSelected) => [...prevSelected, selectedid]);
    } else {
      setselectedfund((prevSelected) =>
        prevSelected.filter((id) => id !== selectedid)
      );
    }
  };
  useEffect(() => {
    if (changedDallar == null) {
      setchangedDallar(1);
    }
  }, [changedDallar]);
  return (
    <div>
      <Row className="mt-4">
        <Col xl="12">
          <div className="margin_minus stock_div">
            <Table hover responsive className="break_table stock_scroll">
              <thead>
                <tr>
                  {groupselected == true && (
                    <th style={{ minWidth: "100px", maxWidth: "100%" }}>
                      Select
                    </th>
                  )}
                  <th style={{ minWidth: "190px", maxWidth: "100%" }}>
                    Name of Stocks
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Bloomberg Ticker
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Currency
                  </th>
                  <th style={{ minWidth: "180px", maxWidth: "100%" }}>
                    Suggested Allocation $
                  </th>
                  <th style={{ minWidth: "180px", maxWidth: "100%" }}>
                    Suggested Allocation %
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Current Price
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    LC Target Price (next 12 months)
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Upside Potential (%)
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Dividend Yield
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Geography
                  </th>
                  <th style={{ minWidth: "120px", maxWidth: "100%" }}>
                    Sector
                  </th>
                </tr>
              </thead>

              <tbody className="pr-1 d-block fix_auto">
                {!loading ? (
                  <Fragment>
                    {stockData.length > 0 ? (
                      stockData.map((record, index) => {
                        const isEventSelected = selectedfund.includes(
                          record.stock_id
                        );
                        const {
                          stock_name,
                          stock_bloomberg_ticker,
                          stock_currency,
                          stock_suggested_allocation_dollar,
                          stock_suggested_allocations_percentage,
                          stock_current_price,
                          stock_lc_current_price,
                          stock_upside_potential,
                          stock_dividend_yield,
                          stock_investment_geography,
                          stock_industry_focus,
                          ustry_focus,
                        } = record;

                        return (
                          <>
                            {record.stock_taken == 1 ? (
                              <tr key={index + 1}>
                                {groupselected == true && (
                                  <td
                                    style={{ minWidth: "100px", Width: "11%" }}
                                  >
                                    <input
                                      type="checkbox"
                                      id="html"
                                      checked={isEventSelected}
                                      onChange={() =>
                                        handleSelectEvent(record.stock_id)
                                      }
                                    />
                                  </td>
                                )}
                                <td style={{ minWidth: "190px", width: "11%" }}>
                                  {stock_name}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {stock_bloomberg_ticker}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {stock_currency}
                                </td>
                                {}{" "}
                                <td style={{ minWidth: "180px", width: "11%" }}>
                                  {" "}
                                  {changedDallar && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={parseFloat(
                                        stock_suggested_allocation_dollar?.toString()
                                          .replaceAll("$", "")
                                          .replaceAll(",", "")
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.stock_suggested_allocation_dollar =
                                          value.floatValue;
                                        record.stock_suggested_allocations_percentage =
                                      (value?.floatValue ? value?.floatValue : 0)/ eqvalue;
                                        setchangedpercentage(null);
                                        checkPercentage();
                                      }}
                                      prefix={"$"}
                                    />
                                  )}
                                </td>
                                <td style={{ minWidth: "180px", width: "11%" }}>
                                  {changedpercentage && (
                                    <NumberFormat
                                      className="green-text text-right"
                                      defaultValue={(
                                        stock_suggested_allocations_percentage *
                                        100
                                      ).toFixed(1)}
                                      displayType={type}
                                      thousandSeparator={true}
                                      onValueChange={(value) => {
                                        record.stock_suggested_allocations_percentage =
                                          value.floatValue / 100;
                                        record.stock_suggested_allocation_dollar =
                                          (value.floatValue / 100) * eqvalue;
                                        //setchangedpercentage(null);
                                        checkPercentage();
                                        setchangedDallar(null);
                                      }}
                                      suffix={"%"}
                                    />
                                  )}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  ${stock_current_price}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  ${stock_lc_current_price}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {(stock_upside_potential * 100).toFixed(1) +
                                    "%"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {stock_dividend_yield
                                    ? parseFloat(stock_dividend_yield).toFixed(
                                        1
                                      ) + "%"
                                    : "-"}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {stock_investment_geography}
                                </td>
                                <td style={{ minWidth: "120px", width: "11%" }}>
                                  {stock_industry_focus}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    ) : (
                      <CustomEmptyTableRecord
                        columnsCount={tableColumns.length}
                      />
                    )}
                  </Fragment>
                ) : (
                  <CustomTableLoader columnsCount={tableColumns.length} />
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
}
